import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import PreviewButtonIcon from "../../assets/PreviewButton.svg";
import EditButtonIcon from "../../assets/EditButton.svg";
import GuestButtonIcon from "../../assets/GuestButton.svg";
import AdminButtonIcon from "../../assets/AdminButton.svg";
import DeleteButtonIcon from "../../assets/DeleteButton.svg";
import StatusButtonIcon from "../../assets/StatusButton.svg";
import CancelButtonIcon from "../../assets/CancelButton.svg";
import TooltipWrapper from "../common/TooltipWrapper";

const listButtonIcons = {
  preview: PreviewButtonIcon,
  edit: EditButtonIcon,
  guest: GuestButtonIcon,
  admin: AdminButtonIcon,
  delete: DeleteButtonIcon,
  cancel: CancelButtonIcon,
  status: StatusButtonIcon
};

const LinkWrapper = ({
  outbound,
  link,
  onClick,
  className,
  children,
  isDisabled,
  title
}) => {
  if (outbound === true) {
    return (
      <TooltipWrapper title={title} show={title && isDisabled}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          className={className}
          data-testid={"link-wrapper"}
        >
          {children}
        </a>
      </TooltipWrapper>
    );
  } else if (link) {
    return (
      <TooltipWrapper title={title} show={title && isDisabled}>
        <Link
          to={link}
          onClick={onClick}
          className={className}
          data-testid={"link-wrapper"}
        >
          {children}
        </Link>
      </TooltipWrapper>
    );
  } else {
    return (
      <TooltipWrapper title={title} show={title && isDisabled}>
        <button
          onClick={onClick}
          className={className}
          data-testid={"link-wrapper"}
        >
          {children}
        </button>
      </TooltipWrapper>
    );
  }
};

LinkWrapper.propTypes = {
  outbound: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any.isRequired
};

function ListButton({
  link,
  outbound = false,
  icon,
  onClick = null,
  children,
  isDisabled,
  title
}) {
  const iconURL = listButtonIcons[icon];
  return (
    <div
      className={`home-list-button ${isDisabled ? "disabled" : ""}`}
      title={icon}
      data-testid="list-button"
    >
      <LinkWrapper
        outbound={outbound}
        link={link}
        onClick={isDisabled ? null : onClick}
        isDisabled={isDisabled}
        className={`home-list-button__inner `}
        title={title}
      >
        <div className="home-list-button__icon">
          <img src={iconURL} alt={icon} />
        </div>
        <div className="home-list-button__label">{children}</div>
      </LinkWrapper>
    </div>
  );
}

ListButton.propTypes = {
  link: PropTypes.string,
  outbound: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool
};

export default ListButton;
