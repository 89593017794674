import React from "react";

function PromptPhotoCanvasLoader() {
  return (
    <div className="photo-canvas-loader">
      <div className="form-input__spinner" />
    </div>
  );
}

export default PromptPhotoCanvasLoader;
