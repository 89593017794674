import React from "react";
import PropTypes from "prop-types";
import GridHeader from "../../GridHeader";
import GridLayout from "../../GridLayout";

import _get from "lodash.get";
import GridItem from "../../GridItem";
import { useSelector } from "react-redux";
import clsx from "clsx";
import TooltipWrapper from "../../../common/TooltipWrapper";
import BlankGridItem from "../../BlankGridItem";
import HostPlaceholder from "../../../../assets/icons/HostPlaceholder.png";
import permissionTypeSelector from "../../../../selectors/permissionTypeSelector";

function AddNewHost({ onNew, currentCategory, isDisabled, disabledMessage }) {
  return (
    <div
      className={clsx({
        "grid-item": true,
        "grid-item--add": true,
        "grid-item--disabled": isDisabled
      })}
    >
      <TooltipWrapper title={disabledMessage} show={isDisabled}>
        <div className="grid-item__inner">
          <button
            className="grid-item__btn"
            disabled={isDisabled}
            onClick={isDisabled ? () => {} : onNew}
          >
            <div className="grid-item__title">
              <h4>Add a {currentCategory === "hosts" ? "host" : "chef"}</h4>
            </div>
          </button>
        </div>
      </TooltipWrapper>
    </div>
  );
}

function HostsAvailableGrid({
  items,
  currentPageHash,
  onDragStop,
  onView,
  onAdd,
  onDelete,
  onNew
}) {
  const isHost = useSelector(state => permissionTypeSelector(state) === "host");

  return (
    <div
      className="guest-services-hosts-available"
      data-testid="guest-services-hosts-available-grid"
    >
      <GridHeader color="gray">{`Add Hosts Below`}</GridHeader>
      <GridLayout
        rows={4}
        compact={true}
        columns={3}
        withAdd={true}
        items={items}
      >
        {({ gridArray }) => (
          <div className="guest-services-available-grid-items">
            {gridArray.map((item, i) => (
              // TODO: Change this back to using unique ID
              <React.Fragment key={_get(item, "id", Math.random())}>
                {i === 0 && (
                  <>
                    <AddNewHost
                      onNew={onNew}
                      currentCategory={"hosts"}
                      isDisabled={isHost ? true : false}
                      disabledMessage={
                        "Hosts can not add or delete other hosts."
                      }
                    />
                  </>
                )}
                {item.id ? (
                  <GridItem
                    photoData={
                      item.image
                        ? item.image.length > 0
                          ? item.image.find(({ size }) => size === "thumb").url
                          : HostPlaceholder
                        : HostPlaceholder
                    }
                    isHost={isHost}
                    currentCategory={"hosts"}
                    id={item.id}
                    title={`${item.first_name} ${item.last_name}`}
                    gridIndex={i}
                    sequence={item.sequence}
                    type="available"
                    currentPageHash={currentPageHash}
                    overlayItems={{
                      available: ["edit", "add", "delete"],
                      selected: ["edit", "remove"]
                    }}
                    onDragStop={(...values) => {
                      onDragStop(...values, "hosts");
                    }}
                    onDelete={values => onDelete(values, "hosts")}
                    onEdit={onView}
                    onAdd={values => onAdd(values, "hosts")}
                  />
                ) : (
                  <BlankGridItem
                    gridIndex={i}
                    sequence={i + 1}
                    key={i}
                    type="available"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </GridLayout>
    </div>
  );
}

HostsAvailableGrid.propTypes = {
  currentPageHash: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.oneOf(["thumb", "tablet", "desktop"]).isRequired,
          url: PropTypes.string.isRequired
        })
      ),
      phone: PropTypes.string,
	  phoneText: PropTypes.string,
      sequence: PropTypes.number,
      website: PropTypes.string
    })
  ),
  onDragStop: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
};

export default HostsAvailableGrid;
