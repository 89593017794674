import React, { useState } from "react";
import AccountPage from "../AccountPage";

import { connect } from "react-redux";
import ButtonMain from "../../common/ButtonMain";
import PromptWrapper from "../../common/PromptWrapper";

import { CSSTransition } from "react-transition-group";
import SpinnerLoader from "../../common/SpinnerLoader";

import _get from "lodash.get";

import { accountOperations } from "../duck";
import { toastOperations } from "../../../duck/toast";

import ChoosePlanPrompt from "../ChoosePlanPrompt";

function CancelPlanPrompt({ onClose, onCancel, loading }) {
  return (
    <PromptWrapper onClose={onClose} className="cancel-plan-prompt-wrapper">
      <div
        className="cancel-plan-prompt-wrapper__inner"
        data-testid="cancel-plan-prompt"
      >
        <h2>Are you sure?</h2>
        <p>
          If you cancel your subscription, all of your current house info will
          be deleted.
        </p>
        <ButtonMain onClick={onCancel} loading={loading} color="red">
          Yes, cancel my current subscription.
        </ButtonMain>
      </div>
    </PromptWrapper>
  );
}

function AccountPageSubscription({
  accountInfo,
  paymentMethods,
  loading,
  loadStatus,
  fetchAvailablePlansLoading,
  changeSubscription,
  addToast,
  changeSubscriptionLoading,
  availablePlans,
  registerSubscription,
  currentDefaultPaymentMethod,
  registerSubscriptionLoading,
  cancelSubscription,
  cancelSubscriptionLoading
}) {
  const hasPlan = accountInfo.plan_name && accountInfo.plan_id;
  const hasPaymentMethods = paymentMethods ? paymentMethods.length > 0 : false;

  const [showChangePlanPrompt, setShowChangePlanPrompt] = useState(false);
  const [showCancelPlanPrompt, setShowCancelPlanPrompt] = useState(false);
  const [showChoosePlanPrompt, setShowChoosePlanPrompt] = useState(false);
  const HeaderNoSubscription = () => (
    <h4 data-testid="header-no-subscription">
      You are not currently subscribed to a plan.
    </h4>
  );

  const HeaderWithSubscription = () => (
    <h4 data-testid="header-with-subscription">
      You are subscribed to the <strong>{accountInfo.plan_name}</strong> Plan
    </h4>
  );

  const handleCancelSubscription = () => {
    setShowCancelPlanPrompt(true);
  };

  const handleChangePlanPrompt = () => {
    setShowChangePlanPrompt(true);
  };

  const handleChoosePlanPrompt = () => {
    setShowChoosePlanPrompt(true);
  };
  const handlePlanCancel = () => {
    cancelSubscription()
      .then(() => {
        addToast({ text: "Subscription cancelled." });
        setShowCancelPlanPrompt(false);
      })
      .catch(error => {
        addToast({ text: error.message, intent: "error" });
      });
  };

  const handlePlanChange = ({ selectedPlan, selectedCycle }) => {
    const selectedPlanObj = availablePlans[selectedCycle].find(
      ({ id }) => id === selectedPlan.id
    );
    if (!selectedPlanObj) {
      addToast({
        text: "Trying to add invalid plan. Please try again.",
        intent: "warning"
      });
    }
    changeSubscription(selectedPlanObj)
      .then(() => {
        addToast({ text: "Succesfully changed subscription plan." });

        setShowChangePlanPrompt(false);
      })
      .catch(err => {
        if (_get(err, "message")) {
          addToast({ text: err.message, intent: "error" });
        } else {
          addToast({
            text:
              "There was an issue changing your subscription - please contact help@homeconcierge.com for further assistance",
            intent: "error"
          });
        }
        // setShowChangePlanPrompt(false);
      });
  };

  const handlePlanChoose = ({ selectedPlan }) => {
    // @TODO: close prompt on success of api call

    registerSubscription(
      selectedPlan.id,
      null,
      currentDefaultPaymentMethod.token
    )
      .then(() => {
        addToast({
          text: "Successfully registered with subscription plan."
        });
        setShowChoosePlanPrompt(false);
      })
      .catch(err => {
        addToast({
          text: err.message
            ? err.message
            : "Issue while registering subscription."
        });
      });
  };

  return (
    <AccountPage page="subscription">
      <div className="account-page__header">
        <h3>Subscription</h3>
      </div>

      <div className="account-page__content">
        <CSSTransition
          in={loading}
          classNames="fade"
          timeout={350}
          mountOnEnter
          unmountOnExit
        >
          <div className="spinner-loader-wrapper" style={{ minHeight: "90px" }}>
            <SpinnerLoader />
          </div>
        </CSSTransition>
        <CSSTransition
          in={!loading}
          timeout={{ in: 350, out: 0 }}
          classNames="fade"
          mountOnEnter
          unmountOnExit
        >
          {loadStatus === "FAILED" ? (
            <p>
              Unable to get current subscription. Please try again later or
              contact{" "}
              <a href="mailto:help@homeconcierge.com">help@homeconcierge.com</a>
            </p>
          ) : (
            <div className="account-page__contentinner">
              {hasPlan ? <HeaderWithSubscription /> : <HeaderNoSubscription />}
              {hasPlan ? (
                <>
                  <ButtonMain onClick={handleChangePlanPrompt} color="blue">
                    Change Plan
                  </ButtonMain>

                  <ButtonMain onClick={handleCancelSubscription} color="red">
                    Cancel Subscription
                  </ButtonMain>
                </>
              ) : (
                <>
                  <ButtonMain
                    onClick={handleChoosePlanPrompt}
                    isDisabled={!hasPaymentMethods}
                    color="blue"
                  >
                    Choose A Plan
                  </ButtonMain>
                  {!hasPaymentMethods && (
                    <p
                      className="error"
                      data-testid="account-page-subscription-error"
                    >
                      You must add a payment method before choosing a plan.
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </CSSTransition>
      </div>

      {(showChangePlanPrompt || showChoosePlanPrompt) && (
        <ChoosePlanPrompt
          currentPlan={accountInfo.plan_id}
          onClose={() => {
            setShowChangePlanPrompt(false);
            setShowChoosePlanPrompt(false);
          }}
          submitLoading={
            accountInfo.plan_id
              ? changeSubscriptionLoading
              : registerSubscriptionLoading
          }
          fetchAvailablePlansLoading={fetchAvailablePlansLoading}
          onSubmit={accountInfo.plan_id ? handlePlanChange : handlePlanChoose}
        />
      )}
      {showCancelPlanPrompt && (
        <CancelPlanPrompt
          onClose={() => setShowCancelPlanPrompt(false)}
          onCancel={handlePlanCancel}
          loading={cancelSubscriptionLoading}
        />
      )}
    </AccountPage>
  );
}

const mapState = state => ({
  accountInfo: state.account.accountInfo,
  paymentMethods: state.account.paymentMethods,
  availablePlans: state.account.availablePlans,
  fetchAvailablePlansLoading: _get(
    state.loading,
    "fetchAvailablePlans.loading",
    false
  ),
  changeSubscriptionLoading: _get(
    state.loading,
    "changeSubscription.loading",
    false
  ),
  currentDefaultPaymentMethod: state.account.paymentMethods
    ? state.account.paymentMethods.find(({ default: is_default }) => {
        return is_default === true;
      })
    : null,
  registerSubscriptionLoading: _get(
    state.loading,
    "registerSubscription.loading",
    false
  ),
  cancelSubscriptionLoading: _get(
    state.loading,
    "cancelSubscription.loading",
    false
  ),
  userPlanCycle: _get(state.account, "accountInfo.plan_id")
    ? state.account.accountInfo.plan_id.slice(-1) === "a"
      ? "annual"
      : "monthly"
    : null
});

const mapDispatch = dispatch => ({
  changeSubscription: newPlanId =>
    dispatch(accountOperations.changeSubscription(newPlanId)),
  registerSubscription: (planId, paymentMethodNonce, paymentMethodToken) =>
    dispatch(
      accountOperations.registerSubscription(
        planId,
        paymentMethodNonce,
        paymentMethodToken
      )
    ),
  cancelSubscription: () => dispatch(accountOperations.cancelSubscription()),
  addToast: options => dispatch(toastOperations.addToast(options))
});

export default connect(mapState, mapDispatch)(AccountPageSubscription);
