import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Yup } from "hc-utils/FormValidator";
import { connect } from "react-redux";
import AccountPage from "../AccountPage";
import InputText from "hc-common/InputText";
import { deepEqual } from "hc-utils";
import _get from "lodash.get";
import ButtonMain from "../../common/ButtonMain";
import { accountOperations } from "../duck";
import { toastOperations } from "../../../duck/toast";
import InputTel from "../../common/InputTel";
import accountTypeSelector from "../../../selectors/accountTypeSelector";

function ChangeLink({ onClick, className }) {
  return (
    <div className={`change-link ${className ? className : ""}`}>
      <button onClick={onClick}>Change</button>
    </div>
  );
}

let AccountPageProfileForm = ({
  data,
  isReadOnly,
  isUpdating,
  rootFormikProps,
  accountType
}) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched
  } = rootFormikProps;

  useEffect(() => {
    if (isReadOnly === true) {
      setFieldValue("lastName", _get(data, "last_name", ""));
      setFieldValue("firstName", _get(data, "first_name", ""));
    }
  }, [data, isReadOnly, setFieldValue]);
  return (
    <form
      name="profile-info-form"
      className={"profile-info-form"}
      onSubmit={handleSubmit}
    >
      <InputText
        name="firstName"
        type="text"
        size="full"
        required={true}
        autoComplete="given-name"
        placeholder="First Name"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isReadOnly}
        displayError={
          errors.firstName && touched.firstName ? errors.firstName : false
        }
      />
      <InputText
        name="lastName"
        type="text"
        size="full"
        required={true}
        autoComplete="family-name"
        placeholder="Last Name"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isReadOnly}
        displayError={
          errors.lastName && touched.lastName ? errors.lastName : false
        }
      />
      {accountType === "company" && (
        <InputText
          name="companyName"
          type="text"
          size="full"
          required={true}
          autoComplete="org"
          placeholder="Company Name"
          value={values.companyName}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isReadOnly}
          displayError={
            errors.companyName && touched.companyName
              ? errors.companyName
              : false
          }
        />
      )}
      <InputTel
        name={"phoneNumber"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values["phoneNumber"]}
        autoComplete="tel"
        required={true}
        type="tel"
        size="full"
        placeholder="Phone Number"
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        disabled={isReadOnly}
        displayError={
          errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : false
        }
      />

      <ButtonMain
        color="aqua"
        type="submit"
        isDisabled={isReadOnly}
        loading={isUpdating}
      >
        Update Profile
      </ButtonMain>
    </form>
  );
};

function AccountPageProfile({
  data,
  updateAccountProfileInfo,
  addToast,
  isUpdating,
  accountType
}) {
  const [isReadOnly, setReadOnly] = useState(true);

  const AccountPageProfileFormSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    ...(accountType === "company" && {
      companyName: Yup.string().required("Company Name is required.")
    }),
    phoneNumber: Yup.string().required("Phone number is required.")
  });

  const handleUpdate = values => {
    if (
      deepEqual(
        {
          first_name: values.firstName,
          last_name: values.lastName,
          ...(accountType === "company" && { companyName: values.companyName }),
          phoneNumber: values.phoneNumber
        },
        {
          first_name: data.first_name,
          last_name: data.last_name,
          ...(accountType === "company" && { companyName: data.companyName }),
          phoneNumber: data.phoneNumber
        }
      )
    ) {
    } else {
      updateAccountProfileInfo(values)
        .then(() => {
          setReadOnly(true);
          addToast({ text: "Updated profile info." });
        })
        .catch(error => {
          addToast({ text: error.message, intent: "error" });
          window.console.error(error);
        });
    }
  };
  return (
    <AccountPage page="profile">
      <div className="account-page__header">
        <h3>Profile</h3>
        <ChangeLink
          className={isReadOnly && `is-read-only`}
          onClick={() => setReadOnly(!isReadOnly)}
        />
      </div>
      <div className={`account-page__content ${isReadOnly && `is-read-only`}`}>
        <Formik
          initialValues={{
            firstName: _get(data, "first_name", ""),
            lastName: _get(data, "last_name", ""),
            ...(accountType === "company" && {
              companyName: _get(data, "company_name", "")
            }),
            phoneNumber: _get(data, "phone_number", "")
          }}
          validationSchema={AccountPageProfileFormSchema}
          onSubmit={handleUpdate}
        >
          {rootFormikProps => (
            <AccountPageProfileForm
              isReadOnly={isReadOnly}
              isUpdating={isUpdating}
              data={data}
              rootFormikProps={rootFormikProps}
              accountType={accountType}
            />
          )}
        </Formik>
      </div>
    </AccountPage>
  );
}

const mapState = state => ({
  isUpdating: _get(state.loading, "updateAccountProfileInfo.loading", false),
  accountType: accountTypeSelector(state) || "individual"
});

const mapDispatch = dispatch => ({
  updateAccountProfileInfo: profileInfo =>
    dispatch(accountOperations.updateAccountProfileInfo(profileInfo)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

export default connect(mapState, mapDispatch)(AccountPageProfile);
