import React, { useState } from "react";
import GridHeader from "../../GridHeader";
import GridLayout from "../../GridLayout";
import GridItem from "../../GridItem";
import BlankGridItem from "../../BlankGridItem";
import UserPlaceholderIcon from "../../../../assets/icons/UserPlaceholder.jpeg";
import { escapeRegExp } from "hc-utils";
const uuidv4 = require("uuid/v4");

const PlayGridItem = props => {
  if (!props.id) return <BlankGridItem banner={props.banner} {...props} />;
  return (
    <>
      <GridItem
        {...props}
        photoData={props.image ? props.image : UserPlaceholderIcon}
        overlayItems={{
          selected: ["view", "remove"],
          available: ["view", "add"]
        }}
        onDragStop={props.onDragStop}
      />
    </>
  );
};

function DragAndDropIndicator() {
  return (
    <div className="dnd-indicator">
      <div className="dnd-indicator__arrow" />
      <div className="dnd-indicator__text">Drag & Drop</div>
    </div>
  );
}

function PlayAvailableGrid({
  items,
  onDragStop,
  onView,
  onAdd,
  onNew,
  currentCategory
}) {
  const [searchQuery, setSearchQuery] = useState("");

  let filteredItems = (items, query) => {
    return items.filter(
      item =>
        `${item.name}`.toLowerCase().search(escapeRegExp(query.toLowerCase())) >
        -1
    );
  };

  return (
    <div
      className="play-grid-items play-grid-items--available"
      data-testid="play-available-grid"
    >
      <GridHeader color="gray">Mix & Match from these favorites</GridHeader>
      <div className="play-grid-items__search">
        <DragAndDropIndicator />

        <input
          type="text"
          name="play-search"
          className="play-search"
          onChange={({ currentTarget }) => setSearchQuery(currentTarget.value)}
          value={searchQuery}
          placeholder={"Search for location"}
        />
      </div>

      <GridLayout
        rows={3}
        compact={true}
        withAdd={false}
        columns={4}
        items={items}
      >
        {({ gridArray }) =>
          filteredItems(gridArray, searchQuery).length > 0 ? (
            <div className="play-grid-items__grid">
              {filteredItems(gridArray, searchQuery).map((item, i) => (
                <React.Fragment key={item.id ? item.id : uuidv4()}>
                  <PlayGridItem
                    columns={4}
                    image={
                      item.image
                        ? item.image.length > 0
                          ? item.image.find(({ size }) => size === "thumb").url
                          : null
                        : null
                    }
                    id={item.id}
                    title={item.name}
                    sequence={item.sequence}
                    // banner={this.getBannerText(i)}
                    gridIndex={i}
                    type="available"
                    currentPageHash={6}
                    onDragStop={onDragStop}
                    onAdd={onAdd}
                    onView={onView}
                    disableAvailableDrop={true}
                    // onDelete={onDeleteHost}
                    // onEdit={this.onHostEdit}
                  />
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="play-grid-items__empty">
              No locations found for "{searchQuery}"
            </div>
          )
        }
      </GridLayout>
    </div>
  );
}

export default PlayAvailableGrid;
