import React, { useContext } from "react";

import Banner from "./Banner";
import { BannerContext } from "./BannerProvider";

function DemoBanner(props) {
  const { hideBanner } = useContext(BannerContext);

  const handleBannerClose = () => {
    hideBanner();
  };

  return (
    <Banner
      ctaLink="https://calendly.com/homeconcierge"
      ctaColor="white"
      ctaText="Schedule a Demo"
      className="demo"
      overlineText={
        <>
          <p>Home Owners:</p>
          <p>Property Managers:</p>
          <p>Hosts:</p>
        </>
      }
      onClose={handleBannerClose}
    >
      <h2>We'd love to help you create the digital guide for your home.</h2>
      <h3>
        Let's schedule a call or quick video conference to get you started.
      </h3>
    </Banner>
  );
}

DemoBanner.propTypes = {};

export default DemoBanner;
