import React from "react";

function BraintreeInput({ id, isEmpty, label, focused, error, errorMessage }) {
  return (
    <div
      className={`form-input ${error ? "error" : ""} ${
        focused || !isEmpty ? "focused" : ""
      } ${isEmpty ? "empty" : ""} braintree-input braintree-input--${id}`}
    >
      <label htmlFor={id} className="form-input__label">
        {error ? errorMessage : label}
      </label>
      <div id={id} className="braintree-input__inputwrapper" />
    </div>
  );
}

export default BraintreeInput;
