import types from "./types";

const setVisited = pageHash => ({
  type: types.SET_VISITED,
  pageHash
});

const removeSelectedGridItem = (
  pageHash,
  value,
  availabilityType,
  id,
  isCompact
) => ({
  type: types.REMOVE_SELECTED_GRID_ITEM,
  availabilityType,
  value,
  pageHash,
  id,
  isCompact
});

const removeAllGridItems = (pageHash, value, availabilityType) => ({
  type: types.REMOVE_ALL_GRID_ITEMS,
  pageHash,
  value,
  availabilityType
});
const addSelectedGridItem = (pageHash, value, availabilityType, itemData) => ({
  type: types.ADD_SELECTED_GRID_ITEM,
  pageHash,
  availabilityType,
  value,
  itemData
});

const changeSelectedGridItemSequence = (
  pageHash,
  value,
  availabilityType,
  id,
  newSequence
) => ({
  type: types.CHANGE_SELECTED_GRID_ITEM_SEQUENCE,
  pageHash,
  value,
  availabilityType,
  id,
  newSequence
});

const setSelectedGridItemSequence = (
  pageHash,
  value,
  availabilityType,
  id,
  newSequence
) => ({
  type: types.SET_SELECTED_GRID_ITEM_SEQUENCE,
  pageHash,
  value,
  availabilityType,
  id,
  newSequence
});

const changeGridItemAvailability = (
  originalAvailability,
  newAvailability,
  pageHash,
  value,
  id,
  isCompact,
  newSequence
) => ({
  type: types.CHANGE_GRID_ITEM_AVAILABILITY,
  originalAvailability,
  newAvailability,
  pageHash,
  value,
  id,
  isCompact,
  newSequence
});

const updateGridItem = (pageHash, value, availabilityType, id, newValues) => ({
  type: types.UPDATE_GRID_ITEM,
  pageHash,
  value,
  availabilityType,
  id,
  newValues
});

const setCurrentBuilder = (homeId, builder, regionId, regions) => ({
  type: types.SET_CURRENT_BUILDER,
  homeId,
  builder,
  regionId,
  regions
});

const clearCurrentBuilder = () => ({
  type: types.CLEAR_CURRENT_BUILDER
});

const cacheCurrentBuilder = (homeId, currentBuilder, regionId, regions) => ({
  type: types.CACHE_USER_HOME_DATA,
  homeId,
  currentBuilder,
  regionId,
  regions
});

const setCalendarValues = values => ({
  type: types.SET_CALENDAR_VALUES,
  values
});

const setFormValues = values => ({
  type: types.SET_FORM_VALUES,
  values
});

const switchGridItemAvailability = (
  originalAvailability,
  newAvailability,
  pageHash,
  value,
  id,
  isCompact,
  newSequence,
  originalSequence
) => ({
  type: types.SWITCH_GRID_ITEM_AVAILABILITY,
  originalAvailability,
  newAvailability,
  pageHash,
  value,
  id,
  isCompact,
  newSequence,
  originalSequence
});

const setHouseVideoLink = link => ({
  type: types.SET_HOUSE_VIDEO_LINK,
  link
});

const setHouseVrTourLink = link => ({
  type: types.SET_HOUSE_VR_TOUR_LINK,
  link
});

export default {
  setVisited,
  removeSelectedGridItem,
  addSelectedGridItem,
  changeSelectedGridItemSequence,
  setSelectedGridItemSequence,
  changeGridItemAvailability,
  updateGridItem,
  setCurrentBuilder,
  clearCurrentBuilder,
  setCalendarValues,
  setFormValues,
  removeAllGridItems,
  switchGridItemAvailability,
  cacheCurrentBuilder,
  setHouseVideoLink,
  setHouseVrTourLink
};
