import React from "react";
import PropTypes from "prop-types";
import posed, { PoseGroup } from "react-pose";
import GridHeader from "./GridHeader";
import BuilderButton from "./BuilderButton";

const TransWrapper = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    opacity: { duration: 150, ease: "out" }
  }
});

function CategorySelector({ tabs, category: currCategory, onCategoryChange }) {
  return (
    <div className="category-selector">
      <div className="category-selector__inner">
        <div className="category-selector__tabs">
          <GridHeader color="blue">Choose a category below</GridHeader>
          <div className="category-selector__items">
            <PoseGroup flipMove={false} animateOnMount={true}>
              {tabs.map((category, i) => (
                <TransWrapper key={i}>
                  <BuilderButton
                    key={category.key}
                    onClick={() => onCategoryChange(category)}
                    type="svg"
                    icon={{ type: "image", data: category.image }}
                    name={category.name}
                    isEmpty={category.isEmpty}
                    isActive={currCategory === category.key}
                  >
                    {category.label}
                  </BuilderButton>
                </TransWrapper>
              ))}
            </PoseGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

CategorySelector.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      image: PropTypes.string,
      feature_id: PropTypes.number
    })
  ),
  category: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func.isRequired
};

export default CategorySelector;
