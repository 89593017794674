import React from "react";
import PropTypes from "prop-types";

function DashboardTable({ children, layout }) {
  const getHeaderItems = ({ layout }) => {
    if (layout === "userHomes") {
      return ["Image", "Name", "Address", "Manage"];
    }
    if (layout === "guests") {
      return ["Name", "Email", "Phone", "Check-in", "Check-out", "Manage"];
    }
    if (layout === "admin") {
      return ["Name", "Email", "Manage"];
    }
  };

  const headerItems = getHeaderItems({ layout });

  return (
    <div className={`dashboard-table dashboard-table--${layout}`}>
      {headerItems && (
        <div className="dashboard-table__heading">
          {headerItems.map(item => (
            <p key={item}>{item}</p>
          ))}
        </div>
      )}
      <div className="dashboard-table__inner">{children}</div>
    </div>
  );
}

DashboardTable.propTypes = {
  children: PropTypes.any,
  layout: PropTypes.string
};

export default DashboardTable;
