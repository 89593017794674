import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

import GardenerIcon from "../../assets/gardener.svg";
import TrashPickupIcon from "../../assets/trashPickup.svg";
import PoolJacuzziIcon from "../../assets/poolJacuzzi.svg";
import HousekeepingIcon from "../../assets/houseKeeping.svg";
import GuestFormSection from "./GuestFormSection";
import SpinnerLoader from "../common/SpinnerLoader";
import { Field } from "formik";
import InputTextFormik from "../common/InputTextFormik";
import { dashboardOperations } from "./duck";
import { connect } from "react-redux";
import { connect as formikConnect } from "formik";

const itemIcons = {
  gardener: GardenerIcon,
  trashPickup: TrashPickupIcon,
  poolJacuzzi: PoolJacuzziIcon,
  houseKeeping: HousekeepingIcon
};
const ItemIcon = ({ image }) => <img src={image} alt="Calendar Icon" />;
const GuestScheduleItem = ({ category }) => {
  const { title, key } = category;

  return (
    <div className="guest-schedule-item">
      <div className="guest-schedule-item__inner">
        <div className="guest-schedule-item__title">
          <h5>{title} Schedule</h5>
        </div>
        <div className="guest-schedule-item__content">
          <div className="guest-schedule-item__icon">
            <ItemIcon image={itemIcons[key]} />
          </div>
          <div className="guest-schedule-item__fields">
            <Field
              component={InputTextFormik}
              name={`${key}.day`}
              placeholder="Day(s)"
              size="full"
            />
            <Field
              component={InputTextFormik}
              name={`${key}.time`}
              placeholder="Time"
              size="full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GuestScheduleItem.propTypes = {
  category: PropTypes.objectOf(PropTypes.string).isRequired
};
const GuestFormScheduleInfo = ({
  loading,
  calendar,
  getCalendar,
  homeId,
  isNew,
  formik: { setFieldValue, values }
}) => {
  useEffect(() => {
    if (!isNew) return;
    getCalendar(homeId).then(({ guestServices: calendar }) => {
      setFieldValue("houseKeeping.day", _get(calendar, "houseKeeping.day", ""));
      setFieldValue(
        "houseKeeping.time",
        _get(calendar, "houseKeeping.time", "")
      );
      setFieldValue("gardener.day", _get(calendar, "gardener.day", ""));
      setFieldValue("gardener.time", _get(calendar, "gardener.time", ""));
      setFieldValue("poolJacuzzi.day", _get(calendar, "poolJacuzzi.day", ""));
      setFieldValue("poolJacuzzi.time", _get(calendar, "poolJacuzzi.time", ""));
      setFieldValue("trashPickup.day", _get(calendar, "trashPickup.day", ""));
      setFieldValue("trashPickup.time", _get(calendar, "trashPickup.time", ""));
    });
  }, [calendar, getCalendar, homeId, isNew, setFieldValue]);

  return (
    <GuestFormSection
      title="Guest Schedule Info"
      subtitle="Add scheduled events that are specific to your guest."
      className={loading ? "loading" : ""}
    >
      {loading ? <SpinnerLoader /> : null}
      <div className="guest-schedule">
        <GuestScheduleItem
          category={{ title: "Housekeeping", key: "houseKeeping" }}
        />
        <GuestScheduleItem
          category={{ title: "Trash Pickup", key: "trashPickup" }}
        />
        <GuestScheduleItem category={{ title: "Gardener", key: "gardener" }} />
        <GuestScheduleItem
          category={{ title: "Pool/Jacuzzi", key: "poolJacuzzi" }}
        />
      </div>
    </GuestFormSection>
  );
};

GuestFormScheduleInfo.propTypes = {
  calendar: PropTypes.object,
  formik: PropTypes.object,
  getCalendar: PropTypes.func.isRequired,
  homeId: PropTypes.number.isRequired,
  isNew: PropTypes.bool,
  loading: PropTypes.bool.isRequired
};

const mapState = state => ({
  homeId: _get(state.global, "current.home.id", null),
  loading: _get(state.loading, "getCalendar.loading", false)
});

const mapDispatch = dispatch => ({
  getCalendar: houseId => dispatch(dashboardOperations.getCalendar(houseId))
});
export default formikConnect(
  connect(mapState, mapDispatch)(GuestFormScheduleInfo)
);
