import React from "react";

import InputText from "../../../common/InputText";
import InputTel from "../../../common/InputTel";
import InputEditor from "../../InputEditor";

import { PropertyInfoSchema } from "./propertyInfoData";

import { Formik } from "formik";
import { ReactComponent as PropertyOwnerIcon } from "../../../../assets/icons/PropertyOwner.svg";
import { ReactComponent as VacationServicesIcon } from "../../../../assets/icons/VacationServices.svg";
import { ReactComponent as ArrivalIcon } from "../../../../assets/icons/ArrivalDetails.svg";
import { ReactComponent as CheckoutIcon } from "../../../../assets/icons/Checkout.svg";
import _get from "lodash.get";
import SelectInput from "../../../common/SelectInput";
import { useSelector } from "react-redux";
import InputAddress from "../../../common/InputAddress";

import { ReactComponent as AirbnbIcon } from "../../../../assets/icons/Airbnb.svg";
import { ReactComponent as VrboIcon } from "../../../../assets/icons/Vrbo.svg";
import { ReactComponent as LuxeIcon } from "../../../../assets/icons/Luxe.svg";
import { DEFAULT_CHECK_OUT_INFO } from "../../config/formDefaults";

function PropertyInfoForm({
  visited,
  data,
  bindSubmitFormHandler,
  onSubmit
}) {
  const regions = useSelector(state => state.global.regions);
  const currentRegionId = useSelector(
    state => state.builder.currentBuilderRegionId
  );

  const houseRegionOptions = regions.map(region => ({
    value: String(region.id),
    label: region.name
  }));

  const houseRegion = regions
    ? houseRegionOptions.find(
        ({ value }) => String(value) === String(currentRegionId)
      )
    : null;

  return (
    <Formik
      initialValues={{
        houseName: _get(data, "houseName", ""),
        ownerName: _get(data, "ownerName", ""),
        houseAddr: _get(data, "houseAddr", {
          fullAddress: "",
          lat: null,
          lng: null
        }),
        housePhone: _get(data, "housePhone", ""),
        homeEmail: _get(data, "homeEmail", ""),
        homeWebsite: _get(data, "homeWebsite", ""),
        homeWifiNetworkName: _get(data, "wifiName", ""),
        homeWifiNetworkPass: _get(data, "wifiCode", ""),
        homeGateCode: _get(data, "gateCode", ""),
        homeDoorCode: _get(data, "doorCode", ""),
        homeSecurityInfo: _get(data, "securityInfo", ""),
		homeCheckOutInfo: _get(data, "checkOutInfo", "") || DEFAULT_CHECK_OUT_INFO,
        houseRegion: houseRegion ? houseRegion : null,
        book_airbnb: _get(data, "book_airbnb", ""),
        book_vrbo: _get(data, "book_vrbo", ""),
        book_luxe: _get(data, "book_luxe", ""),
        book_other_name: _get(data, "book_other_name", ""),
        book_other_url: _get(data, "book_other_url", "")
      }}
      validationSchema={PropertyInfoSchema}
      onSubmit={onSubmit}
    >
      {rootFormikProps => {
        const {
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          errors,
          touched,
          submitForm,
          setFieldTouched
        } = rootFormikProps;

        bindSubmitFormHandler(submitForm);
        return (
          <div
            className="property-info-form-wrapper"
            data-testid="property-info-form"
          >
            <form className="builder-page__fieldgroup">
              <InputText
                name={"houseName"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["houseName"]}
                required={true}
                type="text"
                size="full"
                placeholder="House Name"
                maxLength={23}
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.houseName && touched.houseName
                    ? errors.houseName
                    : false
                }
              />

              <InputAddress
                name={"houseAddr"}
                placeholder="Home Address"
                required={true}
              />

              <SelectInput
                name="houseRegion"
                placeholder={"Select a region for the home"}
                disabled={currentRegionId ? true : false}
                options={houseRegionOptions}
              />
            </form>
            <div className="builder-page__header">
              <PropertyOwnerIcon />
              <h2>Home Owner Info</h2>
            </div>
            <form className="builder-page__fieldgroup owner">
              <InputText
                name={"ownerName"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["ownerName"]}
                required={false}
                type="text"
                size="full"
                autoComplete="name"
                placeholder="Home Owner Name"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.ownerName && touched.ownerName
                    ? errors.ownerName
                    : false
                }
              />
              <InputTel
                name={"housePhone"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["housePhone"]}
                required={false}
                type="tel"
                size="half"
                autoComplete="tel"
                placeholder="Home Owner Phone Number"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                displayError={
                  errors.housePhone && touched.housePhone
                    ? errors.housePhone
                    : false
                }
              />
              <InputText
                name={"homeEmail"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["homeEmail"]}
                required={false}
                type="email"
                size="half"
                autoComplete="email"
                placeholder="Home Owner Email"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.homeEmail && touched.homeEmail
                    ? errors.homeEmail
                    : false
                }
              />
              <InputText
                name={"homeWebsite"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["homeWebsite"]}
                required={false}
                type="text"
                size="full"
                autoComplete="url"
                placeholder="Home Owner Website"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.homeWebsite && touched.homeWebsite
                    ? errors.homeWebsite
                    : false
                }
              />
            </form>
            <div className="builder-page__header">
              <VacationServicesIcon />
              <h2>Vacation Services</h2>
            </div>
            <form className="builder-page__fieldgroup builder-page__fieldgroup--columns vacation-services">
              <div className="builder-page-column">
                <div className="builder-page-column__top">
                  <AirbnbIcon />
                </div>
                <div className="builder-page-column__bottom">
                  <InputText
                    name={"book_airbnb"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values["book_airbnb"]}
                    required={false}
                    type="text"
                    size="half"
                    placeholder="Your Airbnb URL"
                    pageVisited={visited}
                    setFieldValue={setFieldValue}
                    displayError={
                      errors.book_airbnb && touched.book_airbnb
                        ? errors.book_airbnb
                        : false
                    }
                  />
                </div>
              </div>

              <div className="builder-page-column">
                <div className="builder-page-column__top">
                  <VrboIcon />
                </div>
                <div className="builder-page-column__bottom">
                  <InputText
                    name={"book_vrbo"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values["book_vrbo"]}
                    required={false}
                    type="text"
                    size="half"
                    placeholder="Your Vrbo URL"
                    pageVisited={visited}
                    setFieldValue={setFieldValue}
                    displayError={
                      errors.book_vrbo && touched.book_vrbo
                        ? errors.book_vrbo
                        : false
                    }
                  />
                </div>
              </div>
              <div className="builder-page-column">
                <div className="builder-page-column__top">
                  <LuxeIcon />
                </div>
                <div className="builder-page-column__bottom">
                  <InputText
                    name={"book_luxe"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values["book_luxe"]}
                    required={false}
                    type="text"
                    size="half"
                    placeholder="Your Luxe URL"
                    pageVisited={visited}
                    setFieldValue={setFieldValue}
                    displayError={
                      errors.book_luxe && touched.book_luxe
                        ? errors.book_luxe
                        : false
                    }
                  />
                </div>
              </div>
              <div className="builder-page-column">
                <div className="builder-page-column__top">
                  <InputText
                    name={"book_other_name"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values["book_other_name"]}
                    required={false}
                    type="text"
                    size="half"
                    placeholder="Other site name"
                    pageVisited={visited}
                    setFieldValue={setFieldValue}
                    displayError={
                      errors.book_other_name && touched.book_other_name
                        ? errors.book_other_name
                        : false
                    }
                  />
                </div>
                <div className="builder-page-column__bottom">
                  <InputText
                    name={"book_other_url"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values["book_other_url"]}
                    required={false}
                    type="text"
                    size="half"
                    placeholder='Your "Other" URL'
                    pageVisited={visited}
                    setFieldValue={setFieldValue}
                    displayError={
                      errors.book_other_url && touched.book_other_url
                        ? errors.book_other_url
                        : false
                    }
                  />
                </div>
              </div>
            </form>

            <div className="builder-page__header">
              <ArrivalIcon />
              <h2>Arrival Info & Security Codes</h2>
            </div>
            <form className="builder-page__fieldgroup security">
              <InputText
                name={"homeWifiNetworkName"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["homeWifiNetworkName"]}
                required={false}
                type="text"
                size="half"
                placeholder="WiFi Network Name"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.homeWifiNetworkName && touched.homeWifiNetworkName
                    ? errors.homeWifiNetworkName
                    : false
                }
              />
              <InputText
                name={"homeWifiNetworkPass"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["homeWifiNetworkPass"]}
                required={false}
                type="text"
                size="half"
                placeholder="WiFi Network Password"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.homeWifiNetworkPass && touched.homeWifiNetworkPass
                    ? errors.homeWifiNetworkPass
                    : false
                }
              />
              <InputText
                name={"homeGateCode"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["homeGateCode"]}
                required={false}
                type="text"
                size="half"
                placeholder="Gate Code"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.homeGateCode && touched.homeGateCode
                    ? errors.homeGateCode
                    : false
                }
              />
              <InputText
                name={"homeDoorCode"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["homeDoorCode"]}
                required={false}
                type="text"
                size="half"
                placeholder="Door Code"
                pageVisited={visited}
                setFieldValue={setFieldValue}
                displayError={
                  errors.homeDoorCode && touched.homeDoorCode
                    ? errors.homeDoorCode
                    : false
                }
              />
              <InputEditor
                name={`homeSecurityInfo`}
                id={`homeSecurityInfo`}
                touched={touched}
                placeholder="Arrival Info"
                disabled={false}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                required={false}
                value={values["homeSecurityInfo"]}
                note={"Add any additional ARRIVAL information here."}
                displayError={
                  errors["homeSecurityInfo"] && touched["homeSecurityInfo"]
                    ? errors["homeSecurityInfo"] && touched["homeSecurityInfo"]
                      ? errors["homeSecurityInfo"]
                      : null
                    : null
                }
				testId={'editor-input-ai'}
				style={{"paddingTop": 0}}
              />
            </form>

			<div className="builder-page__header">
			  <CheckoutIcon />
			  <h2>Check Out Info</h2>
			</div>
			<form className="builder-page__fieldgroup security">
			  <InputEditor
				name={`homeCheckOutInfo`}
				id={`homeCheckOutInfo`}
				touched={touched}
				disabled={false}
				setFieldValue={setFieldValue}
				setFieldTouched={setFieldTouched}
				required={false}
				value={values["homeCheckOutInfo"]}
				note={"Set your Check Out information here."}
				displayError={
				  errors["homeCheckOutInfo"] && touched["homeCheckOutInfo"]
					? errors["homeCheckOutInfo"] && touched["homeCheckOutInfo"]
					  ? errors["homeCheckOutInfo"]
					  : null
					: null
				}
				testId={'editor-input-co'}
				style={{"paddingTop": 0}}
			  />
			</form>
          </div>
        );
      }}
    </Formik>
  );
}

PropertyInfoForm.propTypes = {};

export default PropertyInfoForm;
