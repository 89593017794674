import React, { useState, useEffect } from "react";

import AccountPage from "../AccountPage";

import PaymentMethodsList from "../PaymentMethodsList";
import PromptWrapper from "../../common/PromptWrapper";

import { Formik } from "formik";
import { Yup } from "hc-utils/FormValidator";
import BraintreeClient from "../../../utilities/braintree-react/BraintreeClient";
import ButtonMain from "../../common/ButtonMain";
import { accountOperations } from "../duck";
import { connect } from "react-redux";
import _get from "lodash.get";
import { toastOperations } from "../../../duck/toast";
import PaypalCheckoutBtn from "../../common/PaypalCheckoutBtn";
import InputChoiceBox from "../../common/InputChoiceBox";
import cardIcon from "../../../assets/icons/Card.svg";
import payPalIcon from "../../../assets/icons/paypal-icon.svg";
import { CSSTransition } from "react-transition-group";
import SpinnerLoader from "../../common/SpinnerLoader";

const NewPaymentPromptSchema = Yup.object().shape({
  paymentMethodChoice: Yup.string().required(
    "You must select a payment method."
  ),
  paypal: Yup.boolean().test(
    "isRequired",
    "You must authorize your payment with paypal.",
    function (paypalIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return paypalIsValid;
      } else {
        return true;
      }
    }
  ),
  number: Yup.boolean().test(
    "isRequired",
    "Card Number is not valid.",
    function (numberIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return true;
      } else {
        return numberIsValid;
      }
    }
  ),
  cvv: Yup.boolean().test(
    "isRequired",
    "CVV is not valid",
    function (cvvIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return true;
      } else {
        return cvvIsValid;
      }
    }
  ),
  expirationDate: Yup.boolean().test(
    "isRequired",
    "Expiration date is not valid",
    function (expIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return true;
      } else {
        return expIsValid;
      }
    }
  )
});

function NewPaymentPrompt({
  onClose,
  onSubmit,
  isLoading,
  fetchAccountPaymentMethods,
  addToast
}) {
  const [hostedFieldInstance, setHostedFieldInstance] = useState(null);
  const [paypalNonce, setPaypalNonce] = useState(null);
  const submitNonces = values => {
    if (values.paymentMethodChoice === "paymentMethodPP") {
      onSubmit(values, null, paypalNonce);
    }

    if (values.paymentMethodChoice === "paymentMethodCard") {
      hostedFieldInstance.tokenize((err, payload) => {
        onSubmit(values, payload.nonce, null);
      });
    }
  };

  // useEffect(() => {
  //   if (paypalNonce && paypalData) {
  //     fetchAccountPaymentMethods()
  //       .then(() => {
  //         addToast({
  //           text: `Successfully added paypal account "${paypalData.details.email}" to your payment methods.`
  //         });
  //         onClose();
  //       })
  //       .catch(() => {
  //         addToast({
  //           text:
  //             "There was an issue getting your payment methods. Please try again."
  //         });
  //       });
  //   }
  // }, [addToast, fetchAccountPaymentMethods, onClose, paypalData, paypalNonce]);

  const handlePaypalAuthorize = (nonce, data) => {
    setPaypalNonce(nonce);

    fetchAccountPaymentMethods()
      .then(() => {
        addToast({
          text: `Successfully added paypal account "${data.details.email}" to your payment methods.`
        });
      })
      .catch(err => {
        addToast({
          text:
            "There was an issue getting your payment methods. Please try again."
        });
      });
    onClose();
  };

  return (
    <PromptWrapper onClose={onClose} className="new-payment-prompt-wrapper">
      <div className="new-payment-prompt-wrapper__inner">
        <h2>Add New Payment Method</h2>

        <Formik
          initialValues={{
            paymentMethodChoice: "paymentMethodCard",
            paypal: false,
            number: false,
            cvv: false,
            expirationDate: false
          }}
          validationSchema={NewPaymentPromptSchema}
          onSubmit={submitNonces}
        >
          {({
            handleSubmit,
            touched,
            setFieldTouched,
            setFieldValue,
            values,
            handleChange,
            handleBlur
          }) => (
            <form
              name="new-payment-form"
              className="new-payment-form"
              onSubmit={handleSubmit}
            >
              <div className="new-payment-form__method">
                <InputChoiceBox
                  name="paymentMethodCard"
                  groupName="paymentMethodChoice"
                  size="half"
                  checked={values.paymentMethodChoice === "paymentMethodCard"}
                  icon={cardIcon}
                  placeholder="Credit Card"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <InputChoiceBox
                  name="paymentMethodPP"
                  groupName="paymentMethodChoice"
                  size="half"
                  icon={payPalIcon}
                  checked={values.paymentMethodChoice === "paymentMethodPP"}
                  placeholder="Paypal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {values.paymentMethodChoice === "paymentMethodPP" && (
                <div className="new-payment-form__paypal">
                  <h4>Pay with Paypal</h4>

                  <PaypalCheckoutBtn
                    onAuthorize={handlePaypalAuthorize}
                    setFieldTouched={setFieldTouched}
                    onError={() => setFieldValue("paypal", false)}
                    onCancel={() => setFieldValue("paypal", false)}
                  />
                </div>
              )}
              {values.paymentMethodChoice === "paymentMethodCard" && (
                <div className="new-payment-form__credit">
                  <h4>Pay with Credit Card</h4>
                  <BraintreeClient
                    touched={touched}
                    setInstance={instance => setHostedFieldInstance(instance)}
                    fields={{
                      number: {
                        id: "number",
                        label: "Credit Card Number*",
                        placeholder: "**** **** **** ****",
                        errorMsg: "Not a valid Credit Card Number."
                      },
                      cvv: {
                        id: "cvv",
                        label: "CVV*",
                        placeholder: "***",
                        errorMsg: "Not a valid CVV."
                      },
                      expirationDate: {
                        id: "expiration-date",
                        label: "Expiration Date*",
                        placeholder: `MM/YY`,
                        errorMsg: "Not a valid expiration date."
                      }
                    }}
                    styleObj={{
                      input: {
                        display: "block",
                        width: "100%",
                        border: "0",
                        outline: "0",
                        background: "transparent",
                        height: "auto",
                        padding: "0px 0px 0px 10px"
                      }
                    }}
                  />
                </div>
              )}
              <ButtonMain type="submit" loading={isLoading}>
                SUBMIT
              </ButtonMain>
            </form>
          )}
        </Formik>
      </div>
    </PromptWrapper>
  );
}

const UpdatePaymentPromptValidationSchema = Yup.object().shape({
  cvv: Yup.boolean().oneOf([true], "Invalid CVV."),
  expirationDate: Yup.boolean().oneOf([true], "Invalid Expiration date")
});

function UpdatePaymentPrompt({ onClose, onSubmit, queuedData, isLoading }) {
  const [hostedFieldInstance, setHostedFieldInstance] = useState(null);

  const submitUpdatedPayment = () => {
    onSubmit(hostedFieldInstance);
  };
  return (
    <PromptWrapper onClose={onClose} className="update-payment-prompt-wrapper">
      <div className="update-payment-prompt-wrapper__inner">
        <h2>Edit Payment Info</h2>
        <p>
          Update <strong>{queuedData.cardType}</strong> card{" "}
          <strong>{queuedData.maskedNumber}</strong>
        </p>

        <Formik
          initialValues={{
            cvv: false,
            expirationDate: false
          }}
          validationSchema={UpdatePaymentPromptValidationSchema}
          onSubmit={submitUpdatedPayment}
        >
          {({ handleSubmit, touched, setFieldTouched, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <BraintreeClient
                touched={touched}
                setInstance={instance => setHostedFieldInstance(instance)}
                fields={{
                  cvv: {
                    id: "cvv",
                    label: "CVV*",
                    placeholder: "***",
                    errorMsg: "Not a valid CVV."
                  },
                  expirationDate: {
                    id: "expiration-date",
                    label: "Expiration Date*",
                    placeholder: `${queuedData.expirationMonth}/${queuedData.expirationYear}`,
                    errorMsg: "Not a valid expiration date."
                  }
                }}
                styleObj={{
                  input: {
                    display: "block",
                    width: "100%",
                    border: "0",
                    outline: "0",
                    background: "transparent",
                    height: "auto",
                    padding: "0px 0px 0px 10px"
                  }
                }}
              />

              <ButtonMain type="submit" loading={isLoading}>
                Update
              </ButtonMain>
            </form>
          )}
        </Formik>
      </div>
    </PromptWrapper>
  );
}

function AccountPageBilling({
  data,
  fetchAccountPaymentMethods,
  updatePaymentMethod,
  paymentMethods,
  updatePaymentMethodLoading,
  updateDefaultCard,
  createNewPaymentMethod,
  addToast,
  createNewPaymentMethodLoading,
  deletePaymentMethod,
  loading,
  loadStatus,
  fetchAccountPaymentMethodsLoading,
  hasSubscription
}) {
  const [showUpdatePaymentsPrompt, setShowUpdatePaymentsPrompt] = useState(
    false
  );
  const [queuedUpdatePayment, setQueuedUpdatePayment] = useState(null);

  const [showNewPaymentsPrompt, setShowNewPaymentsPrompt] = useState(false);
  useEffect(() => {
    if (!paymentMethods) {
      fetchAccountPaymentMethods().catch(window.console.error);
    }
  }, [fetchAccountPaymentMethods, paymentMethods]);

  const setDefaultCard = token => {
    updateDefaultCard(token)
      .then(newDefaultMethod => {
        const isCard = newDefaultMethod.cardType;
        addToast({
          text: isCard
            ? `You have set the "${newDefaultMethod.cardType}" card ending in "${newDefaultMethod.last4}" as your default. All future billing will be charged to this card.`
            : `You have set the paypal account with email "${newDefaultMethod.email}" as your default. All future billing will be charged to this account.`
        });
      })
      .catch(err => {
        window.console.error(err);
      });
  };

  const handleUpdatePaymentSubmit = instance => {
    instance.tokenize((err, payload) => {
      if (err) {
        addToast({ text: err.message, intent: "error" });

        return;
      }
      updatePaymentMethod({
        paymentMethodToken: queuedUpdatePayment.token,
        paymentMethodNonce: payload.nonce
      })
        .then(updatedMethod => {
          addToast({
            text: `Successfully updated ${queuedUpdatePayment.cardType} ending in "${queuedUpdatePayment.last4}".`
          });
          setShowUpdatePaymentsPrompt(false);
        })
        .catch(err => {
          const errMessage = err.response.data.message;
          addToast({
            text: errMessage
              ? errMessage
              : "There was an issue updating your payment information.",
            intent: "error"
          });
        });
    });
  };

  const onNewPaymentMethod = () => {
    setShowNewPaymentsPrompt(true);
  };

  const handleNewPaymentSubmit = (values, hostedFieldsNonce, paypalNonce) => {
    createNewPaymentMethod(
      values.paymentMethodChoice === "paymentMethodCard"
        ? hostedFieldsNonce
        : paypalNonce,
      "creditCard"
    )
      .then(newPaymentMethod => {
        setShowNewPaymentsPrompt(false);
        if (newPaymentMethod.cardType) {
          addToast({
            text: `Successfully added ${newPaymentMethod.cardType} card ending with "${newPaymentMethod.last4}" to your payment methods.`
          });
        } else {
          addToast({
            text: `Successfully added paypal account "${newPaymentMethod.email}" to your payment methods.`
          });
        }
      })
      .catch(error => {
        addToast({
          text: _get(error, "message", `Unable to create payment method`),
          intent: "error"
        });
        window.console.error(error);
      });
  };

  const handleDeletePaymentMethod = method => {
    if (method.default === true && hasSubscription === true) {
      addToast({
        text:
          "This payment method is your current default payment method - please select another payment method as your default before deleting this one, or cancel your subscription.",
        intent: "warning"
      });
      return;
    }

    deletePaymentMethod(method.token)
      .then(() => {
        addToast({ text: "Successfully deleted payment method." });
      })
      .catch(err => {
        window.console.error(err);
        addToast({ text: err.message, intent: "error" });
      });
  };
  return (
    <AccountPage page="billing">
      <div className="account-page__header">
        <h3>Billing</h3>
      </div>

      <div className={`account-page__content`}>
        <CSSTransition
          in={loading}
          classNames="fade"
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <div className="spinner-loader-wrapper" style={{ minHeight: "90px" }}>
            <SpinnerLoader />
          </div>
        </CSSTransition>
        <CSSTransition
          in={!loading}
          timeout={300}
          classNames="fade"
          mountOnEnter
          unmountOnExit
        >
          {loadStatus === "FAILED" ? (
            <p>
              Unable to get billing information. Please try again later or
              contact{" "}
              <a href="mailto:help@homeconcierge.com">help@homeconcierge.com</a>
            </p>
          ) : (
            <div className="account-page__contentinner">
              <PaymentMethodsList
                onDelete={handleDeletePaymentMethod}
                methods={paymentMethods}
                options={{ update: true, showDefault: true }}
                onUpdate={method => {
                  setShowUpdatePaymentsPrompt(true);
                  setQueuedUpdatePayment(method);
                }}
                onSetDefault={setDefaultCard}
              />
              <ButtonMain color="aqua" onClick={onNewPaymentMethod}>
                Add new Payment Method
              </ButtonMain>
            </div>
          )}
        </CSSTransition>
      </div>
      {showUpdatePaymentsPrompt && (
        <UpdatePaymentPrompt
          queuedData={queuedUpdatePayment}
          onSubmit={handleUpdatePaymentSubmit}
          isLoading={updatePaymentMethodLoading}
          onClose={() => setShowUpdatePaymentsPrompt(false)}
        />
      )}
      {showNewPaymentsPrompt && (
        <NewPaymentPrompt
          onSubmit={handleNewPaymentSubmit}
          isLoading={
            createNewPaymentMethodLoading || fetchAccountPaymentMethodsLoading
          }
          onClose={() => setShowNewPaymentsPrompt(false)}
          fetchAccountPaymentMethods={fetchAccountPaymentMethods}
          addToast={addToast}
        />
      )}
    </AccountPage>
  );
}

const mapDispatch = dispatch => ({
  fetchAccountPaymentMethods: customerId =>
    dispatch(accountOperations.fetchAccountPaymentMethods(customerId)),
  updatePaymentMethod: data =>
    dispatch(accountOperations.updateAccountPaymentMethod(data)),
  addToast: options => dispatch(toastOperations.addToast(options)),
  updateDefaultCard: paymentMethodToken =>
    dispatch(accountOperations.updateDefaultCard(paymentMethodToken)),
  createNewPaymentMethod: (nonce, methodType) =>
    dispatch(accountOperations.createNewPaymentMethod(nonce, methodType)),
  deletePaymentMethod: paymentMethodToken =>
    dispatch(accountOperations.deletePaymentMethod(paymentMethodToken))
});

const mapState = state => ({
  paymentMethods: _get(state.account, "paymentMethods", null),
  fetchAccountPaymentMethodsLoading: _get(
    state.loading,
    "fetchAccountPaymentMethods.loading",
    false
  ),
  updatePaymentMethodLoading: _get(
    state.loading,
    "updateAccountPaymentMethod.loading",
    false
  ),
  createNewPaymentMethodLoading: _get(
    state.loading,
    "createNewPaymentMethod.loading",
    false
  ),
  hasSubscription: Boolean(
    _get(state.account, "accountInfo.plan_id") &&
      _get(state.account, "accountInfo.plan_name")
  )
});
export default connect(mapState, mapDispatch)(AccountPageBilling);
