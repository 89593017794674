import React from "react";
import PropTypes from "prop-types";

function SignupFormSection({ children, header }) {
  return (
    <div className="signup-form-section">
      {header && (
        <div className="signup-form-section__header">
          <h4>{header}</h4>
        </div>
      )}
      <div className="signup-form-section__content">{children}</div>
    </div>
  );
}

SignupFormSection.propTypes = {
  header: PropTypes.string.isRequired
};

export default SignupFormSection;
