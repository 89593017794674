import React from "react";
import PropTypes from "prop-types";
import BuilderController from "./BuilderController";
import SpinnerLoader from "../common/SpinnerLoader";

import { connect } from "react-redux";
import { loadingOperations } from "../../duck/loading";
import { builderOperations } from "./duck";

import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";
import SuggestionProvider from "../common/SuggestionProvider";
import { globalOperations } from "../../duck/global";

import _get from "lodash.get";

class Builder extends React.PureComponent {
  constructor(props) {
    super(props);
    const { match } = props;

    this.state = {
      builderPageKey: match.params.builderPage,
      homeId: match.params.homeId
    };
  }

  handleGAPILoaded = () => {
    const { setGoogleLoading } = this.props;
    setGoogleLoading("SUCCESS");
  };

  componentDidMount() {
    const { homeId } = this.state;
    const { regions } = this.props;

    if (!regions) this.props.fetchRegionsList().catch(console.error);
    this.loadCurrentHomeBuilder(homeId);
  }

  componentWillUnmount() {
    this.props.clearHomeBuilder();
  }
  loadCurrentHomeBuilder(homeId) {
    if (this.props.match.params.createType === "new") {
      this.props.loadHomeBuilder().catch(err => console.error(err));
      return;
    }
    this.props.loadHomeBuilder(homeId).catch(err => console.error(err));
  }

  render() {
    const {
      match,
      currentBuilder,
      isGoogleLoading,
      isNew,
      isBuilderLoading,
      currentBuilderId
    } = this.props;

    const { builderPage } = match.params;

    // if (builderPage !== "propertyInfo" && !currentBuilderId && isNew) {
    //   return <Redirect to="/home/new/propertyInfo" />;
    // }
    // if (!builderPage) return null;

    return (
      <SuggestionProvider>
        <Helmet>
          <title>Home Concierge | Home Builder</title>
          <meta
            name="description"
            content="Edit or Add a home in the Home Builder."
          />
        </Helmet>
        <div className="builder">
          <div className="builder__inner">
            <CSSTransition
              in={(!isGoogleLoading || window.google) && isBuilderLoading}
              timeout={300}
              classNames="builder-loading-wrapper"
            >
              {isBuilderLoading !== false ? ( // if loading
                <div className="builder__loading">
                  <SpinnerLoader />
                </div>
              ) : !currentBuilder ? ( // if not loading but current builder is not set
                <p>
                  There was an error grabbing the home content - please try
                  again.
                </p>
              ) : (
                // otherwise, render the builder controller/builder page

                <BuilderController
                  isNew={isNew}
                  builderPageKey={
                    this.props.match.params.createType === "new"
                      ? "propertyInfo"
                      : builderPage
                  }
                  builderPages={currentBuilder}
                  currentBuilder={currentBuilder}
                  homeId={currentBuilderId}
                  asyncScriptOnLoad={this.handleGAPILoaded}
                >
                  {({ component: BuilderPage }) => {
                    if (isGoogleLoading || !window.google) {
                      // if google is loading dont render builder
                      return null;
                    }
                    return BuilderPage;
                  }}
                </BuilderController>
              )}
            </CSSTransition>
          </div>
        </div>
      </SuggestionProvider>
    );
  }
}

Builder.propTypes = {
  match: PropTypes.object.isRequired,
  currentBuilder: PropTypes.object,
  isGoogleLoading: PropTypes.bool,
  isNew: PropTypes.bool,
  isBuilderLoading: PropTypes.bool,
  currentBuilderId: PropTypes.number
};

const mapState = state => ({
  currentBuilder: state.builder.currentBuilder,
  isGoogleLoading: state.loading.gapi ? state.loading.gapi.loading : null,
  isBuilderLoading:
    _get(state.loading, "currentBuilder.loading", false) ||
    _get(state.loading, "fetchRegionsList.loading", false),
  currentBuilderId: state.builder.currentBuilderId,
  regions: state.global.regions
});

const mapDispatch = dispatch => ({
  setGoogleLoading: requestStatus =>
    dispatch(loadingOperations.setLoading("gapi", requestStatus)),

  loadHomeBuilder: homeId =>
    dispatch(builderOperations.loadHomeBuilder(homeId)),
  clearHomeBuilder: () =>
    dispatch(builderOperations.cacheAndClearCurrentBuilder()),
  fetchRegionsList: () => dispatch(globalOperations.fetchRegionsList())
});

export default connect(mapState, mapDispatch)(Builder);
