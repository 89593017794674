import React, { useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useField } from "formik";
// import useGoogleAutocompletePredictions from "../../hooks/useGoogleAutocompletePredictions";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails
} from "use-places-autocomplete";
import clsx from "clsx";

const getAddressComponents = address_components => {
  return address_components.reduce((acc, curr) => {
    const types = curr.types;
    if (types.includes("administrative_area_level_1")) {
      acc.state = curr.short_name;
    } else if (types.includes("locality")) {
      acc.city = curr.long_name;
    } else if (types.includes("postal_code")) {
      acc.zip = curr.long_name;
    } else if (types.includes("country")) {
      acc.country = curr.long_name;
    }

    return acc;
  });
};

function InputAddress({ name, className, required = false, placeholder }) {
  const [field, meta, helpers] = useField({ name });
  const { suggestions, setValue } = usePlacesAutocomplete();
  const [geoLoading, setGeoLoading] = useState(false);

  // change handler for input
  const handleInputChange = evt => {
    const val = evt.target.value;
    setValue(val);
    if (!val) {
      helpers.setValue({ fullAddress: "" });
    }
  };

  // change handler for autocomplete select
  const handleAutoChange = async (evt, value) => {
    helpers.setValue({ fullAddress: value });
    if (!value) return;
    setGeoLoading(true);
    // get the selected suggestion
    const suggestion = suggestions.data.find(
      suggestion => suggestion.description === value
    );
    // get geocode results
    const results = await getGeocode({ address: value });
    // get latlng
    const coords = await getLatLng(results[0]);
    // get details for location
    const details = await getDetails({ placeId: suggestion.place_id });

    // if details has no address_components, set value as empty
    // and return
    if (!details.address_components) {
      setValue(null);
      helpers.setValue({ fullAddress: "" });
      return;
    }

    // get individual address components
    const { city, state, zip, country } = getAddressComponents(
      details.address_components
    );

    // set address field values
    helpers.setValue({
      street: details.formatted_address,
      city,
      state,
      zip,
      country,
      fullAddress: value,
      lat: coords.lat,
      lng: coords.lng
    });

    setGeoLoading(false);
  };

  const displayError = meta.touched && meta.error && !geoLoading;

  return (
    <Autocomplete
      freeSolo
      className={clsx("input-address", `input-address--${name}`)}
      filterOptions={options => options}
      onChange={handleAutoChange}
      clearOnBlur
      options={suggestions.data.map(suggestion => suggestion.description)}
      value={field.value.fullAddress}
      fullWidth
      disabled={geoLoading}
      renderInput={params => (
        <TextField
          {...params}
          {...field}
          error={displayError}
          fullWidth
          disabled={geoLoading}
          data-testid="input-text-address"
          onChange={handleInputChange}
          label={
            displayError
              ? String(meta.error)
              : required
              ? `${placeholder}*`
              : placeholder
          }
          margin="normal"
          variant="outlined"
        />
      )}
    />
  );
}

InputAddress.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string
};

export default InputAddress;
