import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import InputText from "../common/InputText";
import DashboardIcon from "../../assets/icons/DashboardIcon.svg";
import _get from "lodash.get";

import DashboardAddButton from "./DashboardAddButton";

const HomeFilters = ({ filterState, onCheck }) => {
  return (
    <div className="dashboard-controller__filterswrapper">
      <div className="dashboard-controller__filter">
        <label htmlFor="published-filter">Published</label>
        <div className="dashboard-controller__filtercheckbox">
          <input
            type="checkbox"
            id="published-filter"
            name="published-filter"
            checked={filterState.published === true}
            value="published"
            onChange={() => onCheck("published")}
            data-testid="published-filter-checkbox"
          ></input>
          <span className="custom-checkbox"></span>
        </div>
      </div>
      <div className="dashboard-controller__filter">
        <label htmlFor="drafted-filter">Draft</label>
        <div className="dashboard-controller__filtercheckbox">
          <input
            type="checkbox"
            id="drafted-filter"
            name="drafted-filter"
            value="drafted"
            onChange={() => onCheck("drafted")}
            checked={filterState.drafted === true}
            data-testid="drafted-filter-checkbox"
          ></input>
          <span className="custom-checkbox"></span>
        </div>
      </div>
    </div>
  );
};

HomeFilters.propTypes = {
  filterState: PropTypes.objectOf(PropTypes.bool),
  onCheck: PropTypes.func.isRequired
};

const GuestFilters = ({ filterState, onCheck }) => {
  return (
    <div className="dashboard-controller__filterswrapper">
      <div className="dashboard-controller__filter">
        <label htmlFor="confirmed-guest-filter">Confirmed</label>
        <div className="dashboard-controller__filtercheckbox">
          <input
            id="confirmed-guest-filter"
            type="checkbox"
            name="confirmed-guest-filter"
            checked={filterState.confirmed === true}
            value="confirmed"
            onChange={() => onCheck("confirmed")}
            data-testid="confirmed-filter-checkbox"
          ></input>
          <span className="custom-checkbox"></span>
        </div>
      </div>
      <div className="dashboard-controller__filter">
        <label htmlFor="potential-guest-filter">Brochure</label>
        <div className="dashboard-controller__filtercheckbox">
          <input
            id="potential-guest-filter"
            type="checkbox"
            name="potential-guest-filter"
            value="potential"
            onChange={() => onCheck("potential")}
            checked={filterState.potential === true}
            data-testid="potential-filter-checkbox"
          ></input>
          <span className="custom-checkbox"></span>
        </div>
      </div>
    </div>
  );
};

GuestFilters.propTypes = {
  filterState: PropTypes.objectOf(PropTypes.bool),
  onCheck: PropTypes.func.isRequired
};

const DashboardController = ({
  children,
  title = "My Homes",
  layout = "userHomes",

  onNew,
  hasPlan,
  userHomes,
  planId,
  userHomesLoading,
  getUserDataLoading
}) => {
  const filterReducer = (state, action) => {
    switch (action.type) {
      case "TOGGLE_FILTER": {
        return { ...state, [action.filterName]: !state[action.filterName] };
      }
      case "CLEAR_FILTERS": {
        const clearedFilters = Object.keys(state).reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, state);

        return {
          ...state,
          ...clearedFilters
        };
      }
      default: {
        return state;
      }
    }
  };

  const addReducer = (state, action) => {
    switch (action.type) {
      case "SET_ADD_STATE": {
        const { disabled, message } = action;
        return {
          ...state,
          disabled,
          message
        };
      }
      default: {
        return state;
      }
    }
  };

  const [query, setQuery] = useState("");
  const [filterState, filterStateDispatch] = useReducer(filterReducer, {
    drafted: true,
    published: true,
    confirmed: true,
    potential: true
  });
  const [addState, addStateDispatch] = useReducer(addReducer, {
    disabled: false,
    message: null
  });

  useEffect(() => {
    if (userHomesLoading || getUserDataLoading) {
      addStateDispatch({
        type: "SET_ADD_STATE",
        disabled: true,
        message: "Loading your account information..."
      });
      return;
    }
    if (layout === "userHomes") {
      if (!planId) {
        addStateDispatch({
          type: "SET_ADD_STATE",
          disabled: true,
          message: "You are not subscribed to a subscription plan."
        });
        return;
      }

      if (
        planId.substring(0, 11) === "hcho_plan_1" &&
        layout === "userHomes" &&
        userHomes.filter(({ permission: { type } }) => type !== "host")
          .length >= 1
      ) {
        addStateDispatch({
          type: "SET_ADD_STATE",
          disabled: true,
          message: "Your current plan only allows a single home."
        });
        return;
      }

      if (
        planId.substring(0, 11) === "hcho_plan_2" &&
        layout === "userHomes" &&
        userHomes.filter(({ permission: { type } }) => type !== "host")
          .length >= 10
      ) {
        addStateDispatch({
          type: "SET_ADD_STATE",
          disabled: true,
          message: "Your current plan only allows up to 10 homes."
        });
        return;
      }
    }

    addStateDispatch({
      type: "SET_ADD_STATE",
      disabled: false,
      message: null
    });
  }, [userHomesLoading, getUserDataLoading, userHomes, layout, planId]);

  const handleSearchChange = ({ currentTarget }) => {
    setQuery(currentTarget.value);
  };

  const handleFilterChange = value => {
    filterStateDispatch({ type: "TOGGLE_FILTER", filterName: value });
  };

  return (
    <div
      className={`dashboard-controller dashboard-controller--${layout} ${
        !hasPlan && "limited-access"
      }  }`}
      data-testid="dashboard-controller"
    >
      <div className="dashboard-controller__inner">
        <div className="dashboard-controller__header">
          <div className="dashboard-controller__title">
            <span className="dashboard-controller__titleicon">
              <img src={DashboardIcon} alt="Page Title Icon" />
            </span>
            <h2 className="dashboard-controller__titletext">{title}</h2>
          </div>

          <DashboardAddButton
            layout={layout}
            onNew={onNew}
            disabled={addState.disabled}
            message={addState.message}
          >
            {layout === "guests" && "Add new guest"}
            {layout === "userHomes" && "Add new home"}
            {layout === "admin" && "Add new account manager"}
          </DashboardAddButton>

          <div className="dashboard-controller__search">
            <InputText
              name="dashboard-search"
              placeholder="Search"
              onChange={handleSearchChange}
              value={query}
              type="text"
              data-testid="dashboard-controller-search"
            />
            <div className="dashboard-controller__filters">
              {layout === "userHomes" && (
                <HomeFilters
                  filterState={filterState}
                  onCheck={handleFilterChange}
                />
              )}
              {layout === "guests" && (
                <GuestFilters
                  filterState={filterState}
                  onCheck={handleFilterChange}
                />
              )}
            </div>
          </div>
        </div>

        <div className="dashboard-controller__content">
          {children({
            query,
            filters: filterState,
            clearFilters: () => filterStateDispatch({ type: "CLEAR_FILTERS" })
          })}
        </div>
      </div>
    </div>
  );
};

DashboardController.propTypes = {
  children: PropTypes.func.isRequired,
  hasPlan: PropTypes.string,
  layout: PropTypes.string,
  planId: PropTypes.string,
  userHomes: PropTypes.array
};

const mapState = state => ({
  hasPlan:
    _get(state.account, "accountInfo.plan_id", false) &&
    _get(state.account, "accountInfo.plan_id", false),
  planId: _get(state.account, "accountInfo.plan_id", null),
  userHomes: _get(state.dashboard, "userHomes", null),
  getUserDataLoading: _get(state.loading, "getUserData.loading", false),
  userHomesLoading: _get(state.loading, "getUserHomes.loading", false)
});

DashboardController.propTypes = {};

export default connect(mapState)(DashboardController);
