import React from "react";
import PropTypes from "prop-types";

import ConfirmedGuestImg from "../../assets/icons/ConfirmedGuestIcon.png";
import BrochureGuestImg from "../../assets/BrochureGuest.png";

function GuestFormTypeSelectorItem({
  type,
  title,
  description,
  icon,
  onClick
}) {
  return (
    <div
      className={`guest-type-selector-item ${
        type ? `guest-type-selector-item--${type}` : ""
      }`}
    >
      <div className="guest-type-selector-item__inner">
        <button
          className="guest-type-selector-item__box"
          onClick={onClick}
          data-testid={`guest-type-selector-item-btn-${type}`}
        >
          <div className="guest-type-selector-item__icon">
            <img src={icon} alt={type} />
          </div>
          <div className="guest-type-selector-item__title">
            <h4>{title}</h4>
          </div>
        </button>

        <div className="guest-type-selector-item__description">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

GuestFormTypeSelectorItem.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
};

function GuestFormTypeSelector({ onSelect }) {
  return (
    <div
      className="guest-form-type-selector"
      data-testid="guest-form-type-selector"
    >
      <div className="guest-form-type-selector__inner">
        <GuestFormTypeSelectorItem
          type="active"
          title="Confirmed Guest"
          description="A Confirmed Guest has a confirmed reservation and check-in and check-out date."
          icon={ConfirmedGuestImg}
          onClick={() => onSelect("active")}
        />
        <GuestFormTypeSelectorItem
          type="potential"
          title="Send Brochure"
          description="A Potential Guest will receive a secure brochure version of Home Concierge."
          icon={BrochureGuestImg}
          onClick={() => onSelect("potential")}
        />
      </div>
    </div>
  );
}

GuestFormTypeSelector.propTypes = {
  onSelect: PropTypes.func
};

export default GuestFormTypeSelector;
