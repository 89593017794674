import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TooltipWrapper from "../common/TooltipWrapper";

const DashboardAddButton = ({ layout, onNew, children, disabled, message }) => {
  return (
    <div className={`dashboard-add-btn ${clsx({ disabled })}`}>
      <TooltipWrapper title={message} show={disabled}>
        <button
          className="dashboard-add-btn__button"
          onClick={evt => (disabled ? evt.preventDefault() : onNew(evt))}
          data-testid="dashboard-add-button"
        ></button>
      </TooltipWrapper>
      <h2 className="dashboard-add-btn__text"> {children}</h2>
    </div>
  );
};

DashboardAddButton.propTypes = {
  onNew: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  message: PropTypes.string
};

export default DashboardAddButton;
