import React from "react";
import { BraintreeProvider } from "./contexts/braintreeContext";

function Provider({ clientToken, children }) {
  return (
    <BraintreeProvider value={{ clientToken }}>{children}</BraintreeProvider>
  );
}

export default Provider;
