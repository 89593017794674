import React from "react";
import PropTypes from "prop-types";

import GridHeader from "./GridHeader";
import GridLayout from "./GridLayout";
import GridItem from "./GridItem";
import BlankGridItem from "./BlankGridItem";
import UserPlaceholderIcon from "../../assets/icons/UserPlaceholder.jpeg";

const POIGridItem = props => {
  if (!props.id) return <BlankGridItem {...props} banner={props.banner} />;
  return (
    <GridItem
      key={props.title}
      {...props}
      photoData={props.image ? props.image : UserPlaceholderIcon}
      overlayItems={{
        available: ["view", "add"],
        selected: ["view", "remove"]
      }}
      onDragStop={props.onDragStop}
    />
  );
};

function POISelectedGrid({ items, onDragStop, onView, onRemove }) {
  return (
    <div
      className="poi-grid-items poi-grid-items--selected"
      data-testid="poi-selected-grid"
    >
      <GridHeader color="blue">Use our Pre-Selected Favorites</GridHeader>

      <GridLayout rows={3} compact={true} columns={4} items={items}>
        {({ gridArray }) => (
          <div className="poi-grid-items__grid">
            {gridArray.map((item, i) => {
              return (
                <POIGridItem
                  key={item.id ? `${item.id}-${item.title}` : i}
                  image={
                    item.image
                      ? item.image.length > 0
                        ? item.image.find(({ size }) => size === "thumb").url
                        : null
                      : null
                  }
                  id={item.id}
                  title={item.name}
                  sequence={item.sequence}
                  gridIndex={i}
                  type="selected"
                  currentPageHash={6}
                  onDragStop={onDragStop}
                  columns={4}
                  onView={onView}
                  onRemove={onRemove}
                  scrollContainer={`.poi-grid-items__grid`}
                />
              );
            })}
          </div>
        )}
      </GridLayout>
    </div>
  );
}
POISelectedGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        fullAddress: PropTypes.string
      }),
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired
        })
      ),
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sequence: PropTypes.number.isRequired
    })
  ),
  onRemove: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
};
export default POISelectedGrid;
