import React, { useRef } from "react";
import PropTypes from "prop-types";
import AccordionItem from "../common/AccordionItem";
import UploadBorderWrapper from "../common/UploadBorderWrapper";
import PromptImageUpload from "./PromptImageUpload";
import { Field, useFormikContext } from "formik";
import ButtonMain from "../common/ButtonMain";
import InputEditorFormik from "../common/InputEditorFormik";
import PromptPhotoCanvas from "./PromptPhotoCanvas";
import InputTextFormik from "../common/InputTextFormik";
import { processImage } from "hc-utils/fileHelper";

import { ReactComponent as featureVideoSVG } from "../../assets/icons/FeatureVideo.svg";
import { ReactComponent as userManualSVG } from "../../assets/icons/userManual.svg";

import uuidv4 from "uuid";
import _get from "lodash.get";

function FeatureViewPromptPageNew({
  index,
  item = {},
  prefilled = '',
  onMove,
  onRemove
}) {
  const imageObjRef = useRef(null);
  const circleObjRef = useRef(null);
  const canvasObjRef = useRef(null);

  const { setFieldValue, errors, touched } = useFormikContext();

  const handleFileUpload = files => {
    processImage(files[0])
      .then(file => {
        setFieldValue(
          `features[${index}].image.url`,
          window.URL.createObjectURL(file)
        );
        setFieldValue(`features[${index}].image.file`, file);
        setFieldValue(`features[${index}].coords.photo`, { x: 100, y: 100 });
        setFieldValue(`features[${index}].coords.circle`, null);
      })
      .catch(error => {
        console.error(error);
      });
  };

  // reverts current page text to default prefilled or empty string
  const revertToDefaultText = () => {
    setFieldValue(`features[${index}].text`, prefilled || "");
  };

  // sets handlers/local ref for imageObject for feature page
  const handleImageObjInit = imageObject => {
    imageObjRef.current = imageObject;
    imageObjRef.current.addListener("change", ({ pos, height, width }) => {
      setFieldValue(`features[${index}].coords.photo`, {
        ...pos,
        height,
        width
      });
    });
  };

  const handleCircleObjInit = circleObj => {
    circleObjRef.current = circleObj;
    circleObjRef.current.addListener("change", ({ pos, radius }) => {
      setFieldValue(`features[${index}].coords.circle`, {
        ...pos,
        radius
      });
    });
  };

  const handleCanvasObjInit = canvasObj => {
    canvasObjRef.current = canvasObj;
    setFieldValue(`features[${index}].canvas`, canvasObjRef.current);
  };

  // removes the image for the current page
  const removeImage = () => {
    setFieldValue(`features[${index}].image`, null);
    setFieldValue(`features[${index}].coords.circle`, null);
    setFieldValue(`features[${index}].coords.photo`, null);
  };

  const removeCircle = () => {
    setFieldValue(`features[${index}].coords.circle`, null);
  };

  const { id, image, coords } = item;
  return (
    <div className="feature-prompt-page">
      <AccordionItem
        title={`Page ${index + 1}`}
        onRemove={onRemove}
        onMove={onMove}
      >
        <UploadBorderWrapper onUpload={handleFileUpload}>
          {_get(image, "url", false) ? (
            <PromptPhotoCanvas
              id={id || uuidv4()}
              image={image.url}
              setImageObject={handleImageObjInit}
              setArcObject={handleCircleObjInit}
              setCanvas={handleCanvasObjInit}
              coords={
                coords ? { arc: coords.circle, photo: coords.photo } : null
              }
              index={index}
              item={item}
              removeImage={removeImage}
              onRemoveCircle={removeCircle}
            />
          ) : (
            <PromptImageUpload onUpload={handleFileUpload} />
          )}
        </UploadBorderWrapper>
        <div
          name="user-form user-form--prompt"
          className="user-form user-form--prompt"
        >
          <Field
            component={InputEditorFormik}
            id={`features[${index}].text`}
            name={`features[${index}].text`}
            placeholder={
              item.text || !prefilled
                ? "Description"
                : "Descriptions below are examples only. Please alter them to reflect YOUR details."
            }
            note="Add a description for the photo."
          />

          <ButtonMain
            color="ghost-gray"
            className="revert-button"
            onClick={revertToDefaultText}
          >
            Revert to default text
          </ButtonMain>

          <Field
            component={InputTextFormik}
            size="full"
            name={`features[${index}].user_manual_url`}
            id={`features[${index}].user_manual_url`}
            placeholder="User Manual URL"
            icon={userManualSVG}
          />
          <Field
            component={InputTextFormik}
            size="full"
            name={`features[${index}].video_url`}
            id={`features[${index}].video_url`}
            placeholder="Youtube, Vimeo, or other video URL"
            icon={featureVideoSVG}
          />
        </div>
      </AccordionItem>
    </div>
  );
}

FeatureViewPromptPageNew.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object,
  prefilled: PropTypes.string,
  onMove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default FeatureViewPromptPageNew;
