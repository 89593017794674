import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import _get from "lodash.get";

import GuestFormSection from "./GuestFormSection";

import { connect as formikConnect, Field } from "formik";
import { connect } from "react-redux";

import { dashboardOperations } from "./duck";
import GuestHostList from "./GuestHostList";
import InputCheckboxFormik from "../common/InputCheckboxFormik";

const GuestFormHostsInfo = ({
  data,
  getHosts,
  loading,
  currentHomeId,
  isNew,
  formik: {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  }
}) => {
  const [hosts, setHosts] = useState(null);
  const [homeMainHost, setHomeMainHost] = useState(null);
  const [homeBackupHost, setHomeBackupHost] = useState(null);

  const getHostByRole = (role, hosts) =>
    hosts.selected.length > 0
      ? hosts.selected.find(
          ({ sequence_no }) => sequence_no === (role === "backup" ? 2 : 1)
        )
      : null;

  useEffect(() => {
    if (hosts) return;
    getHosts(currentHomeId).then(({ hosts }) => {
      const mainHost = getHostByRole("main", hosts);
      const backupHost = getHostByRole("backup", hosts);

      setHosts(hosts);
      if (mainHost) {
        setHomeMainHost(mainHost);
      }
      if (backupHost) {
        setHomeBackupHost(backupHost);
      }

      if (!values.mainHostId)
        setFieldValue("mainHostId", {
          value: mainHost ? mainHost.id : null,
          label: mainHost
            ? `${mainHost.first_name} ${mainHost.last_name}`
            : "No Host Selected",
          isDefault: true,
          isDisabled: false
        });

      if (!values.backupHostId)
        setFieldValue("backupHostId", {
          value: null,
          label: backupHost
            ? `${backupHost.first_name} ${backupHost.last_name}`
            : "No Host Selected",
          isDefault: true,
          isDisabled: false
        });
    });
  }, [
    currentHomeId,
    getHosts,
    hosts,
    setFieldValue,
    values.backupHostId,
    values.mainHostId
  ]);

  if (loading) return <p>loading hosts...</p>;

  const handleHostChange = (value, type) => {
    setFieldValue(type === "main" ? "mainHostId" : "backupHostId", value);
  };

  const handleHostBlur = type => {
    setFieldTouched(type === "main" ? "mainHostId" : "backupHostId");
  };
  return (
    <GuestFormSection
      title="Guest Host(s) Info"
      className={loading ? "loading" : ""}
    >
      <GuestHostList
        type="main"
        values={values}
        hosts={hosts}
        homeHosts={{ main: homeMainHost, backup: homeBackupHost }}
        onChange={handleHostChange}
        onBlur={handleHostBlur}
        title="Main Host"
        loading={loading}
        error={
          _get(errors, "mainHostId", false) &&
          _get(touched, "mainHostId", false)
            ? _get(errors, "mainHostId", null)
            : false
        }
      />
      <GuestHostList
        type="backup"
        values={values}
        hosts={hosts}
        homeHosts={{ main: homeMainHost, backup: homeBackupHost }}
        onChange={handleHostChange}
        onBlur={handleHostBlur}
        title="Backup Host"
        loading={loading}
      />
      {isNew && (
        <Field
          component={InputCheckboxFormik}
          label={
            <p>
              Do you want to send a <strong>receipt</strong> to the main host?
            </p>
          }
          name="sendReceipt"
          size="full"
          id="sendReceipt"
          required={false}
        />
      )}
    </GuestFormSection>
  );
};

GuestFormHostsInfo.propTypes = {
  currentHomeId: PropTypes.number.isRequired,
  formik: PropTypes.object,
  getHosts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
const mapState = state => ({
  loading: _get(state.loading, "getHosts.loading", false),
  currentHomeId: _get(state.global, "current.home.id", null)
});

const mapDispatch = dispatch => ({
  getHosts: houseId => {
    return dispatch(dashboardOperations.getHosts(houseId));
  }
});

export default formikConnect(
  connect(mapState, mapDispatch)(GuestFormHostsInfo)
);
