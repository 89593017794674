import Creators from "./actions";

import { api } from "hc-utils/HomeAPIWrapper";
import { globalOperations } from "../../../duck/global";
import { loadingOperations } from "../../../duck/loading";
import _get from "lodash.get";
import composePlans from "../../../utilities/composePlans";

const setUserCredentials = globalOperations.setUserCredentials;
const logoutUser = globalOperations.logoutUser;
const setLoading = loadingOperations.setLoading;
const setUserData = Creators.setUserData;
const setAccountProfileInfo = Creators.setAccountProfileInfo;
const setAccountEmail = Creators.setAccountEmail;
const setAccountPaymentMethods = Creators.setAccountPaymentMethods;
const setAccountPaymentMethod = Creators.setAccountPaymentMethod;
const setAccountBillingHistory = Creators.setAccountBillingHistory;
const setDefaultCard = Creators.setDefaultCard;
const addNewPaymentMethod = Creators.addNewPaymentMethod;
const removePaymentMethod = Creators.removePaymentMethod;
const setClientToken = Creators.setClientToken;
const addSubscription = Creators.addSubscription;
const setAvailablePlans = Creators.setAvailablePlans;
const removeSubscription = Creators.removeSubscription;
const setBillingAddress = Creators.setBillingAddress;
const createAccount = Creators.createAccount;
const fetchUserData = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("getUserData", "REQUEST"));
    api
      .getUserData()
      .then(({ userData }) => {
        dispatch(setUserData(userData));
        dispatch(setLoading("getUserData", "SUCCESS"));
        resolve(userData);
      })
      .catch(error => {
        window.console.error(error);
        dispatch(setLoading("getUserData", "FAILED"));
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({ message: "You are unauthorized to view this content" });
          });
        } else {
          reject({ message: "Unable to fetch user data. Please try again." });
        }
      });
  });
};

const changePassword = (oldPassword, newPassword) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("changePassword", "REQUEST"));
    api
      .changePassword(oldPassword, newPassword)
      .then(() => {
        dispatch(setLoading("changePassword", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("changePassword", "FAILED"));

        if (_get(error, "response.status") === 400) {
          reject({ message: "Incorrect password. Please try again." });
        } else if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({ message: "You are unauthorized to view this content" });
          });
        } else {
          reject({
            message: "Unable to change your password. Please try again."
          });
        }
      });
  });
};

const updateAccountProfileInfo = profileInfo => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateAccountProfileInfo", "REQUEST"));
    api
      .updateAccountProfileInfo(profileInfo)
      .then(() => {
        dispatch(setAccountProfileInfo(profileInfo));
        dispatch(setLoading("updateAccountProfileInfo", "SUCCESS"));
        resolve(profileInfo);
      })
      .catch(error => {
        dispatch(setLoading("updateAccountProfileInfo", "FAILED"));
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to preform this action."
            });
          });
        } else {
          reject({
            message:
              "Unable to update your account info at this time. Please try again."
          });
        }
      });
  });
};

const changeAccountEmail = newEmail => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("changeAccountEmail", "REQUEST"));
    api
      .changeAccountEmail(newEmail)
      .then(() => {
        dispatch(setAccountEmail(newEmail));
        dispatch(setLoading("changeAccountEmail", "SUCCESS"));
        resolve(newEmail);
      })
      .catch(error => {
        dispatch(setLoading("changeAccountEmail", "FAILED"));
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            message: "Unable to change the account email. Please try again."
          });
        }
      });
  });
};

const registerAccount = values => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("registerAccount", "REQUEST"));
    api
      .registerAccount(values)
      .then(userData => {
        dispatch(createAccount(userData));

        dispatch(
          setUserCredentials({ token: userData.token, role: userData.role })
        );
        dispatch(setAccountEmail(userData.email));
        dispatch(setLoading("registerAccount", "SUCCESS"));
        resolve(userData);
      })
      .catch(error => {
        dispatch(setLoading("registerAccount", "FAILED"));
        if (
          _get(error, "response.status", null) === 401 &&
          _get(error, "response.data.error") === "token_invalid"
        ) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            error,
            message: _get(error, "response.data.message")
              ? error.response.data.message
              : "There was an issue registering your account. Please try again."
          });
        }
      });
  });
};

const fetchAccountPaymentMethods = customerId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("fetchAccountPaymentMethods", "REQUEST"));
    api
      .fetchAccountPaymentMethods(customerId)
      .then(data => {
        const creditCards = !data.creditCards
          ? []
          : data.creditCards.map(card => ({
              ...card,
              type: "creditCard"
            }));

        const paypalAccounts = !data.paypalAccounts
          ? []
          : data.paypalAccounts.map(acct => ({
              ...acct,
              type: "payPal"
            }));

        const paymentMethods = creditCards.concat(paypalAccounts);

        dispatch(setAccountPaymentMethods(paymentMethods));
        dispatch(setLoading("fetchAccountPaymentMethods", "SUCCESS"));
        resolve(data);
      })
      .catch(err => {
        dispatch(setLoading("fetchAccountPaymentMethods", "FAILED"));
        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            message:
              "Unable to fetch the active payment methods. Please try again."
          });
        }
      });
  });
};

const updateAccountPaymentMethod = ({
  paymentMethodToken,
  paymentMethodNonce
}) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateAccountPaymentMethod", "REQUEST"));
    api
      .updateAccountPaymentMethod({ paymentMethodToken, paymentMethodNonce })
      .then(updatedMethod => {
        dispatch(setAccountPaymentMethod(updatedMethod));
        dispatch(setLoading("updateAccountPaymentMethod", "SUCCESS"));
        resolve(updatedMethod);
      })
      .catch(err => {
        dispatch(setLoading("updateAccountPaymentMethod", "FAILED"));

        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            message:
              "Unable to update account payment method. Please try again."
          });
        }
      });
  });
};

const fetchBillingHistory = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("fetchBillingHistory", "REQUEST"));
    api
      .fetchBillingHistory()
      .then(billingHistory => {
        dispatch(setAccountBillingHistory(billingHistory));
        dispatch(setLoading("fetchBillingHistory", "SUCCESS"));
        resolve(billingHistory);
      })
      .catch(err => {
        dispatch(setLoading("fetchBillingHistory", "FAILED"));
        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to view this content."
            });
          });
        } else {
          reject({
            message: "Unable to fetch billing history. Please try again."
          });
        }
      });
  });
};

const updateDefaultCard = paymentMethodToken => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateDefaultCard", "REQUEST"));
    dispatch(setLoading(`${paymentMethodToken}_updateDefaultCard`, "REQUEST"));
    api
      .updateDefaultCard(paymentMethodToken)
      .then(newPaymentMethod => {
        dispatch(setDefaultCard(paymentMethodToken));
        dispatch(setLoading("updateDefaultCard", "SUCCESS"));
        dispatch(
          setLoading(`${paymentMethodToken}_updateDefaultCard`, "SUCCESS")
        );
        resolve(newPaymentMethod);
      })
      .catch(error => {
        dispatch(setLoading("updateDefaultCard", "FAILED"));
        dispatch(
          setLoading(`${paymentMethodToken}_updateDefaultCard`, "FAILED")
        );

        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            message: "Unable to update default card. Please try again."
          });
        }
      });
  });
};

const createNewPaymentMethod = (nonce, methodType) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("createNewPaymentMethod", "REQUEST"));
    api
      .createNewPaymentMethod(nonce)
      .then(newPaymentMethod => {
        dispatch(addNewPaymentMethod(newPaymentMethod, methodType));
        dispatch(setLoading("createNewPaymentMethod", "SUCCESS"));
        resolve(newPaymentMethod);
      })
      .catch(err => {
        const errorStatus = _get(err, "response.status", null);
        const errorMessage = _get(err, "response.data.message", null);
        dispatch(setLoading("createNewPaymentMethod", "FAILED"));
        if (errorStatus === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else if (errorStatus === 409) {
          if (errorMessage === "Processor Network Unavailable - Try Again") {
            reject({
              message:
                "There was an issue connecting to your banks payment processor - this is most likely a temporary issue with your bank. Please try again later."
            });
          } else {
            reject({
              message:
                "There was an issue verifying your payment method. Please check your payment details entered above and contact help@homeconcierge.com if the issue persists."
            });
          }
        } else {
          reject({
            message: "Unable to create new payment method. Please try again."
          });
        }
      });
  });
};

const deletePaymentMethod = paymentMethodToken => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("deletePaymentMethod", "REQUEST"));
    dispatch(
      setLoading(`${paymentMethodToken}_deletePaymentMethod`, "REQUEST")
    );
    api
      .deletePaymentMethod(paymentMethodToken)
      .then(deletedPaymentMethod => {
        dispatch(removePaymentMethod(paymentMethodToken));
        dispatch(
          setLoading(`${paymentMethodToken}_deletePaymentMethod`, "SUCCESS")
        );
        dispatch(setLoading("deletePaymentMethod", "SUCCESS"));
        resolve(deletedPaymentMethod);
      })
      .catch(error => {
        dispatch(
          setLoading(`${paymentMethodToken}_deletePaymentMethod`, "FAILED")
        );
        dispatch(setLoading("deletePaymentMethod", "FAILED"));
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            message: "Unable to delete payment method. Please try again."
          });
        }
      });
  });
};

const registerSubscription = (
  planId,
  paymentMethodNonce,
  paymentMethodToken
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("registerSubscription", "REQUEST"));
    api
      .registerSubscription(planId, paymentMethodNonce, paymentMethodToken)
      .then(subscription => {
        dispatch(addSubscription(subscription));
        dispatch(setLoading("registerSubscription", "SUCCESS"));
        resolve(subscription);
      })
      .catch(err => {
        const errorStatus = _get(err, "response.status", null);
        const errorMessage = _get(err, "response.data.message", null);

        dispatch(setLoading("registerSubscription", "FAILED"));
        if (errorStatus === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else if (errorStatus === 409) {
          if (errorMessage === "Processor Network Unavailable - Try Again") {
            reject({
              message:
                "There was an issue connecting to your banks payment processor - this is most likely a temporary issue with your bank. Please try again later."
            });
          } else {
            reject({
              message:
                "There was an issue verifying your payment method. Please check your payment details entered above and contact help@homeconcierge.com if the issue persists."
            });
          }
        } else {
          reject({
            message: "Unable to register subscription. Please try again."
          });
        }
      });
  });
};

const fetchAvailablePlans = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("fetchAvailablePlans", "REQUEST"));
    api
      .getAvailablePlans()
      .then(plans => {
        const plansObject = composePlans(plans);

        dispatch(setAvailablePlans(plansObject));

        dispatch(setLoading("fetchAvailablePlans", "SUCCESS"));
        resolve(plansObject);
      })
      .catch(err => {
        dispatch(setLoading("fetchAvailablePlans", "FAILED"));
        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({ message: "You are unauthorized to view this content." });
          });
        } else {
          reject({
            message: "Unable to fetch available plans. Please try again."
          });
        }
      });
  });
};

const changeSubscription = newPlanObject => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("changeSubscription", "REQUEST"));
    api
      .changeSubscription(newPlanObject.id)
      .then(data => {
        dispatch(
          addSubscription({
            planId: newPlanObject.id,
            planName: newPlanObject.name
          })
        );
        dispatch(setLoading("changeSubscription", "SUCCESS"));
        resolve(data);
      })
      .catch(err => {
        dispatch(setLoading("changeSubscription", "FAILED"));
        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else if (_get(err, "response.status") === 405) {
          reject({
            message:
              "You have more homes than this plan (My Home) allows. Please remove homes before changing your subscription plan."
          });
        } else {
          reject({
            message:
              "Unable to change subscription at this time. Please try again."
          });
        }
      });
  });
};

const cancelSubscription = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("cancelSubscription", "REQUEST"));
    api
      .cancelSubscription()
      .then(() => {
        dispatch(removeSubscription());
        dispatch(setLoading("cancelSubscription", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("cancelSubscription", "FAILED"));
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            message: "Unable to cancel subscription. Please try again."
          });
        }
      });
  });
};

const fetchBillingAddress = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("fetchBillingAddress", "REQUEST"));
    api
      .getBillingAddress()
      .then(billingAddress => {
        dispatch(setBillingAddress(billingAddress));
        dispatch(setLoading("fetchBillingAddress", "SUCCESS"));
        resolve(billingAddress);
      })
      .catch(err => {
        dispatch(setLoading("fetchBillingAddress", "FAILED"));
        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({ message: "You are not authorized to view this content." });
          });
        } else {
          reject({
            message: "Unable to get billing address. Please try again."
          });
        }
      });
  });
};

const updateBillingAddress = values => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateBillingAddress", "REQUEST"));
    api
      .updateBillingAddress(values)
      .then(billingAddress => {
        dispatch(setBillingAddress(billingAddress));
        dispatch(setLoading("updateBillingAddress", "SUCCESS"));
        resolve(billingAddress);
      })
      .catch(err => {
        dispatch(setLoading("updateBillingAddress", "FAILED"));
        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to perform this action."
            });
          });
        } else {
          reject({
            message: "Unable to update billing address. Please try again."
          });
        }
      });
  });
};

export default {
  setUserData,
  fetchUserData,
  changePassword,
  updateAccountProfileInfo,
  changeAccountEmail,
  setAccountEmail,
  fetchAccountPaymentMethods,
  updateAccountPaymentMethod,
  fetchBillingHistory,
  updateDefaultCard,
  createNewPaymentMethod,
  deletePaymentMethod,
  registerSubscription,
  setClientToken,
  fetchAvailablePlans,
  changeSubscription,
  cancelSubscription,
  fetchBillingAddress,
  updateBillingAddress,
  setAccountProfileInfo,
  setAccountPaymentMethods,
  setAccountPaymentMethod,
  removePaymentMethod,
  addSubscription,
  setAvailablePlans,
  removeSubscription,
  setBillingAddress,
  setAccountBillingHistory,
  setDefaultCard,
  addNewPaymentMethod,
  registerAccount,
  createAccount
};
