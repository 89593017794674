import React, { useState } from "react";
import PropTypes from "prop-types";

export const SuggestionContext = React.createContext({});

function SuggestionProvider({ children }) {
  const [featureId, setFeatureId] = useState(null);
  const [featureName, setFeatureName] = useState(null);
  const [suggestionPrompt, setSuggestionPrompt] = useState(false);

  return (
    <SuggestionContext.Provider
      value={{
        featureId,
        featureName,
        setFeatureId,
        setFeatureName,
        suggestionPrompt,
        showSuggestionPrompt: () => setSuggestionPrompt(true),
        closeSuggestionPrompt: () => setSuggestionPrompt(false)
      }}
    >
      {children}
    </SuggestionContext.Provider>
  );
}

SuggestionProvider.propTypes = {
  children: PropTypes.any
};

export const withSuggestionContext = Component => {
  return function WrapperComponent(props) {
    return (
      <SuggestionContext.Consumer>
        {state => <Component {...props} suggestionContext={state} />}
      </SuggestionContext.Consumer>
    );
  };
};

export default SuggestionProvider;
