import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import parseQuery from "hc-utils/parseQuery";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import GridHeader from "../../GridHeader";
import posed, { PoseGroup } from "react-pose";
import BuilderButton from "../../BuilderButton";
import { SuggestionContext } from "../../../common/SuggestionProvider";

const TransWrapper = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    opacity: { duration: 150, ease: "out" }
  }
});

function GuestServicesSelector({ tabs, children }) {
  const { setFeatureId, setFeatureName } = useContext(SuggestionContext);

  const location = useLocation();
  const history = useHistory();
  const currentBuilderId = useSelector(state => state.builder.currentBuilderId);

  const currentCat = parseQuery(location.search);

  useEffect(() => {
    const currentTab = tabs.find(({ key }) => key === currentCat.category);

    if (!currentTab) return;
    setFeatureName(currentTab.label);
    setFeatureId(currentTab.feature_id);
  }, [currentCat.category, setFeatureId, setFeatureName, tabs]);

  const handleTabChange = (tab, index) => {
    setFeatureId(tab.feature_id);
    setFeatureName(tab.label);
    history.push({
      pathname: `/home/edit/${currentBuilderId}/guestServices`,
      search: `?category=${tab.key}`
    });
  };

  const tabPage = tabs.findIndex(tab => tab.key === currentCat.category);

  return (
    <div className="guest-services-selector">
      <div className="guest-services-selector__inner">
        <div className="guest-services-selector__tabs">
          <GridHeader color="blue">Choose a category below</GridHeader>
          <div className="play-grid-selector__items">
            <PoseGroup flipMove={false} animateOnMount={true}>
              {tabs.map((category, i) => (
                <TransWrapper key={i}>
                  <BuilderButton
                    key={category.key}
                    onClick={() => handleTabChange(category, i)}
                    type="svg"
                    icon={{ type: "image", data: category.image }}
                    name={category.name}
                    isEmpty={category.isEmpty}
                    isActive={
                      currentCat.category === category.key ? true : false
                    }
                  >
                    {category.label}
                  </BuilderButton>
                </TransWrapper>
              ))}
            </PoseGroup>
          </div>
        </div>
        <div className="guest-services-selector__content">
          {Array.isArray(children)
            ? children.filter(ch => ch)[tabPage]
            : children}
        </div>
      </div>
    </div>
  );
}

GuestServicesSelector.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      feature_id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

export default GuestServicesSelector;
