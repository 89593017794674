import types from "./types";

let id = 0;
const defaultOptions = {
  intent: "success"
};
const createToast = options => {
  return {
    ...defaultOptions,
    ...options,
    id: id++
  };
};

const addToast = (options = {}) => {
  return {
    type: types.ADD_TOAST,
    toast: createToast(options)
  };
};

const removeToast = id => {
  return {
    type: types.REMOVE_TOAST,
    id
  };
};

export default {
  addToast,
  removeToast
};
