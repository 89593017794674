import React from "react";
import payPalIcon from "../../assets/icons/paypal-icon.svg";
function PaypalAccountCard({
  firstName = "testfn",
  lastName = "testln",
  email = "testemail"
}) {
  return (
    <div className="paypal-account-card">
      <div className="paypal-account-card__inner">
        <div className="paypal-account-card__icon">
          <img src={payPalIcon} alt={"Paypal Account"} />
        </div>
        <div className="paypal-account-card__info">
          <h4>
            {firstName} {lastName}
          </h4>
          <p>{email}</p>
        </div>
      </div>
    </div>
  );
}

export default PaypalAccountCard;
