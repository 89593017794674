import React, { useState, useEffect } from "react";
import AccountPage from "../AccountPage";
import { Formik } from "formik";
import _get from "lodash.get";
import InputText from "../../common/InputText";
import ButtonMain from "../../common/ButtonMain";
import { connect } from "react-redux";
import { accountOperations } from "../../my-account/duck";
import { toastOperations } from "../../../duck/toast";
import { CSSTransition } from "react-transition-group";
import SpinnerLoader from "../../common/SpinnerLoader";

function ChangeLink({ onClick, className }) {
  return (
    <div className={`change-link ${className ? className : ""}`}>
      <button onClick={onClick}>Change</button>
    </div>
  );
}

function AccountPageAddressForm({
  data,
  onSubmit,
  isReadOnly,
  submitLoading,
  getProfileAddress
}) {
  return (
    <Formik
      initialValues={{
        addressLine1: _get(data, "addressLine1", ""),
        addressLine2: _get(data, "addressLine2", ""),
        state: _get(data, "state", ""),
        city: _get(data, "city", ""),
        zipCode: _get(data, "zipCode", "")
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        errors,
        touched
      }) => (
        <form className="account-page-address-form" onSubmit={handleSubmit}>
          <InputText
            name="addressLine1"
            type="text"
            size="full"
            placeholder="Address Line 1"
            autoComplete="street-address"
            value={values.addressLine1}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isReadOnly}
            displayError={
              errors.addressLine1 && touched.addressLine1
                ? errors.addressLine1
                : false
            }
          />
          <InputText
            name="addressLine2"
            type="text"
            size="full"
            placeholder="Address Line 2"
            autoComplete="address-line2"
            value={values.addressLine2}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isReadOnly}
            displayError={
              errors.addressLine2 && touched.addressLine2
                ? errors.addressLine2
                : false
            }
          />
          <InputText
            name="city"
            type="text"
            size="full"
            placeholder="City"
            autoComplete="address-level2"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isReadOnly}
            displayError={errors.city && touched.city ? errors.city : false}
          />
          <InputText
            name="state"
            type="text"
            size="half"
            placeholder="State"
            autoComplete="address-level1"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isReadOnly}
            displayError={errors.state && touched.state ? errors.state : false}
          />
          <InputText
            name="zipCode"
            type="text"
            size="half"
            placeholder="ZIP Code"
            autoComplete="postal-code"
            value={values.zipCode}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isReadOnly}
            displayError={
              errors.zipCode && touched.zipCode ? errors.zipCode : false
            }
          />
          <ButtonMain
            color="aqua"
            type="submit"
            isDisabled={isReadOnly}
            loading={submitLoading}
          >
            Update Address
          </ButtonMain>
        </form>
      )}
    </Formik>
  );
}

function AccountPageAddress({
  data,
  loading,
  updateAddressLoading,
  fetchBillingAddress,
  fetchBillingAddressLoading,
  updateBillingAddress,
  billingAddress,
  addToast
}) {
  const [isReadOnly, setReadOnly] = useState(true);

  useEffect(() => {
    if (!billingAddress) {
      fetchBillingAddress().catch(err => {
        window.console.error(err);
      });
    }
  }, [billingAddress, fetchBillingAddress]);

  const handleUpdate = values => {
    updateBillingAddress(values)
      .then(() => {
        setReadOnly(true);
        addToast({ text: "Updated profile address." });
      })
      .catch(err => {
        console.error(err);
        addToast({
          text:
            "There was an error getting your billing address. Please try again later.",
          intent: "error"
        });
      });
  };

  return (
    <AccountPage page="address">
      <div className="account-page__header">
        <h3>Address</h3>
        <ChangeLink
          className={isReadOnly && `is-read-only`}
          onClick={() => setReadOnly(!isReadOnly)}
        />
      </div>
      <div className={`account-page__content ${isReadOnly && `is-read-only`}`}>
        <CSSTransition
          in={loading}
          classNames="fade"
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <div className="spinner-loader-wrapper" style={{ minHeight: "90px" }}>
            <SpinnerLoader />
          </div>
        </CSSTransition>
        <CSSTransition
          in={!loading}
          timeout={300}
          classNames="fade"
          mountOnEnter
          unmountOnExit
        >
          <div className="account-page__contentinner">
            <AccountPageAddressForm
              data={billingAddress}
              onSubmit={handleUpdate}
              isReadOnly={isReadOnly}
              submitLoading={updateAddressLoading}
            />
          </div>
        </CSSTransition>
      </div>
    </AccountPage>
  );
}

const mapDispatch = dispatch => ({
  fetchBillingAddress: () => dispatch(accountOperations.fetchBillingAddress()),
  updateBillingAddress: values =>
    dispatch(accountOperations.updateBillingAddress(values)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

const mapState = state => ({
  updateAddressLoading: _get(
    state.loading,
    "updateBillingAddress.loading",
    false
  ),
  fetchBillingAddressLoading: _get(
    state.loading,
    "fetchBillingAddress.loading",
    true
  ),
  billingAddress: _get(state.account, "billingAddress", null)
});

export default connect(mapState, mapDispatch)(AccountPageAddress);
