import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ButtonMain from "./ButtonMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function Banner({
  className,
  children,
  ctaLink,
  overlineText,
  ctaText = "open",
  ctaColor = "gray",
  onClose
}) {
  return (
    <div className={clsx("banner container", className)}>
      <div className={clsx("banner__inner container__inner")}>
        <div className="banner__text">{children}</div>
        {overlineText && <div className="banner__overline">{overlineText}</div>}
        <div className="banner__cta">
          <ButtonMain link={ctaLink} color={ctaColor} outbound={true}>
            {ctaText}
          </ButtonMain>
        </div>

        <div className="banner__close">
          <FontAwesomeIcon icon={faTimes} size="2x" onClick={onClose} />
        </div>
      </div>
    </div>
  );
}

Banner.propTypes = {
  className: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  overlineText: PropTypes.any,
  ctaColor: PropTypes.oneOf([
    "red",
    "aqua",
    "blue",
    "gray",
    "white",
    "ghost-gray"
  ]),
  onClose: PropTypes.func.isRequired
};

export default Banner;
