import React from "react";

function BillingLedger({ price, term, loading }) {
  const computeTermText = term => {
    switch (term) {
      case "monthly": {
        return "per month";
      }
      case "annual": {
        return "per year";
      }
      default: {
        return;
      }
    }
  };
  return (
    <div className="billing-ledger">
      <div className="billing-ledger__total">
        <p>Total</p>
        <p>
          {loading ? "loading..." : price ? `$${price}` : "No Plan Selected"}
        </p>
      </div>
      <div className="billing-ledger__term">
        <small>{computeTermText(term)}</small>
      </div>
    </div>
  );
}

export default BillingLedger;
