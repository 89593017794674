import React, { useState } from "react";
import {
  faMinusCircle,
  faArrowCircleUp,
  faArrowCircleDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AccordionMove({ direction, onClick }) {
  const moveIcon = direction => {
    switch (direction) {
      case "up": {
        return faArrowCircleUp;
      }
      case "down": {
        return faArrowCircleDown;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className="accordion-action accordion-action--move" onClick={onClick}>
      <FontAwesomeIcon icon={moveIcon(direction)} />
    </div>
  );
}
function AccordionRemove({ onClick }) {
  return (
    <div
      className="accordion-action accordion-action--remove"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faMinusCircle} />
    </div>
  );
}

function AccordionItem({
  children,
  title,
  onRemove,
  onMove,
  initialToggled = true
}) {
  const [isToggled, setToggled] = useState(initialToggled);
  const toggle = () => {
    setToggled(!isToggled);
  };
  return (
    <div
      className={`accordion-item ${isToggled ? `accordion-item--toggled` : ""}`}
    >
      <div className="accordion-item__inner">
        <div className="accordion-item__header" onClick={toggle}>
          <div className="accordion-item__title">
            <h2>{title}</h2>
          </div>
          <div className="accordion-item__actions">
            <AccordionRemove
              onClick={evt => {
                onRemove();
                evt.stopPropagation();
              }}
            />
            <AccordionMove
              direction="up"
              onClick={evt => {
                onMove("up");
                evt.stopPropagation();
              }}
            />
            <AccordionMove
              direction="down"
              onClick={evt => {
                onMove("down");
                evt.stopPropagation();
              }}
            />
          </div>
        </div>
        <div className="accordion-item__content">{children}</div>
      </div>
    </div>
  );
}

export default AccordionItem;
