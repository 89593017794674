import React from "react";

const uuidv4 = require("uuid/v4");

function IFrameWrapper({
  id = uuidv4(),
  url,
  width = "100%",
  height = "100%"
}) {
  return (
    <div className="iframe-wrapper">
      <iframe id={id} src={url} title={id} width={width} height={height} />
    </div>
  );
}

export default IFrameWrapper;
