import { parsePhoneNumberFromString } from "libphonenumber-js";

const formatPhone = phoneNumber => {
  if (!phoneNumber) return phoneNumber;
  const formattedPhoneNumber = parsePhoneNumberFromString(phoneNumber, "US");
  try {
	return formattedPhoneNumber.formatNational();
  } catch (e) {
	return formattedPhoneNumber;
  }
};

export default formatPhone;
