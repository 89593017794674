import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

export class FeatureController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      options: []
    };
  }

  componentDidMount() {
    const { pageValues } = this.props;
    if (pageValues) {
      this.setGridItems(pageValues);
    }
  }

  componentDidUpdate(prevProps) {
    const { pageValues } = this.props;

    // if the values of play dont match or categories is not yet set
    if (
      JSON.stringify(prevProps.pageValues) !==
      JSON.stringify(this.props.pageValues)
    ) {
      this.setGridItems(pageValues);
    }
  }
  setGridItems(pageValues) {
    const categoryValues = pageValues;
    const options = categoryValues.options.map(optionsValue => {
      const inOptions = categoryValues.selected.find(
        selectedValue => optionsValue.key === selectedValue.key
      );
      return {
        ...optionsValue,
        isSelected: inOptions ? true : false
      };
    });
    if (categoryValues) {
      this.setState({
        selected: categoryValues.selected,
        options: options
      });
    }
  }

  render() {
    const { children } = this.props;
    const { selected, options } = this.state;
    return (
      <div className="feature-controller">
        <div className="feature-controller__inner">
          <div className="feature-controller__content">
            {children({ selected, options })}
          </div>
        </div>
      </div>
    );
  }
}

FeatureController.propTypes = {
  pageValues: PropTypes.object.isRequired,
  onCategoryChange: PropTypes.func,
  children: PropTypes.any
};

const mapState = (state, ownProps) => {
  return {
    pageValues:
      state.builder.currentBuilder[ownProps.pageHash].values[ownProps.value]
  };
};

export default connect(mapState)(FeatureController);
