import React from "react";
import PropTypes from "prop-types";
import PromptWrapper from "../common/PromptWrapper";

import IFrameWrapper from "../common/IFrameWrapper";

function BuilderHelpPrompt({ builderPageKey, onClose }) {
  return (
    <PromptWrapper onClose={onClose} className="builder-help-prompt-wrapper">
      <div className="builder-help-prompt">
        <IFrameWrapper
          url={`https://homeconcierge.com/${builderPageKey.toLowerCase()}.html`}
        />
      </div>
    </PromptWrapper>
  );
}

BuilderHelpPrompt.propTypes = {
  builderPageKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default BuilderHelpPrompt;
