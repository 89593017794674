import { useState, useEffect, useRef } from "react";

import braintree from "braintree-web";
import { useFormikContext } from "formik";

const useBraintreeHostedFields = ({ clientToken, fields, styles }) => {
  const hostedFieldsInstance = useRef(null);

  const [clientState, setClientState] = useState(null);

  const { setFieldValue, setFieldTouched } = useFormikContext();

  const canLoad = useRef(true);

  useEffect(() => {
    // on focus of any braintree hosted fields
    const handleHostedFieldsFocus = event => {
      const state = hostedFieldsInstance.current.getState();
      setClientState(state);
    };

    // on blur of any braintree hosted fields
    const handleHostedFieldsBlur = event => {
      const currentFields = event.fields;

      const state = hostedFieldsInstance.current.getState();
      const field = currentFields[event.emittedBy];

      setClientState(state);
      setFieldValue(event.emittedBy, field.isValid);
      setFieldTouched(event.emittedBy);
    };

    // on validityChange of any braintree hosted fields
    const handleValidityChange = event => {
      const currentFields = event.fields;

      const state = hostedFieldsInstance.current.getState();
      const field = currentFields[event.emittedBy];

      setClientState(state);
      setFieldValue(event.emittedBy, field.isValid);
    };

    // setting up hosted fields
    const initializeHostedFields = async () => {
      // create client instance
      const cInstance = await braintree.client.create({
        authorization: clientToken
      });

      // create hosted fields instance with given config
      const hfInstance = await braintree.hostedFields.create({
        client: cInstance,
        styles: styles,
        fields: Object.keys(fields).reduce((obj, fieldKey) => {
          obj[fieldKey] = {
            container: `#${fields[fieldKey].id}`,
            placeholder: `${fields[fieldKey].placeholder}`
          };
          return obj;
        }, {})
      });

      // set the hostedFieldsInstance
      hostedFieldsInstance.current = hfInstance;
      setClientState(hfInstance.getState());

      // handle event listeners for individual hosted fields
      hfInstance.on("blur", handleHostedFieldsBlur);

      hfInstance.on("focus", handleHostedFieldsFocus);

      hfInstance.on("validity", handleValidityChange);
    };

    // if there is not yet a set hosted fields instance and clientToken
    // exists, initialize the fields.
    if (!hostedFieldsInstance.current && clientToken && canLoad.current) {
      canLoad.current = false;
      initializeHostedFields();
    }
  }, [
    clientToken,
    fields,
    hostedFieldsInstance,
    setFieldTouched,
    setFieldValue,
    styles
  ]);

  useEffect(() => {
    // on unmount, teardown the hosted fields
    return () => {
      if (!hostedFieldsInstance.current) {
        return;
      }

      // on unmount, teardown the exiting fields instance
      hostedFieldsInstance.current.teardown(err => {
        if (err) {
          window.console.error(err);
        }
      });
    };
  }, []);

  // return clientState and hostedFieldsInstance
  return { clientState, hostedFieldsInstance: hostedFieldsInstance.current };
};

export default useBraintreeHostedFields;
