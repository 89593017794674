import types from "./types";

const setUserCredentials = ({ token, role }) => ({
  type: types.SET_USER_CREDENTIALS,
  isLoggedIn: role && token ? true : false,
  role
});

const setCurrentPage = ({ routeKey, routeName }) => ({
  type: types.SET_CURRENT_PAGE,
  routeKey,
  routeName
});

const setCurrentHome = (homeName, homeId) => ({
  type: types.SET_CURRENT_HOME,
  homeName,
  homeId
});

const logoutUser = () => ({
  type: types.LOGOUT_USER
});

const setRegionsList = regions => ({
  type: types.SET_REGIONS_LIST,
  regions
});

export default {
  setUserCredentials,
  setCurrentPage,
  setRegionsList,
  logoutUser,
  setCurrentHome
};
