import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";

function InputCheckboxFormik({
  id,
  label,
  required = false,
  className,
  size = "full",
  form: { setFieldValue, setFieldTouched, touched, errors, submitCount },
  field: { onBlur, name, value, onChange }
}) {
  const displayError =
    (_get(touched, name, null) || submitCount > 0) && _get(errors, name, null)
      ? _get(errors, name)
      : false;

  return (
    <div
      className={`form-checkbox ${className ? className : ""} ${
        size ? `form-checkbox--${size}` : ""
      }`}
    >
      <div className="form-checkbox__box">
        <input
          className={"form-checkbox__input"}
          id={name}
          name={name}
          type="checkbox"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
        />
        <span className="form-checkbox__visiblebox" />
      </div>
      <label className="form-checkbox__label" htmlFor={name}>
        {displayError ? (
          <p dangerouslySetInnerHTML={{ __html: displayError }} />
        ) : (
          label
        )}
      </label>
    </div>
  );
}

InputCheckboxFormik.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(["full", "half"])
};

export default InputCheckboxFormik;
