import React from "react";
import PropTypes from "prop-types";

function FormNotice({ children }) {
  return (
    <div className="user-form__notice">
      <p>{children}</p>
    </div>
  );
}

FormNotice.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FormNotice;
