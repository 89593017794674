import React from "react";
import PropTypes from "prop-types";

const GuestFormSection = ({ title, children, subtitle, className }) => (
  <div className={`form-section ${className}`}>
    <div className="form-section__title">
      {title && <h3>{title}</h3>}
      {subtitle && <p>{subtitle}</p>}
    </div>
    <div className="form-section__content">{children}</div>
  </div>
);

GuestFormSection.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

export default GuestFormSection;
