import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import GuestFormSection from "./GuestFormSection";
import InputTextFormik from "../common/InputTextFormik";
import { Field } from "formik";
import InputEditorFormik from "../common/InputEditorFormik";
import { connect as formikConnect } from "formik";
import _get from "lodash.get";
import { connect } from "react-redux";
import { dashboardOperations } from "./duck";

const GuestFormSecurityInfo = ({
  getSecurityData,
  homeId,
  isNew,
  formik: { setFieldValue, values }
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!isNew) return;
    setLoading(true);
    getSecurityData(homeId).then(securityData => {
      setFieldValue("wifiNetworkName", _get(securityData, "wifiName", ""));

      setFieldValue("wifiNetworkPass", _get(securityData, "wifiCode", ""));

      setFieldValue("doorCode", _get(securityData, "doorCode", ""));

      setFieldValue("gateCode", _get(securityData, "gateCode", ""));

      setFieldValue("securityInfo", _get(securityData, "securityInfo", ""));

      setLoading(false);
    });
  }, [getSecurityData, homeId, isNew, setFieldValue]);

  return (
    <GuestFormSection
      title="Guest Security Info"
      subtitle="Security Information that is unique to your guest"
      className={loading ? "loading" : ""}
    >
      <Field
        component={InputTextFormik}
        name="wifiNetworkName"
        placeholder="WiFi Network Name"
        size="half"
      ></Field>
      <Field
        component={InputTextFormik}
        name="wifiNetworkPass"
        placeholder="WiFi Password"
        size="half"
      ></Field>
      <Field
        component={InputTextFormik}
        name="doorCode"
        placeholder="Door Code"
        size="half"
      ></Field>
      <Field
        component={InputTextFormik}
        name="gateCode"
        placeholder="Gate Code"
        size="half"
      ></Field>
      {loading === false && (
        <Field
          component={InputEditorFormik}
          id="securityInfo"
          name="securityInfo"
          placeholder="Enter arrival info"
          size="full"
        />
      )}
    </GuestFormSection>
  );
};

GuestFormSecurityInfo.propTypes = {
  formik: PropTypes.object,
  getSecurityData: PropTypes.func.isRequired,
  homeId: PropTypes.number.isRequired,
  isNew: PropTypes.bool,
  loading: PropTypes.bool.isRequired
};

const mapState = state => ({
  homeId: _get(state.global, "current.home.id", null),
  loading: _get(state.loading, "getSecurityData.loading", false)
});

const mapDispatch = dispatch => ({
  getSecurityData: homeId =>
    dispatch(dashboardOperations.getSecurityData(homeId))
});

export default formikConnect(
  connect(mapState, mapDispatch)(GuestFormSecurityInfo)
);
