export default {
  removePlugins: [
    "Bold",
    "Italic",
    "Underline",
    "UploadAdapter",
    "BlockQuote",
    "CKFinder",
    "EasyImage",
    "Heading",
    "Image",
    "ImageCaption",
    "ImageStyle",
    "ImageToolbar",
    "ImageUpload",
    "Indent",
    "List",
    "MediaEmbed",
    "PasteFromOffice",
    "Table",
    "TableToolbar"
  ],
  toolbar: []
};
