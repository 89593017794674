import React from "react";
import PropTypes from "prop-types";
import GridWrapper from "../../GridWrapper";
import GridHeader from "../../GridHeader";
import { Formik, Field } from "formik";
import InputTextFormik from "../../../common/InputTextFormik";
import { Yup } from "hc-utils/FormValidator";
import ButtonMain from "../../../common/ButtonMain";

const HouseVideoValidationSchema = Yup.object().shape({
  link: Yup.string().matches(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    "Must be a valid URL"
  )
});

function HouseVideo({ onSave, data }) {
  const initialValues = {
    link: data.url || ""
  };

  return (
    <GridWrapper>
      <GridHeader size={12}>Add house video link</GridHeader>

      <Formik
        validationSchema={HouseVideoValidationSchema}
        onSubmit={onSave}
        initialValues={initialValues}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form className="house-video-form" onSubmit={handleSubmit}>
            <Field
              component={InputTextFormik}
              name="link"
              placeholder="Vimeo, Youtube, or other URL"
              size="half"
              autoComplete="url"
              required={false}
            />
            <small>
              Paste the URL for a youtube or vimeo video, OR another external
              link.
            </small>
            <small>i.e. https://www.youtube.com/watch?v=dQw4w9WgXcQ</small>
            <ButtonMain
              type="submit"
              color="aqua"
              loading={isSubmitting}
              isDisabled={values.link === initialValues.link}
            >
              Save Video Link
            </ButtonMain>
          </form>
        )}
      </Formik>
    </GridWrapper>
  );
}

HouseVideo.propTypes = {
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    url: PropTypes.string
  })
};

export default HouseVideo;
