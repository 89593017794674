import React, { PureComponent } from "react";
import PropTypes from "prop-types";
export class GridLayout extends PureComponent {
  componentDidUpdate(prevProps) {}
  computeGridArray() {
    const {
      rows,
      columns,
      items,
      compact = true,
      withAdd = false
    } = this.props;

    const gridCount = Math.max(
      items
        ? Math.ceil((items.length + (withAdd ? 1 : 0)) / columns) * columns
        : 0,
      rows * columns
    );

    // const gridInitialCount = rows * columns - (withAdd ? 1 : 0);

    let gridMatrix = new Array(gridCount - (withAdd ? 1 : 0)).fill({});

    const sortedItems = items.sort((a, b) =>
      a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
    );
    if (!compact) {
      for (let i = 0; i < sortedItems.length; i++) {
        gridMatrix[sortedItems[i].sequence - 1] = sortedItems[i];
      }
    } else {
      for (let i = 0; i < sortedItems.length; i++) {
        gridMatrix[i] = sortedItems[i];
      }
    }

    return gridMatrix;
  }

  render() {
    const { children } = this.props;
    return (
      <div className="grid-layout">
        <div className="grid-layout__inner">
          {children({
            gridArray: this.computeGridArray()
          })}
        </div>
      </div>
    );
  }
}

GridLayout.defaultProps = {
  compact: true,
  withAdd: false
};

GridLayout.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.number.isRequired,
  compact: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sequence: PropTypes.number.isRequired,
      text: PropTypes.string,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired
        })
      ),
      coords: PropTypes.shape({
        circle: PropTypes.shape({
          height: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        }),
        photo: PropTypes.shape({
          image: PropTypes.arrayOf(
            PropTypes.shape({
              size: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired
            })
          ),
          height: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        })
      })
    })
  ),
  rows: PropTypes.number.isRequired,
  withAdd: PropTypes.bool
};
export default GridLayout;
