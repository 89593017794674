import React from "react";
import ButtonMain from "../common/ButtonMain";
import PromptWrapper from "../common/PromptWrapper";
import PropTypes from "prop-types";
function GuestCancelPrompt({ onCancel, onClose, isLoading }) {
  return (
    <PromptWrapper onClose={onClose} className="guest-cancel-prompt">
      <h3>Are you sure you want to delete this reservation/brochure client?</h3>
      <p>This action can not be undone.</p>
      <small>The guest will be informed via email of their cancellation.</small>
      <ButtonMain onClick={onCancel} color="red" loading={isLoading}>
        Delete
      </ButtonMain>
    </PromptWrapper>
  );
}

GuestCancelPrompt.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default GuestCancelPrompt;
