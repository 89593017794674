import React from "react";

function InputChoiceBox({
  groupName,
  name,
  size = "full",
  placeholder,
  value,
  icon,
  checked,
  onChange,
  onBlur,
  className
}) {
  return (
    <div
      className={`form-choicebox ${checked ? "checked" : ""} ${
        className ? className : ""
      } ${size ? `form-input--${size}` : ""}`}
    >
      <div className="form-choicebox__inner">
        <div className="form-choicebox__content">
          {icon && (
            <img src={icon} alt={name} className="form-choicebox__icon" />
          )}
          <label
            className="form-choicebox__label"
            data-text={placeholder}
            htmlFor={name}
          >
            {placeholder}
          </label>
        </div>
        <input
          className={"form-choicebox__input"}
          id={name}
          name={groupName}
          type="radio"
          value={name}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          tabIndex={0}
        />
      </div>
    </div>
  );
}

export default InputChoiceBox;
