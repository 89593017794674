import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import BuilderPageWrapper from "../../BuilderPageWrapper";
import HouseTourGrid from "./HouseTourGrid";

const HouseTour = forwardRef(({ propertyInfo, pageKey }, forwardedRef) => {
  return (
    <>
      <Helmet>
        <title>Home Concierge | {propertyInfo.houseName} | House Tour</title>
        <meta
          name="description"
          content="Add information about your property to display to your guests."
        />
      </Helmet>
      <BuilderPageWrapper pageKey={pageKey} ref={forwardedRef}>
        <HouseTourGrid />
      </BuilderPageWrapper>
    </>
  );
});

const mapState = state => ({
  propertyInfo: _get(
    state.builder.currentBuilder[1].values,
    "propertyInfo.form",
    null
  )
});

HouseTour.propTypes = {
  propertyInfo: PropTypes.shape({
    houseName: PropTypes.string.isRequired
  }),
  pageKey: PropTypes.string.isRequired
};

export default connect(mapState)(HouseTour);
