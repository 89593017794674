import React, { useState } from "react";
import PropTypes from "prop-types";

function InputCheckbox({
  name,
  type,
  size = "full",
  text,
  value,
  onChange,
  onBlur,
  className,
  displayError = false
}) {
  const [isFocused, setFocus] = useState(false);
  const onFocus = () => {
    if (!isFocused) {
      setFocus(true);
    }
  };

  const handleBlur = evt => {
    if (isFocused) {
      setFocus(false);
    }
    onBlur(evt);
  };

  return (
    <div
      className={`form-checkbox ${isFocused || value !== "" ? "focused" : ""} ${
        className ? className : ""
      } ${size ? `form-checkbox--${size}` : ""}`}
    >
      <div className="form-checkbox__box">
        <input
          className={"form-checkbox__input"}
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={onFocus}
        />
        <span className="form-checkbox__visiblebox" />
      </div>
      <label className="form-checkbox__label" htmlFor={name}>
        {displayError ? (
          <p dangerouslySetInnerHTML={{ __html: displayError }} />
        ) : (
          text()
        )}
      </label>
    </div>
  );
}

InputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string,

  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  className: PropTypes.string
};
export default InputCheckbox;
