export default plans => {
  const plansFiltered = plans.filter(({ id }) =>
    id.match(/^(hcho_plan_1_a|hcho_plan_2_a|hcho_plan_1_m|hcho_plan_2_m)$/)
  );

  return plansFiltered.reduce(
    (acc, curr) => {
      if (curr.id.match(/^(hcho_plan_1_a|hcho_plan_2_a)$/)) {
        acc.annual.push({
          ...curr,
          cycleType: "monthly"
        });
      }
      if (curr.id.match(/^(hcho_plan_1_m|hcho_plan_2_m)$/)) {
        acc.monthly.push({
          ...curr,
          cycleType: "annual"
        });
      }
      return acc;
    },
    {
      monthly: [],
      annual: []
    }
  );
};
