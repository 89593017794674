import Creators from "./actions";
import { loadingOperations } from "../../../duck/loading";
import initialBuilderState from "../initialBuilderState";

import { deepCopy } from "hc-utils";
import { api } from "hc-utils/HomeAPIWrapper";

import _uniqBy from "lodash.uniqby";
import playKeyToName from "../config/playKeyToName";
import { dashboardOperations } from "../../dashboard/duck";
import { globalOperations } from "../../../duck/global";
import _get from "lodash.get";

const logoutUser = globalOperations.logoutUser;

const setLoading = loadingOperations.setLoading;

const setHousePublishStatus = dashboardOperations.setHousePublishStatus;
const updateHouseInfo = dashboardOperations.updateHouseInfo;
const updateHousePhoto = dashboardOperations.updateHousePhoto;
const addUserHome = dashboardOperations.addUserHome;

const setVisited = Creators.setVisited;

const removeSelectedGridItem = Creators.removeSelectedGridItem;
const addSelectedGridItem = Creators.addSelectedGridItem;
const changeSelectedGridItemSequence = Creators.changeSelectedGridItemSequence;
const setSelectedGridItemSequence = Creators.setSelectedGridItemSequence;
const changeGridItemAvailability = Creators.changeGridItemAvailability;
const updateGridItem = Creators.updateGridItem;

const setCurrentBuilder = Creators.setCurrentBuilder;
const clearCurrentBuilder = Creators.clearCurrentBuilder;

const setCalendarValues = Creators.setCalendarValues;
const setFormValues = Creators.setFormValues;
const removeAllGridItems = Creators.removeAllGridItems;

const switchGridItemAvailability = Creators.switchGridItemAvailability;

// cache actions
const cacheCurrentBuilder = Creators.cacheCurrentBuilder;

const setHouseVideoLink = Creators.setHouseVideoLink;
const setHouseVrTourLink = Creators.setHouseVrTourLink;

const updateSwitchGridItemAvailability =
  (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact,
    newSequence,
    originalSequence
  ) =>
  (dispatch, getState) => {
    dispatch(
      switchGridItemAvailability(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact,
        newSequence,
        originalSequence
      )
    );
    const statePost = getState();
    let availableSequencesToUpdate;
    if (statePost.builder.currentBuilder[pageHash].values[value]["available"]) {
      availableSequencesToUpdate = statePost.builder.currentBuilder[
        pageHash
      ].values[value]["available"].map(value => ({
        id: value.id,
        sequence: value.sequence,
        isSelected: 0
      }));
    }

    const selectedSequencesToUpdate = statePost.builder.currentBuilder[
      pageHash
    ].values[value]["selected"].map(value => ({
      id: value.id,
      sequence: value.sequence,
      isSelected: 1
    }));

    const sequences = [
      ...selectedSequencesToUpdate,
      ...(availableSequencesToUpdate && availableSequencesToUpdate)
    ];

    dispatch(
      updateSequences(statePost.builder.currentBuilderId, sequences, value)
    );
  };

const changeGridItemAvailabilityAndSequence =
  (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact,
    newSequence
  ) =>
  (dispatch, getState) => {
    dispatch(
      changeGridItemAvailability(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact,
        newSequence
      )
    );

    if (newSequence) {
      dispatch(
        isCompact
          ? changeSelectedGridItemSequence(
              pageHash,
              value,
              newAvailability,
              id,
              newSequence
            )
          : setSelectedGridItemSequence(
              pageHash,
              value,
              newAvailability,
              id,
              newSequence
            )
      );
    }
    const statePost = getState();
    let availableSequencesToUpdate;
    if (statePost.builder.currentBuilder[pageHash].values[value]["available"]) {
      availableSequencesToUpdate = statePost.builder.currentBuilder[
        pageHash
      ].values[value]["available"].map(value => ({
        id: value.id,
        sequence: value.sequence,
        isSelected: 0
      }));
    }

    const selectedSequencesToUpdate = statePost.builder.currentBuilder[
      pageHash
    ].values[value]["selected"].map(value => ({
      id: value.id,
      sequence: value.sequence,
      isSelected: 1
    }));

    const sequences = [
      ...selectedSequencesToUpdate,
      ...(availableSequencesToUpdate && availableSequencesToUpdate)
    ];

    dispatch(
      updateSequences(statePost.builder.currentBuilderId, sequences, value)
    );
  };

const changeSelectedGridItemSequences =
  (pageHash, value, type, id, newSequence, isCompact) =>
  (dispatch, getState) => {
    dispatch(
      isCompact
        ? changeSelectedGridItemSequence(pageHash, value, type, id, newSequence)
        : value !== "hosts" && type !== "selected"
        ? changeSelectedGridItemSequence(pageHash, value, type, id, newSequence)
        : setSelectedGridItemSequence(pageHash, value, type, id, newSequence)
    );

    const statePost = getState();

    const availableSequencesToUpdate = statePost.builder.currentBuilder[
      pageHash
    ].values[value]["available"]
      ? statePost.builder.currentBuilder[pageHash].values[value][
          "available"
        ].map(value => ({
          id: value.id,
          sequence: value.sequence,
          isSelected: 0
        }))
      : [];
    const selectedSequencesToUpdate = statePost.builder.currentBuilder[
      pageHash
    ].values[value]["selected"].map(value => ({
      id: value.id,
      sequence: value.sequence,
      isSelected: 1
    }));

    const sequences = [
      ...selectedSequencesToUpdate,
      ...availableSequencesToUpdate
    ];

    dispatch(
      updateSequences(statePost.builder.currentBuilderId, sequences, value)
    );
  };

function getTypeByPageKey(pageKey) {
  switch (pageKey) {
    case "farmersMarket":
    case "personalShopper":
    case "privateMasseuse":
    case "privateTours":
    case "rentals":
    case "shop":
    case "dine":
    case "play":
    case "spa": {
      return "poi";
    }

    case "music":
    case "tvOffice":
    case "emergency":
    case "houseFeatures": {
      return "feature";
    }

    case "hosts": {
      return "hosts";
    }
    case "maintanenceCalendar": {
      return "calendar";
    }
    case "privateChefs": {
      return "people";
    }

    case "guestServices": {
      return "people";
    }

    case "propertyInfo": {
      return "form";
    }

    case "houseTour": {
      return "houseTour";
    }

    default: {
      return "";
    }
  }
}

function createPOIObject(
  {
    poi_id,
    sequence_no,
    name,
    description,
    address_line_1,
    city,
    state,
    zip,
    image,

    email,
    phone_number,
    website,
    ...rest
  },
  i
) {
  return {
    id: poi_id,
    name,
    address: {
      fullAddress: `${address_line_1 || ""}${city ? ", " + city : ""}${
        state ? ", " + state : ""
      }${zip ? ", " + zip : ""}`
    },
    key: name,
    email,
    website,
    phone: phone_number,
    sequence: sequence_no,
    description,
    image: image ? image : null
  };
}

function createFeatureObject(data, i) {
  const { id, name, type, key, items, sequenceNo, embedUrl, guestApp } = data;

  return {
    id,
    name,
    key: type ? type : key,
    sequence: sequenceNo ? sequenceNo : i + 1,
	embedUrl,
	guestApp,
    items: items
      .sort((a, b) =>
        a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
      )
      .map(item => ({
        ...item,
        coords: {
          ...item.coords,
          arc:
            item.coords.circle.x === "null" || item.coords.circle.y === "null"
              ? null
              : {
                  ...item.coords.arc
                }
        }
      }))
  };
}

function createHostObject({
  id,
  first_name,
  last_name,

  phone_number,
  phone_number_text,
  email,
  image,
  website,
  description,
  sequence_no
}) {
  return {
    id,
    first_name,
    last_name,

    phone: phone_number,
	phoneText: phone_number_text,
    email,
    website: website ? website : "",
    description: description ? description : "",
    sequence: sequence_no,
    image: image ? image : null
  };
}

function createPhotosObject({ id, image, title, description, sequence_no }) {
  return {
    id,
    title,
    image,
    description,
    sequence: sequence_no
  };
}

function computePlayName(key) {
  try {
    return playKeyToName[key];
  } catch (error) {
    return "ERR";
  }
}

function formatGetHouseResponse(response, isNew) {
  let newObj = {};
  let copy = deepCopy(response);

  // for each key in response
  Object.keys(copy).forEach((key, i) => {
    // get current houseFeatures value object
    let currObject = copy[key];

    const currObjectValues = {};

    if (getTypeByPageKey(key) === "feature") {
      if (key === "houseFeatures") {
        Object.keys(currObject.values).forEach(valueKey => {
          currObjectValues[valueKey] = {
            id: currObject.values[valueKey].id,
            image: currObject.values[valueKey].image,
            required: key !== "houseFeatures",
            name: currObject.values[valueKey].name,
			embedUrl: currObject.values[valueKey].embedUrl,
            options: currObject.values[valueKey].options
              ? currObject.values[valueKey].options.sort((a, b) =>
                  a.sequence_no > b.sequence_no
                    ? 1
                    : b.sequence_no > a.sequence_no
                    ? -1
                    : 0
                )
              : [],
            selected: currObject.values[valueKey].features
              ? currObject.values[valueKey].features.map(createFeatureObject)
              : [],
			sequenceNo: currObject.values[valueKey].sequenceNo || 100
          };
        });
      } else {
        Object.keys(currObject.values).forEach(valueKey => {
          currObjectValues[valueKey] = {
            id: currObject.values[valueKey].id,
            required: key !== "houseFeatures" ? true : false,
            name: currObject.name,
            options: currObject.values[valueKey].options
              ? currObject.values[valueKey].options.sort((a, b) =>
                  a.sequence_no > b.sequence_no
                    ? 1
                    : b.sequence_no > a.sequence_no
                    ? -1
                    : 0
                )
              : [],
            selected: currObject.values[valueKey].features
              ? currObject.values[valueKey].features.map(createFeatureObject)
              : []
          };
        });
      }
    } else if (getTypeByPageKey(key) === "form") {
      currObjectValues["propertyInfo"] = {
        required: true,
        name: "Property Info",
        form: {
          houseName: currObject.values.houseName,
          ownerName: currObject.values.ownerName,
          houseAddr: currObject.values.houseAddr,
          housePhone: currObject.values.housePhone,
          homeEmail: currObject.values.houseEmail,
          homeWebsite: currObject.values.homeWebsite,
          wifiCode: currObject.values.wifiCode,
          wifiName: currObject.values.wifiName,
          gateCode: currObject.values.gateCode,
          doorCode: currObject.values.doorCode,
          securityInfo: currObject.values.securityInfo,
		  checkOutInfo: currObject.values.checkOutInfo,
          book_airbnb: currObject.values.book_airbnb,
          book_vrbo: currObject.values.book_vrbo,
          book_luxe: currObject.values.book_luxe,
          book_other_name: currObject.values.book_other_name,
          book_other_url: currObject.values.book_other_url
        }
      };
    } else if (key === "houseTour") {
      Object.keys(currObject.values).forEach(valueKey => {
        if (valueKey === "housePhotos") {
          currObjectValues[valueKey] = {
            ...currObject.values[valueKey],
            required: true,
            selected:
              currObject.values[valueKey].selected.map(createPhotosObject)
          };
        } else {
          currObjectValues[valueKey] = {
            required: true,
            name: valueKey,
            ...currObject.values[valueKey]
          };
        }
      });
    } else if (getTypeByPageKey(key) === "poi") {
      Object.keys(currObject.values).forEach(valueKey => {
        currObjectValues[valueKey] = {
          required: true,
          name: key === "play" ? computePlayName(valueKey) : currObject.name,
          image: currObject.values[valueKey].image,
          feature_id: currObject.values[valueKey].feature_id,
          available: _uniqBy(
            currObject.values[valueKey].available.map(createPOIObject),
            "id"
          ),
          selected: _uniqBy(
            currObject.values[valueKey].selected.map(createPOIObject),
            "id"
          )
        };
      });
    } else if (key === "guestServices") {
      Object.keys(currObject.values).forEach(valueKey => {
        if (getTypeByPageKey(valueKey) === "people") {
          currObjectValues[valueKey] = {
            required: true,
            name: currObject.values[valueKey].name,
            image: currObject.values[valueKey].image,
            key: valueKey,
            feature_id: currObject.values[valueKey].feature_id,
            selected:
              currObject.values[valueKey].selected.length > 0
                ? currObject.values[valueKey].selected.map(createPOIObject)
                : [],
            available:
              currObject.values[valueKey].available.length > 0
                ? currObject.values[valueKey].available.map(createPOIObject)
                : []
          };
        } else if (getTypeByPageKey(valueKey) === "poi") {
          currObjectValues[valueKey] = {
            required: true,
            name: currObject.values[valueKey].name,
            key: valueKey,
            feature_id: currObject.values[valueKey].feature_id,
            image: currObject.values[valueKey].image,
            available: _uniqBy(
              currObject.values[valueKey].available.map(createPOIObject),
              "id"
            ),
            selected: _uniqBy(
              currObject.values[valueKey].selected.map(createPOIObject),
              "id"
            )
          };
        } else if (getTypeByPageKey(valueKey) === "calendar") {
          currObjectValues["calendar"] = {
            required: true,
            name: currObject.values[valueKey].name,
            key: valueKey,
            feature_id: currObject.values[valueKey].feature_id,
            image: currObject.values[valueKey].image,
            houseKeeping: currObject.values[valueKey].houseKeeping
              ? currObject.values[valueKey].houseKeeping
              : {},
            trashPickup: currObject.values[valueKey].trashPickup
              ? currObject.values[valueKey].trashPickup
              : {},
            gardener: currObject.values[valueKey].gardener
              ? currObject.values[valueKey].gardener
              : {},
            poolJacuzzi: currObject.values[valueKey].poolJacuzzi
              ? currObject.values[valueKey].poolJacuzzi
              : {}
          };
        } else if (getTypeByPageKey(valueKey) === "hosts") {
          currObjectValues[valueKey] = {
            required: true,
            name: currObject.values[valueKey].name,
            key: valueKey,
            feature_id: currObject.values[valueKey].feature_id,
            image: currObject.values[valueKey].image,
            available: currObject.values[valueKey].available
              ? currObject.values[valueKey].available.map(createHostObject)
              : [],
            selected: currObject.values[valueKey].selected
              ? currObject.values[valueKey].selected.map(createHostObject)
              : []
          };
        }
        // if (valueKey === "privateChefs") {
        //   currObjectValues[valueKey] = {
        //     required: true,
        //     name: "Private Chefs",
        //     selected:
        //       currObject.values[valueKey].selected.length > 0
        //         ? currObject.values[valueKey].selected.map(createPeopleObject)
        //         : [],
        //     available:
        //       currObject.values[valueKey].available.length > 0
        //         ? currObject.values[valueKey].available.map(createPeopleObject)
        //         : [],
        //   };
        // } else if (valueKey === "maintanenceCalendar") {
        //   currObjectValues["calendar"] = {
        //     required: true,
        //     name: "Maintenance Schedule",
        //     houseKeeping: currObject.values[valueKey].houseKeeping
        //       ? currObject.values[valueKey].houseKeeping
        //       : {},
        //     trashPickup: currObject.values[valueKey].trashPickup
        //       ? currObject.values[valueKey].trashPickup
        //       : {},
        //     gardener: currObject.values[valueKey].gardener
        //       ? currObject.values[valueKey].gardener
        //       : {},
        //     poolJacuzzi: currObject.values[valueKey].poolJacuzzi
        //       ? currObject.values[valueKey].poolJacuzzi
        //       : {},
        //   };
        // } else if (valueKey === "hosts") {
        //   currObjectValues[valueKey] = {
        //     required: true,
        //     name: valueKey,
        //     available: currObject.values[valueKey].available
        //       ? currObject.values[valueKey].available.map(createHostObject)
        //       : [],
        //     selected: currObject.values[valueKey].selected
        //       ? currObject.values[valueKey].selected.map(createHostObject)
        //       : [],
        //   };
        // } else {
        //   currObjectValues[valueKey] = {
        //     required: true,
        //     name: valueKey,
        //     available: currObject.values[valueKey].available
        //       ? currObject.values[valueKey].available.map(createPeopleObject)
        //       : [],
        //     selected: currObject.values[valueKey].selected
        //       ? currObject.values[valueKey].selected.map(createPeopleObject)
        //       : [],
        //   };
        // }
      });
    } else {
      Object.keys(currObject.values).forEach(valueKey => {
        currObjectValues[valueKey] = {
          required: true,
          name: valueKey,
          ...currObject.values[valueKey]
        };
      });
    }

    newObj[i + 1] = {
      // this either needs to be in the API or need a switch method to render
      // correct human-readable name
      name: currObject.name,
      key: key,
      feature_id: currObject.feature_id,
      image: currObject.image,
      visited: isNew ? false : true,
      errors: {},
      touched: {},
      values: currObjectValues
    };
  });
  return newObj;
}

const loadHomeBuilder = homeId => async (dispatch, getState) => {
  dispatch(setLoading("currentBuilder", "REQUEST"));

  if (homeId) {
    try {
      // if cached data for the home exists, use it as the response. otherwise fetch houseData
      const houseData = await api.getHouseData(homeId);
      // if cached data, use it since its already formatted.
      // otherwise format the getHouseAllData response

      const formattedResponse = formatGetHouseResponse(
        houseData.house,
        getState().builder.currentBuilder ? true : false
      );

      const regionId = houseData.region_id;
      const regions = houseData.regions;
      dispatch(setCurrentBuilder(homeId, formattedResponse, regionId, regions));

      if (getState().dashboard.userHomes) {
        if (getState().dashboard.userHomes.length === 0) {
          dispatch(dashboardOperations.getUserHomesList()).then(() => {
            dispatch(setLoading("currentBuilder", "SUCCESS"));
          });
        } else {
          dispatch(setLoading("currentBuilder", "SUCCESS"));
        }
      } else {
        dispatch(setLoading("currentBuilder", "SUCCESS"));
      }
    } catch (error) {
      window.console.error(error);

      dispatch(setLoading("currentBuilder", "FAILED"));
      if (_get(error, "response.status") === 401) {
        await api.manualLogout();
        dispatch(logoutUser());
        throw new Error("You are not authorized to view this content");
      } else {
        throw new Error("Unable to get home data. Please try again later.");
      }
    }
  } else {
    dispatch(setCurrentBuilder(homeId, initialBuilderState, null, null));
    dispatch(setLoading("currentBuilder", "SUCCESS"));
  }
};

const updateHost =
  (pageHash, value, availabilityType, hostData, originalHostObject) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("updateHost", "REQUEST"));

      api
        .updateHost(hostData)
        .then(host => {
          const hostObj = {
            id: host.id,
            first_name: host.first_name,
            last_name: host.last_name,
            phone: host.phone_number,
			phoneText: host.phone_number_text,
            email: host.email,
            website: host.website,
            description: host.description,
            image: host.image ? host.image : null
          };

          dispatch(
            updateGridItem(pageHash, value, availabilityType, host.id, hostObj)
          );
          dispatch(setLoading("updateHost", "SUCCESS"));
          resolve();
        })
        .catch(error => {
          dispatch(setLoading("updateHost", "FAILED"));
          window.console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to update the host for this home. Please make sure you are logged in to the correct account."
                });
              });
            } else {
              reject({
                message: "Unable to update host. Please try again."
              });
            }
          }
        });
    });
  };

const updateChef =
  (pageHash, value, availabilityType, chefData) => dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("updateChef", "REQUEST"));

      api
        .updateChef(chefData)
        .then(chef => {
          const { description, email, name, id, phone_number, website, image } =
            chef;

          const chefObj = {
            id,
            name,
            phone: phone_number,
            email,
            website,
            description,
            image: image ? image : null
          };
          dispatch(
            updateGridItem(pageHash, value, availabilityType, id, chefObj)
          );
          dispatch(setLoading("updateChef", "SUCCESS"));
          resolve();
        })
        .catch(error => {
          dispatch(setLoading("updateChef", "FAILED"));
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to update chef for this home. Please make sure you are logged in to the correct account."
                });
              });
            } else {
              reject({
                message: "Unable to create chef. Please try again."
              });
            }
          } else {
            reject({
              message: "Unable to create chef. Please try again."
            });
          }
        });
    });
  };

const createChef =
  (pageHash, value, availabilityType, chefData) => dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("createChef", "REQUEST"));
      api
        .createChef(chefData)
        .then(chef => {
          const {
            description,
            email,
            name,
            id,
            phone_number,

            website,
            image
          } = chef;

          const chefObj = {
            id,
            name,

            phone: phone_number,
            email,
            website,
            description,
            sequence: 1,
            image
          };

          dispatch(
            addSelectedGridItem(pageHash, value, availabilityType, chefObj)
          );

          dispatch(setLoading("createChef", "SUCCESS"));
          resolve(chef);
        })
        .catch(error => {
          window.console.error(error);
          dispatch(setLoading("createChef", "FAILED"));
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to create a chef for this home. Please make sure you are logged in to the correct account."
                });
              });
            }
          } else {
            reject({
              message: "Unable to create chef. Please try again."
            });
          }
        });
    });
  };

const createPhoto =
  (pageHash, value, availabilityType, photoData) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const homeId = photoData.get("houseId");
      if (!homeId) return;
      const currentHome = getState().dashboard.userHomes.find(
        ({ id }) => id === parseInt(homeId)
      );

      dispatch(setLoading("createPhoto", "REQUEST"));

      api
        .createPhoto(photoData)
        .then(photos => {
          for (var i = 0; i < photos.length; i++) {
            const photoObj = {
              id: photos[i].id,
              image: photos[i].image,
              sequence: photos[i].sequence_no
            };
            dispatch(
              addSelectedGridItem(pageHash, value, availabilityType, photoObj)
            );

            const image = photos[0].image;

            if (!_get(currentHome, "image[0]")) {
              dispatch(updateHousePhoto(parseInt(homeId), image));
            }
          }

          dispatch(setLoading("createPhoto", "SUCCESS"));
          resolve(photos);
        })
        .catch(error => {
          window.console.error(error);
          dispatch(setLoading("createPhoto", "FAILED"));
          if (!error.response) {
            window.console.error(error);
            reject({
              message: "Unable to create images. Please try again."
            });
            return;
          }
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message:
                  "You are not authorized to upload a photo for this home. Please make sure you are logged in to the correct account."
              });
            });
          } else {
            reject({
              message: "Unable to create images. Please try again."
            });
          }
        });
    });
  };

const createHost =
  (pageHash, value, availabilityType, hostData) => dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("createHost", "REQUEST"));

      api
        .createHost(hostData)
        .then(host => {
          const hostObj = {
            id: host.id,
            first_name: host.first_name,
            last_name: host.last_name,
            phone: host.phone_number,
			phoneText: host.phone_number_text,
            email: host.email,
            website: host.website,
            description: host.description,
            sequence: host.sequence_no,
            image: host.image ? host.image : null
          };

          dispatch(
            addSelectedGridItem(pageHash, value, availabilityType, hostObj)
          );
          dispatch(setLoading("createHost", "SUCCESS"));

          resolve();
        })
        .catch(error => {
          window.console.error(error);
          dispatch(setLoading("createHost", "FAILED"));
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to create a host for this home. Please make sure you are logged in to the correct account."
                });
              });
            } else {
              reject({
                message: "Unable to create host. Please try again."
              });
            }
          } else {
            reject({
              message: "Unable to create host. Please try again."
            });
          }
        });
    });
  };

const deleteHost =
  (pageHash, value, availabilityType, hostId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("deleteHost", "REQUEST"));
      dispatch(setLoading(`itemDelete_${hostId}`, "REQUEST"));
      const houseId = getState().builder.currentBuilderId
        ? parseInt(getState().builder.currentBuilderId)
        : null;
      if (!houseId) {
        dispatch(setLoading("deleteHost", "FAILED"));
        dispatch(setLoading(`itemDelete_${hostId}`, "FAILED"));
        reject("No home id available");
      }
      api
        .deleteHost(houseId, hostId)
        .then(host => {
          dispatch(
            removeSelectedGridItem(
              pageHash,
              value,
              availabilityType,
              hostId,
              availabilityType === "selected" ? false : true
            )
          );

          const statePost = getState();

          const sequencesToUpdate = statePost.builder.currentBuilder[
            pageHash
          ].values[value][availabilityType].map(value => ({
            id: value.id,
            sequence: value.sequence,
            isSelected: availabilityType === "selected" ? 1 : 0
          }));

          dispatch(
            updateSequences(
              statePost.builder.currentBuilderId,
              sequencesToUpdate,
              value
            )
          );

          dispatch(setLoading("deleteHost", "SUCCESS"));
          dispatch(setLoading(`itemDelete_${hostId}`, "SUCCESS"));
          resolve();
        })
        .catch(error => {
          dispatch(setLoading("deleteHost", "FAILED"));
          dispatch(setLoading(`itemDelete_${hostId}`, "FAILED"));
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to delete a host from this home. Please make sure you are logged in to the correct account."
                });
              });
            }

            reject({
              message: _get(
                error.response,
                "data.message",
                "There was an error while deleting the host."
              )
            });
          }
        });
    });
  };
const deletePhoto =
  (pageHash, value, availabilityType, photoId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("deletePhoto", "REQUEST"));
      dispatch(setLoading(`itemDelete_${photoId}`, "REQUEST"));
      const statePre = getState();
      const houseId = statePre.builder.currentBuilderId;

      if (!houseId) return;

      api
        .deletePhoto(houseId, [{ id: photoId }])
        .then(() => {
          dispatch(
            removeSelectedGridItem(pageHash, value, availabilityType, photoId)
          );

          const statePost = getState();
          const photos =
            statePost.builder.currentBuilder[2].values.housePhotos.selected;
          const sequencesToUpdate = statePost.builder.currentBuilder[
            pageHash
          ].values[value][availabilityType].map(value => ({
            id: value.id,
            sequence: value.sequence,
            isSelected: availabilityType === "selected" ? 1 : 0
          }));

          dispatch(
            updateSequences(
              statePost.builder.currentBuilderId,
              sequencesToUpdate,
              value
            )
          );

          dispatch(setLoading("deletePhoto", "SUCCESS"));

          dispatch(setLoading(`itemDelete_${photoId}`, "SUCCESS"));
          if (photos.length === 0) {
            dispatch(updateHousePhoto(parseInt(houseId), null));
          } else {
            dispatch(updateHousePhoto(parseInt(houseId), photos[0].image));
          }
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          dispatch(setLoading("deletePhoto", "FAILED"));
          dispatch(setLoading(`itemDelete_${photoId}`, "FAILED"));
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());

                reject({
                  message:
                    "You are not authorized to delete this house photo. Please make sure you are logged in to the correct account."
                });
              });
            }
            reject({
              message: "Unable to delete photo. Please try again."
            });
          }
        });
    });
  };
const deleteChef = (pageHash, value, availabilityType, chefId) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("deleteChef", "REQUEST"));
    dispatch(setLoading(`itemDelete_${chefId}`, "REQUEST"));
    api
      .deleteChef(chefId)
      .then(chef => {
        dispatch(
          removeSelectedGridItem(
            pageHash,
            value,
            availabilityType,
            chefId,
            true
          )
        );

        dispatch(setLoading("deleteChef", "SUCCESS"));
        dispatch(setLoading(`itemDelete_${chefId}`, "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("deleteChef", "FAILED"));
        dispatch(setLoading(`itemDelete_${chefId}`, "FAILED"));
        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message:
                  "You are not authorized to delete a chef from this home. Please make sure you are logged in to the correct account."
              });
            });
          }
          reject({
            message: "Unable to delete chef. Please try again."
          });
        }
      });
  });
};

const updateSequences = (houseId, sequences, pageType) => dispatch => {
  return new Promise((resolve, reject) => {
    sequences = sequences.filter(
      ({ id, sequence }) =>
        id !== null || sequence !== null || sequence !== undefined
    );
    if (pageType === "hosts") {
      api
        .updateHostSequences(houseId, sequences)
        .then(() => {
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          reject({ error });
        });
      return;
    } else if (pageType === "housePhotos") {
      api
        .updatePhotoSequences(houseId, sequences)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    } else if (
      pageType
        .trim()
        .match(
          /^(plays|shops|dines|spas|beaches|sightseeing|golf|surfSUP|snorkelling|diving|biking|hiking|adventures|entertainment|classicHawaii|volcanoLava|otherPlay|privateChefs|privateTours|personalShopper|privateMasseuse|farmersMarket|rentals)$/
        )
    ) {
      api
        .updatePOISequences(houseId, sequences)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    } else if (pageType === "tvOffice") {
      api
        .updateTvOfficeSequences(houseId, sequences)
        .then(() => {
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          reject(error);
        });
    } else if (pageType === "music") {
      api
        .updateMusicSequences(houseId, sequences)
        .then(() => {
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          reject(error);
        });
    } else if (pageType === "emergency") {
      api
        .updateEmergencySequences(houseId, sequences)
        .then(() => {
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          reject(error);
        });
    } else {
      api
        .updateHouseFeatureSequences(houseId, sequences)
        .then(() => {
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          reject(error);
        });
    }
  });
};

const updateHouse =
  ({
    houseId,
    houseOwnerName,
    houseName,
    phoneNumber,
    email,
    houseAddr,
    website,
    fullAddress,
    lat,
    lng,
    wifiCode,
    wifiName,
    securityInfo,
    doorCode,
    gateCode,
    book_airbnb,
    book_vrbo,
    book_luxe,
    book_other_name,
    book_other_url,
	checkOutInfo,
  }) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("updateHouse", "REQUEST"));
      const initData = {
        houseId,
        name: houseName,
        houseOwnerName,
        phoneNumber,
        fullAddress,
        lat,
        lng,
        email,
        houseAddr,
        website,
        wifiCode,
        wifiName,
        securityInfo,
        gateCode,
        doorCode,
        book_airbnb,
        book_vrbo,
        book_luxe,
        book_other_name,
        book_other_url,
		checkOutInfo
      };

      api
        .updateHouse(initData)
        .then(house => {
          dispatch(globalOperations.setCurrentHome(houseName, houseId));

          dispatch(
            updateHouseInfo(
              house.id,
              house.houseName,
              house.address.fullAddress
            )
          );
          dispatch(
            setFormValues({
              houseName: house.houseName,
              ownerName: house.ownerName,
              houseAddr: {
                fullAddress: house.address.fullAddress,
                lat: house.address.coordinates.lat,
                lng: house.address.coordinates.lng
              },
              housePhone: house.phone,
              homeEmail: house.email,
              homeWebsite: house.website,
              wifiCode: house.wifiCode,
              wifiName: house.wifiName,
              gateCode: house.gateCode,
              doorCode: house.doorCode,
              securityInfo: house.securityInfo,
              book_airbnb: house.book_airbnb,
              book_vrbo: house.book_vrbo,
              book_luxe: house.book_luxe,
              book_other_name: house.book_other_name,
              book_other_url: house.book_other_url,
			  checkOutInfo: house.checkOutInfo,
            })
          );
          dispatch(setLoading("updateHouse", "SUCCESS"));
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          dispatch(setLoading("updateHouse", "FAILED"));
          reject(error);
        });
    });
  };

const createHouse =
  ({
    houseId,
    houseOwnerName,
    houseName,
    phoneNumber,
    email,
    houseAddr,
    website,
    fullAddress,
    street,
    city,
    state,
    zip,
    country,
    lat,
    lng,
    wifiCode,
    wifiName,
    securityInfo,
	checkOutInfo,
    doorCode,
    gateCode,
    regionId,
    book_airbnb,
    book_vrbo,
    book_luxe,
    book_other_name,
    book_other_url
  }) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading("createHouse", "REQUEST"));

      const initData = {
        regionId,
        houseId,
        name: houseName,
        houseOwnerName,
        phoneNumber,
        fullAddress,
        street,
        city,
        state,
        zip,
        country,
        lat,
        lng,
        email,
        houseAddr,
        website,
        wifiCode,
        wifiName,
        securityInfo,
		checkOutInfo,
        doorCode,
        gateCode,
        book_airbnb,
        book_vrbo,
        book_luxe,
        book_other_name,
        book_other_url
      };

      api
        .createHouse(initData)
        .then(({ getData, createData }) => {
          dispatch(
            setFormValues({ ...createData, homeEmail: createData.houseEmail })
          );
          dispatch(
            addUserHome({
              ...createData,
              permission: { type: "owner", owner: null }
            })
          );

          const formattedResponse = formatGetHouseResponse(
            getData.house,
            getState().builder.currentBuilder ? true : false
          );
          dispatch(
            setCurrentBuilder(
              createData.id,
              formattedResponse,
              getData.region_id,
              getData.regions
            )
          );
          dispatch(setLoading("createHouse", "SUCCESS"));
          resolve(createData);
        })
        .catch(error => {
          dispatch(setLoading("createHouse", "FAILED"));
          reject(error);
        });
    });
  };

const setCalendar = values => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("setCalendar", "REQUEST"));
    const { currentBuilderId } = getState().builder;

    api
      .updateCalendar(currentBuilderId, values)
      .then(() => {
        dispatch(setCalendarValues(values));
        dispatch(setLoading("setCalendar", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message:
                  "You are not authorized to set the calendar for this home. Please make sure you are logged in to the correct account."
              });
            });
          }
          reject({
            message: "Unable to update calendar. Please try again."
          });
        }
        reject({
          message: "Unable to update calendar. Please try again."
        });
        dispatch(setLoading("setCalendar", "FAILED"));
      });
  });
};

const createUnapprovedPOI = data => (dispatch, getState) => {
  const poiData = new FormData();
  const { currentBuilderId, currentBuilderRegionId } = getState().builder;
  const { name, email, description, address, phone, website, featureId } = data;

  poiData.set("poi_name", name);
  poiData.set("poi_description", description);
  poiData.set("region_id", currentBuilderRegionId);
  poiData.set("feature_id", featureId);

  poiData.set("address_line_1", address.street);
  poiData.set("city", address.city);
  poiData.set("state", address.state);
  poiData.set("country", address.country);
  poiData.set("zip", address.zip);
  poiData.set("latitude", address.lat);
  poiData.set("longitude", address.lng);

  poiData.set("email", email);
  poiData.set("phone_number", phone);
  poiData.set("website", website);
  poiData.set("houseId", currentBuilderId);

  return new Promise((resolve, reject) => {
    dispatch(setLoading("createUnapprovedPOI", "REQUEST"));

    api
      .createUnapprovedPOI(poiData)
      .then(() => {
        dispatch(setLoading("createUnapprovedPOI", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("createUnapprovedPOI", "FAILED"));
        reject(error);
      });
  });
};

const deleteAllPhotos = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("deleteAllPhotos", "REQUEST"));
    const houseId = getState().builder.currentBuilderId;

    const allPhotos =
      getState().builder.currentBuilder[2].values.housePhotos.selected.map(
        photo => ({ id: photo.id })
      );
    api
      .deletePhoto(houseId, allPhotos)
      .then(() => {
        dispatch(removeAllGridItems(2, "housePhotos", "selected"));
        // remove userHomes image for the house
        dispatch(updateHousePhoto(parseInt(houseId), null));

        dispatch(setLoading("deleteAllPhotos", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message:
                  "You are not authorized to delete house photos. Please make sure you are logged in to the correct account."
              });
            });
          }
          reject({
            message: "Unable to delete house photos. Please try again."
          });
        }
        reject({
          message: "Unable to delete house photos. Please try again."
        });
        dispatch(setLoading("deleteAllPhotos", "FAILED"));
      });
  });
};

const createFeaturePage =
  (pageHash, value, availabilityType, featureData, houseFeatureId) =>
  (dispatch, getState) => {
    const houseId = getState().builder.currentBuilderId;

    return new Promise((resolve, reject) => {
      dispatch(setLoading("createFeaturePage", "REQUEST"));

      api
        .createFeaturePage(houseId, value, featureData, houseFeatureId)
        .then(feature => {
          dispatch(
            addSelectedGridItem(
              pageHash,
              value,
              availabilityType,
              createFeatureObject(feature, feature.sequence - 1)
            )
          );
          dispatch(setLoading("createFeaturePage", "SUCCESS"));
          resolve(feature);
        })
        .catch(error => {
          window.console.error(error);
          dispatch(setLoading("createFeaturePage", "FAILED"));
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to create a feature page. Please make sure you are logged in to the correct account."
                });
              });
              return;
            }
            reject({
              message: "Unable to create house feature. Please try again."
            });
          }
          reject({
            message: "Unable to create house feature. Please try again."
          });
        });
    });
  };

const deleteFeature =
  (pageHash, value, availabilityType, featureId) => (dispatch, getState) => {
    const houseId = getState().builder.currentBuilderId;
    return new Promise((resolve, reject) => {
      dispatch(setLoading("deleteFeaturePage", "REQUEST"));
      dispatch(setLoading(`itemDelete_${featureId}`, "REQUEST"));

      api
        .deleteFeature(houseId, value, featureId)
        .then(() => {
          dispatch(
            removeSelectedGridItem(
              pageHash,
              value,
              availabilityType,
              featureId,
              true
            )
          );
          dispatch(setLoading("deleteFeaturePage", "SUCCESS"));
          dispatch(setLoading(`itemDelete_${featureId}`, "SUCCESS"));
          resolve();
        })
        .catch(error => {
          dispatch(setLoading("deleteFeaturePage", "FAILED"));
          dispatch(setLoading(`itemDelete_${featureId}`, "FAILED"));
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to delete a feature page for this home. Please make sure you are logged in to the correct account."
                });
              });
            }
            reject({
              message: "Unable to delete house photo. Please try again."
            });
          }
          reject({
            message: "Unable to delete house photo. Please try again."
          });
        });
    });
  };

const updateFeature =
  (pageHash, value, availabilityType, featureId, featureData) =>
  (dispatch, getState) => {
    const houseId = getState().builder.currentBuilderId;

    const originalFeatureData = getState().builder.currentBuilder[
      pageHash
    ].values[value][availabilityType].find(feature => feature.id === featureId);

    return new Promise((resolve, reject) => {
      dispatch(setLoading("updateFeature", "REQUEST"));

      api
        .updateFeature(
          houseId,
          value,
          featureId,
          featureData,
          originalFeatureData
        )
        .then(feature => {
          dispatch(
            updateGridItem(
              pageHash,
              value,
              availabilityType,
              featureId,
              createFeatureObject(feature, featureData.sequence)
            )
          );
          dispatch(setLoading("updateFeature", "SUCCESS"));
          resolve();
        })
        .catch(error => {
          window.console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              api.manualLogout().then(() => {
                dispatch(logoutUser());
                reject({
                  message:
                    "You are not authorized to update a feature page for this house. Please make sure you are logged in to the correct account."
                });
              });
            }
            reject({
              message: "Unable to delete house photo. Please try again."
            });
          }
          dispatch(setLoading("updateFeature", "FAILED"));
          reject({
            message: "Unable to delete house photo. Please try again."
          });
        });
    });
  };

const updateHousePublishStatus = (houseId, publishStatus) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateHousePublishStatus", "REQUEST"));
    api
      .updateHousePublishStatus(houseId, publishStatus)
      .then(() => {
        dispatch(setHousePublishStatus(houseId, publishStatus));
        dispatch(setLoading("updateHousePublishStatus", "SUCCESS"));
      })
      .catch(error => {
        dispatch(setLoading("updateHousePublishStatus", "FAILED"));
      });
  });
};

const updateHouseVideo = (houseId, link) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateHouseVideo", "REQUEST"));
    api
      .updateHouseVideo(houseId, link)
      .then(() => {
        dispatch(setHouseVideoLink(link));
        dispatch(setLoading("updateHouseVideo", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("updateHouseVideo", "FAILED"));
        reject(error);
      });
  });
};

const updateHouseVrTour = (houseId, link) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateHouseVrTour", "REQUEST"));
    api
      .updateHouseVrTour(houseId, link)
      .then(() => {
        dispatch(setHouseVrTourLink(link));
        dispatch(setLoading("updateHouseVrTour", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("updateHouseVrTour", "FAILED"));
        reject(error);
      });
  });
};

const cacheAndClearCurrentBuilder = () => (dispatch, getState) => {
  const builderState = getState().builder;
  const homeId = parseInt(builderState.currentBuilderId);
  const regionId = builderState.currentBuilderRegionId;
  const regions = builderState.currentBuilderRegions;
  dispatch(
    cacheCurrentBuilder(homeId, builderState.currentBuilder, regionId, regions)
  );
  dispatch(clearCurrentBuilder());
};

export default {
  setVisited,
  setCalendarValues,
  removeSelectedGridItem,
  addSelectedGridItem,
  changeSelectedGridItemSequence,
  setSelectedGridItemSequence,
  changeGridItemAvailability,
  updateGridItem,
  cacheCurrentBuilder,
  loadHomeBuilder,
  clearCurrentBuilder,
  setCurrentBuilder,
  createHost,
  createChef,
  updateHost,
  updateChef,
  deleteHost,
  deleteChef,
  updateSequences,
  createPhoto,
  createHouse,
  updateHouse,
  changeGridItemAvailabilityAndSequence,
  changeSelectedGridItemSequences,
  deletePhoto,
  setCalendar,
  createUnapprovedPOI,
  deleteAllPhotos,
  removeAllGridItems,
  createFeaturePage,
  deleteFeature,
  updateFeature,
  updateHousePublishStatus,
  switchGridItemAvailability,
  updateHousePhoto,
  cacheAndClearCurrentBuilder,
  setFormValues,
  formatGetHouseResponse,
  updateSwitchGridItemAvailability,
  updateHouseVideo,
  updateHouseVrTour,
  setHouseVideoLink,
  setHouseVrTourLink
};
