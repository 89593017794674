import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SimpleNavBar from "../common/SimpleNavBar";
import { Formik } from "formik";
import { Yup } from "hc-utils/FormValidator";
import InputText from "../common/InputText";
import _get from "lodash.get";
import ButtonMain from "../common/ButtonMain";
import parseQuery from "../../utilities/parseQuery";
import { globalOperations } from "../../duck/global";
import { connect } from "react-redux";
import { toastOperations } from "../../duck/toast";
import { withRouter } from "react-router-dom";
import SpinnerLoader from "../common/SpinnerLoader";
import { CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet";

const ResetPasswordFormSchema = Yup.object().shape({
  email: Yup.string().required("You must enter your email."),
  newPassword: Yup.string().required("New Password is required."),
  confirmPassword: Yup.string()
    .required("You must confirm your password.")
    .test("passwords-match", "Passwords do not match.", function (value) {
      return this.parent.newPassword === value;
    })
});

function ResetPasswordForm({ email, onSubmit, isLoading }) {
  return (
    <>
      <Helmet>
        <title>Home Concierge | Reset Password</title>
        <meta
          name="description"
          content="Forgot your password? Reset your password by entering your email."
        />
      </Helmet>
      <Formik
        onSubmit={onSubmit}
        validationSchema={ResetPasswordFormSchema}
        initialValues={{
          email: email ? email : "",
          newPassword: "",
          confirmPassword: ""
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched
        }) => (
          <form
            className="user-form user-form--resetpass"
            name="reset-password-form"
            onSubmit={handleSubmit}
          >
            <InputText
              name="email"
              type="email"
              size="full"
              placeholder="Email"
              disabled={email ? true : false}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              displayError={
                touched.email && errors.email ? errors.email : false
              }
            />
            <InputText
              name="newPassword"
              type="password"
              size="full"
              placeholder="New Password"
              disabled={false}
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              displayError={
                touched.newPassword && errors.newPassword
                  ? errors.newPassword
                  : false
              }
            />

            <InputText
              name="confirmPassword"
              type="password"
              size="full"
              placeholder="Confirm Password"
              disabled={false}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              displayError={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : false
              }
            />
            <ButtonMain color="aqua" type="submit" loading={isLoading}>
              Reset Password
            </ButtonMain>
          </form>
        )}
      </Formik>
    </>
  );
}

ResetPasswordForm.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

function ResetPassword({
  validateResetId,
  validateResetIdLoading,
  validateResetIdLoadingStatus,
  resetPassword,
  resetPasswordLoading,
  addToast,
  history
}) {
  const params = parseQuery(window.location.search);
  const [resetEmail, setResetEmail] = useState(null);
  const [showResetForm, setShowResetForm] = useState(false);
  useEffect(() => {
    if (params.id) {
      validateResetId(params.id)
        .then(data => {
          setResetEmail(data.email);
          setShowResetForm(true);
        })
        .catch(err => {
          window.console.error(err);
          setShowResetForm(true);
        });
    }
  }, [params.id, validateResetId]);

  const handleResetPassword = values => {
    resetPassword(
      values.email,
      values.newPassword,
      values.confirmPassword,
      params.id
    )
      .then(() => {
        addToast({
          text:
            "You have successfully reset your password. Please login to your account to continue."
        });
        history.push({ pathname: "/login" });
      })
      .catch(err => {
        window.console.error(err);
        addToast({
          text:
            "There was an error with resetting your password. Please try again.",
          intent: "error"
        });
      });
  };

  if (validateResetIdLoadingStatus === "FAILED") {
    return (
      <p>
        Sorry, this link was either already used or expired. Please request to
        reset your password again.
      </p>
    );
  }

  if (!params.id) {
    return (
      <p>
        This is not a valid reset link. Please contact{" "}
        <a href="mailto:help@homeconcierge.com">help@homeconcierge.com</a> with
        any questions or concerns.
      </p>
    );
  }

  return (
    <div className="reset-password">
      <div className="reset-password__inner">
        <SimpleNavBar
          prev="/"
          prevText="Back To Home"
          type="simple"
          pageName="Reset Your Password"
        />

        <CSSTransition
          in={showResetForm}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="fade"
          timeout={250}
        >
          <ResetPasswordForm
            email={resetEmail}
            onSubmit={handleResetPassword}
            isLoading={resetPasswordLoading}
          />
        </CSSTransition>

        <CSSTransition
          in={validateResetIdLoading === true}
          out={validateResetIdLoading === false}
          classNames="fade"
          timeout={250}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <SpinnerLoader />
        </CSSTransition>
      </div>
    </div>
  );
}

const mapState = state => ({
  validateResetIdLoading: _get(state.loading, "validateResetId.loading", true),
  validateResetIdLoadingStatus: _get(
    state.loading,
    "validateResetId.status",
    true
  ),
  resetPasswordLoading: _get(state.loading, "resetPassword.loading", false)
});

const mapDispatch = dispatch => ({
  validateResetId: uniqueId =>
    dispatch(globalOperations.validateResetId(uniqueId)),
  resetPassword: (email, password, confirmPassword, uniqueId) =>
    dispatch(
      globalOperations.resetPassword(email, password, confirmPassword, uniqueId)
    ),
  addToast: options => dispatch(toastOperations.addToast(options))
});

ResetPassword.propTypes = {
  validateResetId: PropTypes.func.isRequired,
  validateResetIdLoading: PropTypes.bool.isRequired,
  validateResetIdLoadingStatus: PropTypes.string,
  resetPassword: PropTypes.func.isRequired,
  resetPasswordLoading: PropTypes.bool.isRequired,
  addToast: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(connect(mapState, mapDispatch)(ResetPassword));
