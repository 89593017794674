import React from "react";
import ButtonMain from "../common/ButtonMain";
import _get from "lodash.get";

import { connect } from "react-redux";

const formatMaskedNumber = ccNumber => {
  if (ccNumber) {
    let formattedNumber = ccNumber.match(/.{1,4}/g).join(" ");
    return formattedNumber;
  } else {
    return null;
  }
};

function PaymentMethodsListItem({
  method,
  options,
  onUpdate,
  onSetDefault,
  onSelect,
  onDelete,
  updateDefaultCardLoading,
  deletePaymentMethodLoading
}) {
  return (
    <div className="pm-list-item" key={method.token}>
      <div className="pm-list-item__info">
        {method.type === "creditCard" && (
          <>
            <h4>{method.cardType}</h4>
            <p>{formatMaskedNumber(method.maskedNumber)}</p>
          </>
        )}
        {method.type === "payPal" && (
          <>
            <h4>Paypal Account</h4>
            <p>{method.email}</p>
          </>
        )}
        {!method.type && (
          <>
            <h4>Unknown Payment Type</h4>
          </>
        )}
      </div>
      <div className="pm-list-item__actions">
        {method.type === "payPal" ? null : options.update ? (
          <ButtonMain color="blue" onClick={() => onUpdate(method)}>
            Update
          </ButtonMain>
        ) : options.select ? (
          <ButtonMain onClick={onSelect} color="blue">
            Select
          </ButtonMain>
        ) : null}

        {options.delete && (
          <ButtonMain
            loading={deletePaymentMethodLoading}
            onClick={() => onDelete(method)}
            color="red"
          >
            Remove
          </ButtonMain>
        )}
        {method.default ? (
          <p>Your Default Card</p>
        ) : options.showDefault ? (
          <ButtonMain
            color="blue"
            loading={updateDefaultCardLoading}
            onClick={() => onSetDefault(method.token, method)}
          >
            Use As Default
          </ButtonMain>
        ) : null}
      </div>
    </div>
  );
}

const getUpdateDefaultCardLoading = (state, token) => {
  if (!token) return false;
  return _get(state.loading, `${token}_updateDefaultCard.loading`, false);
};

const getDeletePaymentMethodLoading = (state, token) => {
  if (!token) return false;
  return _get(state.loading, `${token}_deletePaymentMethod.loading`, false);
};

const mapState = (state, ownProps) => ({
  updateDefaultCardLoading: getUpdateDefaultCardLoading(
    state,
    ownProps.method.token
  ),
  deletePaymentMethodLoading: getDeletePaymentMethodLoading(
    state,
    ownProps.method.token
  )
});

const ConnectedPaymentMethodsListItem = connect(mapState)(
  PaymentMethodsListItem
);

function PaymentMethodsList({
  methods,
  options = {},
  onSelect,
  onUpdate,
  onDelete,
  onSetDefault
}) {
  if (methods === null) return null;

  const defaultOptions = {
    update: true,
    showDefault: true,
    select: true,
    delete: true
  };

  const optionsConfig = {
    ...defaultOptions,
    ...options
  };

  return (
    <div className="payment-methods-list">
      <h4 className="payment-methods-list__header">Payment Methods</h4>
      <div className="payment-methods-list__content">
        {methods.length > 0 ? (
          methods.map(method => (
            <ConnectedPaymentMethodsListItem
              method={method}
              options={optionsConfig}
              onUpdate={onUpdate}
              onSelect={onSelect}
              onSetDefault={onSetDefault}
              onDelete={onDelete}
            />
          ))
        ) : (
          <small>
            No payment methods added. Add a new payment method below.
          </small>
        )}
      </div>
    </div>
  );
}

export default PaymentMethodsList;
