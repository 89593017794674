import React, { Component } from "react";
import { connect } from "react-redux";

import { builderOperations } from "../../duck";
import { toastOperations } from "../../../../duck/toast";

import BuilderPageWrapper from "../../BuilderPageWrapper";
import FeatureController from "../../FeatureController";
import FeatureSelectedGrid from "../../FeatureSelectedGrid";
import FeatureOptionsGrid from "../../FeatureOptionsGrid";

import { canvasToFile } from "hc-utils";
import WarningPrompt from "../../../common/WarningPrompt";

import _get from "lodash.get";
import { Helmet } from "react-helmet";
import FeatureViewPrompt from "../../FeatureViewPrompt";

export class TvOffice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPrompt: false,
      queuedData: null
    };
  }

  getFeatureById(id) {
    const { tvOffice } = this.props;

    if (tvOffice.selected.find(tvOfficeObject => id === tvOfficeObject.id)) {
      return tvOffice.selected.find(tvOfficeObject => id === tvOfficeObject.id);
    } else {
      return null;
    }
  }

  handleNew = (key, name, icon, id, prefilled) => {
    const featureData = this.getFeatureById(id);

    this.setState({
      newPrompt: true,
      queuedCategory: { key, name, icon, prefilled },
      queuedData: featureData
    });
  };

  onDragStop = (
    id,
    originalAvailability,
    newAvailability,
    originalIndex,
    newIndex
  ) => {
    const { changeSelectedGridItemSequences, currentPageHash } = this.props;

    changeSelectedGridItemSequences(
      parseInt(currentPageHash),
      "tvOffice",
      originalAvailability,
      id,
      newIndex,
      true
    );
  };

  onDragStop2 = (data, pageHash, value, type, id, newSequence) => {
    const { changeSelectedGridItemSequences, currentPageHash } = this.props;

    changeSelectedGridItemSequences(
      parseInt(currentPageHash),
      "tvOffice",
      "selected",
      id,
      newSequence,
      true
    );
  };
  onEdit = ({ id }) => {
    const { tvOffice } = this.props;
    const featureData = this.getFeatureById(id);
    const option = tvOffice.options.find(({ key }) => key === featureData.key);
    this.setState({
      newPrompt: true,
      queuedData: featureData,
      queuedCategory: {
        key: featureData.key,
        name: featureData.name,
        icon: option.image,
        prefilled: option.prefilled
      }
    });
  };
  onDelete = ({ id }) => {
    this.setState({ showDeleteModal: true, queuedDeleteData: id });
  };

  deleteTvOffice = id => {
    const { currentPageHash, addToast, deleteFeature } = this.props;

    deleteFeature(currentPageHash, "tvOffice", "selected", id)
      .then(() => {
        addToast({ text: "Succesfully removed info page." });
      })
      .catch(error => {
        addToast({ text: error.message, intent: "error" });
      });
  };
  handleSubmit = values => {
    const { createFeaturePage, updateFeature, addToast, tvOffice } = this.props;
    const { queuedCategory, queuedData } = this.state;

    if (!queuedData) {
      createFeaturePage("9", "tvOffice", "selected", {
        name: queuedCategory.name,
        key: queuedCategory.key,
        sequence: tvOffice.selected.length,
        items: values.features.map((value, i) => ({
          id: value.id,
          sequence: i + 1,
          text: value.text,
          photoImage: _get(value.image, "file", null),
          pageRenderedImage: value.canvas ? canvasToFile(value.canvas.canvas, "image/png", 1) : null,
          coords: {
            photo: value.coords.photo,
            arc: value.coords.circle
          },
          videoURL: value.video_url,
          userManualURL: value.user_manual_url,
          isNew: Boolean(value.isNew)
        }))
      })
        .then(() => {
          this.setState({
            newPrompt: false,
            queuedCategory: null,
            queuedData: null
          });
          addToast({ text: "Succesfully added feature." });
        })
        .catch(error => {
          addToast({ text: error.message, intent: "error" });
        });
    } else {
      updateFeature("9", "tvOffice", "selected", queuedData.id, {
        name: queuedCategory.name,
        key: queuedCategory.key,
        sequence: queuedData.sequence,
        items: values.features
          .filter(value => value !== null)
          .map(value => {
            return {
              id: value.id,
              text: value.text || "",
              pageRenderedImage: this.state.isNew
                ? null
                : value.canvas ? canvasToFile(value.canvas.canvas, "image/jpeg", 0.8) : null,

              photoImage: _get(value.image, "file", false),

              coords: {
                photo: value.coords.photo,
                arc: value.coords.circle
              },
              videoURL: value.video_url,
              userManualURL: value.user_manual_url,
              isNew: Boolean(value.isNew)
            };
          })
      })
        .then(() => {
          this.setState({
            newPrompt: false,
            queuedCategory: null,
            queuedData: null
          });
          addToast({ text: "Succesfully updated feature." });
        })
        .catch(error => {
          addToast({ text: error.message, intent: "error" });
        });
    }
  };

  render() {
    const { pageKey, propertyInfoBuilder } = this.props;
    const {
      newPrompt,
      queuedCategory,
      queuedData,
      showDeleteModal,
      queuedDeleteData
    } = this.state;
    return (
      <>
        <Helmet>
          <title>
            Home Concierge | {propertyInfoBuilder.houseName} | TV Wifi Office
          </title>
          <meta
            name="description"
            content="Add a page describing your houses TV and office features."
          />
        </Helmet>
        <BuilderPageWrapper pageKey={pageKey}>
          <FeatureController pageHash={9} value={"tvOffice"}>
            {({ selected, options }) => (
              <>
                <FeatureSelectedGrid
                  items={selected}
                  onDragStop={this.onDragStop}
                  onEdit={this.onEdit}
                  onDelete={this.onDelete}
                  options={options}
                />
                <div className="grid-seperator">OR</div>
                <FeatureOptionsGrid items={options} onNew={this.handleNew} />
              </>
            )}
          </FeatureController>
        </BuilderPageWrapper>
        {newPrompt && (
          <FeatureViewPrompt
            data={queuedData}
            icon={queuedCategory.icon}
            title={queuedCategory.name}
            prefilled={queuedCategory.prefilled}
            onSubmit={this.handleSubmit}
            onClose={() =>
              this.setState({
                newPrompt: false,
                queuedData: null
              })
            }
          />
        )}
        {showDeleteModal && (
          <WarningPrompt
            onClose={() =>
              this.setState({
                showDeleteModal: false,
                queuedDeleteData: null
              })
            }
            onConfirm={() => {
              this.setState({ showDeleteModal: false }, () => {
                this.deleteTvOffice(queuedDeleteData);
              });
            }}
            onCancel={() =>
              this.setState({
                showDeleteModal: false,
                queuedDeleteData: null
              })
            }
          >
            Are you sure you want to delete this feature page?
          </WarningPrompt>
        )}
      </>
    );
  }
}

const mapState = (state, ownProps) => ({
  currentPageHash: Object.keys(state.builder.currentBuilder).find(
    pageHash => state.builder.currentBuilder[pageHash].key === "tvOffice"
  ),
  tvOffice: state.builder.currentBuilder[9].values["tvOffice"],
  propertyInfoBuilder: _get(
    state.builder.currentBuilder[1].values,
    "propertyInfo.form",
    null
  )
});

const mapDispatch = dispatch => ({
  removeSelectedGridItem: (pageHash, value, availabilityType, id) =>
    dispatch(
      builderOperations.removeSelectedGridItem(
        pageHash,
        value,
        availabilityType,
        id
      )
    ),
  addSelectedGridItem: (pageHash, value, availabilityType, itemData) =>
    dispatch(
      builderOperations.addSelectedGridItem(
        pageHash,
        value,
        availabilityType,
        itemData
      )
    ),

  changeSelectedGridItemSequences: (
    pageHash,
    value,
    availabilityType,
    id,
    newSequence,
    isCompact
  ) =>
    dispatch(
      builderOperations.changeSelectedGridItemSequences(
        pageHash,
        value,
        availabilityType,
        id,
        newSequence,
        isCompact
      )
    ),
  changeGridItemAvailabilityAndSequence: (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact
  ) =>
    dispatch(
      builderOperations.changeGridItemAvailabilityAndSequence(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact
      )
    ),
  updateGridItem: (pageHash, value, availabilityType, id, newValues) =>
    dispatch(
      builderOperations.updateGridItem(
        pageHash,
        value,
        availabilityType,
        id,
        newValues
      )
    ),
  createFeaturePage: (pageHash, value, availabilityType, featureData) =>
    dispatch(
      builderOperations.createFeaturePage(
        pageHash,
        value,
        availabilityType,
        featureData
      )
    ),
  deleteFeature: (pageHash, value, availabilityType, featureId) =>
    dispatch(
      builderOperations.deleteFeature(
        pageHash,
        value,
        availabilityType,
        featureId
      )
    ),
  updateFeature: (pageHash, value, availabilityType, featureId, featureData) =>
    dispatch(
      builderOperations.updateFeature(
        pageHash,
        value,
        availabilityType,
        featureId,
        featureData
      )
    ),

  addToast: options => dispatch(toastOperations.addToast(options))
});

export default connect(mapState, mapDispatch)(TvOffice);
