import React, { Component, Suspense } from "react";
import BuilderPageWrapper from "../../BuilderPageWrapper";
import POIController from "../../POIController";
// import POISelectedGrid from "../../POISelectedGrid";
// import POIAvailableGrid from "../../POIAvailableGrid";
import { connect } from "react-redux";
import { builderOperations } from "../../duck";
import { toastOperations } from "../../../../duck/toast";

import POIViewPrompt from "../../POIViewPrompt";
import POISuggestPrompt from "../../POISuggestPrompt";

import POISelectedGrid from "../../POISelectedGrid";
import POIAvailableGrid from "../../POIAvailableGrid";
import { Helmet } from "react-helmet";
import _get from "lodash.get";
import { withSuggestionContext } from "../../../common/SuggestionProvider";

export class Dine extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { setFeatureId, setFeatureName } = this.props.suggestionContext;
    const { dine, dineFeatureId } = this.props;
    setFeatureId(dineFeatureId);
    setFeatureName(dine.name);
  }

  onDragStop = (
    id,
    originalAvailability,
    newAvailability,
    originalIndex,
    newIndex
  ) => {
    const {
      changeGridItemAvailabilityAndSequence,
      changeSelectedGridItemSequences,
      currentPageHash,
      dine,
      switchGridItemAvailability
    } = this.props;

    if (
      newAvailability === "selected" &&
      originalAvailability === "available"
    ) {
      if (dine.selected.length >= 12) {
        switchGridItemAvailability(
          originalAvailability,
          newAvailability,
          currentPageHash,
          "dines",
          id,
          true,
          newIndex,
          originalIndex
        );
        return;
      }
    }

    if (originalAvailability !== newAvailability) {
      changeGridItemAvailabilityAndSequence(
        originalAvailability,
        newAvailability,
        currentPageHash,
        "dines",
        id,
        true,
        newIndex
      );
    } else {
      if (newIndex !== originalIndex) {
        changeSelectedGridItemSequences(
          parseInt(currentPageHash),
          "dines",
          originalAvailability,
          id,
          newIndex,
          true
        );
      }
    }
  };

  getItemById(id) {
    const { dine } = this.props;

    if (dine.selected.find(host => id === host.id)) {
      return dine.selected.find(host => id === host.id);
    } else if (dine.available.find(host => id === host.id)) {
      return dine.available.find(host => id === host.id);
    } else {
      return null;
    }
  }

  onView = ({ title, id, type }) => {
    const item = this.getItemById(id);
    this.setState({ viewPrompt: true, queuedData: item });
  };
  onToggleAvailability = ({ title, id, type }) => {
    const {
      changeGridItemAvailabilityAndSequence,
      currentPageHash,
      dine,
      addToast
    } = this.props;
    const newAvailability = type === "selected" ? "available" : "selected";

    if (newAvailability === "selected" && type === "available") {
      if (dine.selected.length >= 12) {
        addToast({
          text: "You can not have more than 12 items selected.",
          intent: "warning"
        });
        return;
      }
    }

    changeGridItemAvailabilityAndSequence(
      type,
      newAvailability,
      currentPageHash,
      "dines",
      id,
      true
    );
  };
  onSuggestPrompt = () => {
    this.setState({ suggestPrompt: true });
  };

  onSuggestSubmit = values => {
    const { addToast, createUnapprovedPOI } = this.props;
    // since there is no edit functionality on POI, no need to handle updateGridItem

    // Here needs to handle sending call to suggest POI

    createUnapprovedPOI({ ...values, featureId: 2 })
      .then(() => {
        this.setState({ suggestPrompt: false });
        addToast({
          text:
            "Thank you for sugggesting a new point of interest. We will review your submission and will be available once approved."
        });
      })
      .catch(error => {
        window.console.error(error);
        addToast({
          text: error.message,
          intent: "error"
        });
      });
  };

  render() {
    const { viewPrompt, queuedData, suggestPrompt } = this.state;
    const { propertyInfoBuilder, dineImage } = this.props;
    return (
      <>
        <Helmet>
          <title>Home Concierge | {propertyInfoBuilder.houseName} | Dine</title>
          <meta
            name="description"
            content="Choose locations for your guests to dine."
          />
        </Helmet>
        <BuilderPageWrapper pageKey={this.props.pageKey}>
          <Suspense fallback={<p>LOADING</p>}>
            <POIController pageHash={5} value={"dines"}>
              {({ available, selected }) => (
                <>
                  <POISelectedGrid
                    items={selected}
                    onDragStop={this.onDragStop}
                    onView={this.onView}
                    onRemove={this.onToggleAvailability}
                  />
                  <div className="grid-seperator">OR</div>
                  <POIAvailableGrid
                    items={available}
                    onDragStop={this.onDragStop}
                    onView={this.onView}
                    onAdd={this.onToggleAvailability}
                  />
                </>
              )}
            </POIController>
          </Suspense>
        </BuilderPageWrapper>
        {viewPrompt && (
          <POIViewPrompt
            data={queuedData}
            icon={dineImage}
            title={"Dine"}
            onClose={() => this.setState({ viewPrompt: false })}
          />
        )}
        {suggestPrompt && (
          <POISuggestPrompt
            icon={dineImage}
            title={"Dine"}
            onSubmit={this.onSuggestSubmit}
            onClose={() => this.setState({ suggestPrompt: false })}
          />
        )}
      </>
    );
  }
}

const mapState = state => ({
  currentPageHash: parseInt(
    Object.keys(state.builder.currentBuilder).find(
      pageHash => state.builder.currentBuilder[pageHash].key === "dine"
    )
  ),
  dine: state.builder.currentBuilder[5].values["dines"],
  dineImage: state.builder.currentBuilder[5].image,
  dineFeatureId: state.builder.currentBuilder[5].feature_id,
  currentBuilderId: state.builder.currentBuilderId,
  propertyInfoBuilder: _get(
    state.builder.currentBuilder[1].values,
    "propertyInfo.form",
    null
  )
});

const mapDispatch = dispatch => ({
  changeSelectedGridItemSequences: (
    pageHash,
    value,
    availabilityType,
    id,
    newSequence,
    isCompact
  ) =>
    dispatch(
      builderOperations.changeSelectedGridItemSequences(
        pageHash,
        value,
        availabilityType,
        id,
        newSequence,
        isCompact
      )
    ),

  changeGridItemAvailabilityAndSequence: (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact,
    newSequence
  ) =>
    dispatch(
      builderOperations.changeGridItemAvailabilityAndSequence(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact,
        newSequence
      )
    ),
  switchGridItemAvailability: (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact,
    newSequence,
    originalSequence
  ) =>
    dispatch(
      builderOperations.updateSwitchGridItemAvailability(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact,
        newSequence,
        originalSequence
      )
    ),
  createUnapprovedPOI: data =>
    dispatch(builderOperations.createUnapprovedPOI(data)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

Dine.propTypes = {};

export default withSuggestionContext(
  connect(mapState, mapDispatch, null, { forwardRef: true })(Dine)
);
