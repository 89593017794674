import React, { useEffect } from "react";

function Toast({ onDismiss, text, intent }) {
  const toastDelayLength = Math.min(Math.max(text.length * 100, 3500), 7000);

  useEffect(() => {
    setTimeout(() => {
      onDismiss();
    }, toastDelayLength);
  }, [onDismiss, toastDelayLength]);

  return (
    <div className={`toast-item `}>
      <div className={`toast-item__inner toast-item__inner--${intent}`}>
        <div className="toast-item__text">
          <p>{text}</p>
        </div>
        <div className="toast-item__close" onClick={onDismiss} />
      </div>
    </div>
  );
}

export default Toast;
