import React from "react";
import NoPlan from "./NoPlan";

function DashboardListNotFound({
  items,
  filters,
  query,
  hasPlan,
  clearFilters,
  noun
}) {
  const hasActiveQuery = query !== "" && query ? true : false;

  const hasActiveFilter =
    filters && typeof filters === "object"
      ? Object.keys(filters).reduce((acc, curr) => {
          // if acc has already been set to true, that means there is atleast one active filter.
          // early return here
          if (acc === true) {
            return acc;
          }

          // get current value of the current filter iteration
          const currentFilterValue = filters[curr];

          // if the current filter value is false, that means there is an active filter
          if (currentFilterValue === false) {
            return true;
          }

          return false;
        }, false)
      : false;

  return (
    <div
      className="dashboard-list-not-found"
      data-testid="dashboard-list-not-found"
    >
      {hasPlan === false ? (
        <NoPlan />
      ) : items.length > 0 ? (
        <p data-testid="home-list-no-results">
          {hasActiveQuery ? (
            <span>
              No results found for "<strong>{query}</strong>".
            </span>
          ) : hasActiveFilter ? (
            <span>
              No results. Click <button onClick={clearFilters}>here</button> to
              remove all active filters.
            </span>
          ) : (
            <p>No results.</p>
          )}
        </p>
      ) : (
        <p>No {noun}s found.</p>
      )}
    </div>
  );
}

export default DashboardListNotFound;
