import { createSelector } from "reselect";
import _get from "lodash.get";

const currentHouseSelector = state => {
  const currentBuilderId = _get(state.builder, "currentBuilderId", null);

  if (!currentBuilderId || _get(state.dashboard, "userHomes", []).length <= 0)
    return null;

  const currentHouse = state.dashboard.userHomes.find(
    ({ id }) => id === parseInt(currentBuilderId)
  );

  return currentHouse;
};

const permissionTypeSelector = createSelector(
  [currentHouseSelector],
  currentHouse => {
    return _get(currentHouse, "permission.type", null);
  }
);

export default permissionTypeSelector;
