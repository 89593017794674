import React, { Component } from "react";
import _get from "lodash.get";

export class UploadButton extends Component {
  onChange = evt => {
    const { onUpload } = this.props;
    const files = _get(evt, "target.files", null);
    onUpload(files);
    evt.target.value = null;
  };
  render() {
    const { name, multiple, children } = this.props;
    return (
      <div className="upload-button">
        <input
          className="hidden"
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          id={name}
          name={name}
          multiple={multiple}
          onChange={this.onChange}
        />
        <label htmlFor={name}>{children}</label>
      </div>
    );
  }
}

export default UploadButton;
