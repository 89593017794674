import React from "react";

import PromptWrapper from "../../../common/PromptWrapper";

function HousePhotosViewPrompt({ data, onClose }) {
  const medImage = data.image.find(image => image.size === "tablet").url;

  return (
    <PromptWrapper onClose={onClose} className="home-photos-prompt">
      <div className="home-photos-prompt">
        <img src={medImage} alt="Home" />
      </div>
    </PromptWrapper>
  );
}

export default HousePhotosViewPrompt;
