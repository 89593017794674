const defaultCheckoutInfo = "Do not make beds that have been occupied<br>" +
  "Put all dirty towels on top of the washer in the laundry room<br>" +
  "Leave the house neat and tidy<br>" +
  "Take all trash and recycling to the bins provided<br>" +
  "Turn off all lights, fans, AC, radios, TV, etc.<br>" +
  "Close and lock all windows, sliders and doors<br>" +
  "Please check carefully one more time for personal items you may have left behind, especially cell phone and camera chargers.<br>" +
  "There is postage and handling fee for returning forgotten personal items.<br>" +
  "When you leave, put both sets of keys on the kitchen counter and lock the door behind you.<br><br>" +
  "Have a safe flight home and please come back!";

export const DEFAULT_CHECK_OUT_INFO = defaultCheckoutInfo;
