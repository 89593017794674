import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GridWrapper from "../../GridWrapper";
import { useQueryParam, StringParam } from "use-query-params";
import _get from "lodash.get";
import { connect } from "react-redux";
import CategorySelector from "../../CategorySelector";
import HousePhotos from "./HousePhotos";
import { toastOperations } from "../../../../duck/toast";
import WarningPrompt from "../../../common/WarningPrompt";
import { builderOperations } from "../../duck";

import { processImage } from "hc-utils/fileHelper";
import HousePhotosViewPrompt from "./HousePhotosViewPrompt";
import HouseVideo from "./HouseVideo";
import HouseVRTour from "./HouseVRTour";

function HouseTourGrid({
  houseTour,
  addToast,
  deleteAllPhotosLoading,
  deleteAllPhotos,
  changeSelectedGridItemSequences,
  currentPageHash,
  currentBuilderId,
  updateHousePhoto,
  deletePhoto,
  createPhoto,
  updateHouseVideo,
  updateHouseVrTour
}) {
  const [currentCategory, setCurrentCategory] = useQueryParam(
    "category",
    StringParam
  );

  const [showDeleteAllPrompt, setShowDeleteAllPrompt] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showViewPrompt, setShowViewPrompt] = useState(false);

  const [viewData, setViewData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    if (!currentCategory) {
      setCurrentCategory("housePhotos");
    }
  }, [currentCategory, setCurrentCategory]);

  const handleDeleteAll = () => {
    deleteAllPhotos()
      .then(() => {
        setShowDeleteAllPrompt(false);
        addToast({ text: "Succesfully deleted all house photos." });
      })
      .catch(error => {
        addToast({
          text: error.message,
          intent: "error"
        });
      });
  };

  const handleDeleteAllPrompt = () => {
    if (_get(houseTour.housePhotos, "selected.length", 0) > 0) {
      setShowDeleteAllPrompt(true);
    } else {
      addToast({
        text: "You don't have any uploaded images to delete.",
        intent: "warning"
      });
    }
  };

  const handleDragStop = (
    id,
    originalAvailability,
    newAvailability,
    originalIndex,
    newIndex
  ) => {
    changeSelectedGridItemSequences(
      parseInt(currentPageHash),
      "housePhotos",
      "selected",
      id,
      newIndex,
      true
    );
    // if it is either being placed in the first position OR
    // if it is being removed from the first position,
    // update the house photo dynamically to displaym most up-to-date
    // house photo.

    if (newIndex === 1 || originalIndex === 1) {
      const homePhotos = houseTour.housePhotos;
      // get the newly added first item in house photos
      const photo = homePhotos.selected.sort((a, b) =>
        a.last_nom > b.last_nom ? 1 : b.last_nom > a.last_nom ? -1 : 0
      )[0];

      // if their is an item with seqeunce of 1, update the global house photo in userHomes
      if (photo) {
        updateHousePhoto(parseInt(currentBuilderId), photo.image);
      }
    }
  };

  const handleViewPhoto = ({ id }) => {
    const housePhotos = houseTour.housePhotos;
    const item = housePhotos.selected.find(photo => photo.id === id);
    setViewData(item);
    setShowViewPrompt(true);
  };

  const handleDeletePhotoPrompt = ({ title, gridIndex, id }) => {
    setDeleteData({ title, gridIndex, id });
    setShowDeletePrompt(true);
  };

  const handleDeletePhoto = () => {
    const { id } = deleteData;

    deletePhoto(currentPageHash, "housePhotos", "selected", id)
      .then(() => {
        setShowDeletePrompt(false);
        setDeleteData(null);
        addToast({ text: "Succesfully deleted image." });
      })
      .catch(err => {
        setDeleteData(null);
        addToast({ text: err.message, intent: "error" });
      });
  };

  const handlePhotoUpload = files => {
    const photoLimit = 28;

    const homePhotos = houseTour.housePhotos;

    if (homePhotos.selected.length + files.length > photoLimit) {
      if (homePhotos.selected.length >= photoLimit || files.length === 1) {
        addToast({
          intent: "warning",
          text: "You can only upload up to 28 images."
        });
        return;
      }

      addToast({
        intent: "warning",
        text:
          "You can only upload up to 28 images. Uploading remaining photos..."
      });

      files = files.slice(0, photoLimit - homePhotos.selected.length);
    }
    // init formdata, set houseID before iterating over files.
    const photoData = new FormData();
    photoData.set("houseId", currentBuilderId);

    // determine if any image has too large of a file size
    const fileSizePromises = [...files].map(file => {
      return processImage(file)
        .then(() => {
          return {
            file,
            isOk: true
          };
        })
        .catch(error => {
          return {
            file,
            isOk: false,
            error: error.message
          };
        });
    });

    //resolve the image check for file sizes
    Promise.all(fileSizePromises).then(files => {
      // get files that were returned as "notOk" a.k.a. too large to upload,
      // and add error toasts for those files.

      const notOkFiles = files.filter(file => !file.isOk);

      for (var i = 0; i < notOkFiles.length; i++) {
        addToast({
          text: `${notOkFiles[i].error} - ${notOkFiles[i].file.name}`,
          intent: "error"
        });
      }

      // grab files that are within file size limits,
      // and iterate over them, appending the files to formdata.
      const okFiles = files.filter(file => file.isOk);

      if (okFiles.length !== 0) {
        for (var fileIndex = 0; fileIndex < okFiles.length; fileIndex++) {
          photoData.append("image[]", okFiles[fileIndex].file);
        }

        // fire operation to upload formdata
        createPhoto(currentPageHash, "housePhotos", "selected", photoData)
          .then(photos => {
            // list of all uploaded photos is returned, fire toast for each
            photos.forEach((photo, i) => {
              addToast({
                text: `Uploaded image ${okFiles[i].file.name}`,
                intent: "success"
              });
            });
          })
          .catch(error => {
            window.console.error(error);
            // this means there was some kind of syntax/upload error.
            addToast({
              text: `There was an error while uploading your files. Please try again later.`,
              intent: "error"
            });
          });
      }
    });
  };

  const handleSaveVideo = ({ link }, actions) => {
    updateHouseVideo(currentBuilderId, link)
      .then(() => {
        addToast({
          intent: "success",
          text: "Succesfully updated house video."
        });
        actions.setSubmitting(false);
      })
      .catch(err => {
        addToast({
          intent: "error",
          text:
            "There was an error while updating house video. Please try again or contact help@homeconcierge.com"
        });
        actions.setSubmitting(false);
      });
  };

  const handleSaveVrTour = ({ link }, actions) => {
    updateHouseVrTour(currentBuilderId, link)
      .then(() => {
        addToast({
          intent: "success",
          text: "Succesfully updated house VR tour."
        });
        actions.setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        addToast({
          intent: "error",
          text:
            "There was an error while updating house VR tour link. Please try again or contact help@homeconcierge.com"
        });
        actions.setSubmitting(false);
      });
  };

  const renderCategoryPage = category => {
    switch (category) {
      case "housePhotos": {
        return (
          <HousePhotos
            onUpload={handlePhotoUpload}
            onDeleteAll={handleDeleteAllPrompt}
            onDragStop={handleDragStop}
            onView={handleViewPhoto}
            onDelete={handleDeletePhotoPrompt}
          />
        );
      }
      case "houseVideo": {
        return (
          <HouseVideo onSave={handleSaveVideo} data={houseTour.houseVideo} />
        );
      }
      case "360Tour": {
        // return <HouseVRTour onSave={handleSaveVrTour}/>
        return (
          <HouseVRTour onSave={handleSaveVrTour} data={houseTour["360Tour"]} />
        );
      }
      default: {
        return (
          <p>
            There was an error displaying your designated page for House Tour.
          </p>
        );
      }
    }
  };

  const handleCategoryChange = category => setCurrentCategory(category.key);

  if (!currentCategory) return null;

  return (
    <GridWrapper>
      <CategorySelector
        tabs={[
          houseTour.housePhotos,
          houseTour.houseVideo,
          houseTour["360Tour"]
        ].map(({ name, key, image, feature_id }) => ({
          label: name,
          key,
          image,
          feature_id
        }))}
        category={currentCategory}
        onCategoryChange={handleCategoryChange}
      />
      {renderCategoryPage(currentCategory)}
      <>
        {showDeletePrompt && (
          <WarningPrompt
            onClose={() => {
              setShowDeletePrompt(false);
              setDeleteData(null);
            }}
            onConfirm={() => {
              setShowDeletePrompt(false);
              handleDeletePhoto();
            }}
            onCancel={() => {
              setShowDeletePrompt(false);
              setDeleteData(null);
            }}
            isLoading={deleteAllPhotosLoading}
          >
            Are you sure you want to delete this house photo?
          </WarningPrompt>
        )}
        {showDeleteAllPrompt && (
          <WarningPrompt
            onClose={() => setShowDeleteAllPrompt(false)}
            onConfirm={handleDeleteAll}
            onCancel={() => setShowDeleteAllPrompt(false)}
            isLoading={deleteAllPhotosLoading}
          >
            Are you sure you want to delete all house photos?
          </WarningPrompt>
        )}
        {showViewPrompt && viewData && (
          <HousePhotosViewPrompt
            data={viewData}
            onClose={() => setShowViewPrompt(false)}
          />
        )}
      </>
    </GridWrapper>
  );
}

HouseTourGrid.propTypes = {
  houseTour: PropTypes.shape({
    housePhotos: PropTypes.object.isRequired,
    "360Tour": PropTypes.object.isRequired,
    houseVideo: PropTypes.object.isRequired
  }),
  addToast: PropTypes.func.isRequired,
  deleteAllPhotosLoading: PropTypes.bool,
  deleteAllPhotos: PropTypes.func.isRequired,
  changeSelectedGridItemSequences: PropTypes.func.isRequired,
  currentPageHash: PropTypes.string.isRequired,
  currentBuilderId: PropTypes.number.isRequired,
  updateHousePhoto: PropTypes.func.isRequired,
  deletePhoto: PropTypes.func.isRequired,
  createPhoto: PropTypes.func.isRequired
};

const mapState = state => ({
  houseTour: _get(state.builder.currentBuilder[2], "values", {}),
  deleteAllPhotosLoading: _get(state.loading, "deleteAllPhotos.loading", false),
  currentBuilderId: state.builder.currentBuilderId,
  currentPageHash: Object.keys(state.builder.currentBuilder).find(
    pageHash => state.builder.currentBuilder[pageHash].key === "houseTour"
  )
});

const mapDispatch = dispatch => ({
  addToast: options => dispatch(toastOperations.addToast(options)),
  deleteAllPhotos: () => dispatch(builderOperations.deleteAllPhotos()),
  changeSelectedGridItemSequences: (
    pageHash,
    value,
    availabilityType,
    id,
    newSequence,
    isCompact
  ) =>
    dispatch(
      builderOperations.changeSelectedGridItemSequences(
        pageHash,
        value,
        availabilityType,
        id,
        newSequence,
        isCompact
      )
    ),
  updateHousePhoto: (houseId, image) =>
    dispatch(builderOperations.updateHousePhoto(houseId, image)),
  deletePhoto: (pageHash, value, availabilityType, id) =>
    dispatch(
      builderOperations.deletePhoto(pageHash, value, availabilityType, id)
    ),
  createPhoto: (pageHash, value, availabilityType, photoData) =>
    dispatch(
      builderOperations.createPhoto(
        pageHash,
        value,
        availabilityType,
        photoData
      )
    ),
  updateHouseVideo: (houseId, link) =>
    dispatch(builderOperations.updateHouseVideo(houseId, link)),
  updateHouseVrTour: (houseId, link) =>
    dispatch(builderOperations.updateHouseVrTour(houseId, link))
});

export default connect(mapState, mapDispatch)(HouseTourGrid);
