import React from "react";

import PropTypes from "prop-types";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import CKEditorConfig from "../../utilities/CKEditor/config";

import clsx from "clsx";

function InputEditor({
  id,
  displayError,
  placeholder,
  setFieldTouched,
  setFieldValue,
  required,
  note = null,
  disabled = false,
  value,
  testId,
  style,
}) {
  // extend the base CKEditor config and add the note as placeholder
  const config = {
    ...CKEditorConfig,
    placeholder: note
  };

  // set field as touched on blur and fire onBlur handler if it exists
  const handleEditorBlur = (event, editor) => {
    setFieldTouched(id);
  };

  // Set field value when editor value changes.
  const handleEditorChange = (event, editor) => {
    const html = editor.getData();

    setFieldValue(id, html);
  };

  return (
    <div
      className={clsx({
        "editor-input": true,
        "editor-input--error": Boolean(displayError),
        "editor-input--disabled": disabled
      })}
      data-testid={testId}
	  style={style}
    >
      <label className="editor-input__label">
        {displayError
          ? displayError
          : required
          ? `${placeholder}*`
          : placeholder}
      </label>
      <CKEditor
        disabled={disabled}
        className="ck-editor"
        editor={ClassicEditor}
        config={config}
        data={value}
        onChange={handleEditorChange}
        onBlur={handleEditorBlur}
      />
    </div>
  );
}

InputEditor.defaultProps = {
  disabled: false,
  value: "",
  testId: 'editor-input',
  style: {}
};

InputEditor.propTypes = {
  disabled: PropTypes.bool,
  displayError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  name: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  note: PropTypes.string,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  value: PropTypes.string,
  testId: PropTypes.string,
  style: PropTypes.object,
};

export default InputEditor;
