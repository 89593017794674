import React from "react";
import PropTypes from "prop-types";

class GridWrapper extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="builder-grid">
        <div className="builder-grid__inner">{children}</div>
      </div>
    );
  }
}

GridWrapper.propTypes = {
  children: PropTypes.node
};

export default GridWrapper;
