import React from "react";
import { connect } from "react-redux";

function AccountPage({ page, children }) {
  return (
    <div className={`account-page account-page--${page}`}>
      <div className="account-page__inner">{children}</div>
    </div>
  );
}

export default connect(
  null,
  null
)(AccountPage);
