import React from "react";

function CalendarGrid({ children }) {
  return (
    <div className="calendar-grid">
      <div className="calendar-grid__inner">{children}</div>
    </div>
  );
}

export default CalendarGrid;
