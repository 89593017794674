import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function BuilderErrorItem({ message, id, link, onClose }) {
  return (
    <div className="builder-error-item">
      <div className="builder-error-item__icon">
        <FontAwesomeIcon icon={faQuestion} />
      </div>
      <Link to={link} onClick={onClose}>
        <p>{`${message}`}</p>
      </Link>
    </div>
  );
}
BuilderErrorItem.propTypes = {
  message: PropTypes.string,
  id: PropTypes.number,
  link: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};
function BuilderErrorList({ errors, onClose }) {
  return errors.map(error => (
    <>
      {Object.keys(error.errors).length !== 0 && (
        <ul className="builder-error-list">
          <li className="builder-error__item">
            <div className="builder-error__itemheader">
              <h4>{error.name}</h4>
            </div>

            {Object.keys(error.errors).map(errorKey => {
              return (
                <BuilderErrorItem
                  id={error.id}
                  message={error.errors[errorKey]}
                  link={`${error.link}${
                    error.key === "play" ? `?category=${errorKey}` : ""
                  }`}
                  onClose={onClose}
                />
              );
            })}
          </li>
        </ul>
      )}
    </>
  ));
}
BuilderErrorList.propTypes = {
  errors: PropTypes.array,
  onClose: PropTypes.func
};
export default BuilderErrorList;
