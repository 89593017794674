import React, { useState, useRef, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import _get from "lodash.get";
import clsx from "clsx";

const formatPhone = value => {
  // Hyphenate phone value
  // const re = new RegExp("-", "g");

  // const cleanedValue = value.replace(re, "");

  if (value.substr(value.length - 1, value.length) === "-") {
    return value.substr(0, value.length - 1);
  }

  var USNumber = value.match(/(\d{3})(\d{3})(\d{4})/);
  if (USNumber) {
    return "(" + USNumber[1] + ") " + USNumber[2] + "-" + USNumber[3];
  } else {
    return value;
  }
};

/// This does not yet support address type - use non-formik version
function InputTextFormik({
  type = "text",
  className,
  placeholder,
  disabled,
  size,
  required,
  maxLength = 2048,
  autoComplete = "new-password",
  field: { name, onBlur, onChange, value },
  form,
  icon: Icon
}) {
  const clickRef = useRef(null);
  const inputRef = useRef(null);

  const { errors, touched, submitCount } = form;
  const [isFocused, setFocus] = useState(false);

  const displayError =
    _get(errors, `${name}`, false) &&
    (_get(touched, `${name}`, false) || submitCount > 0)
      ? _get(errors, `${name}`, null)
      : false;

  useEffect(() => {
    const _clickRef = clickRef.current;

    const focusOnClick = evt => {
      evt.preventDefault();
      if (isFocused) return;
      inputRef.current.focus();
    };

    if (!clickRef.current || !inputRef.current) return;
    clickRef.current.addEventListener("click", focusOnClick);

    return () => {
      _clickRef.removeEventListener("click", focusOnClick);
    };
  }, [isFocused]);
  return (
    <div
      className={clsx("form-input", name, className, {
        focused: Boolean(isFocused || (value !== "" && value)),
        [`form-input--${size}`]: Boolean(size),
        error: Boolean(displayError),
        "form-input--disabled": Boolean(disabled),
        "form-input--with-icon": Boolean(Icon)
      })}
    >
      {Icon && (
        <span className="form-input__icon">
          <Icon />
        </span>
      )}
      <label className="form-input__label" htmlFor={name}>
        {displayError
          ? displayError
          : required
          ? `${placeholder}*`
          : placeholder}
      </label>
      {type === "textarea" ? (
        <textarea
          className={"form-input__input"}
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={evt => {
            setFocus(false);
            onBlur(evt);
          }}
          onFocus={() => setFocus(true)}
          maxLength={maxLength}
          disabled={disabled}
          ref={inputRef}
        />
      ) : type === "tel" ? (
        <PhoneInput
          id={name}
          name={name}
          country="US"
          placeholder="Enter phone number"
          value={value}
          onChange={onChange}
          onBlur={evt => {
            setFocus(false);
            onBlur(evt);
          }}
          onFocus={() => {
            setFocus(true);
          }}
          ref={inputRef}
        />
      ) : (
        <>
          <input
            className={"form-input__input"}
            id={name}
            autoComplete={autoComplete}
            name={name}
            type={type === "address" ? "text" : type}
            value={type === "tel" ? formatPhone(value) : value || ''}
            onChange={onChange}
            onBlur={evt => {
              setFocus(false);
              onBlur(evt);
            }}
            onFocus={() => {
              setFocus(true);
            }}
            maxLength={type === "tel" ? 14 : maxLength}
            disabled={disabled}
            ref={inputRef}
          />
        </>
      )}
    </div>
  );
}

export default InputTextFormik;
