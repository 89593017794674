import React from "react";
import PropTypes from "prop-types";
import GridHeader from "./GridHeader";
import BuilderButton from "./BuilderButton";
import posed, { PoseGroup } from "react-pose";
const TransWrapper = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    opacity: { duration: 150, ease: "out" }
  }
});
function FeatureOptionsGrid({ items, onNew }) {
  return (
    <div
      className="feature-grid-items feature-grid-items--options"
      data-testid="feature-options-grid"
    >
      <GridHeader color="gray">Create Your Own Info Page</GridHeader>
      <div className="feature-grid-items__actions">
        <PoseGroup flipMove={true} animateOnMount={true}>
          {items.map(
            ({ name, key, id, image, prefilled, isSelected }, index) => (
              <TransWrapper key={`${id}-${key}`}>
                <BuilderButton
                  key={`${index}-${key}`}
                  onClick={() => onNew(key, name, image, id, prefilled)}
                  name={key}
                  icon={{ type: "image", data: image }}
                  disabled={isSelected}
                >
                  {name}
                </BuilderButton>
              </TransWrapper>
            )
          )}
        </PoseGroup>
      </div>
    </div>
  );
}

FeatureOptionsGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool.isRequired,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      sequence_no: PropTypes.number.isRequired
    })
  ),
  onNew: PropTypes.func.isRequired
};

export default FeatureOptionsGrid;
