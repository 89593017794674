const SET_USER_HOMES_LIST = "SET_USER_HOMES_LIST";
const SET_HOME_GUEST_LIST = "SET_HOME_GUEST_LIST";

const REMOVE_HOME_GUEST = "REMOVE_HOME_GUEST";

const ADD_HOME_GUEST = "ADD_HOME_GUEST";

const REMOVE_HOUSE = "REMOVE_HOUSE";
const ADD_USER_HOME = "ADD_USER_HOME";
const UPDATE_HOME_GUEST = "UPDATE_HOME_GUEST";
const SET_HOUSE_PUBLISH_STATUS = "SET_HOUSE_PUBLISH_STATUS";
const UPDATE_HOUSE_PHOTO = "UPDATE_HOUSE_PHOTO";
const UPDATE_HOUSE_INFO = "UPDATE_HOUSE_INFO";
const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";
const SET_GUEST_STATUS = "SET_GUEST_STATUS";

export default {
  SET_USER_HOMES_LIST,
  SET_HOME_GUEST_LIST,
  REMOVE_HOME_GUEST,
  ADD_HOME_GUEST,
  REMOVE_HOUSE,
  ADD_USER_HOME,
  UPDATE_HOME_GUEST,
  SET_HOUSE_PUBLISH_STATUS,
  UPDATE_HOUSE_PHOTO,
  UPDATE_HOUSE_INFO,
  CLEAR_DASHBOARD,
  SET_GUEST_STATUS
};
