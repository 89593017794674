import React from "react";
import PropTypes from "prop-types";
import _get from "lodash.get";
import { connect } from "react-redux";
import DashboardController from "./DashboardController";
import HomeList from "./HomeList";
import { dashboardOperations } from "./duck";
import { toastOperations } from "../../duck/toast";
import { Helmet } from "react-helmet";

class HomeDashboard extends React.Component {
  componentDidMount() {
    const { getUserHomesList, addToast, history, userHomesList } = this.props;

    if (userHomesList.length > 0) return;

    getUserHomesList().catch(err => {
      addToast({
        text: err.message,
        intent: "error"
      });
      if (err.status === 401) {
        history.push("/login");
      }
    });
  }

  addNewHouse = () => {
    const { history } = this.props;
    history.push({
      pathname: "/home/new"
    });
  };
  render() {
    const { userHomesList, isUserHomesLoading } = this.props;

    return (
      <>
        <Helmet>
          <title>Home Concierge | My Homes</title>
          <meta name="description" content="View all of your managed homes." />
        </Helmet>
        {userHomesList && (
          <DashboardController onNew={this.addNewHouse} layout="userHomes">
            {({ query, filters, clearFilters }) => (
              <HomeList
                items={userHomesList}
                query={query}
                filters={filters}
                clearFilters={clearFilters}
                isLoading={isUserHomesLoading}
              />
            )}
          </DashboardController>
        )}
      </>
    );
  }
}

HomeDashboard.propTypes = {
  addToast: PropTypes.func.isRequired,
  getUserHomesList: PropTypes.func.isRequired,
  history: PropTypes.object,
  isUserHomesLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  userHomesList: PropTypes.array
};

const mapState = state => ({
  userHomesList: state.dashboard.userHomes,
  isUserHomesLoading:
    _get(state, "loading.userHomes.loading", true) ||
    _get(state, "loading.getUserData.loading", false)
});
const mapDispatch = dispatch => ({
  getUserHomesList: () => dispatch(dashboardOperations.getUserHomesList()),
  addToast: options => dispatch(toastOperations.addToast(options))
});
HomeDashboard.propTypes = {};

export default connect(mapState, mapDispatch)(HomeDashboard);
