import React from "react";

import InputText from "../../../common/InputText";

import calendarSubIcon from "../../../../assets/icons/CalendarO.svg";

import GardenerIcon from "../../../../assets/gardener.svg";
import TrashPickupIcon from "../../../../assets/trashPickup.svg";
import PoolJacuzziIcon from "../../../../assets/poolJacuzzi.svg";
import HousekeepingIcon from "../../../../assets/houseKeeping.svg";

import _get from "lodash.get";

const calendarIcons = {
  houseKeeping: HousekeepingIcon,
  trashPickup: TrashPickupIcon,
  gardener: GardenerIcon,
  poolJacuzzi: PoolJacuzziIcon
};

const categoryToTitle = (category) => {
  switch (category) {
    case "houseKeeping": {
      return "House Keeping";
    }
    case "gardener": {
      return "Gardener";
    }
    case "trashPickup": {
      return "Trash Pickup";
    }
    case "poolJacuzzi": {
      return "Pool / Jacuzzi";
    }
    default: {
      return null;
    }
  }
};

function CalendarItem({
  category,

  setFieldValue,
  handleSubmit,
  values,
  errors,
  touched,
  setFieldTouched
}) {
  return (
    <div className="calendar-item">
      <div className="calendar-item__inner">
        <div className="calendar-item__icon">
          <img src={calendarIcons[category]} alt="maintenance calendar icon" />
        </div>
        <div className="calendar-item__subicon">
          <img src={calendarSubIcon} alt="maintenance calendar subicon" />
        </div>
        <div className="calendar-item__fields">
          <h4>{categoryToTitle(category)}</h4>

          <form
            name="calendar-form"
            className="user-form user-form--calendar"
            onSubmit={handleSubmit}
            data-testid="calendar-form"
          >
            <InputText
              name={`${category}-days`}
              fieldName={`calendarItems[${category}].day`}
              type="text"
              size="full"
              placeholder="Day(s)"
              value={values.calendarItems[category].day || ""}
              onChange={(evt) => {
                setFieldValue(
                  `calendarItems[${category}].day`,
                  evt.target.value
                );
              }}
              onBlur={() => {
                setFieldTouched(`calendarItems[${category}].day`);
              }}
              displayError={
                _get(errors, `calendarItems[${category}].day`, false) &&
                _get(touched, `calendarItems[${category}].day`, false)
                  ? errors.calendarItems[category].day
                  : false
              }
            />

            <InputText
              name={`${category}-times`}
              type="text"
              size="full"
              placeholder="Time(s)"
              value={values.calendarItems[category].time || ""}
              onChange={(evt) => {
                setFieldValue(
                  `calendarItems[${category}].time`,
                  evt.target.value
                );
              }}
              onBlur={() => {
                setFieldTouched(`calendarItems[${category}].time`);
              }}
              displayError={
                _get(errors, `calendarItems[${category}].time`, false) &&
                _get(touched, `calendarItems[${category}].time`, false)
                  ? errors.calendarItems[category].time
                  : false
              }
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default CalendarItem;
