import React from "react";
import PropTypes from "prop-types";
import PromptWrapper from "../common/PromptWrapper";
import PromptHeader from "./PromptHeader";
import PersonImage from "./PersonImage";
import { Formik } from "formik";
import InputText from "../common/InputText";
import InputEditor from "./InputEditor";

function PeoplePoiPrompt({ onClose, data = {}, categoryImage, categoryName }) {
  const peopleImageUrl = Array.isArray(data.image)
    ? data.image.find(({ size }) => size === "tablet").url
    : null;

  return (
    <>
      <PromptWrapper onClose={onClose} className="people-poi-prompt-wrapper">
        <div className="people-poi-prompt">
          <div className="people-poi-prompt__inner">
            <PromptHeader icon={categoryImage}>{categoryName}</PromptHeader>
            <PersonImage src={peopleImageUrl} withUpload={false} />
            <Formik
              initialValues={{
                name: data ? data.name : "",
                phone: data ? data.phone : "",
                website: data ? data.website : "",
                email: data ? data.email : "",
                description: data ? data.description : ""
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue,
                setFieldTouched
              }) => (
                <form
                  name="chef-prompt-form"
                  className="user-form user-form--prompt"
                  onSubmit={handleSubmit}
                >
                  <InputText
                    disabled={true}
                    name="name"
                    type="text"
                    size="full"
                    placeholder="Name"
                    autoComplete="name"
                    required={true}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayError={
                      errors.name && touched.name ? errors.name : false
                    }
                  />

                  <InputText
                    type="text"
                    disabled={true}
                    name="phone"
                    size="full"
                    placeholder="Phone Number"
                    autoComplete="phone"
                    required={true}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayError={
                      errors.phone && touched.phone ? errors.phone : false
                    }
                    id="phone"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                  <InputText
                    name="email"
                    disabled={true}
                    type="email"
                    size="full"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    autoComplete="email"
                    onBlur={handleBlur}
                    displayError={
                      errors.email && touched.email ? errors.email : false
                    }
                  />
                  <InputText
                    name="website"
                    disabled={true}
                    type="text"
                    size="full"
                    placeholder="Website"
                    value={values.website}
                    onChange={handleChange}
                    autoComplete="url"
                    onBlur={handleBlur}
                    displayError={
                      errors.website && touched.website ? errors.website : false
                    }
                  />
                  <InputEditor
                    disabled={true}
                    name="description"
                    id="description"
                    touched={touched}
                    placeholder="Description"
                    required={true}
                    initialMarkup={values.description}
                    note="Add a description for the photo."
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayError={
                      errors.description && touched.description
                        ? errors.description
                        : false
                    }
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </PromptWrapper>
    </>
  );
}

PeoplePoiPrompt.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    address: PropTypes.shape({
      fullAddress: PropTypes.string
    }),
    description: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.oneOf(["mobile", "thumb", "tablet"]).isRequired,
        url: PropTypes.string.isRequired
      })
    ),
    key: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    sequence: PropTypes.number,
    website: PropTypes.string
  }),
  categoryImage: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired
};

export default PeoplePoiPrompt;
