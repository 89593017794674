export default {
  beaches: "Beaches",
  sightseeing: "Sightseeing",
  golf: "Golf",
  surfSUP: "Surf/SUP",
  snorkelling: "Snorkelling",
  diving: "Diving",
  biking: "Biking",
  hiking: "Hiking",
  adventures: "Adventures",
  entertainment: "Entertainment",
  classicHawaii: "Classic Hawaii",
  volcanoLava: "Volcano Lava",
  otherPlay: "More..."
};
