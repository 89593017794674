import React from "react";
import PropTypes from "prop-types";
import { DropTarget } from "react-dnd";

let BlankGridItem = ({ banner, isOver, connectDropTarget }) =>
  connectDropTarget(
    <div className="grid-item grid-item--blank">
      {banner && (
        <div
          className={`grid-item__banner ${
            banner.color ? `grid-item__banner--${banner.color}` : ""
          }`}
        >
          {banner.text}
        </div>
      )}
      <div className="grid-item__inner">
        <button className="grid-item__btn">
          {isOver && (
            <div
              className="grid-item__over"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                background: "black",
                opacity: 0.5,
                top: 0
              }}
            />
          )}
        </button>
      </div>
    </div>
  );

BlankGridItem.propTypes = {
  banner: PropTypes.objectOf(PropTypes.string),
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func
};

const gridItemTarget = {
  drop(props, monitor) {
    const draggedItemID = monitor.getItem().sourceId;
    const draggedItemAvailability = monitor.getItem().sourceAvailability;
    const draggedItemIndex = monitor.getItem().sourceIndex;
    const targetIndex = props.gridIndex + 1;
    const targetAvailability = props.type;

    return {
      draggedItemID,
      draggedItemAvailability,
      draggedItemIndex,
      targetIndex,
      targetAvailability
    };
  }
};

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

BlankGridItem = DropTarget("GRID_ITEM", gridItemTarget, collectTarget)(
  BlankGridItem
);

export default BlankGridItem;
