import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import _get from "lodash.get";
import BuilderPageWrapper from "../../BuilderPageWrapper";

import parseQuery from "hc-utils/parseQuery";
import { useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import GuestServicesSelector from "./GuestServicesSelector";
import GuestServicesCalendar from "./GuestServicesCalendar";
import GuestServicesPrivateChefs from "./GuestServicesPrivateChefs";
import GuestServicesPois from "./GuestServicesPois";
import GuestServicesHosts from "./GuestServicesHosts";

function GuestServicesNew({ pageKey }) {
  const location = useLocation();

  const propertyInfoBuilder = useSelector(state =>
    _get(state.builder.currentBuilder[1].values, "propertyInfo.form", null)
  );
  const currentBuilderId = useSelector(state =>
    _get(state.builder, "currentBuilderId")
  );

  const guestServices = useSelector(state =>
    _get(state.builder.currentBuilder[3], "values", {})
  );

  const currentCat = parseQuery(location.search);

  if (!currentCat.category) {
    return (
      <Redirect
        to={`/home/edit/${currentBuilderId}/guestServices?category=hosts`}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Home Concierge | {propertyInfoBuilder.houseName} | Play</title>
        <meta
          name="description"
          content="Choose locations for your guests to dine."
        />
      </Helmet>

      <BuilderPageWrapper pageKey={pageKey} className="play">
        <GuestServicesSelector
          tabs={[
            guestServices.hosts,
            ...(guestServices.privateChefs ? [guestServices.privateChefs] : []),
            guestServices.calendar,
            ...(guestServices.privateTours ? [guestServices.privateTours] : []),
            ...(guestServices.personalShopper
              ? [guestServices.personalShopper]
              : []),
            ...(guestServices.privateMasseuse
              ? [guestServices.privateMasseuse]
              : []),
            ...(guestServices.rentals ? [guestServices.rentals] : [])
          ].map(({ name, key, image, feature_id }) => ({
            label: name,
            key,
            image,
            feature_id
          }))}
        >
          <GuestServicesHosts
            selected={guestServices.hosts.selected}
            available={guestServices.hosts.available}
          />

          {guestServices.privateChefs ? (
            <GuestServicesPrivateChefs
              selected={
                guestServices.privateChefs.selected !== null
                  ? guestServices.privateChefs.selected
                  : []
              }
              available={
                guestServices.privateChefs.available !== null
                  ? guestServices.privateChefs.available
                  : []
              }
            />
          ) : null}
          <GuestServicesCalendar />

          {guestServices.privateTours && (
            <GuestServicesPois
              available={
                guestServices.privateTours.available !== null
                  ? guestServices.privateTours.available
                  : []
              }
              selected={
                guestServices.privateTours.selected !== null
                  ? guestServices.privateTours.selected
                  : []
              }
              valueKey={"privateTours"}
            />
          )}

          {guestServices.personalShopper && (
            <GuestServicesPois
              available={
                guestServices.personalShopper.available !== null
                  ? guestServices.personalShopper.available
                  : []
              }
              selected={
                guestServices.personalShopper.selected !== null
                  ? guestServices.personalShopper.selected
                  : []
              }
              valueKey={"personalShopper"}
            />
          )}

          {guestServices.privateMasseuse && (
            <GuestServicesPois
              available={
                guestServices.privateMasseuse.available !== null
                  ? guestServices.privateMasseuse.available
                  : []
              }
              selected={
                guestServices.privateMasseuse.selected !== null
                  ? guestServices.privateMasseuse.selected
                  : []
              }
              valueKey={"privateMasseuse"}
            />
          )}

          {guestServices.rentals && (
            <GuestServicesPois
              available={
                guestServices.rentals.available !== null
                  ? guestServices.rentals.available
                  : []
              }
              selected={
                guestServices.rentals.selected !== null
                  ? guestServices.rentals.selected
                  : []
              }
              valueKey={"rentals"}
              type="poi"
            />
          )}
        </GuestServicesSelector>
      </BuilderPageWrapper>
    </>
  );
}

GuestServicesNew.propTypes = {
  currentPageHash: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
  pageKey: PropTypes.string.isRequired,
  pageVisited: PropTypes.bool,
  touched: PropTypes.objectOf(PropTypes.string),
  values: PropTypes.shape({
    calendar: PropTypes.shape({
      feature_id: PropTypes.number.isRequired,
      gardener: PropTypes.shape({
        day: PropTypes.string,
        time: PropTypes.string
      }),
      houseKeeping: PropTypes.shape({
        day: PropTypes.string,
        time: PropTypes.string
      }),
      poolJacuzzi: PropTypes.shape({
        day: PropTypes.string,
        time: PropTypes.string
      }),
      trashPickup: PropTypes.shape({
        day: PropTypes.string,
        time: PropTypes.string
      }),
      image: PropTypes.string,
      key: PropTypes.string,
      name: PropTypes.string,
      required: PropTypes.bool
    })
  })
};

export default GuestServicesNew;
