import React, { useState } from "react";
import PropTypes from "prop-types";

import ButtonMain from "../common/ButtonMain";

import RegisterForm from "./RegisterForm";

function AccountTypeSelector({ onSelect }) {
  return (
    <div className="account-type-selector">
      <ButtonMain color="blue" onClick={() => onSelect("individual")}>
        Individual User
      </ButtonMain>
      <ButtonMain color="aqua" onClick={() => onSelect("company")}>
        Company User
      </ButtonMain>
    </div>
  );
}

function SignupForm({ onSubmit, initialValues }) {
  const [accountType, setAccountType] = useState(null);

  if (!accountType) {
    return <AccountTypeSelector onSelect={type => setAccountType(type)} />;
  }

  return (
    <RegisterForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      accountType={accountType}
      withBilling={true}
    />
  );
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired
};

export default SignupForm;
