import types from "./types";

//generate uuids for temparary testing of redux data/photos
const uuidv4 = require("uuid/v4");

export const initialBuilderState = {
  currentPageHash: 1,
  currentBuilderErrors: {},
  currentBuilder: {
    1: {
      name: "Property Info",
      key: "propertyInfo",
      isAddable: true,
      visited: false,
      errors: {},
      touched: {},
      values: {
        propertyInfo: {
          form: {
            houseName: "",
            ownerName: "",
            houseAddr: "",
            housePhone: "",
            homeEmail: "",
            homeWebsite: ""
          }
        }
      }
    },
    2: {
      name: "House Photos",
      key: "housePhotos",
      isAddable: true,
      visited: false,
      errors: {},
      touched: {},
      values: {
        homePhotos: {
          name: "House Photos",
          required: true,
          selected: [
            {
              id: uuidv4(),
              sequence: 0,
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=hp1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/150?text=hp1-thumb",
                  size: "thumb"
                }
              ]
            },
            {
              id: uuidv4(),
              sequence: 1,
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=hp2-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/150?text=hp2-thumb",
                  size: "thumb"
                }
              ]
            },
            {
              id: uuidv4(),
              sequence: 2,
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=hp3-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/150?text=hp3-thumb",
                  size: "thumb"
                }
              ]
            }
          ]
        }
      }
    },

    3: {
      name: "Guest Services",
      key: "guestServices",
      isAddable: true,
      visited: false,
      errors: {},
      touched: {},
      values: {
        hosts: {
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Host 1",
              title: "Main Host",
              phone: "3105551234",
              email: "test@gmail.com",
              website: "https://google.com",
              description: "<p>TEST: I am <strong>Bold</strong></p>",
              sequence: 1,
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=host1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/150?text=host1-thumb",
                  size: "thumb"
                }
              ]
            }
          ],
          available: []
        },
        privateChefs: {
          required: false,
          selected: [
            {
              id: uuidv4(),
              name: "Chef 1",
              title: "Main Chef",
              phone: "3105551234",
              email: "test@gmail.com",
              website: "https://google.com",
              description: "<p>TEST: I am <strong>Bold chef</strong></p>",
              sequence: 1,
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=chef1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/150?text=chef1-thumb",
                  size: "thumb"
                }
              ]
            }
          ],
          available: []
        },
        calendar: {
          required: false,
          houseKeeping: {},
          trashPickup: {},
          gardener: {},
          poolJacuzzi: {}
        }
      }
    },
    4: {
      name: "Shop",
      key: "shop",
      visited: false,
      isAddable: false,
      errors: {},
      touched: {},
      values: {
        shop: {
          name: "Shop",
          required: true,
          selected: [],
          available: [
            {
              id: uuidv4(),
              name: "Shop 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description:
                "<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=shop1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/150?text=shop1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ]
        }
      }
    },
    5: {
      name: "Dine",
      key: "dine",
      isAddable: false,
      visited: false,
      errors: {},
      touched: {},
      values: {
        dine: {
          name: "Dine",
          required: true,
          selected: [],
          available: [
            {
              id: uuidv4(),
              name: "Dine 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description:
                "<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=dine1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/150?text=dine1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ]
        }
      }
    },
    6: {
      name: "Play",
      key: "play",
      isAddable: false,
      visited: false,
      errors: {},
      touched: {},
      values: {
        beaches: {
          name: "Beaches",
          required: true,
          selected: [],
          available: [
            {
              id: uuidv4(),
              name: "Beach 1",
              key: "generalStore",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for general store.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=beach1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=beach1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ]
        },
        hula: {
          name: "Hula",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Hula 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for general store.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=hula1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=hula1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: [
            {
              id: uuidv4(),
              name: "Hula 2",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>hula 2.</strong>",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=hula2-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=hula2-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ]
        },
        lava: {
          name: "Lava",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Lava 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Lava 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=lava1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=lava1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        sightSeeing: {
          name: "Sight Seeing",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Sight Seeing 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Sight Seeing 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=ss1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=ss1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        golf: {
          name: "Golf",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Golf 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Golf 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=golf1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=golf1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        surfSUP: {
          name: "Surf SUP",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Surf SUP 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Surf 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=surf1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=surf1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        snorkel: {
          name: "Snorkel",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Snorkel 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Snorkel 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=snorkel1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=snorkel1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        diving: {
          name: "Diving",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Diving 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Diving 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=diving1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=diving1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        hiking: {
          name: "Hiking",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Hiking 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Hiking 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=hiking1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=hiking1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        biking: {
          name: "Biking",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Biking 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Biking 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=biking1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=biking1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        entertainment: {
          name: "Entertainment",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Entertainment 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Entertainment 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=ent1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=ent1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        },
        adventures: {
          name: "Adventures",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Adventures 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Adventures 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=adv1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=adv1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        }
      }
    },
    7: {
      name: "Spa",
      key: "spa",
      isAddable: false,
      visited: false,
      errors: {},
      touched: {},
      values: {
        spa: {
          name: "Spa",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Spa 1",
              address: {
                fullAddress: "555 Main St, El Segundo, CA",
                lat: 19.94256,
                lng: -155.85572
              },
              description: "Example text for <strong>Spa 1</strong>.",
              image: [
                {
                  url: "https://via.placeholder.com/1100?text=spa1-med",
                  size: "med"
                },
                {
                  url: "https://via.placeholder.com/1100?text=spa1-thumb",
                  size: "thumb"
                }
              ],
              sequence: 1
            }
          ],
          available: []
        }
      }
    },
    8: {
      name: "Music",
      key: "music",
      isAddable: true,
      visited: false,
      errors: {},
      touched: {},
      values: {
        music: {
          name: "Music",
          required: true,
          selected: [
            {
              id: uuidv4(),
              name: "Your Music",
              key: "yourMusic",
              sequence: 0,
              items: [
                {
                  id: uuidv4(),
                  text:
                    "<p>You can connect your phone via&nbsp;<strong>auxillary cable</strong> to listen to your music throughout the home entertainment system.</p>",
                  sequence: 0,
                  images: [
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557947641_feature-test-img-1.jpeg",
                      size: "med"
                    },
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557521666_silentcry_final (1).jpg",
                      size: "thumb"
                    }
                  ],
                  coords: {
                    photo: { x: -277, y: -209, height: 4714, width: 7071 },
                    arc: {
                      x: 495,
                      y: 207,
                      radius: 150
                    }
                  }
                },
                {
                  id: uuidv4(),
                  text:
                    "<p>You can connect your phone via&nbsp;<strong>auxillary cable</strong> to listen to your music throughout the home entertainment system.</p>",
                  sequence: 0,
                  images: [
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557947641_feature-test-img-1.jpeg",
                      size: "med"
                    },
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557521666_silentcry_final (1).jpg",
                      size: "thumb"
                    }
                  ],
                  coords: {
                    photo: { x: -277, y: -209, height: 4714, width: 7071 },
                    arc: {
                      x: 495,
                      y: 207,
                      radius: 150
                    }
                  }
                },
                {
                  id: uuidv4(),
                  text:
                    "<p>You can connect your phone via&nbsp;<strong>auxillary cable</strong> to listen to your music throughout the home entertainment system.</p>",
                  sequence: 0,
                  images: [
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557947641_feature-test-img-1.jpeg",
                      size: "med"
                    },
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557521666_silentcry_final (1).jpg",
                      size: "thumb"
                    }
                  ],
                  coords: {
                    photo: { x: -277, y: -209, height: 4714, width: 7071 },
                    arc: {
                      x: 495,
                      y: 207,
                      radius: 150
                    }
                  }
                },
                {
                  id: uuidv4(),
                  text:
                    "<p>You can connect your phone via&nbsp;<strong>auxillary cable</strong> to listen to your music throughout the home entertainment system.</p>",
                  sequence: 0,
                  images: [
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557947641_feature-test-img-1.jpeg",
                      size: "med"
                    },
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557521666_silentcry_final (1).jpg",
                      size: "thumb"
                    }
                  ],
                  coords: {
                    photo: { x: -277, y: -209, height: 4714, width: 7071 },
                    arc: {
                      x: 495,
                      y: 207,
                      radius: 150
                    }
                  }
                },
                {
                  id: uuidv4(),
                  text:
                    "<p>Feel free to listen to our turntable! We have many records available:</p> <ul> <li><em>Vince Guaraldi Trio, <strong>A Charlie Brown Christmas</strong></em>&nbsp;</li> <li><em>Miles Davis,&nbsp;<strong>Kind Of Blue</strong></em></li> <li><em>Micheal Jackson,&nbsp;</em><em><strong>Thriller</strong></em></li> </ul>",
                  sequence: 1,
                  images: [
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557947508_feature-test-img-2.jpeg",
                      size: "med"
                    },
                    {
                      url:
                        "http://homeconcierge.s3-website.us-east-2.amazonaws.com/images/1557521666_silentcry_final (1).jpg",
                      size: "thumb"
                    }
                  ],
                  coords: {
                    photo: { x: -234, y: -150, height: 3776, width: 5671 },
                    arc: {
                      x: 609,
                      y: 348,
                      radius: 749
                    }
                  }
                }
              ]
            }
          ],
          options: [
            {
              name: "Music",
              key: "music"
            },
            {
              name: "Your Music",
              key: "yourMusic"
            },
            {
              name: "Play Music",
              key: "playMusic"
            }
          ]
        }
      }
    },
    9: {
      name: "TV - Wifi - Office",
      key: "tvOffice",
      isAddable: true,
      visited: false,
      errors: {},
      touched: {},
      values: {
        tvOffice: {
          name: "TV - Wifi - Office",
          required: true,
          selected: [],
          options: [
            { name: "How TV Works", key: "howTvWorks" },
            { name: "Home Office", key: "homeOffice" },
            { name: "Copy Center", key: "copyCenter" },
            { name: "Printer", key: "printer" }
          ]
        }
      }
    },
    10: {
      name: "Emergency",
      key: "emergency",
      isAddable: true,
      visited: false,
      errors: {},
      touched: {},
      values: {
        emergency: {
          name: "Emergency",
          required: true,
          selected: [],
          options: [
            {
              name: "Warning Siren",
              key: "warningSiren"
            },
            {
              name: "Extinguisher",
              key: "extinguisher"
            },
            {
              name: "First Aid",
              key: "firstAid"
            },
            {
              name: "House Emergency",
              key: "houseEmergency"
            }
          ]
        }
      }
    },
    11: {
      name: "House Features",
      key: "houseFeatures",
      isAddable: true,
      visited: false,
      errors: {},
      touched: {},
      values: {
        security: {
          name: "Security",
          required: true,
          selected: [],
          options: [
            {
              name: "Security",
              key: "security"
            },
            {
              name: "Locks",
              key: "locks"
            },
            {
              name: "Front Door",
              key: "frontDoor"
            },
            {
              name: "Safe",
              key: "safe"
            },
            {
              name: "Alarm",
              key: "alarm"
            },
            {
              name: "Keys",
              key: "keys"
            },
            {
              name: "AV Codes",
              key: "avCodes"
            },
            {
              name: "Gates",
              key: "gates"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        doorsAndWindowsCategory: {
          name: "doorsAndWindowsCategory",
          required: true,
          selected: [],
          options: [
            {
              name: "Slide Screens",
              key: "slideScreens"
            },
            {
              name: "Doors",
              key: "doors"
            },
            {
              name: "Folding",
              key: "folding"
            },
            {
              name: "Screens",
              key: "screens"
            },
            {
              name: "Slide Glass",
              key: "slideGlass"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        waterEqp: {
          name: "Water Equipment",
          required: true,
          selected: [],
          options: [
            {
              name: "Pool",
              key: "pool"
            },
            {
              name: "Jacuzzi",
              key: "jacuzzi"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        beAware: {
          name: "Be Aware",
          required: true,
          selected: [],
          options: [
            {
              name: "Rules",
              key: "rules"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        outside: {
          name: "Outside",
          required: true,
          selected: [],
          options: [
            {
              name: "Lanai",
              key: "lanai"
            },
            {
              name: "BBQ",
              key: "bbq"
            },
            {
              name: "Bikes",
              key: "bikes"
            },
            {
              name: "Booties",
              key: "booties"
            },
            {
              name: "Rentals",
              key: "rentals"
            },
            {
              name: "Beach Toys",
              key: "beachToys"
            },
            {
              name: "Bar",
              key: "bar"
            },
            {
              name: "Parking",
              key: "parking"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        tech: {
          name: "Tech",
          required: true,
          selected: [],
          options: [
            {
              name: "WiFi",
              key: "wifi"
            },
            {
              name: "Remotes",
              key: "remotes"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        kitchen: {
          name: "Kitchen",
          required: true,
          selected: [],
          options: [
            {
              name: "Dishwasher",
              key: "dishwasher"
            },
            {
              name: "Microwave",
              key: "microwave"
            },
            {
              name: "Coffee",
              key: "coffee"
            },
            {
              name: "Fridge",
              key: "fridge"
            },
            {
              name: "Pantry",
              key: "pantry"
            },
            {
              name: "Stove",
              key: "stove"
            },
            {
              name: "Ventilation",
              key: "ventilation"
            },
            {
              name: "Wine",
              key: "wine"
            },
            {
              name: "Counters",
              key: "counters"
            },
            {
              name: "Disposal",
              key: "disposal"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        kids: {
          name: "Kids",
          required: true,
          selected: [],
          options: [
            {
              name: "Baby Stuff",
              key: "babyStuff"
            },
            {
              name: "Baby Proof",
              key: "babyProof"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        health: {
          name: "Health",
          required: true,
          selected: [],
          options: [
            {
              name: "Gym",
              key: "gym"
            },
            {
              name: "Treatments",
              key: "treatments"
            },
            {
              name: "Sauna",
              key: "sauna"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        garage: {
          name: "Garage",
          required: true,
          selected: [],
          options: [
            {
              name: "Garage",
              key: "garage"
            },
            {
              name: "Bikes",
              key: "bikes"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        floors: {
          name: "Floors",
          required: true,
          selected: [],
          options: [
            {
              name: "Rugs",
              key: "rugs"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        fansAC: {
          name: "Fans/AC",
          required: true,
          selected: [],
          options: [
            {
              name: "Fans",
              key: "fans"
            },
            {
              name: "AC",
              key: "AC"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        },
        inHouse: {
          name: "In-House",
          required: true,
          selected: [],
          options: [
            {
              name: "Lights",
              key: "lights"
            },
            {
              name: "Washer Dryer",
              key: "washerDryer"
            },
            {
              name: "Trash",
              key: "trash"
            },
            {
              name: "Linens",

              key: "linens"
            },
            {
              name: "Towels",

              key: "towels"
            },
            {
              name: "Grocery",
              key: "grocery"
            },
            {
              name: "Shower",
              key: "shower"
            },
            {
              name: "Laundry Room",
              key: "laundryRoom"
            },
            {
              name: "TV",
              key: "TV"
            },
            {
              name: "Media Room",
              key: "mediaRoom"
            },
            {
              name: "houseRules",
              key: "rules"
            },
            {
              name: "Misc.",
              key: "misc"
            }
          ]
        }
      }
    }
  }
};

export const builderReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SET_VISITED: {
      const { pageHash } = action;

      if (!pageHash || !state.currentBuilder) return state;

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,

          [pageHash]: {
            ...state.currentBuilder[pageHash],
            visited: true
          }
        }
      };
    }

    case types.REMOVE_SELECTED_GRID_ITEM: {
      const {
        pageHash,
        value,
        availabilityType,
        id,
        isCompact = true
      } = action;

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [pageHash]: {
            ...state.currentBuilder[pageHash],
            values: {
              ...state.currentBuilder[pageHash].values,
              [value]: {
                ...state.currentBuilder[pageHash].values[value],
                [availabilityType]: state.currentBuilder[
                  action.pageHash
                ].values[action.value][availabilityType]
                  .filter(value => value.id !== id)
                  .sort((a, b) =>
                    a.sequence > b.sequence
                      ? 1
                      : b.sequence > a.sequence
                      ? -1
                      : 0
                  )
                  .map((item, i) => {
                    if (isCompact) {
                      return {
                        ...item,
                        sequence: i + 1
                      };
                    } else {
                      return item;
                    }
                  })
              }
            }
          }
        }
      };
    }
    case types.ADD_SELECTED_GRID_ITEM: {
      const { pageHash, value, availabilityType, itemData } = action;

      const lastSequence =
        state.currentBuilder[action.pageHash].values[action.value][
          action.availabilityType
        ].length > 0
          ? state.currentBuilder[action.pageHash].values[action.value][
              action.availabilityType
            ].reduce((acc, curr) => {
              if (acc > curr.sequence) {
                return acc;
              } else {
                return curr.sequence;
              }
            }, 0)
          : 0;

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [pageHash]: {
            ...state.currentBuilder[pageHash],

            values: {
              ...state.currentBuilder[pageHash].values,
              [value]: {
                ...state.currentBuilder[pageHash].values[value],
                [availabilityType]: [
                  ...state.currentBuilder[pageHash].values[value][
                    availabilityType
                  ],
                  {
                    ...itemData,
                    id: itemData.id ? itemData.id : uuidv4(),
                    sequence: lastSequence + 1
                  }
                ]
              }
            }
          }
        }
      };
    }
    case types.SET_BUILDER_PAGE_ERRORS: {
      const { errors, pageHash } = action;

      const currentBuilderPageErrors = {
        [pageHash]:
          Object.keys(errors).length > 0
            ? {
                ...errors
              }
            : {}
      };

      const currentIsPublished = Object.keys({
        ...state.currentBuilderErrors,
        ...currentBuilderPageErrors
      }).reduce((acc, curr) => {
        const newCurrentBuilderErrors = {
          ...state.currentBuilderErrors,
          ...currentBuilderPageErrors
        };

        if (
          acc === true &&
          Object.keys(newCurrentBuilderErrors[curr]).length > 0
        ) {
          return false;
        }

        return acc;
      }, true);

      return {
        ...state,
        currentBuilderErrors: {
          ...state.currentBuilderErrors,
          ...currentBuilderPageErrors
        },
        isPublished: currentIsPublished,
        currentBuilder: {
          ...state.currentBuilder,

          [pageHash]: {
            ...state.currentBuilder[pageHash],
            errors
          }
        }
      };
    }
    case types.SET_SELECTED_GRID_ITEM_SEQUENCE: {
      const { pageHash, id, value, availabilityType, newSequence } = action;

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [pageHash]: {
            ...state.currentBuilder[pageHash],
            values: {
              ...state.currentBuilder[pageHash].values,
              [value]: {
                ...state.currentBuilder[pageHash].values[value],
                [availabilityType]: state.currentBuilder[pageHash].values[
                  value
                ][availabilityType].map(newValue => {
                  const draggingValue = state.currentBuilder[pageHash].values[
                    value
                  ][availabilityType].find(found => found.id === id);

                  if (newSequence === draggingValue.sequence) {
                    return newValue;
                  }

                  if (newValue.id === id) {
                    return {
                      ...newValue,
                      sequence: newSequence
                    };
                  }
                  if (
                    state.currentBuilder[pageHash].values[value][
                      availabilityType
                    ].length === 3
                  ) {
                    if (draggingValue.sequence === 3 && newSequence === 1) {
                      return {
                        ...newValue,
                        sequence: newValue.sequence + 1
                      };
                    }

                    if (draggingValue.sequence === 1 && newSequence === 3) {
                      return {
                        ...newValue,
                        sequence: newValue.sequence - 1
                      };
                    }
                  }
                  if (newSequence === newValue.sequence) {
                    if (draggingValue.sequence <= newSequence) {
                      return {
                        ...newValue,
                        sequence: newValue.sequence - 1
                      };
                    } else {
                      return {
                        ...newValue,
                        sequence: newValue.sequence + 1
                      };
                    }
                  }

                  return newValue;
                })
              }
            }
          }
        }
      };
    }

    case types.CHANGE_SELECTED_GRID_ITEM_SEQUENCE: {
      const { pageHash, id, value, availabilityType, newSequence } = action;

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [pageHash]: {
            ...state.currentBuilder[pageHash],
            values: {
              ...state.currentBuilder[pageHash].values,
              [value]: {
                ...state.currentBuilder[pageHash].values[value],
                [availabilityType]: state.currentBuilder[pageHash].values[
                  value
                ][availabilityType]
                  .map(newValue => {
                    if (newValue.sequence === newSequence) {
                      const draggingValue = state.currentBuilder[
                        action.pageHash
                      ].values[value][availabilityType].find(
                        value => value.id === id
                      );
                      if (draggingValue) {
                        if (draggingValue.sequence <= newSequence) {
                          return {
                            ...newValue,
                            sequence: newSequence - 1
                          };
                        } else {
                          return {
                            ...newValue,
                            sequence: newSequence + 1
                          };
                        }
                      }
                    }
                    if (newValue.id === id) {
                      return {
                        ...newValue,
                        sequence: newSequence
                      };
                    }
                    if (newValue.sequence >= newSequence) {
                      return {
                        ...newValue,
                        sequence: newValue.sequence + 1
                      };
                    }

                    return newValue;
                  })
                  .sort((a, b) =>
                    a.sequence > b.sequence
                      ? 1
                      : b.sequence > a.sequence
                      ? -1
                      : 0
                  )
                  .map((value, i) => {
                    return {
                      ...value,
                      sequence: i + 1
                    };
                  })
              }
            }
          }
        }
      };
    }

    case types.CHANGE_GRID_ITEM_AVAILABILITY: {
      const { newSequence, newAvailability, value, id } = action;

      const changedItem = state.currentBuilder[action.pageHash].values[
        action.value
      ][action.originalAvailability].find(item => item.id === action.id);
      var a = state.currentBuilder[action.pageHash].values[action.value][
          action.newAvailability
        ].map(value => value.sequence),
        count = 3,
        missing = [];

      for (var i = 1; i <= count; i++) {
        if (a.indexOf(i) === -1) {
          missing.push(i);
        }
      }

      const lastSequence =
        state.currentBuilder[action.pageHash].values[action.value][
          action.newAvailability
        ].length > 0
          ? state.currentBuilder[action.pageHash].values[action.value][
              action.newAvailability
            ].reduce(
              (prev, current) =>
                prev.sequence > current.sequence
                  ? prev.sequence
                  : current.sequence,
              1
            )
          : 1;

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [action.pageHash]: {
            ...state.currentBuilder[action.pageHash],
            values: {
              ...state.currentBuilder[action.pageHash].values,
              [action.value]: {
                ...state.currentBuilder[action.pageHash].values[action.value],
                [action.newAvailability]: [
                  ...state.currentBuilder[action.pageHash].values[action.value][
                    action.newAvailability
                  ],
                  {
                    ...changedItem,
                    sequence: action.newSequence
                      ? action.newSequence
                      : missing.length > 0
                      ? missing[0]
                      : lastSequence + 1
                  }
                ]
                  .map(newValue => {
                    if (!action.isCompact) {
                      // if the item is the same ID as the dragging item, return value
                      if (missing[0] === newSequence) {
                        return newValue;
                      }
                      if (newValue.id === id) {
                        return newValue;
                      }

                      if (newValue.sequence === newSequence) {
                        if (missing[0] < newValue.sequence) {
                          return {
                            ...newValue,
                            sequence: newValue.sequence - 1
                          };
                        }

                        if (missing[0] > newValue.sequence) {
                          return {
                            ...newValue,
                            sequence: newValue.sequence + 1
                          };
                        }
                      } else {
                        if (
                          newValue.sequence < newSequence &&
                          newValue.sequence > missing[0]
                        ) {
                          return {
                            ...newValue,
                            sequence: newValue.sequence - 1
                          };
                        }

                        if (
                          newValue.sequence > newSequence &&
                          newValue.sequence < missing[0]
                        ) {
                          return {
                            ...newValue,
                            sequence: newValue.sequence + 1
                          };
                        }
                      }
                      return newValue;
                      // if (
                      //   newValue.sequence === newSequence &&
                      //   newValue.id !== id
                      // ) {
                      //   if (newSequence) {
                      //     const sequenceOffset =
                      //       missing <= newValue.sequence ? 1 : -1;

                      //     return {
                      //       ...newValue,
                      //       sequence:
                      //         newValue.sequence - sequenceOffset > 3 ||
                      //         newValue.sequence - sequenceOffset < 1
                      //           ? missing
                      //           : newValue.sequence - sequenceOffset
                      //     };
                      //   }
                      // } else {
                      //   return {
                      //     ...newValue
                      //   };
                      // }
                    }

                    if (newValue.sequence === newSequence) {
                      const draggingValue = state.currentBuilder[
                        action.pageHash
                      ].values[value][newAvailability].find(
                        value => value.id === id
                      );

                      if (draggingValue) {
                        if (draggingValue.sequence <= newSequence) {
                          return {
                            ...newValue,
                            sequence: newSequence - 1
                          };
                        } else {
                          return {
                            ...newValue,
                            sequence: newSequence + 1
                          };
                        }
                      }
                    }
                    if (newValue.id === id) {
                      return {
                        ...newValue,
                        sequence: action.newSequence
                          ? action.newSequence
                          : missing[0]
                      };
                    }
                    if (newValue.sequence >= newSequence) {
                      return {
                        ...newValue,
                        sequence: newValue.sequence + 1
                      };
                    }

                    return newValue;
                  })
                  .sort((a, b) =>
                    action.isCompact
                      ? a.sequence > b.sequence
                        ? 1
                        : b.sequence > a.sequence
                        ? -1
                        : 0
                      : 0
                  )
                  .map((value, i) => {
                    if (!action.isCompact) return value;

                    return {
                      ...value,
                      sequence: i + 1
                    };
                  }),
                [action.originalAvailability]: state.currentBuilder[
                  action.pageHash
                ].values[action.value][action.originalAvailability]
                  .filter(item => item.id !== action.id)
                  .sort((a, b) =>
                    action.isCompact
                      ? a.sequence > b.sequence
                        ? 1
                        : b.sequence > a.sequence
                        ? -1
                        : 0
                      : 0
                  )
                  .map((value, i) => {
                    if (
                      action.value === "hosts" &&
                      action.originalAvailability === "selected"
                    ) {
                      return {
                        ...value
                      };
                    }

                    if (
                      action.value === "hosts" &&
                      action.originalAvailability === "available"
                    ) {
                      return {
                        ...value,
                        sequence: i + 1
                      };
                    }

                    if (!action.isCompact)
                      return {
                        ...value
                      };

                    return {
                      ...value,
                      sequence: i + 1
                    };
                  })
              }
            }
          }
        }
      };
    }

    case types.UPDATE_GRID_ITEM: {
      const { pageHash, value, id, availabilityType, newValues } = action;

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [pageHash]: {
            ...state.currentBuilder[pageHash],
            values: {
              ...state.currentBuilder[pageHash].values,
              [value]: {
                ...state.currentBuilder[pageHash].values[value],
                [availabilityType]: state.currentBuilder[pageHash].values[
                  value
                ][availabilityType].map(item => {
                  if (item.id === id) {
                    return {
                      ...item,
                      ...newValues
                    };
                  } else {
                    return item;
                  }
                })
              }
            }
          }
        }
      };
    }

    case types.SET_CURRENT_BUILDER: {
      const { builder, homeId, regionId, regions } = action;

      return {
        ...state,
        currentBuilderId: parseInt(homeId),
        currentBuilderRegionId: regionId,
        currentBuilderRegions: regions,
        currentBuilder: builder
      };
    }

    case types.CLEAR_CURRENT_BUILDER: {
      return {};
    }

    case types.SET_CALENDAR_VALUES: {
      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          3: {
            ...state.currentBuilder[3],
            values: {
              ...state.currentBuilder[3].values,
              calendar: {
                ...state.currentBuilder[3].values.calendar,
                ...action.values
              }
            }
          }
        }
      };
    }

    case types.SET_FORM_VALUES: {
      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          1: {
            ...state.currentBuilder[1],
            values: {
              ...state.currentBuilder[1].values,
              propertyInfo: {
                ...state.currentBuilder[1].values.propertyInfo,
                form: action.values
              }
            }
          }
        }
      };
    }
    case types.SET_HOUSE_VIDEO_LINK: {
      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          2: {
            ...state.currentBuilder[2],
            values: {
              ...state.currentBuilder[2].values,
              houseVideo: {
                ...state.currentBuilder[2].values.houseVideo,
                url: action.link
              }
            }
          }
        }
      };
    }
    case types.SET_HOUSE_VR_TOUR_LINK: {
      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          2: {
            ...state.currentBuilder[2],
            values: {
              ...state.currentBuilder[2].values,
              "360Tour": {
                ...state.currentBuilder[2].values["360Tour"],
                url: action.link
              }
            }
          }
        }
      };
    }

    case types.REMOVE_ALL_GRID_ITEMS: {
      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [action.pageHash]: {
            ...state.currentBuilder[action.pageHash],
            values: {
              ...state.currentBuilder[action.pageHash].values,
              [action.value]: {
                ...state.currentBuilder[action.pageHash].values[action.value],
                [action.availabilityType]: []
              }
            }
          }
        }
      };
    }
    case types.SWITCH_GRID_ITEM_AVAILABILITY: {
      const overlappedItem = state.currentBuilder[action.pageHash].values[
        action.value
      ][action.newAvailability].find(
        item => item.sequence === action.newSequence
      );

      const draggedItem = state.currentBuilder[action.pageHash].values[
        action.value
      ][action.originalAvailability].find(item => item.id === action.id);

      return {
        ...state,
        currentBuilder: {
          ...state.currentBuilder,
          [action.pageHash]: {
            ...state.currentBuilder[action.pageHash],
            values: {
              ...state.currentBuilder[action.pageHash].values,
              [action.value]: {
                ...state.currentBuilder[action.pageHash].values[action.value],
                [action.newAvailability]: state.currentBuilder[
                  action.pageHash
                ].values[action.value][action.newAvailability].map(item =>
                  item.id === overlappedItem.id
                    ? { ...draggedItem, sequence: action.newSequence }
                    : item
                ),
                [action.originalAvailability]: state.currentBuilder[
                  action.pageHash
                ].values[action.value][action.originalAvailability].map(item =>
                  item.id === draggedItem.id
                    ? { ...overlappedItem, sequence: draggedItem.sequence }
                    : item
                )
              }
            }
          }
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const builderCacheReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CACHE_USER_HOME_DATA: {
      const { regionId, regions, homeId, currentBuilder } = action;
      return {
        ...state,
        [homeId]: {
          currentBuilder: currentBuilder,
          currentBuilderRegionId: regionId,
          currentBuilderRegions: regions
        }
      };
    }
    default: {
      return state;
    }
  }
};
