import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import PromptWrapper from "../common/PromptWrapper";
import PromptHeader from "./PromptHeader";
import { SuggestionContext } from "../common/SuggestionProvider";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import InputText from "../common/InputText";
import InputEditor from "./InputEditor";

import InputTel from "../common/InputTel";
import ButtonMain from "../common/ButtonMain";

import { Yup } from "hc-utils/FormValidator";
import InputAddress from "../common/InputAddress";

const POISuggestPromptValidationSchema = Yup.object().shape({
  name: Yup.string().required(
    "You must give a name for the point of interest."
  ),
  email: Yup.string().email("Invalid email"),
  description: Yup.string(),
  address: Yup.object().shape({
    fullAddress: Yup.string().nullable(),
    street: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    country: Yup.string().nullable(),
    zip: Yup.string().nullable()
  }),
  phone: Yup.string(),
  website: Yup.string()
});

function POISuggestPrompt({ onClose, onSubmit }) {
  const { featureId } = useContext(SuggestionContext);
  const builder = useSelector(state => state.builder.currentBuilder);
  const feature = useMemo(() => {
    return Object.keys(builder).reduce((acc, curr, i) => {
      const currBuilderObj = builder[curr];

      if (acc !== null) return acc;

      if (Number(currBuilderObj.feature_id) === Number(featureId)) {
        return {
          name: currBuilderObj.name,
          key: currBuilderObj.key,
          feature_id: currBuilderObj.feature_id,
          image: currBuilderObj.image
        };
      }

      if (currBuilderObj.hasOwnProperty("values")) {
        let sub_feature = null;
        Object.keys(currBuilderObj.values).forEach(subValue => {
          const currSubBuilderObj = currBuilderObj.values[subValue];

          if (Number(currSubBuilderObj.feature_id) === Number(featureId)) {
            sub_feature = {
              name: currSubBuilderObj.name,
              key: currSubBuilderObj.key,
              feature_id: currSubBuilderObj.feature_id,
              image: currSubBuilderObj.image
            };
          }
        });
        return sub_feature;
      }

      return null;
    }, null);
  }, [builder, featureId]);
  if (!feature) return null;

  const handleSuggestSubmit = (values, actions) => {
    onSubmit(featureId, values, actions);
  };
  return (
    <>
      <PromptWrapper onClose={onClose} className="poi-suggest-prompt-wrapper">
        <div className="poi-suggest-prompt">
          <div className="poi-suggest-prompt__inner">
            <PromptHeader icon={feature.image}>{feature.name}</PromptHeader>
            <div className="poi-suggest-prompt__text">
              <h3>
                We'd love to add your suggestion to our list of favorites.
              </h3>
              <h3>Give us a little information</h3>
              <p>We'll take care of the rest.</p>
            </div>
            <Formik
              initialValues={{
                name: "",
                email: "",
                description: "",
                address: {
                  fullAddress: ""
                },
                phone: "",
                website: ""
              }}
              validationSchema={POISuggestPromptValidationSchema}
              onSubmit={handleSuggestSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue,
                setFieldTouched,
                isSubmitting
              }) => (
                <form
                  name="poi-suggest-form"
                  className="user-form user-form--prompt"
                  onSubmit={handleSubmit}
                >
                  <InputText
                    name="name"
                    type="text"
                    size="full"
                    placeholder="Name"
                    required={true}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayError={
                      errors.name && touched.name ? errors.name : false
                    }
                  />
                  <InputText
                    name="email"
                    type="text"
                    size="full"
                    placeholder="Email"
                    required={true}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayError={
                      errors.email && touched.email ? errors.email : false
                    }
                  />
                  <InputAddress
                    name={"address"}
                    placeholder="Address"
                    required={false}
                  />

                  <InputTel
                    name={"phone"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values["phone"]}
                    required={false}
                    type="tel"
                    size="half"
                    placeholder="Phone Number"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    displayError={
                      errors.phone && touched.phone ? errors.phone : false
                    }
                  />
                  <InputText
                    name="website"
                    type="text"
                    size="half"
                    placeholder="Website"
                    value={values.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayError={
                      errors.website && touched.website ? errors.website : false
                    }
                  />
                  <InputEditor
                    name="description"
                    id="description"
                    touched={touched}
                    placeholder="What you love about this place"
                    required={false}
                    initialMarkup={values.description}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    displayError={
                      errors.description && touched.description
                        ? errors.description
                        : false
                    }
                  />
                  <ButtonMain color="aqua" type="submit" loading={isSubmitting}>
                    Send Your Suggestion
                  </ButtonMain>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </PromptWrapper>
    </>
  );
}

POISuggestPrompt.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default POISuggestPrompt;
