import types from "./types";

const setUserHomesList = userHomes => ({
  type: types.SET_USER_HOMES_LIST,
  userHomes
});

const addUserHome = userHome => ({
  type: types.ADD_USER_HOME,
  userHome
});

const setHomeGuestList = (guestList, homeId) => ({
  type: types.SET_HOME_GUEST_LIST,
  guestList,
  homeId
});

const removeHomeGuest = (houseId, guestId) => ({
  type: types.REMOVE_HOME_GUEST,
  houseId,
  guestId
});

const addHomeGuest = (houseId, guest) => ({
  type: types.ADD_HOME_GUEST,
  houseId,
  guest
});

const updateGuest = (houseId, guestId, guest) => ({
  type: types.UPDATE_HOME_GUEST,
  houseId,
  guestId,
  guest
});

const removeHouse = houseId => ({
  type: types.REMOVE_HOUSE,
  houseId
});

const setHousePublishStatus = (houseId, publishStatus) => ({
  type: types.SET_HOUSE_PUBLISH_STATUS,
  houseId,
  publishStatus
});

const updateHousePhoto = (homeId, image) => ({
  type: types.UPDATE_HOUSE_PHOTO,
  homeId,
  image
});

const updateHouseInfo = (houseId, houseName, houseAddress) => ({
  type: types.UPDATE_HOUSE_INFO,
  houseId,
  houseName,
  houseAddress
});

const clearDashboard = () => ({
  type: types.CLEAR_DASHBOARD
});

const setGuestStatus = ({
  id,
  houseId,
  is_app_open,
  emailSent,
  invitedGuests
}) => ({
  type: types.SET_GUEST_STATUS,
  id,
  houseId,
  is_app_open,
  emailSent,
  invitedGuests
});

export default {
  setUserHomesList,
  setHomeGuestList,
  removeHomeGuest,
  addHomeGuest,
  removeHouse,
  addUserHome,
  updateGuest,
  setHousePublishStatus,
  updateHousePhoto,
  updateHouseInfo,
  clearDashboard,
  setGuestStatus
};
