import React, { useEffect } from "react";
import moment from "moment";
import AccountPage from "../AccountPage";
import { accountOperations } from "../duck";
import { connect } from "react-redux";
import _get from "lodash.get";
import { CSSTransition } from "react-transition-group";
import SpinnerLoader from "../../common/SpinnerLoader";

const Table = ({ headers, items }) => {
  return (
    <table className="account-page-table">
      <tbody className="account-page-table__body">
        <tr>
          {headers.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>

        {items.map(({ planName, amount, createdAt }) => (
          <tr key={createdAt}>
            <td>{moment(createdAt).format("MMMM Do YYYY, h:mm a")}</td>
            <td>{planName ? `Subscription for ${planName}` : "Transaction"}</td>
            <td>${amount}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const AccountPageBillingHistoryList = ({ data }) => {
  return (
    <div className="account-page-billing-list">
      <Table
        columns={3}
        headers={["Date", "Description", "Amount"]}
        items={data}
      />
    </div>
  );
};

function AccountPageBillingHistory({
  data,
  fetchBillingHistory,
  billingTransactions,
  nextBillingDate,
  loading,
  loadStatus
}) {
  useEffect(() => {
    if (!billingTransactions) {
      fetchBillingHistory()
        .then(billingHistory => {})
        .catch(err => {
          window.console.error(err);
        });
    }
  }, [billingTransactions, fetchBillingHistory]);

  const nextBillingDateParsed = moment(nextBillingDate).format("MMMM Do, YYYY");

  return (
    <AccountPage page="billing">
      <div className="account-page__header">
        <h3>Billing History</h3>

        {nextBillingDate && (
          <p>
            Your next billing cycle starts on{" "}
            <strong>{nextBillingDateParsed}</strong>
          </p>
        )}
      </div>
      <div className="account-page__content">
        <CSSTransition
          in={loading}
          classNames="fade"
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <div className="spinner-loader-wrapper" style={{ minHeight: "90px" }}>
            <SpinnerLoader />
          </div>
        </CSSTransition>
        <CSSTransition
          in={!loading}
          timeout={300}
          classNames="fade"
          mountOnEnter
          unmountOnExit
        >
          <div className="account-page__contentinner">
            {loadStatus === "FAILED" ? (
              <p>
                Unable to get billing history. Please try again later or contact{" "}
                <a href="mailto:help@homeconcierge.com">
                  help@homeconcierge.com
                </a>
              </p>
            ) : (
              <div className="account-page__contentinner">
                {billingTransactions ? (
                  billingTransactions.length === 0 ? (
                    <small className="empty">No transactions.</small>
                  ) : (
                    <AccountPageBillingHistoryList data={billingTransactions} />
                  )
                ) : null}
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    </AccountPage>
  );
}

const mapDispatch = dispatch => ({
  fetchBillingHistory: () => dispatch(accountOperations.fetchBillingHistory())
});

const mapState = state => ({
  billingTransactions: _get(state.account, "billing.transactions", null),
  nextBillingDate: _get(state.account, "billing.nextBillingDate", null)
});

export default connect(mapState, mapDispatch)(AccountPageBillingHistory);
