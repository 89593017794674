import React from "react";
import BuilderPageWrapper from "../../BuilderPageWrapper";
import PropertyInfoForm from "./PropertyInfoForm";
import { connect } from "react-redux";
import { builderOperations } from "../../duck";
import _get from "lodash.get";
import { deepEqual } from "hc-utils";
import { toastOperations } from "../../../../duck/toast";
import { Helmet } from "react-helmet";
class PropertyInfo extends React.Component {
  constructor(props) {
    super(props);
    const { propertyInfoBuilder } = this.props;
    const {
      houseName,
      ownerName,
      houseAddr,
      housePhone,
      homeEmail,
      homeWebsite,
      wifiName,
      securityInfo,
      wifiCode,
      gateCode,
      doorCode,
	  checkOutInfo,
    } = propertyInfoBuilder;

    this.submitFormHandler = null;

    this.state = {
      lastSavedValues: {
        houseName: houseName ? houseName : null,
        ownerName: ownerName ? ownerName : null,
        houseAddr: houseAddr ? houseAddr : null,
        housePhone: housePhone ? housePhone : null,
        homeEmail: homeEmail ? homeEmail : null,
        homeWebsite: homeWebsite ? homeWebsite : null,
        homeWifiNetworkName: wifiName ? wifiName : null,
        homeWifiNetworkPass: wifiCode ? wifiCode : null,
        homeGateCode: gateCode ? gateCode : null,
        homeDoorCode: doorCode ? doorCode : null,
        homeSecurityInfo: securityInfo ? securityInfo : null,
		homeCheckOutInfo: checkOutInfo ? checkOutInfo : null
      }
    };
  }

  onNav = link => {
    if (this.submitFormHandler) {
      this.setState(
        {
          lastLink: link
        },
        () => {
          this.submitFormHandler();
        }
      );
    }
  };

  submitForm = values => {
    const link = this.state.lastLink;
    const {
      history,
      currentBuilderId,
      updateHouse,
      createHouse,
      addToast
    } = this.props;

    if (values) {
      const {
        houseName,
        ownerName,
        houseAddr,
        housePhone,
        homeEmail,
        homeWebsite,
        homeWifiNetworkName,
        homeWifiNetworkPass,
        homeSecurityInfo,
        homeDoorCode,
        homeGateCode,
        book_airbnb,
        book_vrbo,
        book_luxe,
        book_other_name,
        book_other_url,
		homeCheckOutInfo,
      } = values;

      if (!deepEqual(values, this.state.lastSavedValues)) {
        const houseData = {
          houseId: currentBuilderId,
          houseName: houseName,
          houseOwnerName: ownerName,
          phoneNumber: housePhone,
          email: homeEmail,
          fullAddress: houseAddr.fullAddress,
          street: houseAddr.street,
          city: houseAddr.city,
          state: houseAddr.state,
          zip: houseAddr.zip,
          country: houseAddr.country,
          lat: houseAddr.lat,
          lng: houseAddr.lng,
          website: homeWebsite,
          wifiCode: homeWifiNetworkPass,
          wifiName: homeWifiNetworkName,
          securityInfo: homeSecurityInfo,
          doorCode: homeDoorCode,
          gateCode: homeGateCode,
          book_airbnb,
          book_vrbo,
          book_luxe,
          book_other_name,
          book_other_url,
		  checkOutInfo: homeCheckOutInfo
        };

        if (currentBuilderId) {
          updateHouse(houseData)
            .then(() => {
              this.setState({ lastSavedValues: values }, () => {
                history.push(link(currentBuilderId));
              });
            })
            .catch(error => {
              window.console.error(error);
            });
        } else {
          createHouse({ ...houseData, regionId: values.houseRegion.value })
            .then(house => {
              this.setState({ lastSavedValues: values }, () => {
                addToast({
                  text: `Succesfully created home "${houseName}"`
                });

                history.push(link(house.id));
              });
            })
            .catch(error => {
              if (error.response.status === 405) {
                addToast({
                  text:
                    "Your subscription limit has been reached. Please upgrade your subscription to add more homes.",
                  intent: "error"
                });
              } else {
                addToast({
                  text:
                    "There was an issue while creating your home. Please try again or contact help@homeconcierge.com.",
                  intent: "error"
                });
              }

              history.push({
                pathname: "/user/my-homes"
              });
            });
        }
      } else {
        history.push(link(currentBuilderId));
      }
    }
  };
  bindSubmitFormHandler = submitFormHandler => {
    this.submitFormHandler = submitFormHandler;
  };
  render() {
    const { isCurrentPageVisited, propertyInfoBuilder } = this.props;

    return (
      <>
        <Helmet>
          <title>
            Home Concierge | {propertyInfoBuilder.houseName || "New Home"} |
            Property Info
          </title>
          <meta
            name="description"
            content="Enter the basic information for your home."
          />
        </Helmet>
        <BuilderPageWrapper pageKey={this.props.pageKey}>
          <PropertyInfoForm
            data={propertyInfoBuilder}
            bindSubmitFormHandler={this.bindSubmitFormHandler}
            onSubmit={this.submitForm}
            nextPath={this.props.nextPath}
            visited={isCurrentPageVisited}
            initialValues={this.state.lastSavedValues}
            {...this.props}
          />
        </BuilderPageWrapper>
      </>
    );
  }
}

const mapState = state => ({
  isCurrentPageVisited:
    state.builder.currentBuilder[
      Object.keys(state.builder.currentBuilder).find(
        pageHash =>
          state.builder.currentBuilder[pageHash].key === "propertyInfo"
      )
    ].visited,
  currentBuilderId: parseInt(state.builder.currentBuilderId),
  propertyInfoBuilder: _get(
    state.builder.currentBuilder[1].values,
    "propertyInfo.form",
    null
  )
});

const mapDispatch = dispatch => ({
  updateHouse: houseData => dispatch(builderOperations.updateHouse(houseData)),
  createHouse: houseData => dispatch(builderOperations.createHouse(houseData)),
  addToast: options => dispatch(toastOperations.addToast(options))
});
export default connect(mapState, mapDispatch, null, { forwardRef: true })(
  PropertyInfo
);
