import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Formik, Field, useField } from "formik";
import _get from "lodash.get";
import { Yup } from "hc-utils/FormValidator";
import ButtonMain from "../common/ButtonMain";
import GuestFormSection from "./GuestFormSection";
import InputTextFormik from "../common/InputTextFormik";
import { connect as formikConnect } from "formik";
import { connect } from "react-redux";
import GuestHostList from "./GuestHostList";
import { dashboardOperations } from "./duck";
import InputEditorFormik from "../common/InputEditorFormik";
import InputTelFormik from "../common/InputTelFormik";
import phone from "phone";
import InputCheckboxFormik from "../common/InputCheckboxFormik";

function GuestPotentialCustomEmail({ homeName }) {
  const [, meta, helpers] = useField({ name: "customMessage" });

  useEffect(() => {
    const customEmailTemplate = `
    <p>Here is the HOME CONCIERGE app for ${homeName}.  It is an extensive “tour” of the house and guide for where to shop, dine, play, etc. in the area.</p>
    <p>We hope this helps you choose ${homeName} for your vacation.</p>
    <p>if you have any other questions don’t hesitate to reach out.</p>
    `;

    helpers.setValue(customEmailTemplate);
  }, []); // eslint-disable-line

  return (
    <GuestFormSection title="Customize Your Welcome Email">
      <Field
        component={InputEditorFormik}
        id="customMessage"
        name="customMessage"
        touched={meta.touched}
        className="default-spacing"
        placeholder="Custom Email Message"
      />
    </GuestFormSection>
  );
}

function GuestFormPotentialHostInfo({
  loading,
  currentHomeId,
  getHosts,
  userEmail,
  isNew,
  formik: { values, errors, setFieldValue, setFieldTouched, touched }
}) {
  const getHostByRole = (role, hosts) =>
    hosts.selected.length > 0
      ? hosts.selected.find(
          ({ sequence_no }) => sequence_no === (role === "backup" ? 2 : 1)
        )
      : null;

  const [hosts, setHosts] = useState(null);
  const [homeMainHost, setHomeMainHost] = useState(null);

  useEffect(() => {
    if (values.mainHostId) return;
    getHosts(currentHomeId).then(({ hosts }) => {
      const mainHost = getHostByRole("main", hosts);

      setHosts(hosts);
      if (mainHost) {
        setHomeMainHost(mainHost);
      }

      if (!values.mainHostId) {
        setFieldValue("mainHostId", {
          value: null,
          label: mainHost
            ? `${mainHost.first_name} ${mainHost.last_name}`
            : "No Host Selected",
          isDefault: true,
          isDisabled: false
        });
      }
    });
  }, [currentHomeId, getHosts, setFieldValue, values.mainHostId]);

  const handleHostChange = (value, type) => {
    setFieldValue("mainHostId", value);
  };

  const handleHostBlur = type => {
    setFieldTouched("mainHostId");
  };

  return (
    <GuestFormSection
      title="Guest Host(s) Info"
      className={loading ? "loading" : ""}
    >
      <GuestHostList
        type="main"
        values={values}
        hosts={hosts}
        homeHosts={{ main: homeMainHost }}
        onChange={handleHostChange}
        onBlur={handleHostBlur}
        title="Main Host"
        loading={loading}
        error={
          _get(errors, "mainHostId", false) &&
          _get(touched, "mainHostId", false)
            ? _get(errors, "mainHostId", null)
            : false
        }
      ></GuestHostList>

      {isNew && (
        <Field
          component={InputCheckboxFormik}
          label={
            <p>
              Do you want to send a <strong>receipt</strong> to the main host?
            </p>
          }
          name="sendReceipt"
          size="full"
          id="sendReceipt"
          required={false}
        />
      )}
    </GuestFormSection>
  );
}

GuestFormPotentialHostInfo.propTypes = {
  currentHomeId: PropTypes.number.isRequired,
  formik: PropTypes.object,
  getHosts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapState = state => ({
  loading: _get(state.loading, "getHosts.loading", false),
  currentHomeId: _get(state.global, "current.home.id", null)
});

const mapDispatch = dispatch => ({
  getHosts: houseId => {
    return dispatch(dashboardOperations.getHosts(houseId));
  }
});

const EnhancedGuestFormPotentialHostInfo = formikConnect(
  connect(mapState, mapDispatch)(GuestFormPotentialHostInfo)
);

function GuestPotentialPersonalInfo({ isNew }) {
  return (
    <GuestFormSection
      title="Guest Personal Info"
      subtitle="Personal info for the guest and their vacation information."
    >
      <Field
        component={InputTextFormik}
        name="firstName"
        placeholder="First Name"
        autoComplete="given-name"
        size="half"
        required={true}
      ></Field>
      <Field
        component={InputTextFormik}
        name="lastName"
        placeholder="Last Name"
        autoComplete="family-name"
        size="half"
        required={true}
      ></Field>

      <Field
        component={InputTelFormik}
        name="phoneNumber"
        placeholder="Phone Number"
        autoComplete="phone"
        size="full"
        required={false}
      ></Field>
      <Field
        component={InputTextFormik}
        name="email"
        placeholder="Email"
        autoComplete="email"
        size="full"
        required={true}
        disabled={!isNew}
      ></Field>
    </GuestFormSection>
  );
}

function GuestPotentialPage({ isNew }) {
  return (
    <div className="guest-data-page">
      <GuestPotentialPersonalInfo isNew={isNew} />
      <EnhancedGuestFormPotentialHostInfo isNew={isNew} />
    </div>
  );
}

function GuestFormPotential({
  data,
  onSubmit,
  loading,
  onUpgrade,
  homeGuests,
  currentHomeName,
  userEmail,
  onPageChange
}) {
  const [step, setStep] = useState(1);

  useEffect(() => {
    onPageChange(step);
  }, [onPageChange, step]);

  const handleSubmit = (values, actions) => {
    if (step === 2 || data) {
      onSubmit(values, actions);
    } else {
      setStep(step + 1);
    }
  };
  return (
    <Formik
      initialValues={{
        isPotential: true,
        firstName: _get(data, "contact.first_name", ""),
        lastName: _get(data, "contact.last_name", ""),
        email: _get(data, "contact.email", ""),
        phoneNumber: _get(data, "contact.phone", false)
          ? phone(data.contact.phone)[0]
          : "",
        mainHostId: _get(data, "mainHost.id", null)
          ? {
              value: data.mainHost.id,
              label: `${data.mainHost.first_name} ${data.mainHost.last_name}`,
              isDisabled: false,
              isDefault: false
            }
          : null,
        customMessage: "This is custom message from potential.",
        sendReceipt: true
      }}
      validationSchema={
        step === 1
          ? Yup.object().shape({
              firstName: Yup.string()
                .min(2, "First name too short")
                .max(50, "First name too long")
                .required("First name is required."),
              lastName: Yup.string()
                .min(2, "Last name too short")
                .max(50, "Last name too long")
                .required("Last name is required."),
              email: Yup.string()
                .email("Invalid email.")
                .required("Email is required.")
                .test(
                  "is-duplicate",
                  "This guest is already invited to this home",
                  val => {
                    const guestWithSameEmail = homeGuests.find(
                      ({ contact: { email } }) => email === val
                    );
                    if (data) {
                      return true;
                    }
                    if (guestWithSameEmail) {
                      return false;
                    }
                    return true;
                  }
                )
                .test(
                  "is-home-owner",
                  "You can not invite yourself as a guest to your own home.",
                  val => {
                    if (val === userEmail) {
                      return false;
                    }

                    return true;
                  }
                ),
              sendReceipt: Yup.boolean()
            })
          : Yup.object().shape({
              customMessage: Yup.string()
            })
      }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} data-testid="guest-form-potential">
          {data && (
            <ButtonMain
              type="button"
              color="aqua"
              disabled={loading}
              onClick={onUpgrade}
            >
              Change to Confirmed
            </ButtonMain>
          )}
          {step === 1 && <GuestPotentialPage isNew={data ? false : true} />}
          {step === 2 && (
            <GuestPotentialCustomEmail homeName={currentHomeName} />
          )}
          <div className="guest-form-nav">
            <ButtonMain type="submit" color="blue" loading={loading}>
              {data
                ? "Update Guest"
                : step === 2
                ? "Send To Guest"
                : "Continue"}
            </ButtonMain>
          </div>
        </form>
      )}
    </Formik>
  );
}

GuestFormPotential.propTypes = {
  data: PropTypes.object,
  homeId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired
};

const mapStateGuestForm = (state, ownProps) => ({
  loading: !ownProps.data
    ? _get(state.loading, "createGuest.loading", false)
    : _get(state.loading, "updateGuest.loading", false),
  homeGuests: state.dashboard.homeGuests[state.global.current.home.id],
  currentHomeName: _get(state.global, "current.home.name", null),
  userEmail: _get(state.account, "accountInfo.email", null)
});
export default connect(mapStateGuestForm)(GuestFormPotential);
