import React from "react";
import PropTypes from "prop-types";

import DashboardTable from "./DashboardTable";
import GuestListItem from "./GuestListItem";
import SpinnerLoader from "../common/SpinnerLoader";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import DashboardListNotFound from "./DashboardListNotFound";
import { escapeRegExp } from "hc-utils";
function GuestList({
  items,
  query,
  filters,
  clearFilters,
  cancelGuest,
  isLoading,
  onGuestInfo,
  onGuestEdit,
  hasPlan
}) {
  let filteredItems = (items, query) => {
    if (!items) return [];

    if (items.length === 0) return [];
    return items
      .filter(
        item =>
          `${item.contact.first_name} ${item.contact.last_name} ${item.contact.email}`
            .toLowerCase()
            .search(escapeRegExp(query.toLowerCase())) > -1
      )
      .filter(item => {
        if (item.is_prospective && !filters.potential) return false;
        if (!item.is_prospective && !filters.confirmed) return false;
        return true;
      });
  };

  return (
    <div className="dashboard-list">
      <div className="dashboard-list__inner">
        <DashboardTable layout="guests">
          <TransitionGroup component={null}>
            {!isLoading ? (
              <CSSTransition key="userpages" timeout={300} classNames="fade">
                {filteredItems(items, query).length > 0 ? (
                  <div className="list-wrapper">
                    {filteredItems(items, query).map(guest => (
                      <GuestListItem
                        key={guest.id || Math.random()}
                        name={`${guest.contact.first_name} ${guest.contact.last_name}`}
                        email={guest.contact.email}
                        phone={guest.contact.phone}
                        checkIn={guest.check_in_date_time}
                        checkOut={guest.check_out_date_time}
                        guestId={guest.id}
                        isPotential={guest.is_prospective}
                        cancelGuest={cancelGuest}
                        onGuestEdit={onGuestEdit}
                        onGuestInfo={onGuestInfo}
                      />
                    ))}
                  </div>
                ) : (
                  <DashboardListNotFound
                    hasPlan={true}
                    query={query}
                    items={items}
                    filters={filters}
                    clearFilters={clearFilters}
                    noun="guest"
                  />
                )}
              </CSSTransition>
            ) : (
              <CSSTransition key="loader" timeout={300} classNames="fade">
                <SpinnerLoader />
              </CSSTransition>
            )}
          </TransitionGroup>
        </DashboardTable>
      </div>
    </div>
  );
}

GuestList.propTypes = {
  cancelGuest: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.bool).isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array,
  onGuestEdit: PropTypes.func.isRequired,
  onGuestInfo: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  hasPlan: PropTypes.bool
};

export default GuestList;
