import React, { Component } from "react";
import PropTypes from "prop-types";

import PromptWrapper from "../../../common/PromptWrapper";
import UploadWrapper from "../../../common/UploadWrapper";

import InputText from "../../../common/InputText";
import InputTel from "../../../common/InputTel";
import PromptHeader from "../../PromptHeader";

import ButtonMain from "../../../common/ButtonMain";
import { Formik } from "formik";
import { Yup } from "hc-utils/FormValidator";
import { processImage } from "hc-utils/fileHelper";
import { builderOperations } from "../../duck";
import { toastOperations } from "../../../../duck/toast";
import { connect } from "react-redux";
import InputEditor from "../../InputEditor";
import ImageCropPrompt from "../../../common/ImageCropPrompt";
import FileUpload from "../../../common/FileUpload";
import PersonImage from "../../PersonImage";

class HostPrompt extends Component {
  constructor(props) {
    super(props);

    // set the validation schema for the host form
    this.HostValidationSchema = Yup.object().shape({
      hostFirstName: Yup.string()
        .required("First name is required.")
        .nullable(),
      hostLastName: Yup.string().required("Last name is required.").nullable(),
      hostEmail: Yup.string()
        .email("Invalid Email.")
        .nullable()
        .required("Host Email is required."),

      hostPhone: Yup.string()
        .required("Host phone number is required.")
        .nullable(),

	  hostPhoneText: Yup.string()
		.nullable(),

      hostWebsite: Yup.string().laxUrl("Not a valid website URL.").nullable(),
      hostDescription: Yup.string().nullable()
    });

    // dont show image cropper popup on load
    // set the hostImageURL as the passed in image
    // set the file as null
    this.state = {
      showImageCropper: false,
      hostImageURL: props.image,
      hostImageFile: null
    };
  }

  // image upload callback for FileUpload, PersonImage and UploadWrapper
  handleUpload = files => {
    const { addToast } = this.props;
    // if files is null, return early
    if (!files) return;

    if (files.length > 0) {
      // create a reader to read the given file and convert to base64
      const reader = new FileReader();
      const file = files[0];

      processImage(file)
        .then(() => {
          reader.addEventListener("load", () => {
            // when the reader is loaded, set the raw file, set the base64 result as the raw URL
            // and display the image cropper popup
            this.setState({
              hostRawFile: file,
              showImageCropper: true,
              hostRawURL: reader.result
            });
          });
          reader.readAsDataURL(file);
        })
        .catch(error => {
          addToast({ text: error.message, intent: "error" });
        });
    }
  };

  handleSubmit = values => {
    this.props.onSubmit({ ...values, image: this.state.hostImageFile });
  };

  // handle submit of ImageCropPrompt - gives a file object and a local URL for the cropped image
  handleCropSubmit = (file, url) => {
    // set the file, url, and hide the cropper.
    // setting the file indicates that this is a new image and will be sent to backend
    // the hostImageURL is used to display the cropped photo in PersonImage
    this.setState({
      hostImageFile: file,
      hostImageURL: url,
      showImageCropper: false
    });
  };

  render() {
    const { onClose, data, hostLoading, icon } = this.props;
    const { hostImageURL, showImageCropper } = this.state;
    return (
      <>
        <PromptWrapper onClose={onClose} className="host-prompt-wrapper">
          <div className="host-prompt">
            <div className="host-prompt__inner">
              <PromptHeader icon={icon}>Host</PromptHeader>

              {!hostImageURL && <FileUpload onUpload={this.handleUpload} />}
              {hostImageURL && (
                <PersonImage src={hostImageURL} onUpload={this.handleUpload} />
              )}

              <Formik
                initialValues={{
                  hostFirstName: data ? data.first_name : "",
                  hostLastName: data ? data.last_name : "",
                  hostPhone: data ? data.phone : "",
				  hostPhoneText: data ? data.phoneText : "",
                  hostEmail: data ? data.email : "",
                  hostWebsite: data ? data.website : "",
                  hostDescription: data ? data.description : ""
                }}
                validationSchema={this.HostValidationSchema}
                onSubmit={this.handleSubmit}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <form
                    name="host-prompt-form"
                    className="user-form user-form--prompt"
                    onSubmit={handleSubmit}
                  >
                    <InputText
                      name="hostFirstName"
                      type="text"
                      size="half"
                      placeholder="First Name"
                      autoComplete="given-name"
                      required={true}
                      value={values.hostFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      displayError={
                        errors.hostFirstName && touched.hostFirstName
                          ? errors.hostFirstName
                          : false
                      }
                    />
                    <InputText
                      name="hostLastName"
                      type="text"
                      size="half"
                      placeholder="Last Name"
                      autoComplete="family-name"
                      required={true}
                      value={values.hostLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      displayError={
                        errors.hostLastName && touched.hostLastName
                          ? errors.hostLastName
                          : false
                      }
                    />

                    <InputTel
                      name={"hostPhone"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values["hostPhone"]}
                      autoComplete="tel"
                      required={true}
                      type="tel"
                      size="full"
                      placeholder="Host Phone Number"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      displayError={
                        errors.hostPhone && touched.hostPhone
                          ? errors.hostPhone
                          : false
                      }
                    />

					<InputTel
					  name={"hostPhoneText"}
					  onChange={handleChange}
					  onBlur={handleBlur}
					  value={values["hostPhoneText"]}
					  autoComplete="tel"
					  type="tel"
					  size="full"
					  placeholder="Host Phone Number (Text/SMS)"
					  setFieldValue={setFieldValue}
					  setFieldTouched={setFieldTouched}
					  displayError={
						errors.hostPhoneText && touched.hostPhoneText
						  ? errors.hostPhoneText
						  : false
					  }
					/>

                    <InputText
                      name="hostEmail"
                      type="text"
                      size="full"
                      required={true}
                      placeholder="Email"
                      autoComplete="email"
                      disabled={data ? true : false}
                      value={values.hostEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      displayError={
                        errors.hostEmail && touched.hostEmail
                          ? errors.hostEmail
                          : false
                      }
                    />
                    <InputText
                      name="hostWebsite"
                      type="text"
                      size="full"
                      placeholder="Website"
                      autoComplete="url"
                      value={values.hostWebsite}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      displayError={
                        errors.hostWebsite && touched.hostWebsite
                          ? errors.hostWebsite
                          : false
                      }
                    />
                    <InputEditor
                      name="hostDescription"
                      id="hostDescription"
                      touched={touched}
                      placeholder="Description"
                      required={false}
                      initialMarkup={values.hostDescription}
                      disabled={false}
                      note="Add a description for the photo."
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      value={values.hostDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      displayError={
                        errors.hostDescription && touched.hostDescription
                          ? errors.hostDescription
                          : false
                      }
                    />
                    {!data && (
                      <div className="host-prompt__info">
                        <p>
                          <strong>Note: Adding this Host</strong>
                          <ul>
                            <li>
                              They will be added as an optional Host for all of
                              your houses in your account.
                            </li>
                            <li>
                              The host can be removed from the system by the
                              homeowner.
                            </li>
                            <li>
                              By clicking on "Add new host", this person will
                              AUTOMATICALLY BE SENT AN EMAIL informing them that
                              they are now an available Host for this home.
                            </li>
                          </ul>
                        </p>
                      </div>
                    )}
                    <ButtonMain
                      color="aqua"
                      type="submit"
                      loading={hostLoading}
                    >
                      {!data ? "Add new host" : "Update host"}
                    </ButtonMain>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          {showImageCropper && (
            <ImageCropPrompt
              file={this.state.hostRawFile}
              url={this.state.hostRawURL}
              onSubmit={this.handleCropSubmit}
              onCancel={() => this.setState({ showImageCropper: false })}
            />
          )}
        </PromptWrapper>

        <UploadWrapper onUpload={this.handleUpload} />
      </>
    );
  }
}

const mapState = state => ({
  currentPageHash: Object.keys(state.builder.currentBuilder).find(
    pageHash => state.builder.currentBuilder[pageHash].key === "housePhotos"
  ),
  hostLoading: state.loading.createHost
    ? state.loading.updateHost
      ? state.loading.updateHost.loading || state.loading.createHost.loading
      : state.loading.createHost.loading
    : state.loading.updateHost
    ? state.loading.updateHost.loading
    : null
});

const mapDispatch = dispatch => ({
  addSelectedGridItem: (pageHash, value, availabilityType, itemData) =>
    dispatch(
      builderOperations.addSelectedGridItem(
        pageHash,
        value,
        availabilityType,
        itemData
      )
    ),
  addToast: options => dispatch(toastOperations.addToast(options))
});

HostPrompt.propTypes = {
  data: PropTypes.object,
  image: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect(mapState, mapDispatch)(HostPrompt);
