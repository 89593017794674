import React from "react";
import ButtonMain from "../common/ButtonMain";

function NoPlan() {
  return (
    <div className="no-plan" data-testid="no-plan">
      <div className="no-plan__inner">
        <div className="no-plan__header">
          <h2>Looks like you aren't subscribed to a plan.</h2>
        </div>
        <div className="no-plan__content">
          <p>
            Subscribe to one of our subscription to start creating your home and
            inviting guests to the app.
          </p>
        </div>
        <div className="no-plan__cta">
          <ButtonMain
            color="blue"
            type="link"
            link={{ pathname: "/user/my-account", search: "?page=billing" }}
          >
            Subscribe To A Plan
          </ButtonMain>
        </div>
      </div>
    </div>
  );
}

export default NoPlan;
