import Creators from "./actions";
import HomeAPIWrapper from "hc-utils/HomeAPIWrapper";
import { loadingOperations } from "../../../duck/loading";
import { globalOperations } from "../../../duck/global";
import _get from "lodash.get";

const api = new HomeAPIWrapper();
const setLoading = loadingOperations.setLoading;
const updateHouseInfo = Creators.updateHouseInfo;
const setUserHomesList = Creators.setUserHomesList;
const setHomeGuestList = Creators.setHomeGuestList;
const removeHomeGuest = Creators.removeHomeGuest;

const setHousePublishStatus = Creators.setHousePublishStatus;
const addHomeGuest = Creators.addHomeGuest;
const updateGuest = Creators.updateGuest;

const logoutUser = globalOperations.logoutUser;

const addUserHome = Creators.addUserHome;
const updateHousePhoto = Creators.updateHousePhoto;

const removeHouse = Creators.removeHouse;

const setGuestStatus = Creators.setGuestStatus;

const getUserHomesList = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("userHomes", "REQUEST"));
    api
      .getUserHouses()
      .then(houseList => {
        dispatch(setUserHomesList(houseList.houses));
        dispatch(setLoading("userHomes", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("userHomes", "FAILED"));
        if (!error.response) {
          reject({
            message: "Unable to get list of homes. Please try again."
          });
        }
        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message: "You are not authorized to view this content."
              });
            });
          } else {
            reject({
              message: "Unable to get list of homes. Please try again."
            });
          }
        }
      });
  });
};

const getGuestStatus = (guestId, houseId) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("getGuestStatus", "REQUEST"));
    api
      .getGuestStatus(guestId)
      .then(({ is_app_open, emailSent, invitedGuests }) => {
        dispatch(
          setGuestStatus({
            id: guestId,
            houseId,
            is_app_open,
            emailSent,
            invitedGuests
          })
        );
        dispatch(setLoading("getGuestStatus", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("getGuestStatus", "FAILED"));
        reject({ message: error.response.message });
      });
  });
};

const getHomeGuestList = houseId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("homeGuests", "REQUEST"));
    api
      .getGuests(parseInt(houseId))
      .then(guestList => {
        dispatch(setHomeGuestList(guestList, houseId));
        dispatch(setLoading("homeGuests", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("homeGuests", "FAILED"));
        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message: "You are not authorized to view this content."
              });
            });
          } else {
            reject({
              message: "Unable to get list of guests. Please try again"
            });
          }
        } else {
          reject({
            message: "Unable to get list of guests. Please try again"
          });
        }
      });
  });
};

const deleteHomeGuest = (houseId, guestId) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("cancelGuest", "REQUEST"));
    api
      .deleteGuest(houseId, guestId)
      .then(() => {
        dispatch(removeHomeGuest(houseId, guestId));
        dispatch(setLoading("cancelGuest", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        dispatch(setLoading("cancelGuest", "FAILED"));
        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message: "You are not authorized to delete this content."
              });
            });
          } else {
            reject({
              message: "Unable to delete home guest. Please try again."
            });
          }
        } else {
          reject({
            message: "Unable to delete home guest. Please try again."
          });
        }
      });
  });
};

// const handleReasssignAdmin = (currHomeId, newHomeId, adminId) => dispatch => {
//   return new Promise((resolve, reject) => {
//     dispatch(setLoading("reassignAdmin", "REQUEST"));
//   });
// };

const createHomeGuest = guestValues => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("createGuest", "REQUEST"));
    const houseId = guestValues.houseId;
    api
      .createGuest(guestValues)
      .then(createdGuest => {
        dispatch(addHomeGuest(houseId, createdGuest.guest));
        dispatch(setLoading("createGuest", "SUCCESS"));
        resolve(createdGuest.guest);
      })
      .catch(error => {
        dispatch(setLoading("createGuest", "FAILED"));

        if (error.response) {
          if (error.response.status === 409) {
            reject({
              message: "This guest is already added to this home."
            });
          }
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message: "You are not authorized to create this content."
              });
            });
          } else {
            reject({
              message: `Unable to add guest "${guestValues.firstName} ${guestValues.lastName}". Please try again.`
            });
          }
        } else {
          reject({
            message: `Unable to add guest "${guestValues.firstName} ${guestValues.lastName}". Please try again.`
          });
        }
      });
  });
};

const updateHomeGuest = guestValues => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("updateGuest", "REQUEST"));

    const houseId = guestValues.houseId;
    api
      .updateGuest(guestValues)
      .then(updatedGuest => {
        dispatch(
          updateGuest(houseId, updatedGuest.guest.id, updatedGuest.guest)
        );
        dispatch(setLoading("updateGuest", "SUCCESS"));
        resolve();
      })
      .catch(error => {
        window.console.error(error);
        dispatch(setLoading("updateGuest", "FAILED"));
        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message: "You are not authorized to create this content."
              });
            });
          } else {
            reject({
              message: `Unable to update guest "${guestValues.firstName} ${guestValues.lastName}". Please try again.`
            });
          }
        } else {
          reject({
            message: `Unable to update guest "${guestValues.firstName} ${guestValues.lastName}". Please try again.`
          });
        }
      });
  });
};

const deleteHouse = houseId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("deleteHouse", "REQUEST"));
    api
      .deleteHouse(houseId)
      .then(house => {
        dispatch(removeHouse(houseId));
        dispatch(setLoading("deleteHouse", "SUCCESS"));
        resolve(house);
      })
      .catch(error => {
        dispatch(setLoading("deleteHouse", "FAILED"));

        if (error.response) {
          if (error.response.status === 401) {
            api.manualLogout().then(() => {
              dispatch(logoutUser());
              reject({
                message: "You are not authorized to delete this content."
              });
            });
          } else {
            reject({
              message: "Unable to delete house. Please try again."
            });
          }
        } else {
          reject({
            message:
              "There was an unknown issue while deleting your home. Please try again."
          });
        }
      });
  });
};

const getHosts = houseId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("getHosts", "REQUEST"));
    api
      .getHosts(houseId)
      .then(hosts => {
        dispatch(setLoading("getHosts", "SUCCESS"));
        resolve(hosts);
      })
      .catch(error => {
        dispatch(setLoading("getHosts", "FAILED"));
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({ message: "You are not authorized to view this content." });
          });
        } else {
          reject({
            message: "Unable to get hosts for the home. Please try again"
          });
        }
      });
  });
};

const getCalendar = houseId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("getCalendar", "REQUEST"));
    api
      .getCalendar(houseId)
      .then(calendar => {
        dispatch(setLoading("getCalendar", "SUCCESS"));
        resolve(calendar);
      })
      .catch(error => {
        dispatch(setLoading("getCalendar", "FAILED"));
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({ message: "You are not authorized to view this content." });
          });
        } else {
          reject({
            message: "Unable to get calendar for the home. Please try again."
          });
        }
      });
  });
};

const getSecurityData = houseId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("getSecurityData", "REQUEST"));
    api
      .getHouseById(houseId)
      .then(house => {
        dispatch(setLoading("getSecurityData", "SUCCESS"));
        resolve(house);
      })
      .catch(error => {
        dispatch(setLoading("getSecurityData", "FAILED"));
        if (_get(error, "response.status") === 401) {
          dispatch(logoutUser());
          reject({ message: "You are not authorized to view this content." });
        } else {
          reject({
            message:
              "Unable to get security data for the home. Please try again."
          });
        }
      });
  });
};

const openPreview = () => dispatch => {
  return new Promise((resolve, reject) => {
    api
      .setSharedAuth()
      .then(() => {
        resolve();
      })
      .catch(error => {
        if (_get(error, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to preform this action."
            });
          });
        } else {
          reject({
            message:
              "Unable to set shared authentication - preview was not succesful."
          });
        }
      });
  });
};

const resendEmail = (guestId, houseId) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("resendEmail", "REQUEST"));
    api
      .resendEmail(guestId, houseId)
      .then(() => {
        dispatch(setLoading("resendEmail", "SUCCESS"));
        resolve();
      })
      .catch(err => {
        dispatch(setLoading("resendEmail", "FAILED"));

        if (_get(err, "response.status") === 401) {
          api.manualLogout().then(() => {
            dispatch(logoutUser());
            reject({
              message: "You are not authorized to preform this action."
            });
          });
        } else {
          reject({
            message:
              "Unable to set shared authentication - preview was not succesful."
          });
        }
      });
  });
};

export default {
  setUserHomesList,
  setHomeGuestList,
  removeHouse,
  removeHomeGuest,
  addHomeGuest,
  updateGuest,
  getUserHomesList,
  getHomeGuestList,
  deleteHomeGuest,
  createHomeGuest,
  deleteHouse,
  addUserHome,
  getHosts,
  updateHomeGuest,
  setHousePublishStatus,
  getCalendar,
  getSecurityData,
  updateHousePhoto,
  openPreview,
  updateHouseInfo,
  getGuestStatus,
  resendEmail
};
