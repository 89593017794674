import Hammer from "hammerjs";
import { PhotoCanvasImage, PhotoCanvasArc } from "./PhotoCanvasObjects";
import PhotoCanvasReactor from "./PhotoCanvasEvents";

class PhotoCanvas {
  constructor({ canvas }) {
    this.canvas = canvas;
    this.canvasWithHammer = new Hammer(canvas);
    this.canvasWithHammer.get("pinch").set({ enable: true });
    this.canvasObjects = [];
    this.backgroundLocked = false;
    this.arcLocked = true;
    this.isZoomingBG = false;
    this.circleRadius = 250;
    this.handleDown = this.handleDown.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleOut = this.handleOut.bind(this);
    this.handlePinch = this.handlePinch.bind(this);
    this.handleMouseWheel = this.handleMouseWheel.bind(this);
    this.currentScale = 1;
    this.rotationSet = false;
    this.currentBGPosition = { x: 0, y: 0 };
    this.initializeListeners(this.canvas);

    this.reactor = new PhotoCanvasReactor();
    this.reactor.registerEvent("change");

    this.setCanvasDimensions();
  }
  initializeListeners(canvas) {
    canvas.addEventListener("mouseup", evt => {
      this.checkForCanvasImage(evt, this.handleOut);
    });
    canvas.addEventListener("mouseout", evt => {
      this.checkForCanvasImage(evt, this.handleOut);
    });
    canvas.addEventListener("mousedown", evt => {
      this.checkForCanvasImage(evt, this.handleDown);
    });
    canvas.addEventListener("mousemove", evt =>
      this.checkForCanvasImage(evt, this.handleMove)
    );
    canvas.addEventListener("touchstart", evt => {
      this.checkForCanvasImage(evt, this.handleDown);
    });
    canvas.addEventListener("touchmove", evt => {
      this.checkForCanvasImage(evt, this.handleMove);
    });
    canvas.addEventListener("touchend", evt => {
      this.checkForCanvasImage(evt, this.handleOut);
    });
    this.canvasWithHammer.on("pinch", evt => {
      this.checkForCanvasImage(evt, this.handlePinch);
    });
    // this.canvas.addEventListener("mousewheel", evt => {
    //   this.checkForCanvasImage(evt, this.handleMouseWheel);
    // });
  }

  // dataURLToBlob(dataURL) {
  //   return fetch(dataURL).then(res => res.blob());
  // }

  screenshot() {
    return this.canvas.toDataURL("image/png");
  }

  checkForCanvasImage(evt, callback) {
    if (this.canvasObjects.length !== 0) {
      callback(evt);
    }
  }

  lockImage() {
    this.backgroundLocked = true;
    this.arcLocked = false;
  }

  unlockImage() {
    this.backgroundLocked = false;
    this.arcLocked = true;
  }

  removeItem = type => {
    if (type === "arc") {
      this.canvasObjects = this.canvasObjects.filter(object => {
        if (object instanceof PhotoCanvasArc) {
          return false;
        }
        return true;
      });

      this.redraw();
    }
  };
  setCanvasDimensions() {
    this.canvas.width = 2048;
    this.canvas.height = 2048 * 0.5625;
  }
  rotateByOrientation(orientation) {
    switch (orientation) {
      case 8:
        return (-90 * Math.PI) / 180;
      case 3:
        return (180 * Math.PI) / 180;
      case 6:
        return (90 * Math.PI) / 180;
      default:
        return 0;
    }
  }
  redraw(objectsToDraw = this.canvasObjects) {
    if (objectsToDraw) {
      objectsToDraw
        .sort((objectA, objectB) => {
          if (objectA.priority < objectB.priority) return -1;
          if (objectA.priority > objectB.prority) return 1;
          return 0;
        })
        .forEach((object, i) => {
          //redraw for imaeg
          const ctx = this.canvas.getContext("2d");
          if (i === 0) {
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          }
          if (object instanceof PhotoCanvasImage) {
            const {
              image,
              pos,
              width,
              height,
              resolutionScale,
              orientation
            } = object;
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.save();

            ctx.setTransform(
              1,
              0,
              0,
              1,
              pos.x * resolutionScale,
              pos.y * resolutionScale
            );

            ctx.rotate(this.rotateByOrientation(orientation));

            ctx.drawImage(image, 0, 0, width, height);

            this.rotationSet = true;
            ctx.restore();
          }

          //redraw for arc
          if (object instanceof PhotoCanvasArc) {
            const { pos, radius, resolutionScale } = object;

            const lineWidth = 25;
            ctx.lineWidth = lineWidth;

            ctx.beginPath();
            ctx.strokeStyle = "red";
            ctx.arc(
              pos.x * resolutionScale - radius,
              pos.y * resolutionScale - radius,
              radius,
              0,
              Math.PI * 2,
              true
            );
            ctx.moveTo(110, 75);
            ctx.stroke();
          }
        });
    }
  }

  newArc({ radius, coords }) {
    this.arcObject = new PhotoCanvasArc({
      radius,
      canvas: this.canvas,
      priority: 2,
      coords,
      reactor: this.reactor
    });

    this.canvasObjects.push(this.arcObject);
    this.redraw();
    return Promise.resolve(this.arcObject);
  }
  newImage({ imageData, coords }) {
    return new Promise((resolve, reject) => {
      this.imageObject = new PhotoCanvasImage({
        imageData,
        canvas: this.canvas,
        priority: 1,
        coords,
        reactor: this.reactor
      });
      this.removeItem("arc");
      return this.imageObject
        .loadImage()
        .then(imageObj => {
          this.canvasObjects = this.canvasObjects.filter(object => {
            if (object instanceof PhotoCanvasImage) {
              return false;
            } else {
              return true;
            }
          });

          this.canvasObjects.push(this.imageObject);

          this.redraw();
          this.reactor.dispatchEvent("change", {
            pos: imageObj.pos,
            height: imageObj.height,
            width: imageObj.width
          });
          resolve(this.imageObject);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  handleDown(evt) {
    if (this.backgroundLocked === false) {
      this.imageObject.down(evt);
      this.redraw();
    } else if (this.arcLocked === false) {
      this.arcObject.down(evt);
      this.redraw();
    }
  }

  handleMove(evt) {
    if (this.backgroundLocked === false) {
      this.imageObject.move(evt);
      this.redraw(this.canvasObjects);
    } else if (this.arcLocked === false) {
      this.arcObject.move(evt);
      this.redraw();
    }
  }

  handlePinch(evt) {
    if (this.backgroundLocked === false) {
      this.imageObject.zoom(evt);
      this.redraw();
    } else if (this.arcLocked === false) {
      this.arcObject.zoom(evt);
      this.redraw();
    }
  }
  handleMouseWheel(evt) {
    evt.preventDefault();
    if (this.backgroundLocked === false) {
      this.imageObject.zoom(evt, "wheel");
      this.redraw();
    } else if (this.arcLocked === false) {
      this.arcObject.zoom(evt, "wheel");
      this.redraw();
    }
  }
  handleOut(evt) {
    if (this.backgroundLocked === false) {
      this.imageObject.out(evt);
    } else if (this.arcLocked === false) {
      this.arcObject.out(evt);
    }
  }
}

export default PhotoCanvas;
