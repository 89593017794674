import React from "react";
import PromptWrapper from "./PromptWrapper";
import ButtonMain from "./ButtonMain";

function WarningPrompt({ onClose, onConfirm, children, isLoading, onCancel }) {
  return (
    <PromptWrapper onClose={onClose} className="warning-prompt">
      <div className="warning-prompt__inner">
        <div className="warning-prompt__text">{children}</div>
        <div className="warning-prompt__submit">
          <ButtonMain color="red" loading={isLoading} onClick={onConfirm}>
            Delete
          </ButtonMain>
          <ButtonMain color={"blue"} onClick={onCancel}>
            Cancel
          </ButtonMain>
        </div>
      </div>
    </PromptWrapper>
  );
}

export default WarningPrompt;
