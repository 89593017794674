import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import BraintreeInput from "./BraintreeInput";
import BraintreeContext from "./contexts/braintreeContext";
import useBraintreeHostedFields from "../../hooks/useBraintreeHostedFields";

function BraintreeClient({ fields, styleObj, touched, setInstance }) {
  // get clientToken from the braintree context set in index
  const { clientToken } = useContext(BraintreeContext);

  //initialize braintree hosted fields with
  const { clientState, hostedFieldsInstance } = useBraintreeHostedFields({
    clientToken,
    styles: styleObj,
    fields
  });

  // call setInstance with the hosted field instance for later tokenization
  useEffect(() => {
    if (!hostedFieldsInstance || !setInstance) return;
    setInstance(hostedFieldsInstance);
  }, [hostedFieldsInstance, setInstance]);

  // if client token is not yet set, return null
  if (!clientToken) return null;
  return (
    <div className="braintree-client">
      {Object.keys(fields).map(fieldKey => (
        <BraintreeInput
          key={fieldKey}
          id={fields[fieldKey].id}
          label={fields[fieldKey].label}
          focused={clientState ? clientState.fields[fieldKey].isFocused : null}
          error={
            clientState
              ? touched[fieldKey] && !clientState.fields[fieldKey].isValid
              : false
          }
          errorMessage={clientState ? fields[fieldKey].errorMsg : null}
          touched={touched[fieldKey]}
          isEmpty={clientState ? clientState.fields[fieldKey].isEmpty : null}
        />
      ))}
    </div>
  );
}

BraintreeClient.propTypes = {
  fields: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      errorMsg: PropTypes.string.isRequired
    })
  ),
  styleObj: PropTypes.shape({
    input: PropTypes.object
  }),
  touched: PropTypes.object,
  setInstance: PropTypes.func
};

export default BraintreeClient;
