import React, { useState } from "react";
import PropTypes from "prop-types";

import ButtonMain from "./ButtonMain";
import ImageCropper from "./ImageCropper";

function ImageCropPrompt({ file, url, onSubmit, onCancel }) {
  const [croppedImageFile, setCroppedImageFile] = useState(null);
  const [croppedImageURL, setCroppedImageURL] = useState(null);

  // on change, set the cropped file and url
  const handleChange = (file, url) => {
    setCroppedImageFile(file);
    setCroppedImageURL(url);
  };

  // when submitting, send the cropped image and cropped object URL
  const handleSubmit = () => {
    if (!onSubmit) return;
    onSubmit(croppedImageFile, croppedImageURL);
  };

  // set the cropped file and url when the image is loaded and correct crop is set
  const handleInit = (file, url) => {
    setCroppedImageFile(file);
    setCroppedImageURL(url);
  };

  return (
    <div className="image-crop-prompt">
      <div className="image-crop-prompt__inner">
        <div className="image-crop-prompt__header">
          <h3>Crop Image</h3>
        </div>
        <div className="image-crop-prompt__cropper">
          <ImageCropper
            image={url}
            onChange={handleChange}
            onInit={handleInit}
          />
        </div>
        <div className="image-crop-prompt__submit">
          <ButtonMain color="blue" onClick={handleSubmit}>
            Save Photo
          </ButtonMain>
          <ButtonMain color="red" onClick={onCancel}>
            Cancel
          </ButtonMain>
        </div>
      </div>
    </div>
  );
}

ImageCropPrompt.propTypes = {
  file: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};
export default ImageCropPrompt;
