import React from "react";
import PropTypes from "prop-types";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditorConfig from "../../utilities/CKEditor/config";

import _get from "lodash.get";
import clsx from "clsx";

function InputEditorFormik({
  id,
  placeholder,
  required = false,
  note = null,
  disabled = false,
  className = "",
  form: { setFieldValue, setFieldTouched, touched, errors, submitCount },
  field: { onBlur, name, value }
}) {
  // extend the base CKEditor config and add the note as placeholder
  const config = {
    ...CKEditorConfig,
    placeholder: note
  };

  // set field as touched on blur and fire onBlur handler if it exists
  const handleEditorBlur = (event, editor) => {
    setFieldTouched(id);
  };

  // Set field value when editor value changes.
  const handleEditorChange = (event, editor) => {
    const html = editor.getData();
    setFieldValue(id, html);
  };

  // derive error if it is displayed
  const displayError =
    (_get(touched, name, null) || submitCount > 0) && _get(errors, name, null)
      ? _get(errors, name)
      : false;

  return (
    <div
      className={clsx("editor-input", className, {
        "editor-input--error": Boolean(displayError)
      })}
    >
      <label className="editor-input__label">
        {displayError
          ? displayError
          : required
          ? `${placeholder}*`
          : placeholder}
      </label>

      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={config}
        disabled={disabled}
        onBlur={handleEditorBlur}
        onChange={handleEditorChange}
      />
    </div>
  );
}

InputEditorFormik.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  note: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};

export default InputEditorFormik;
