import beachesSVG from "./Beaches.svg";
import hulaSVG from "./HulaLuau.svg";
import volcanoSVG from "./VolcanoLava.svg";
import sightSeeingSVG from "./SightSeeing.svg";
import golfSVG from "./Golf.svg";
import surfingSVG from "./SurfSUP.svg";
import snorkelingSVG from "./Snorkeling.svg";
import divingSVG from "./Diving.svg";
import hikingSVG from "./Hiking.svg";
import bikingSVG from "./Biking.svg";
import entertainmentSVG from "./Entertainment.svg";
import AdventuresSVG from "./Adventures.svg";
import MusicIcon from "./MusicIcon.svg";
import YourMusicIcon from "./YourMusicIcon.svg";
import PlayMusicIcon from "./PlayMusicIcon.svg";
import HowTVWorksIcon from "./HowTVWorksIcon.svg";
import HomeOfficeIcon from "./HomeOfficeIcon.svg";
import PrinterIcon from "./PrinterIcon.svg";
import CopyCenterIcon from "./CopyCenterIcon.svg";
import HostIcon from "./HostIcon.svg";
import ChefIcon from "./PrivateChefIcon.svg";
import WarningSironIcon from "./WarningSironIcon.svg";
import ExtinguisherIcon from "./ExtinguisherIcon.svg";
import FirstAidIcon from "./FirstAidIcon.svg";
import HouseEmergencyIcon from "./HouseEmergencyIcon.svg";
import BreakerBoxIcon from "./BreakerBoxIcon.svg";
import CalendarIcon from "./MaintanenceCalendarIcon.svg";
import PlayIcon from "./PlayIcon.svg";
import DineIcon from "./DineIcon.svg";
import ShopIcon from "./ShopIcon.svg";
import SpaIcon from "./SpaIcon.svg";

import SecurityIcon from "./SECURITY.svg";
import LocksIcon from "./Locks.svg";
import FrontDoorIcon from "./FrontDoor.svg";
import SafeIcon from "./Safe.svg";
import AlarmIcon from "./Alarm.svg";
import KeysIcon from "./Keys.svg";
import AVCodesIcon from "./AVCodes.svg";
import GatesIcon from "./GateCodes.svg";
import MiscIcon from "./SecurityMisc.svg";

import DoorsAndWindowsCategoryIcon from "./DoorsAndWindowsCategoryIcon.svg";
import SlideScreensIcon from "./SlideScreens.svg";
import DoorsIcon from "./Doors.svg";
import WindowsAndDoorsIcon from "./WindowsAndDoors.svg";
import FoldingIcon from "./Folding.svg";
import ScreensIcon from "./Screens.svg";
import SlideGlassIcon from "./SlideGlass.svg";

import WaterEqpIcon from "./WaterEquipment.svg";
import PoolIcon from "./Pool.svg";
import JacuzziIcon from "./Jacuzzi.svg";

import BeAwareIcon from "./BeAware.svg";
import RulesIcon from "./Rules.svg";

import OutsideIcon from "./Outside.svg";
import LanaiIcon from "./Lanai.svg";
import BBQIcon from "./BBQ.svg";
import BikesIcon from "./Bikes.svg";
import BootiesIcon from "./Booties.svg";
import RentalsIcon from "./Rentals.svg";
import BeachToysIcon from "./BeachStuff.svg";
import BarIcon from "./Bar.svg";
import ParkingIcon from "./Parking.svg";

import TechIcon from "./Tech.svg";
import RemotesIcon from "./Remotes.svg";
import WifiIcon from "./WiFiDetails.svg";

import KitchenIcon from "./Kitchen.svg";
import DishwasherIcon from "./Dishwasher.svg";
import MicrowaveIcon from "./Microwave.svg";
import CoffeeIcon from "./CoffeeMaker.svg";
import FridgeIcon from "./Fridge.svg";
import PantryIcon from "./Pantry.svg";
import StoveIcon from "./Stove.svg";
import VentilationIcon from "./Ventilation.svg";
import WineIcon from "./Wine.svg";
import CountersIcon from "./KitchenCounters.svg";
import DisposalIcon from "./GarbDis.svg";

import KidsIcon from "./Kids.svg";
import BabyStuffIcon from "./BabyStuff.svg";
import BabyProofIcon from "./BabyProof.svg";

import HealthIcon from "./Health.svg";
import GymIcon from "./Gym.svg";
import TreatmentsIcon from "./Treatments.svg";
import SaunaIcon from "./Sauna.svg";

import GarageIcon from "./Garage.svg";
import InsideGarageIcon from "./InsideGarage.svg";

import FloorsIcon from "./Floors.svg";
import RugsIcon from "./Rugs.svg";

import FansACIcon from "./FansAC.svg";
import FansIcon from "./Fans.svg";
import ACIcon from "./AC.svg";

import InHouseIcon from "./InHouse.svg";
import LightsIcon from "./Lights.svg";
import WasherDryerIcon from "./WasherDryer.svg";
import TrashIcon from "./Trash.svg";

import LinenIcon from "./Linens.svg";

import TowelIcon from "./Towels.svg";

import GroceryIcon from "./Grocery.svg";
import ShowerIcon from "./Shower.svg";
import LaundryRoomIcon from "./LaundryRoom.svg";
import MediaRoomIcon from "./MediaRoom.svg";

export const playIcons = {
  play: PlayIcon,
  beaches: beachesSVG,
  classicHawaii: hulaSVG,
  volcanoLava: volcanoSVG,
  siteseeingTours: sightSeeingSVG,
  golf: golfSVG,
  surfSUP: surfingSVG,
  snorkelling: snorkelingSVG,
  diving: divingSVG,
  hiking: hikingSVG,
  biking: bikingSVG,
  entertainment: entertainmentSVG,
  adventures: AdventuresSVG
};

export const dineIcons = {
  dine: DineIcon
};

export const shopIcons = {
  shop: ShopIcon
};

export const spaIcons = {
  spa: SpaIcon
};

export const musicIcons = {
  music: MusicIcon,
  yourMusic: YourMusicIcon,
  playMusic: PlayMusicIcon
};

export const tvOfficeIcons = {
  howTvWorks: HowTVWorksIcon,
  homeOffice: HomeOfficeIcon,
  printer: PrinterIcon,
  copyCenter: CopyCenterIcon
};

export const emergencyIcons = {
  warningSiren: WarningSironIcon,
  extinguisher: ExtinguisherIcon,
  firstAid: FirstAidIcon,
  houseEmergency: HouseEmergencyIcon,
  breakerBox: BreakerBoxIcon
};

export const guestServicesIcon = {
  hosts: HostIcon,
  privateChefs: ChefIcon,
  calendar: CalendarIcon
};

export const houseFeaturesIcon = {
  //SECURITY
  security: SecurityIcon,
  Security: SecurityIcon,
  locks: LocksIcon,
  frontDoor: FrontDoorIcon,
  safe: SafeIcon,
  alarm: AlarmIcon,
  keys: KeysIcon,
  avCodes: AVCodesIcon,
  gates: GatesIcon,
  //Windows
  doorsAndWindowsCategory: DoorsAndWindowsCategoryIcon,
  slideScreens: SlideScreensIcon,
  doors: DoorsIcon,
  securityDoors: DoorsIcon,
  windowsAndDoors: WindowsAndDoorsIcon,
  folding: FoldingIcon,
  screens: ScreensIcon,
  slideGlass: SlideGlassIcon,
  // Water Equipment
  waterEqp: WaterEqpIcon,
  pool: PoolIcon,
  jacuzzi: JacuzziIcon,
  // Be Aware
  beAware: BeAwareIcon,
  rules: RulesIcon,
  // Outside
  outside: OutsideIcon,
  lanai: LanaiIcon,
  bbq: BBQIcon,
  bikes: BikesIcon,
  booties: BootiesIcon,
  rentals: RentalsIcon,
  beachToys: BeachToysIcon,
  bar: BarIcon,
  parking: ParkingIcon,
  // Tech
  tech: TechIcon,
  wifi: WifiIcon,
  remotes: RemotesIcon,
  //Kitchen
  kitchen: KitchenIcon,
  dishwasher: DishwasherIcon,
  microwave: MicrowaveIcon,
  coffee: CoffeeIcon,
  fridge: FridgeIcon,
  pantry: PantryIcon,
  stove: StoveIcon,
  ventilation: VentilationIcon,
  wine: WineIcon,
  counters: CountersIcon,
  disposal: DisposalIcon,
  //Kids
  kids: KidsIcon,
  babyStuff: BabyStuffIcon,
  babyProof: BabyProofIcon,
  //Health
  health: HealthIcon,
  gym: GymIcon,
  treatments: TreatmentsIcon,
  sauna: SaunaIcon,
  //garage
  garage: GarageIcon,
  Garage: GarageIcon,
  insideGarage: InsideGarageIcon,
  /* bikes: BikesIcon */
  //Floors
  floors: FloorsIcon,
  rugs: RugsIcon,
  //fans ac
  fansAC: FansACIcon,
  fans: FansIcon,
  AC: ACIcon,
  // In House
  inHouse: InHouseIcon,
  lights: LightsIcon,
  washerDryer: WasherDryerIcon,
  trash: TrashIcon,
  linens: LinenIcon,
  towels: TowelIcon,
  grocery: GroceryIcon,
  shower: ShowerIcon,
  laundryRoom: LaundryRoomIcon,
  TV: HowTVWorksIcon,
  mediaRoom: MediaRoomIcon,
  houseRules: RulesIcon
};

export default {
  ...playIcons,
  ...dineIcons,
  ...shopIcons,
  ...spaIcons,
  ...musicIcons,
  ...tvOfficeIcons,
  ...emergencyIcons,
  ...guestServicesIcon,
  ...houseFeaturesIcon,
  general: MiscIcon,
  otherPlay: MiscIcon
};
