import React, { Component } from "react";
import { FieldArray, Formik } from "formik";
import { connect } from "react-redux";
import { Yup } from "hc-utils/FormValidator";

import { deepEqual } from "hc-utils";

import ButtonMain from "../../../common/ButtonMain";
import GridHeader from "../../GridHeader";
import CalendarGrid from "./CalendarGrid";
import CalendarItem from "./CalendarItem";
import { builderOperations } from "../../duck";
import { toastOperations } from "../../../../duck/toast";

import _get from "lodash.get";

class GuestServicesCalendar extends Component {
  constructor(props) {
    super(props);

    const { calendar } = props;
    this.state = {
      lastSavedValues: {
        houseKeeping: {
          day: _get(calendar, "houseKeeping.day", ""),
          time: _get(calendar, "houseKeeping.time", "")
        },
        trashPickup: {
          day: _get(calendar, "trashPickup.day", ""),
          time: _get(calendar, "trashPickup.time", "")
        },
        gardener: {
          day: _get(calendar, "gardener.day", ""),
          time: _get(calendar, "gardener.time", "")
        },
        poolJacuzzi: {
          day: _get(calendar, "poolJacuzzi.day", ""),
          time: _get(calendar, "poolJacuzzi.time", "")
        }
      }
    };

    const calendarItemSchema = validationMsg => {
      return Yup.object().shape(
        {
          day: Yup.string()
            .when("time", {
              is: val => {
                return val !== "" && val !== null && val !== undefined;
              },
              then: Yup.string().required(validationMsg),
              otherwise: Yup.string()
            })
            .nullable(),

          time: Yup.string()
            .when("day", {
              is: val => {
                return val !== "" && val !== null && val !== undefined;
              },
              then: Yup.string().required(validationMsg),
              otherwise: Yup.string()
            })
            .nullable()
        },
        ["day", "time"]
      );
    };

    this.GuestServicesCalendarSchema = Yup.object().shape({
      calendarItems: Yup.object().shape({
        houseKeeping: calendarItemSchema(
          "Housekeeping must have time and day."
        ),
        trashPickup: calendarItemSchema("Trash pickup must have time and day."),
        gardener: calendarItemSchema("Gardener must have time and day."),
        poolJacuzzi: calendarItemSchema("Pool/Jacuzzi must have time and day.")
      })
    });
  }

  onSubmit = values => {
    const { setCalendar, addToast } = this.props;

    setCalendar({
      houseKeeping: {
        day: _get(values.calendarItems.houseKeeping, "day", ""),
        time: _get(values.calendarItems.houseKeeping, "time", "")
      },
      trashPickup: {
        day: _get(values.calendarItems.trashPickup, "day", ""),
        time: _get(values.calendarItems.trashPickup, "time", "")
      },
      poolJacuzzi: {
        day: _get(values.calendarItems.poolJacuzzi, "day", ""),
        time: _get(values.calendarItems.poolJacuzzi, "time", "")
      },
      gardener: {
        day: _get(values.calendarItems.gardener, "day", ""),
        time: _get(values.calendarItems.gardener, "time", "")
      }
    })
      .then(() => {
        this.setState(
          {
            lastSavedValues: {
              houseKeeping: values.calendarItems.houseKeeping,
              trashPickup: values.calendarItems.trashPickup,
              poolJacuzzi: values.calendarItems.poolJacuzzi,
              gardener: values.calendarItems.gardener
            }
          },
          () => {
            addToast({ text: "Succesfully updated Maintenance calendar." });
          }
        );
      })
      .catch(error => {
        addToast({
          text: error.message,
          intent: "error"
        });
      });
  };
  render() {
    const { calendar, calendarLoading } = this.props;

    return (
      <div className="calendar-page" data-testid="calendar-page">
        <GridHeader color="blue">Maintenance Calendar</GridHeader>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{
            calendarItems: {
              houseKeeping: {
                day: _get(calendar, "houseKeeping.day", "Check With Host"),
                time: _get(calendar, "houseKeeping.time", "Check With Host")
              },
              trashPickup: {
                day: calendar.trashPickup ? calendar.trashPickup.day : "",
                time: calendar.trashPickup ? calendar.trashPickup.time : ""
              },
              gardener: {
                day: calendar.gardener ? calendar.gardener.day : "",
                time: calendar.gardener ? calendar.gardener.time : ""
              },
              poolJacuzzi: {
                day: calendar.poolJacuzzi ? calendar.poolJacuzzi.day : "",
                time: calendar.poolJacuzzi ? calendar.poolJacuzzi.time : ""
              }
            }
          }}
          validationSchema={this.GuestServicesCalendarSchema}
        >
          {({
            setFieldValue,
            setFieldTouched,
            errors,
            values,
            touched,
            handleSubmit
          }) => (
            <FieldArray name="calendarItems">
              <>
                <CalendarGrid>
                  <CalendarItem
                    handleSubmit={handleSubmit}
                    category="houseKeeping"
                    data={calendar.houseKeeping}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    values={values}
                  />
                  <CalendarItem
                    handleSubmit={handleSubmit}
                    category="trashPickup"
                    data={calendar.trashPickup}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    values={values}
                  />
                  <CalendarItem
                    handleSubmit={handleSubmit}
                    category="gardener"
                    data={calendar.gardener}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    values={values}
                  />
                  <CalendarItem
                    handleSubmit={handleSubmit}
                    category="poolJacuzzi"
                    data={calendar.poolJacuzzi}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    values={values}
                  />
                </CalendarGrid>
                <ButtonMain
                  type="button"
                  onClick={handleSubmit}
                  loading={calendarLoading}
                  isDisabled={deepEqual(
                    this.state.lastSavedValues,
                    values.calendarItems
                  )}
                >
                  Save Calendar
                </ButtonMain>
              </>
            </FieldArray>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatch = dispatch => ({
  setCalendar: values => dispatch(builderOperations.setCalendar(values)),

  addToast: options => dispatch(toastOperations.addToast(options))
});

const mapState = state => ({
  tempState: state,
  calendar: state.builder.currentBuilder[3].values.calendar,
  calendarLoading: _get(state.loading, "setCalendar.loading", false)
});
export default connect(mapState, mapDispatch)(GuestServicesCalendar);
