// api/loadingReducer.js

import types from "./types";

export const initialLoadingState = {
  login: {},
  logout: {},
  cache: { loading: true, status: "REQUEST" },
  userHomes: {},
  homeGuests: {},
  createGuest: {},
  homeAdmins: {},
  createAdmin: {},
  deleteAdmin: {},
  cancelGuest: {}
};

export default (state = initialLoadingState, action) => {
  switch (action.type) {
    case types.CLEAR_LOADING: {
      return { ...initialLoadingState, cache: {} };
    }
    default: {
      const { requestName, requestStatus } = action;

      if (!requestName || !requestStatus) return state;
      return {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving GET_TODOS_REQUEST
        //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
        [requestName]: {
          loading: requestStatus === "REQUEST",
          status: requestStatus
        }
      };
    }
  }
};
