import React from "react";
import PropTypes from "prop-types";
import {
  faEdit,
  faMinusCircle,
  faPlusCircle,
  faEye,
  faArrowCircleRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";
import { builderOperations } from "./duck";

import { DragSource, DropTarget } from "react-dnd";

import _get from "lodash.get";
import SpinnerLoader from "../common/SpinnerLoader";
import EditButtonIcon from "../../assets/EditButton.svg";

const overlayIcons = {
  edit: {
    icon: faEdit,
    title: "Edit"
  },
  delete: {
    icon: faMinusCircle,
    title: "Delete"
  },
  add: {
    icon: faPlusCircle,
    title: "Add"
  },
  view: {
    icon: faEye,
    title: "View"
  },
  remove: {
    icon: faArrowCircleRight,
    title: "Remove"
  }
};

const GridItemLoading = () => (
  <div className="grid-item-loading">
    <div className="grid-item-loading__inner">
      <SpinnerLoader loading={true} />
    </div>
  </div>
);

const OverlayItem = props => {
  const { action } = props;
  const onClickAction = (action, props) => {
    switch (action) {
      case "add": {
        return props.onAdd;
      }
      case "edit": {
        return props.onEdit;
      }
      case "delete": {
        return props.onDelete;
      }
      case "view": {
        return props.onView;
      }
      case "remove": {
        return props.onRemove;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div
      className="overlay-item"
      onMouseDown={evt => evt.stopPropagation()}
      onClick={evt => {
        onClickAction(action, props).call(this);
      }}
    >
      <div className="overlay-item__inner">
        <div className="overlay-item__icon">
          <FontAwesomeIcon icon={overlayIcons[action].icon} />
        </div>
        <div className="overlay-item__label">
          <h5>{overlayIcons[action].title}</h5>
        </div>
      </div>
    </div>
  );
};

const GridItemOverlay = React.memo(function ({
  type,
  overlayItems,
  onEdit,
  onDelete,
  onAdd,
  onRemove,
  onView
}) {
  const dynamicHandlers = {
    onEdit,
    onDelete,
    onAdd,
    onRemove,
    onView
  };
  if (!overlayItems) return null;
  return (
    <div className="grid-item-overlay">
      <div className="grid-item-overlay__inner">
        <div className="grid-item-overlay__actions">
          {type === "selected"
            ? overlayItems.selected.map(action => (
                <OverlayItem
                  key={action}
                  action={action}
                  {...dynamicHandlers}
                />
              ))
            : overlayItems.available.map(action => (
                <OverlayItem
                  key={action}
                  action={action}
                  {...dynamicHandlers}
                />
              ))}
        </div>
      </div>
    </div>
  );
});

class GridItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: null,
      width: null,
      isLoaded: false,
      isDragging: false,
      isOver: false,
      showGridOverlay: false
    };
  }

  showGridOverlay = () => {
    this.setState({ showGridOverlay: true });
  };

  hideGridOverlay = () => {
    this.setState({ showGridOverlay: false });
  };
  componentDidMount() {
    const gridItem = document.querySelector(".grid-item");
    const height = gridItem.clientHeight;
    const width = gridItem.clientWidth;
    this.setState({ height, width });
  }

  setLoaded = () => {
    this.setState({ isLoaded: true });
  };

  render() {
    const {
      title,
      photoData,
      type,
      gridIndex,
      onDelete,
      onEdit,
      onAdd,
      onRemove,
      onView,
      id,

      overlayItems,

      banner,

      connectDragSource,
      connectDropTarget,
      isDragging2,
      isOver,
	  icon
    } = this.props;

    // const handleStop = (event, data) =>

    const { isLoaded } = this.state;

	const gridImage = photoData ? photoData : EditButtonIcon;

    return connectDragSource(
      connectDropTarget(
        <div
          className={`grid-item ${!isLoaded ? `grid-item--loading` : ""} ${
            isDragging2 ? `grid-item--dragging` : ``
          }`}
          data-testid="grid-item"
        >
          {banner && (
            <div
              className={`grid-item__banner ${
                banner.color ? `grid-item__banner--${banner.color}` : ""
              }`}
            >
              {banner.text}
            </div>
          )}

          <div
            className="grid-item__inner"
            onMouseOver={this.showGridOverlay}
            onMouseLeave={this.hideGridOverlay}
          >
			{icon && (
			  <img className="grid-item__icon" src={icon} alt="" />
			)}
            <button
              className="grid-item__btn"
              style={{ opacity: isDragging2 ? 0.15 : 1 }}
            >
              {isOver && (
                <div
                  className="grid-item__over"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    background: "black",
                    opacity: 0.5
                  }}
                />
              )}

              {this.props.isDeleting ? (
                <GridItemLoading />
              ) : (
                <GridItemOverlay
                  type={type}
                  overlayItems={overlayItems}
                  onDelete={() => onDelete({ title, gridIndex, id, type })}
                  onEdit={() => onEdit({ title, gridIndex, id, type })}
                  onAdd={() => onAdd({ title, id, type })}
                  onRemove={() => onRemove({ title, id, type })}
                  onView={() => onView({ title, id, type })}
                />
              )}
              <div className="grid-item__img">
                <img src={gridImage} alt="Grid Item" onLoad={this.setLoaded} />
              </div>
              {title && (
                <div className="grid-item__title">
                  <h4 xmlns="http://www.w3.org/1999/xhtml">{title}</h4>
                </div>
              )}
            </button>
          </div>
        </div>
      )
    );
  }
}

const gridItemSource = {
  beginDrag(props) {
    return {
      sourceId: props.id,
      sourceIndex: props.sequence ? props.sequence : props.gridIndex,
      sourceAvailability: props.type
    };
  },
  endDrag(props, monitor) {
    // const draggedItemId = monitor.getDropResult().draggedItemId

    if (!monitor.getDropResult()) return;

    const {
      draggedItemID,
      draggedItemAvailability,
      draggedItemIndex,
      targetIndex,
      targetAvailability,
      disableAvailableDrop
    } = monitor.getDropResult();

    if (
      targetAvailability === draggedItemAvailability &&
      targetAvailability === "available" &&
      disableAvailableDrop
    ) {
      return;
    }
    props.onDragStop(
      draggedItemID,
      draggedItemAvailability,
      targetAvailability,
      draggedItemIndex,
      targetIndex
    );
  }
};

const gridItemTarget = {
  drop(props, monitor) {
    const draggedItemID = monitor.getItem().sourceId;
    const draggedItemAvailability = monitor.getItem().sourceAvailability;
    const draggedItemIndex = monitor.getItem().sourceIndex;

    const targetIndex = props.sequence ? props.sequence : props.gridIndex + 1;
    const targetAvailability = props.type;
    const disableAvailableDrop = props.disableAvailableDrop;

    return {
      draggedItemID,
      draggedItemAvailability,
      draggedItemIndex,
      targetIndex,
      targetAvailability,
      disableAvailableDrop
    };
  }
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging2: monitor.isDragging()
  };
}

function collectTarget(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const mapDispatch = dispatch => ({
  changeSelectedGridItemSequence: (
    pageHash,
    value,
    availabilityType,
    id,
    newSequence
  ) =>
    dispatch(
      builderOperations.changeSelectedGridItemSequence(
        pageHash,
        value,
        availabilityType,
        id,
        newSequence
      )
    )
});

GridItem.propTypes = {
  bounds: PropTypes.string,
  columns: PropTypes.number,
  gridIndex: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  onDragStop: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  overlayItems: PropTypes.objectOf(PropTypes.array),
  photoData: PropTypes.string,
  sequence: PropTypes.number.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

const mapState = (state, ownProps) => ({
  isDeleting: _get(state.loading, `itemDelete_${ownProps.id}.loading`, false)
});

GridItem = DragSource("GRID_ITEM", gridItemSource, collectSource)(GridItem);
GridItem = DropTarget("GRID_ITEM", gridItemTarget, collectTarget)(GridItem);

export default connect(mapState, mapDispatch)(GridItem);
