import EXIF from "exif-js";
import PhotoCanvasReactor from "./PhotoCanvasEvents";
import _debounce from "lodash.debounce";

// General CanvasObject class, extended by each object type
// Provides general handlers for each object, like down/move/out which tracks/changes
// the current objects position
class PhotoCanvasObject {
  constructor({ canvas, priority }) {
    // get reference to working canvas
    this.canvas = canvas;

    //Instatiate new reactor to dispatch events on change
    this.reactor = new PhotoCanvasReactor();
    this.reactor.registerEvent("change");

    //height/width of object
    this.height = null;
    this.width = null;
    //current x:y position of mouse
    this.mousePos = {
      x: 0,
      y: 0
    };
    //current x:y position of object
    this.pos = {
      x: 0,
      y: 0
    };

    //current zoom scale of object
    this.scale = 1;

    //queue prioritiy
    this.priority = priority;

    // flags for if object is dragging or zooming
    this.isDragging = false;
    this.isZooming = false;
    this.resolution = 2048;
    this.resolutionScale =
      document.body.clientWidth < 700
        ? this.resolution / 700
        : this.resolution / 700;
    window.addEventListener("resize", () => {
      this.resolutionScale =
        document.body.clientWidth < 700
          ? this.resolution / 700
          : this.resolution / 700;
    });
  }
  normalize(val, min, max) {
    const percent = ((val - 0) / (100 - 0)) * (max - min) + min;

    return percent;
  }
  addListener = (eventName, eventCb) => {
    this.reactor.addEventListener(eventName, _debounce(eventCb, 300));
  };

  down(evt) {
    this.mousePos = this.getCurrentMousePos(
      this.canvas,
      evt.touches ? evt.touches[0] : evt
    );
    if (evt.touches) {
      if (evt.touches.length > 1) {
        this.isZooming = true;
        this.isDragging = false;
      } else {
        this.isZooming = false;
        this.isDragging = true;
      }
    } else {
      this.isZooming = false;
      this.isDragging = true;
    }

    if (this instanceof PhotoCanvasArc) {
      this.reactor.dispatchEvent("change", {
        pos: this.pos,
        radius: this.radius
      });
    } else {
      this.reactor.dispatchEvent("change", {
        pos: this.pos,
        height: this.height,
        width: this.width
      });
    }
  }

  move(evt) {
    if (this.isZooming === false && this.isDragging === true) {
      const { x, y } = this.getCurrentMousePos(
        this.canvas,
        evt.touches ? evt.touches[0] : evt
      );

      this.pos = {
        x: this.pos.x - (this.mousePos.x - x),
        y: this.pos.y - (this.mousePos.y - y)
      };

      this.mousePos = {
        x,
        y
      };
      evt.preventDefault();
      if (this instanceof PhotoCanvasArc) {
        this.reactor.dispatchEvent("change", {
          pos: this.pos,
          radius: this.radius
        });
      } else {
        this.reactor.dispatchEvent("change", {
          pos: this.pos,
          height: this.height,
          width: this.width
        });
      }
    }
  }

  out(evt) {
    this.posStart = this.pos;
    this.radiusZoomStart = this.radius;
    if (this.isZooming === true) {
      if (this.widthStart && this.heightStart) {
        this.widthStart = this.width;
        this.heightStart = this.height;
      }
    }

    this.isDragging = false;
    this.isZooming = false;
  }
  forceDispatchEvent = (eventName, eventArgs) => {
    this.reactor.dispatchEvent(eventName, eventArgs);
  };
  getCurrentMousePos(canvas, evt) {
    var rect = this.canvas.getBoundingClientRect();

    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }
}

// PhotoCanvasImage - object which allows for rendering of image by imageData
// argument

class PhotoCanvasImage extends PhotoCanvasObject {
  constructor({ canvas, imageData, priority, coords }) {
    super({ canvas, priority });
    this.image = null;
    this.imageData = imageData;
    this.coords = coords;
    this.changeEvent = new Event("coord-change");
    this.scale = 1;
  }
  getExifOrientation(image) {
    return new Promise((resolve, reject) => {
      EXIF.getData(image, function() {
        if (this.exifdata) {
          resolve(this.exifdata.Orientation);
          return;
        } else {
          reject("Couldnt get exifdata");
        }
        reject("not able to fetch exif data");
      });
    });
  }

  loadImage = () => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        this.image = image;

        this.height = (2048 / this.image.width) * this.image.height;
        this.width = (2048 / this.image.width) * this.image.width;

        if (this.coords) {
          this.pos = {
            x: this.coords.x,
            y: this.coords.y
          };
          this.scale = this.coords.width / this.width;
          this.widthStart = this.width;
          this.heightStart = this.height;
          this.height = this.coords.height;
          this.width = this.coords.width;
        } else {
          this.scale = 1;
          this.widthStart = this.width;
          this.heightStart = this.height;
        }
        this.posStart = this.pos;
        const limitModifier = 5.5;
        this.maxHeight = this.canvas.height * limitModifier;
        this.maxWidth = this.canvas.width * limitModifier;
        this.minWidth = this.canvas.width / limitModifier;
        this.minHeight = this.canvas.height / limitModifier;
        this.reactor.dispatchEvent("change", {
          pos: this.pos,
          height: this.height,
          width: this.width
        });

        resolve(image);
      };
      this.reactor.dispatchEvent("change", {
        pos: this.pos,
        height: this.height,
        width: this.width
      });
      image.src = this.imageData;
      this.image = image;
    });
  };

  manualZoom = scale => {
    this.isZooming = true;
    this.zoomType = "manual";

    const scalePercentage = this.normalize(scale, 0, 4);

    this.scale = scalePercentage;
    // this.currentScaledPosition = {
    //   x:
    //     (imageCenter.x - this.widthStart * scalePercentage) /
    //     this.resolutionScale /
    //     2,
    //   y:
    //     (imageCenter.y - this.heightStart * scalePercentage) /
    //     this.resolutionScale /
    //     2
    // };

    this.height = this.heightStart * scalePercentage;
    this.width = this.widthStart * scalePercentage;

    const imageCenter = {
      x: this.pos.x,
      y: this.pos.y
    };
    this.currentScaledPosition = {
      x: imageCenter.x,
      y: imageCenter.y
    };
    this.pos = this.currentScaledPosition;
    this.reactor.dispatchEvent("change", {
      pos: this.pos,
      height: this.height,
      width: this.width
    });
  };

  zoom(evt, type = "pinch") {
    this.isZooming = true;
    this.zoomType = type;

    if (type === "pinch") {
      this.scale = 1 - (1 - evt.scale) / 2;

      this.currentScaledPosition = {
        x: evt.center.x - (evt.center.x - this.posStart.x) * this.scale,
        y: evt.center.y - (evt.center.y - this.posStart.y) * this.scale
      };

      if (
        this.width * this.scale > this.maxWidth ||
        this.height * this.scale > this.maxHeight ||
        this.height * this.scale < this.minWidth ||
        this.width * this.scale < this.minWidth
      ) {
        this.scale = 1;

        return;
      }

      this.pos = this.currentScaledPosition;
      this.width = this.widthStart * this.scale;
      this.height = this.heightStart * this.scale;
    } else {
      const wheelDelta = evt.wheelDelta / 800;

      this.mousePos = this.getCurrentMousePos(
        this.canvas,
        evt.touches ? evt.touches[0] : evt
      );
      this.currentScale = this.scale - wheelDelta;

      this.currentScaledPosition = {
        x: this.mousePos.x - (this.mousePos.x - this.pos.x) * this.currentScale,
        y: this.mousePos.y - (this.mousePos.y - this.pos.y) * this.currentScale
      };

      if (
        this.width * this.currentScale > this.maxWidth ||
        this.width * this.currentScale < this.minWidth
      ) {
        this.scale = 1;

        return;
      }
      this.scale = this.currentScale;
      this.pos = this.currentScaledPosition;
      this.height = this.height * this.scale;
      this.width = this.width * this.scale;
      this.scale = 1;
    }

    if (this.isZooming) {
      this.reactor.dispatchEvent("change", {
        pos: this.pos,
        height: this.height,
        width: this.width
      });
    }
  }
}

class PhotoCanvasArc extends PhotoCanvasObject {
  constructor({ canvas, radius, priority, coords }) {
    super({ canvas, priority });
    this.radius = 250;
    this.coords = coords;
    this.height = this.radius;
    this.width = this.radius;
    this.pos = {
      x: (this.canvas.width / 2 - radius) / this.resolutionScale,
      y: (this.canvas.height / 2 - radius) / this.resolutionScale
    };
    this.pos.x += (radius * 2) / this.resolutionScale;
    this.pos.y += radius / this.resolutionScale;
    this.radiusZoomStart = this.radius;
    this.maxWidth = this.radius * 5;
    this.minWidth = this.radius / 3;
    this.posStart = this.pos;

    if (this.coords) {
      this.pos = {
        x: this.coords.x,
        y: this.coords.y
      };

      this.scale = this.coords.radius / 250;

      this.height = this.coords.radius / 250;
      this.width = this.coords.radius / 250;
      this.radiusZoomStart = this.coords.radius;
      this.radius = this.coords.radius;
    } else {
      this.scale = 1;
    }
  }

  manualZoom = scale => {
    this.isZooming = true;
    this.zoomType = "manual";

    const scalePercentage = this.normalize(scale, 0, 4);

    this.scale = scalePercentage;

    this.currentScaledPosition = {
      x: this.pos.x,
      y: this.pos.y
    };

    // this.currentScaledPosition = {
    //   x: imageCenter.x,
    //   y: imageCenter.y
    // };

    this.radius = 250 * scalePercentage;
    this.reactor.dispatchEvent("change", {
      pos: this.pos,
      radius: this.radius
    });
  };

  zoom(evt, type = "pinch") {
    this.isZooming = true;
    if (type === "pinch") {
      if (this.isDragging === false) {
        this.scale = 1 - (1 - evt.scale);

        if (
          this.radiusZoomStart * this.scale > this.maxWidth ||
          this.radiusZoomStart * this.scale < this.minWidth
        ) {
          this.scale = 1;

          return;
        }

        this.radius = this.scale * this.radiusZoomStart;
      }
    } else {
      const wheelDelta = evt.wheelDelta / 1600;

      this.currentScale = this.scale - wheelDelta;
      this.radiusZoomStart = this.radius;

      if (
        this.radiusZoomStart * this.currentScale > this.maxWidth ||
        this.radiusZoomStart * this.currentScale < this.minWidth
      ) {
        this.scale = 1;

        return;
      }
      this.scale = this.currentScale;

      this.radius = this.scale * 250;
    }
  }
}

export { PhotoCanvasArc, PhotoCanvasImage };
