import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
// Import the library
import Datetime from "react-datetime";
import clsx from "clsx";
import _get from "lodash.get";
import moment from "moment";

function InputDateTimeFormik({
  type = "text",
  className,
  placeholder,
  disabled,
  size,
  required,
  maxLength = 2048,
  autoComplete = "new-password",
  field: { name, onBlur, onChange, value },
  form,
  icon: Icon,
  isValidDate,
  timeConstraints
}) {
  const [isFocused, setFocus] = useState(false);
  const inputRef = useRef();
  const dateTimeRef = useRef();

  const { errors, touched, submitCount, setFieldValue, setFieldTouched } = form;
  const displayError =
    _get(errors, `${name}`, false) &&
    (_get(touched, `${name}`, false) || submitCount > 0)
      ? _get(errors, `${name}`, null)
      : false;

  const scrollToDateTime = () => {
    if (!inputRef.current) return;

    const wrapper = inputRef.current.parentElement.parentElement;

    const picker = wrapper.querySelector(".rdtPicker");

    if (picker)
      picker.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
  };

  const renderInput = props => <input ref={inputRef} {...props} />;
  // create default viewDate which is same day at 12PM
  const defaultViewDate = moment();
  defaultViewDate.hour(12);
  defaultViewDate.minute(0);

  return (
    <div
      className={clsx("form-datetime", name, className, {
        focused: Boolean(isFocused || (value !== "" && value)),
        [`form-datetime--${size}`]: Boolean(size),
        error: Boolean(displayError),
        "form-datetime--disabled": Boolean(disabled),
        "form-datetime--with-icon": Boolean(Icon)
      })}
    >
      <label className="form-datetime__label" htmlFor={name}>
        {displayError
          ? displayError
          : required
          ? `${placeholder}*`
          : placeholder}
      </label>
      <Datetime
        ref={dateTimeRef}
        isValidDate={isValidDate}
        className={"form-datetime__inputwrapper"}
        initialViewDate={moment(12, "HH")}
        initialViewMode="days"
        renderInput={renderInput}
        onOpen={scrollToDateTime}
        onChange={date => {
          if (!date) {
            setFieldValue(name, "");
            return;
          }

          if (typeof date === "string") {
            setFieldValue(name, date);
            return;
          }
          dateTimeRef.current.navigate("time");
          const fmt = date.isValid() ? date.format("MM/DD/YYYY, hh:mm A") : "";

          setFieldValue(name, fmt);
        }}
        timeConstraints={timeConstraints}
        dateFormat={"MM/DD/YYYY,"}
        timeFormat={"hh:mm A"}
        value={moment(value)}
        updateOnView={"days"}
        onClose={() => {
          setFocus(false);
          setFieldTouched(name, true);
          dateTimeRef.current.navigate("days");
        }}
        inputProps={{
          id: name,
          name,
          placeholder: "mm/dd/yyyy, --:-- --",
          autoComplete: "off",
          onFocus: () => {
            setFocus(true);
          }
        }}
      />
    </div>
  );
}

InputDateTimeFormik.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.string,
  icon: PropTypes.any,
  isValidDate: PropTypes.func,
  timeConstraints: PropTypes.func
};

export default InputDateTimeFormik;
