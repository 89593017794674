import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Yup } from "hc-utils/FormValidator";

import AccountPage from "../AccountPage";
import InputText from "hc-common/InputText";
import _get from "lodash.get";
import ButtonMain from "../../common/ButtonMain";
import { accountOperations } from "../duck";
import { toastOperations } from "../../../duck/toast";

const AccountPagePasswordFormSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Current password is required."),
  newPassword: Yup.string()
    .required("New password is required.")
    .test(
      "password-different",
      "New Password cant be same as current password.",
      function (value) {
        return this.parent.oldPassword !== value;
      }
    ),
  confirmPassword: Yup.string()
    .required("You must confirm your new password.")
    .test("passwords-match", "Passwords do not match.", function (value) {
      return this.parent.newPassword === value;
    })
});
let AccountPagePasswordForm = ({
  data,
  isReadOnly,
  isUpdating,
  rootFormikProps
}) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors
  } = rootFormikProps;

  return (
    <form
      name="password-form"
      className={"password-form"}
      onSubmit={handleSubmit}
      data-testid="password-form"
    >
      <InputText
        name="oldPassword"
        type="password"
        size="full"
        required={true}
        placeholder="Current Password"
        autoComplete="current-password"
        value={values.oldPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isReadOnly}
        displayError={
          errors.oldPassword && touched.oldPassword ? errors.oldPassword : false
        }
      />
      <InputText
        name="newPassword"
        type="password"
        size="full"
        required={true}
        placeholder="New Password"
        autoComplete="new-password"
        value={values.newPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isReadOnly}
        displayError={
          errors.newPassword && touched.newPassword ? errors.newPassword : false
        }
      />
      <InputText
        name="confirmPassword"
        type="password"
        size="full"
        required={true}
        placeholder="Confirm New Password"
        autoComplete="new-password"
        value={values.confirmPassword}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isReadOnly}
        displayError={
          errors.confirmPassword && touched.confirmPassword
            ? errors.confirmPassword
            : false
        }
      />
      <ButtonMain
        color="aqua"
        type="submit"
        isDisabled={!values.oldPassword}
        loading={isUpdating}
      >
        Update Password
      </ButtonMain>
    </form>
  );
};

function AccountPagePassword({ data, changePassword, addToast, isUpdating }) {
  let bindedSetFieldValue, bindedSetFieldTouched;
  const handlePasswordSubmit = values => {
    const { oldPassword, newPassword } = values;
    changePassword(oldPassword, newPassword)
      .then(() => {
        bindedSetFieldValue("oldPassword", "");
        bindedSetFieldValue("newPassword", "");
        bindedSetFieldValue("confirmPassword", "");
        bindedSetFieldTouched("oldPassword", false);
        bindedSetFieldTouched("newPassword", false);
        bindedSetFieldTouched("confirmPassword", false);
        addToast({ text: "Succesfully changed your password." });
      })
      .catch(error => {
        bindedSetFieldValue("oldPassword", "");
        bindedSetFieldValue("newPassword", "");
        bindedSetFieldValue("confirmPassword", "");
        bindedSetFieldTouched("oldPassword", false);
        bindedSetFieldTouched("newPassword", false);
        bindedSetFieldTouched("confirmPassword", false);
        addToast({ text: error.message, intent: "error" });
      });
  };

  return (
    <AccountPage page="profile">
      <div className="account-page__header">
        <h3>Change Password</h3>
      </div>
      <div className={`account-page__content `}>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
          }}
          validationSchema={AccountPagePasswordFormSchema}
          onSubmit={values => handlePasswordSubmit(values)}
        >
          {rootFormikProps => {
            bindedSetFieldValue = rootFormikProps.setFieldValue;
            bindedSetFieldTouched = rootFormikProps.setFieldTouched;
            return (
              <AccountPagePasswordForm
                isReadOnly={false}
                isUpdating={isUpdating}
                data={data}
                rootFormikProps={rootFormikProps}
              />
            );
          }}
        </Formik>
      </div>
    </AccountPage>
  );
}

const mapState = state => ({
  isUpdating: _get(state.loading, "changePassword.loading", false)
});
const mapDispatch = dispatch => ({
  changePassword: (oldPassword, newPassword) =>
    dispatch(accountOperations.changePassword(oldPassword, newPassword)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

export default connect(mapState, mapDispatch)(AccountPagePassword);
