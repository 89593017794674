import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { builderOperations } from "../../duck";
import { toastOperations } from "../../../../duck/toast";

import BuilderPageWrapper from "../../BuilderPageWrapper";
import FeatureSelectedGrid from "../../FeatureSelectedGrid";
import FeatureOptionsGrid from "../../FeatureOptionsGrid";

import HouseFeaturesController from "./HouseFeaturesController";
import { canvasToFile } from "hc-utils";
import parseQuery from "hc-utils/parseQuery";
import WarningPrompt from "../../../common/WarningPrompt";

import { Helmet } from "react-helmet";
import _get from "lodash.get";
import FeatureViewPrompt from "../../FeatureViewPrompt";

export class HouseFeatures extends Component {
  constructor(props) {
	super(props);
	this.state = {
	  newPrompt: false,
	  queuedData: null
	};
  }

  componentDidMount() {
	const { houseFeatures, location } = this.props;
	const currentCat = parseQuery(location.search).category;
	// sub categoryKey
	const currentFeature = parseQuery(location.search).feature;

	if (currentFeature) {
	  console.info('houseFeatures[currentCat].selected.', houseFeatures[currentCat].selected);
	  // let's open the edit window if a user is sent to a direct link to a subcategory feature
	  // like from a pano 360
	  const currentSubCategory = houseFeatures[currentCat].selected.find(
		({ key, id }) => isNaN(currentFeature) ? key.toLowerCase() === currentFeature.toLowerCase() : id == currentFeature
	  );
	  if (currentSubCategory) {
		// ex: http://localhost:3000/home/edit/1878/houseFeatures?category=inHouse&feature=shower

		const subCatData = houseFeatures[currentCat].selected.find(
		  houseFeaturesObject => currentSubCategory.id === houseFeaturesObject.id
		);

		if (subCatData) {
		  this.setState({
			newPrompt: true,
			queuedData: subCatData,
			queuedCategory: {
			  key: currentSubCategory.key,
			  name: currentSubCategory.name,
			  icon: currentSubCategory.image,
			  prefilled: currentSubCategory.prefilled
			}
		  });
		}
	  }
	}
  }

  getFeatureById(id) {
	const { houseFeatures, location } = this.props;

	const currentCat = parseQuery(location.search).category;
	if (
	  houseFeatures[currentCat].selected.find(
		houseFeaturesObject => id === houseFeaturesObject.id
	  )
	) {
	  return houseFeatures[currentCat].selected.find(
		houseFeaturesObject => id === houseFeaturesObject.id
	  );
	} else {
	  return null;
	}
  }

  handleNew = (key, name, icon, id, prefilled) => {
	const featureData = this.getFeatureById(id);

	this.setState({
	  newPrompt: true,
	  queuedCategory: { key, name, icon, prefilled },
	  queuedData: featureData
	});
  };

  onDragStop = (
	id,
	originalAvailability,
	newAvailability,
	originalIndex,
	newIndex
  ) => {
	const {
	  changeSelectedGridItemSequences,
	  currentPageHash,
	  location
	} = this.props;
	const currentCat = parseQuery(location.search).category;
	changeSelectedGridItemSequences(
	  parseInt(currentPageHash),
	  currentCat,
	  originalAvailability,
	  id,
	  newIndex,
	  true
	);
  };
  onEdit = ({ id }) => {
	const { houseFeatures, location } = this.props;
	const currentCat = parseQuery(location.search).category;
	const featureData = this.getFeatureById(id);

	const option = houseFeatures[currentCat].options.find(
	  ({ key }) => key === featureData.key
	);

	this.setState({
	  newPrompt: true,
	  queuedData: featureData,
	  queuedCategory: {
		key: featureData.key,
		name: featureData.name,
		icon: option ? option.image : null,
		prefilled: option ? option.prefilled : null
	  }
	});
  };
  onDelete = ({ id }) => {
	this.setState({ showDeleteModal: true, queuedDeleteData: id });
  };

  deleteHouseFeature = id => {
	const { currentPageHash, addToast, deleteFeature, location } = this.props;
	const currentCat = parseQuery(location.search).category;
	deleteFeature(currentPageHash, currentCat, "selected", id)
	  .then(() => {
		addToast({ text: "Succesfully removed info page." });
	  })
	  .catch(error => {
		addToast({ text: error.message, intent: "error" });
	  });
  };
  handleSubmit = values => {
	const {
	  createFeaturePage,
	  location,
	  updateFeature,
	  addToast,
	  houseFeatures
	} = this.props;
	const { queuedCategory, queuedData } = this.state;
	const currentCat = parseQuery(location.search).category;
	const currentHouseFeatures = houseFeatures[currentCat];

	if (!queuedData) {
	  createFeaturePage(
		"11",
		currentCat,
		"selected",
		{
		  name: queuedCategory.name,
		  key: queuedCategory.key,
		  sequence: currentHouseFeatures.selected.length,
		  items: values.features.map((value, i) => ({
			id: value.id,
			sequence: i + 1,
			text: value.text,
			photoImage: _get(value.image, "file", null),
			pageRenderedImage: value.canvas ? canvasToFile(
			  value.canvas.canvas,
			  "image/png",
			  1
			) : null,
			coords: {
			  photo: value.coords.photo,
			  arc: value.coords.circle
			},
			videoURL: value.video_url,
			userManualURL: value.user_manual_url,
			isNew: Boolean(value.isNew)
		  }))
		},
		houseFeatures[currentCat].id
	  )
		.then(() => {
		  this.setState({
			newPrompt: false,
			queuedCategory: null,
			queuedData: null
		  });
		  addToast({ text: "Successfully added feature." });
		})
		.catch(error => {
		  addToast({ text: error.message, intent: "error" });
		});
	} else {
	  updateFeature("11", currentCat, "selected", queuedData.id, {
		name: queuedCategory.name,
		key: queuedCategory.key,
		sequence: queuedData.sequence,
		items: values.features
		  .filter(value => value !== null)
		  .map(value => {
			return {
			  id: value.id,
			  text: value.text || "",
			  pageRenderedImage: this.state.isNew
				? null
				: value.canvas ? canvasToFile(value.canvas.canvas, "image/jpeg", 0.8) : null,

			  photoImage: _get(value.image, "file", false),

			  coords: {
				photo: value.coords.photo,
				arc: value.coords.circle
			  },
			  videoURL: value.video_url,
			  userManualURL: value.user_manual_url,
			  isNew: Boolean(value.isNew)
			};
		  })
	  })
		.then(() => {
		  this.setState({
			newPrompt: false,
			queuedCategory: null,
			queuedData: null
		  });
		  addToast({ text: "Succesfully updated feature." });
		})
		.catch(error => {
		  addToast({ text: error.message, intent: "error" });
		});
	}
  };

  computeFeatureTitle(category) {
	return this.props.houseFeatures[category]
	  ? this.props.houseFeatures[category].name
	  : null;
  }

  setCategory = currentCategory => {
	this.setState({ currentCategory });
	const { history, currentBuilderId } = this.props;
	history.push(
	  `/home/edit/${currentBuilderId}/houseFeatures?category=${currentCategory}`
	);
  };

  render() {
	const {
	  pageKey,
	  currentPageHash,
	  currentBuilderId,
	  location,
	  propertyInfoBuilder,
	  houseFeatures
	} = this.props;
	const {
	  newPrompt,
	  queuedCategory,
	  queuedData,
	  showDeleteModal,
	  queuedDeleteData
	} = this.state;

	const currentCat = parseQuery(location.search).category;

	if (!currentCat) {
	  return (
		<Redirect
		  to={`/home/edit/${currentBuilderId}/houseFeatures?category=${
			Object.keys(houseFeatures)[0]
		  }`}
		/>
	  );
	}

	const currentCategoryEntity = houseFeatures[currentCat] || {};

	return (
	  <>
		<Helmet>
		  <title>
			Home Concierge | {propertyInfoBuilder.houseName} | House Features
		  </title>
		  <meta
			name="description"
			content="Add a page describing your general house features."
		  />
		</Helmet>
		<BuilderPageWrapper pageKey={pageKey}>
		  <HouseFeaturesController
			pageHash={currentPageHash}
			value={currentCat}
			onCategoryChange={this.setCategory}
		  >
			{({ selected, options, categories }) => (
			  <>
				{categories && (
				  <>
					<FeatureSelectedGrid
					  parentCategory={currentCategoryEntity}
					  items={selected}
					  onDragStop={this.onDragStop}
					  onEdit={this.onEdit}
					  onDelete={this.onDelete}
					  title={`${this.computeFeatureTitle(currentCat)} Pages`}
					  options={options}
					/>
					<div className="grid-seperator">OR</div>
					<FeatureOptionsGrid
					  items={options}
					  onNew={this.handleNew}
					/>
				  </>
				)}
			  </>
			)}
		  </HouseFeaturesController>
		</BuilderPageWrapper>
		{newPrompt && (
		  <FeatureViewPrompt
			data={queuedData}
			icon={queuedCategory.icon}
			title={queuedCategory.name}
			prefilled={queuedCategory.prefilled || ""}
			onSubmit={this.handleSubmit}
			onClose={() =>
			  this.setState({
				newPrompt: false,
				queuedData: null
			  })
			}
		  />
		)}
		{showDeleteModal && (
		  <WarningPrompt
			onClose={() =>
			  this.setState({
				showDeleteModal: false,
				queuedDeleteData: null
			  })
			}
			onConfirm={() => {
			  this.setState({ showDeleteModal: false }, () => {
				this.deleteHouseFeature(queuedDeleteData);
			  });
			}}
			onCancel={() =>
			  this.setState({
				showDeleteModal: false,
				queuedDeleteData: null
			  })
			}
		  >
			Are you sure you want to delete this feature page?
		  </WarningPrompt>
		)}
	  </>
	);
  }
}

const mapState = state => ({
  currentPageHash: parseInt(
	Object.keys(state.builder.currentBuilder).find(
	  pageHash => state.builder.currentBuilder[pageHash].key === "houseFeatures"
	)
  ),
  houseFeatures: state.builder.currentBuilder[11].values,
  currentBuilderId: state.builder.currentBuilderId,
  propertyInfoBuilder: _get(
	state.builder.currentBuilder[1].values,
	"propertyInfo.form",
	null
  )
});

const mapDispatch = dispatch => ({
  removeSelectedGridItem: (pageHash, value, availabilityType, id) =>
	dispatch(
	  builderOperations.removeSelectedGridItem(
		pageHash,
		value,
		availabilityType,
		id
	  )
	),
  addSelectedGridItem: (pageHash, value, availabilityType, itemData) =>
	dispatch(
	  builderOperations.addSelectedGridItem(
		pageHash,
		value,
		availabilityType,
		itemData
	  )
	),

  changeSelectedGridItemSequences: (
	pageHash,
	value,
	availabilityType,
	id,
	newSequence,
	isCompact
  ) =>
	dispatch(
	  builderOperations.changeSelectedGridItemSequences(
		pageHash,
		value,
		availabilityType,
		id,
		newSequence,
		isCompact
	  )
	),
  changeGridItemAvailability: (
	originalAvailability,
	newAvailability,
	pageHash,
	value,
	id,
	isCompact
  ) =>
	dispatch(
	  builderOperations.changeGridItemAvailability(
		originalAvailability,
		newAvailability,
		pageHash,
		value,
		id,
		isCompact
	  )
	),
  updateGridItem: (pageHash, value, availabilityType, id, newValues) =>
	dispatch(
	  builderOperations.updateGridItem(
		pageHash,
		value,
		availabilityType,
		id,
		newValues
	  )
	),
  createFeaturePage: (
	pageHash,
	value,
	availabilityType,
	featureData,
	houseFeatureId
  ) =>
	dispatch(
	  builderOperations.createFeaturePage(
		pageHash,
		value,
		availabilityType,
		featureData,
		houseFeatureId
	  )
	),
  deleteFeature: (pageHash, value, availabilityType, featureId) =>
	dispatch(
	  builderOperations.deleteFeature(
		pageHash,
		value,
		availabilityType,
		featureId
	  )
	),
  updateFeature: (pageHash, value, availabilityType, featureId, featureData) =>
	dispatch(
	  builderOperations.updateFeature(
		pageHash,
		value,
		availabilityType,
		featureId,
		featureData
	  )
	),
  addToast: options => dispatch(toastOperations.addToast(options))
});

HouseFeatures.propTypes = {
  addSelectedGridItem: PropTypes.func,
  addToast: PropTypes.func,
  changeGridItemAvailability: PropTypes.func,
  changeSelectedGridItemSequence: PropTypes.func,
  currentPageHash: PropTypes.number,
  errors: PropTypes.object,
  houseFeatures: PropTypes.object,
  pageKey: PropTypes.string,
  pageVisited: PropTypes.bool,
  removeSelectedGridItem: PropTypes.func,
  touched: PropTypes.object,
  updateGridItem: PropTypes.func,
  values: PropTypes.object
};

export default withRouter(connect(mapState, mapDispatch, null, { forwardRef: true })(HouseFeatures));
