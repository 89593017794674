import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BillingForm from "./BillingForm";
import SimpleNavBar from "../common/SimpleNavBar";
import _get from "lodash.get";
import { globalOperations } from "../../duck/global";
import { Helmet } from "react-helmet";

function FinishUp({ isLoggedIn, history, clientToken, generateClientToken }) {
  useEffect(() => {
    if (!_get(history.location, "state.fromRegisterAccount", false)) {
      history.push({
        pathname: "/user/my-homes"
      });
    }
    if (!clientToken) {
      generateClientToken().catch(err => {
        window.console.error(err);
      });
    }
  }, [clientToken, generateClientToken, history]);

  const [selectedPlan, setSelectedPlan] = useState(
    _get(history.location, "state.plan", null)
  );

  const changePlan = planKey => {
    setSelectedPlan(planKey);
  };
  return (
    <>
      <Helmet>
        <title>Home Concierge | Finish Up</title>
        <meta
          name="description"
          content="Finish your account registration by selecting a subscription plan."
        />
      </Helmet>
      <SimpleNavBar
        type="simple"
        next="/nextone"
        prev="/create-account"
        pageName="Finish Up"
      />
      <BillingForm selectedPlan={selectedPlan} changePlan={changePlan} />
    </>
  );
}

FinishUp.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  clientToken: PropTypes.string,
  generateClientToken: PropTypes.func.isRequired
};

const mapState = state => ({
  isLoggedIn: state.global.userAuth.isLoggedIn
});

const mapDispatch = dispatch => ({
  generateClientToken: () => dispatch(globalOperations.generateClientToken())
});

export default withRouter(connect(mapState, mapDispatch)(FinishUp));
