import React from "react";
import PropTypes from "prop-types";

import DashboardTableRow from "./DashboardTableRow";
import ListButton from "./ListButton";
import TooltipWrapper from "../common/TooltipWrapper";

import formatPhone from "../../utilities/formatPhone";
import formatDate from "../../utilities/formatDate";

function GuestListItem({
  name,
  email,
  phone,
  checkIn,
  checkOut,
  guestId,
  isPotential,
  cancelGuest,
  onGuestEdit,
  onGuestInfo
}) {
  return (
    <DashboardTableRow>
      <div
        className={`d-list-item d-list-item--status ${
          isPotential ? "potential" : "invited"
        }`}
        data-testid="d-list-item-guest"
      >
        <TooltipWrapper
          show={true}
          title={
            isPotential
              ? "This is a brochure guest - Some functionality of your home may be limited for this guest."
              : "This guest is invited to the home."
          }
        >
          <span className="status-indicator"></span>
        </TooltipWrapper>
      </div>
      <div className="d-list-item d-list-item--guestname">
        <span title={name}>{name}</span>
      </div>
      <div className="d-list-item d-list-item--guestemail">
        <span title={email}>
          <a href={`mailto:${email}`}>{email}</a>
        </span>
      </div>
      <div className="d-list-item d-list-item--guestphone">
        <span title={phone} data-testid="guest-list-item-phone">
          {phone ? (
            <a title={formatPhone(phone)} href={`tel:${phone}`}>
              {formatPhone(phone)}
            </a>
          ) : (
            <span>No Confirmed Number</span>
          )}
        </span>
      </div>
      <div className="d-list-item d-list-item--checkin">
        <span
          data-testid="guest-list-item-checkin"
          title={
            checkIn
              ? formatDate(checkIn, "MM/DD/YYYY, hh:mma")
              : "Check in time"
          }
        >
          {isPotential ? (
            <strong>Brochure Guest</strong>
          ) : checkIn ? (
            formatDate(checkIn, "MM/DD/YYYY, hh:mma")
          ) : (
            "No check-in date"
          )}
        </span>
      </div>
      <div className="d-list-item d-list-item--checkout">
        <span title={formatDate(checkOut, "MM/DD/YYYY, hh:mma")}>
          {isPotential ? (
            <strong>Brochure Guest</strong>
          ) : checkOut ? (
            formatDate(checkOut, "MM/DD/YYYY, hh:mma")
          ) : (
            "No check-out date"
          )}
        </span>
      </div>
      <div className="d-list-item d-list-item--actions">
        <ListButton
          icon={"edit"}
          onClick={e => onGuestEdit(e, guestId)}
          color="blue"
        >
          Edit
        </ListButton>
        <ListButton
          icon={"status"}
          onClick={e => onGuestInfo(e, guestId)}
          color="blue"
        >
          Status
        </ListButton>
        <ListButton
          icon={"cancel"}
          onClick={e => cancelGuest(e, guestId)}
          color="red"
        >
          Cancel
        </ListButton>
      </div>
    </DashboardTableRow>
  );
}

GuestListItem.propTypes = {
  cancelGuest: PropTypes.func.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  email: PropTypes.string.isRequired,
  guestId: PropTypes.number.isRequired,
  isPotential: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onGuestEdit: PropTypes.func.isRequired,
  onGuestInfo: PropTypes.func.isRequired,
  phone: PropTypes.string
};

export default GuestListItem;
