import React, { Component } from "react";
import BuilderPageWrapper from "../../BuilderPageWrapper";
import POIController from "../../POIController";
import POISelectedGrid from "../../POISelectedGrid";
import POIAvailableGrid from "../../POIAvailableGrid";
import { connect } from "react-redux";
import { builderOperations } from "../../duck";
import { toastOperations } from "../../../../duck/toast";

import POIViewPrompt from "../../POIViewPrompt";
import POISuggestPrompt from "../../POISuggestPrompt";
import { Helmet } from "react-helmet";
import _get from "lodash.get";
import { withSuggestionContext } from "../../../common/SuggestionProvider";

export class Spa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { setFeatureId, setFeatureName } = this.props.suggestionContext;
    const { spa, spaFeatureId } = this.props;
    setFeatureId(spaFeatureId);
    setFeatureName(spa.name);
  }
  onDragStop = (
    id,
    originalAvailability,
    newAvailability,
    originalIndex,
    newIndex
  ) => {
    const {
      changeGridItemAvailabilityAndSequence,
      changeSelectedGridItemSequences,
      currentPageHash,
      spa,
      switchGridItemAvailability
    } = this.props;

    if (newAvailability === "selected") {
      if (spa.selected.length >= 12) {
        switchGridItemAvailability(
          originalAvailability,
          newAvailability,
          currentPageHash,
          "spas",
          id,
          true,
          newIndex,
          originalIndex
        );
        return;
      }
    }

    if (originalAvailability !== newAvailability) {
      changeGridItemAvailabilityAndSequence(
        originalAvailability,
        newAvailability,
        currentPageHash,
        "spas",
        id,
        true,
        newIndex
      );
    } else {
      if (newIndex !== originalIndex) {
        changeSelectedGridItemSequences(
          parseInt(currentPageHash),
          "spas",
          originalAvailability,
          id,
          newIndex,
          true
        );
      }
    }
  };

  getItemById(id) {
    const { spa } = this.props;

    if (spa.selected.find(host => id === host.id)) {
      return spa.selected.find(host => id === host.id);
    } else if (spa.available.find(host => id === host.id)) {
      return spa.available.find(host => id === host.id);
    } else {
      return null;
    }
  }

  onView = ({ title, id, type }) => {
    const item = this.getItemById(id);
    this.setState({ viewPrompt: true, queuedData: item });
  };
  onToggleAvailability = ({ title, id, type }) => {
    const {
      changeGridItemAvailabilityAndSequence,
      currentPageHash,
      spa,
      addToast
    } = this.props;
    const newAvailability = type === "selected" ? "available" : "selected";

    if (newAvailability === "selected") {
      if (spa.selected.length >= 12) {
        addToast({
          text: "You can not have more than 12 items selected.",
          intent: "warning"
        });
        return;
      }
    }

    changeGridItemAvailabilityAndSequence(
      type,
      newAvailability,
      currentPageHash,
      "spas",
      id,
      true
    );
  };
  onSuggestPrompt = () => {
    this.setState({ suggestPrompt: true });
  };

  onSuggestSubmit = values => {
    const { addToast, createUnapprovedPOI } = this.props;
    // since there is no edit functionality on POI, no need to handle updateGridItem

    // Here needs to handle sending call to suggest POI

    createUnapprovedPOI({ ...values, featureId: 4 })
      .then(() => {
        this.setState({ suggestPrompt: false });
        addToast({
          text:
            "Thank you for sugggesting a new point of interest. We will review your submission and will be available once approved."
        });
      })
      .catch(error => {
        window.console.error(error);
        addToast({
          text: error.message,
          intent: "error"
        });
      });
  };
  render() {
    const { viewPrompt, queuedData, suggestPrompt } = this.state;
    const { propertyInfoBuilder, spaImage } = this.props;

    return (
      <>
        <Helmet>
          <title>Home Concierge | {propertyInfoBuilder.houseName} | Spa</title>
          <meta
            name="description"
            content="Choose locations for your guests to spa."
          />
        </Helmet>
        <BuilderPageWrapper pageKey={this.props.pageKey}>
          <POIController pageHash={7} value={"spas"}>
            {({ available, selected }) => (
              <>
                <POISelectedGrid
                  items={selected}
                  onDragStop={this.onDragStop}
                  onView={this.onView}
                  onRemove={this.onToggleAvailability}
                />
                <div className="grid-seperator">OR</div>
                <POIAvailableGrid
                  items={available}
                  onDragStop={this.onDragStop}
                  onView={this.onView}
                  onAdd={this.onToggleAvailability}
                />
              </>
            )}
          </POIController>
        </BuilderPageWrapper>
        {viewPrompt && (
          <POIViewPrompt
            data={queuedData}
            icon={spaImage}
            title={"Spa"}
            onClose={() => this.setState({ viewPrompt: false })}
          />
        )}
        {suggestPrompt && (
          <POISuggestPrompt
            icon={spaImage}
            title={"Spa"}
            onSubmit={this.onSuggestSubmit}
            onClose={() => this.setState({ suggestPrompt: false })}
          />
        )}
      </>
    );
  }
}

const mapState = state => ({
  currentPageHash: Object.keys(state.builder.currentBuilder).find(
    pageHash => state.builder.currentBuilder[pageHash].key === "spa"
  ),
  spa: state.builder.currentBuilder[7].values["spas"],
  spaImage: state.builder.currentBuilder[7].image,
  spaFeatureId: state.builder.currentBuilder[7].feature_id,
  propertyInfoBuilder: _get(
    state.builder.currentBuilder[1].values,
    "propertyInfo.form",
    null
  )
});

const mapDispatch = dispatch => ({
  changeSelectedGridItemSequences: (
    pageHash,
    value,
    availabilityType,
    id,
    newSequence,
    isCompact
  ) =>
    dispatch(
      builderOperations.changeSelectedGridItemSequences(
        pageHash,
        value,
        availabilityType,
        id,
        newSequence,
        isCompact
      )
    ),
  changeGridItemAvailabilityAndSequence: (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact,
    newSequence
  ) =>
    dispatch(
      builderOperations.changeGridItemAvailabilityAndSequence(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact,
        newSequence
      )
    ),
  switchGridItemAvailability: (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact,
    newSequence,
    originalSequence
  ) =>
    dispatch(
      builderOperations.updateSwitchGridItemAvailability(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact,
        newSequence,
        originalSequence
      )
    ),

  addToast: options => dispatch(toastOperations.addToast(options)),
  createUnapprovedPOI: data =>
    dispatch(builderOperations.createUnapprovedPOI(data))
});

export default withSuggestionContext(
  connect(mapState, mapDispatch, null, { forwardRef: true })(Spa)
);
