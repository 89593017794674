import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export class POIController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available: [],
      selected: []
    };
  }

  componentDidMount() {
    const { pageValues } = this.props;

    this.setGridItems(pageValues);
  }

  componentDidUpdate(prevProps) {
    const { pageValues } = this.props;

    // if the values of play dont match or categories is not yet set
    if (
      JSON.stringify(prevProps.pageValues) !==
      JSON.stringify(this.props.pageValues)
    ) {
      this.setGridItems(pageValues);
    }
  }
  setGridItems(pageValues) {
    const categoryValues = pageValues;

    if (categoryValues) {
      this.setState({
        available: categoryValues.available,
        selected: categoryValues.selected
      });
    }
  }

  handleCategoryChange = name => {
    const { onCategoryChange } = this.props;
    this.setState({ currentCategory: name }, () => {
      this.setGridItems(this.state.currentCategory);
      onCategoryChange(this.state.currentCategory);
    });
  };
  render() {
    const { children } = this.props;
    const { available, selected } = this.state;
    return (
      <div className="poi-controller">
        <div className="poi-controller__inner">
          <div className="poi-controller__content">
            {children({ available, selected })}
          </div>
        </div>
      </div>
    );
  }
}

POIController.propTypes = {
  children: PropTypes.any,
  pageHash: PropTypes.number.isRequired,
  pageValues: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired
};

const mapState = (state, ownProps) => ({
  pageValues:
    state.builder.currentBuilder[ownProps.pageHash].values[ownProps.value]
});

export default connect(mapState)(POIController);
