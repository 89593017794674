import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { globalOperations } from "../../duck/global";
import _get from "lodash.get";
import { withRouter } from "react-router-dom";
class BodyWrapper extends React.Component {
  componentDidMount() {
    const { setCurrentPage, routeName, routeKey, userHomes } = this.props;
    setCurrentPage({ routeKey, routeName });
    // if userHomes is set on mount, directly call handleHomeState.
    // otherwise, let componentDidUpdate handle setting current home name
    if (userHomes.length > 0) {
      this.handleHomeState(routeKey);
    }
  }

  handleHomeState(routeKey) {
    const { setCurrentHome, currentHome, match } = this.props;

    const { createType } = match.params;
    switch (routeKey) {
      case "homeGuestPage":
      case "homeAdminPage":
      case "editHomePage":
        // if creating new home, set current home to null
        if (createType === "new") {
          setCurrentHome(null);
        }

        // if their is a current home from userHomes array, use that house name
        if (_get(currentHome, "houseName", false)) {
          // if on create new home page, set current home as null
          setCurrentHome(currentHome.houseName);
        }
        break;
      default:
        break;
    }
  }

  componentDidUpdate(prevProps) {
    // if current home name is not set yet, set it
    if (!this.props.currentHomeName) {
      this.handleHomeState(this.props.routeKey);
    }
  }

  render() {
    const { children, routeKey } = this.props;

    return (
      <main className="content" id={routeKey}>
        <div className="content__inner">{children}</div>
      </main>
    );
  }
}
BodyWrapper.propTypes = {
  routeKey: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const mapDispatch = (dispatch, ownProps) => ({
  setCurrentPage: ({ routeKey, routeName }) =>
    dispatch(globalOperations.setCurrentPage({ routeKey, routeName })),
  setCurrentHome: (homeName) =>
    dispatch(
      globalOperations.setCurrentHome(
        homeName,
        parseInt(ownProps.match.params.homeId)
      )
    ),
});

const mapState = (state, ownProps) => ({
  currentHome:
    state.dashboard.userHomes && ownProps.match
      ? state.dashboard.userHomes.find((home) => {
          return parseInt(home.id) === parseInt(ownProps.match.params.homeId);
        })
      : null,
  currentHomeName: state.global.current.home.name,
  userHomes: state.dashboard.userHomes,
});

export default withRouter(connect(mapState, mapDispatch)(BodyWrapper));
