import React from "react";

const footerLinks = [
  {
    index: 1,
    title: "Privacy Policy",
    link: "https://www.homeconcierge.com/builderterms"
  },
  {
    index: 2,
    title: "Terms of Use",
    link: "https://www.homeconcierge.com/builderterms"
  },
  {
    index: 3,
    title: "Pricing",
    link: "https://homeconcierge.com/request-a-quote.html"
  },
  {
    index: 4,
    title: "Schedule a Demo",
    link: "https://calendly.com/homeconcierge"
  },
  {
    index: 5,
    title: "Contact Us",
    link: "mailto:help@homeconcierge.com"
  }
];

function AppFooter() {
  return (
    <footer className="app-footer container">
      <div className="app-footer__inner container__inner">
        <div className="app-footer__nav">
          <ul className="app-footer-list">
            {footerLinks
              .sort((a, b) => Number(a.index) - parseFloat(b.index))
              .map((footerLink, i) => (
                <li className="app-footer-list__item" key={i}>
                  <a
                    href={footerLink.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {footerLink.title}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="app-footer__text">
          <small>Copyright© 2023 Home Concierge Inc. All rights reserved</small>
        </div>
      </div>
    </footer>
  );
}

AppFooter.propTypes = {};

export default AppFooter;
