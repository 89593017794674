import React from "react";
import PropTypes from "prop-types";
import PanoIcon from "../../assets/icons/360pano.svg";

import GridHeader from "./GridHeader";
import GridLayout from "./GridLayout";
import GridItem from "./GridItem";
import BlankGridItem from "./BlankGridItem";

const uuidv4 = require("uuid/v4");

const FeatureGridItem = props => {
  if (!props.id || !props.categoryKey) {
    return <BlankGridItem banner={props.banner} />;
  }

  return (
    <GridItem
      {...props}
      photoData={props.optionIcon ? props.optionIcon.image : null}
      overlayItems={{
        selected: ["edit", "delete"]
      }}
      onDragStop={props.onDragStop}
    />
  );
};

FeatureGridItem.propTypes = {
  bounds: PropTypes.string,
  categoryKey: PropTypes.string,
  columns: PropTypes.number,
  currentPageHash: PropTypes.number.isRequired,
  gridIndex: PropTypes.number.isRequired,
  id: PropTypes.number,
  onDelete: PropTypes.func,
  onDragStop: PropTypes.func,
  onEdit: PropTypes.func,
  sequence: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string,
  optionIcon: PropTypes.shape({
    image: PropTypes.string,
    key: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string
  })
};

function FeatureSelectedGrid({
  parentCategory,
  items,
  options = [],
  onDragStop,
  onEdit,
  onDelete,
  title = "Use Our Pre-selected favorites"
}) {

  const gridIcon = (item) => {
	if (! item.embedUrl) {
	  return null;
	}
	return PanoIcon;
  };

  return (
    <div
      className="feature-grid-items feathre-grid-items--selected"
      data-testid="feature-selected-grid"
    >
      <GridHeader color="blue">{title}</GridHeader>
	  {parentCategory && parentCategory.embedUrl && (
		<div style={{marginTop: '10px'}}>
			<iframe src={parentCategory.embedUrl + '?builder=1'} width="100%" height="300" frameBorder="0" scrolling="no" />
		</div>
	  )}
      <GridLayout rows={3} compact={true} columns={4} items={items}>
        {({ gridArray }) => (
          <div className="feature-grid-items__grid">
            {gridArray.map((item, i) => {
              return (
                <FeatureGridItem
                  key={item.id ? item.id : uuidv4()}
                  id={item.id}
                  categoryKey={item.key}
                  title={item.name}
                  gridIndex={i}
                  sequence={item.sequence}
                  type="selected"
                  currentPageHash={6}
                  onDragStop={onDragStop}
                  columns={4}
                  optionIcon={options.find(({ key }) => key === item.key)}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  bounds={".grid-layout"}
				  icon={gridIcon(item)}
                />
              );
            })}
          </div>
        )}
      </GridLayout>
    </div>
  );
}

FeatureSelectedGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      key: PropTypes.string,
      name: PropTypes.string
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sequence_no: PropTypes.number.isRequired
    })
  ),
  onDragStop: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default FeatureSelectedGrid;
