import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import PhoneInput from "react-phone-number-input/input";
import _get from "lodash.get";
import { connect } from "react-redux";
import { toastOperations } from "../../duck/toast";
const formatPhone = value => {
  // Hyphenate phone value
  // const re = new RegExp("-", "g");

  // const cleanedValue = value.replace(re, "");
  if (value.substr(value.length - 1, value.length) === "-") {
    return value.substr(0, value.length - 1);
  }

  var USNumber = value.match(/(\d{3})(\d{3})(\d{4})/);
  if (USNumber) {
    return "(" + USNumber[1] + ") " + USNumber[2] + "-" + USNumber[3];
  } else {
    return value;
  }
};

function InputText({
  name,
  type = "text",
  size = "full",
  placeholder,
  value,
  onChange,
  onBlur,
  className,
  disabled,
  displayError = false,
  maxLength = null,
  setFieldValue,
  setFieldTouched,
  required = false,
  autoComplete = "new-password",
  addToast
}) {
  const clickRef = useRef(null);
  const inputRef = useRef(null);

  const [isGeocoding, setGeocoding] = useState(false);
  const [autocompleteTriggered, setAutocompleteTriggered] = useState(false);
  const setupGoogleAutocomplete = useCallback(
    name => {
      const options = { types: ["geocode", "establishment"] };

      const inputWrapperEl = document.querySelector(`.form-input.${name}`);

      const inputEl = inputWrapperEl.querySelector("input");

      const geocodeAddress = (geocoder, value) => {
        return new Promise((resolve, reject) => {
          geocoder.geocode({ address: value }, (results, status) => {
            if (
              status === window.google.maps.GeocoderStatus.OK &&
              results.length > 0
            ) {
              resolve(results);
              return;
            } else {
              reject(status);
            }
          });
        });
      };

      if (inputEl) {
        var formattedAddress;

        window.google.autocomplete = new window.google.maps.places.Autocomplete(
          inputEl,
          options
        );
        inputEl.placeholder = "";
        const geocoder = new window.google.maps.Geocoder();

        // disable form submit on enter of input when selecting
        window.google.maps.event.addDomListener(
          inputEl,
          "keydown",
          function (event) {
            if (event.keyCode === 13) {
              event.preventDefault();
            }
          }
        );

        window.google.autocomplete.addListener("place_changed", evt => {
          setAutocompleteTriggered(true);
          const addressObject = window.google.autocomplete.getPlace();
          if (!addressObject) {
            setFieldValue(`${name}.fullAddress`, "");
            return;
          }

          if (!addressObject.geometry) {
            setFieldValue(`${name}.fullAddress`, "");
            return;
          }

          formattedAddress = addressObject.formatted_address;
          const coords = {
            lat: addressObject.geometry.location.lat(),
            lng: addressObject.geometry.location.lng()
          };

          setFieldValue(`${name}.fullAddress`, formattedAddress);
          setFieldValue(`${name}.lat`, coords.lat);
          setFieldValue(`${name}.lng`, coords.lng);
        });

        inputEl.addEventListener("change", evt => {
          setAutocompleteTriggered(false);
        });

        inputEl.addEventListener("blur", () => {
          if (autocompleteTriggered === true) {
            return;
          }
          setGeocoding(true);
          geocodeAddress(geocoder, inputEl.value)
            .then(results => {
              setGeocoding(false);

              const result = results[0];

              const addressComponents = result.address_components.reduce(
                (acc, curr) => {
                  const types = curr.types;

                  if (!types) return acc;
                  if (types.includes("locality")) {
                    acc.city = curr.long_name;
                  } else if (types.includes("route")) {
                    acc.street_name = curr.long_name;
                  } else if (types.includes("street_number")) {
                    acc.street_number = curr.long_name;
                  } else if (types.includes("subpremise")) {
                    acc.subpremise = curr.long_name;
                  } else if (types.includes("administrative_area_level_1")) {
                    acc.state = curr.long_name;
                  } else if (types.includes("postal_code")) {
                    acc.zip = curr.long_name;
                  } else if (types.includes("country")) {
                    acc.country = curr.long_name;
                  }
                  return acc;
                },
                {}
              );

              const city = addressComponents.city;
              const street = `${_get(
                addressComponents,
                "street_number",
                ""
              )} ${_get(addressComponents, "street_name", "")} ${_get(
                addressComponents,
                "subpremise",
                ""
              )}`;

              const state = _get(addressComponents, "state", "");

              const zip = _get(addressComponents, "zip", "");
              const country = _get(addressComponents, "country", "");

              setFieldValue(`${name}.street`, street);
              setFieldValue(`${name}.city`, city);
              setFieldValue(`${name}.state`, state);
              setFieldValue(`${name}.zip`, zip);
              setFieldValue(`${name}.country`, country);
              setFieldValue(`${name}.lat`, results[0].geometry.location.lat());
              setFieldValue(`${name}.lng`, results[0].geometry.location.lat());
            })
            .catch(err => {
              window.console.error(err);
              addToast({
                text:
                  "The address you have entered does not appear to be a valid address.",
                intent: "warning"
              });

              setGeocoding(false);
              setFieldValue(`${name}.fullAddress`, "");
              setFieldValue(`${name}.street`, null);
              setFieldValue(`${name}.city`, null);
              setFieldValue(`${name}.state`, null);
              setFieldValue(`${name}.zip`, null);
              setFieldValue(`${name}.country`, null);
              setFieldValue(`${name}.lat`, null);
              setFieldValue(`${name}.lng`, null);
            });
        });
      }
    },
    [addToast, autocompleteTriggered, setFieldValue]
  );

  const [isFocused, setFocus] = useState(false);

  const onFocus = () => {
    if (!isFocused) {
      setFocus(true);
    }
  };

  const handleBlur = evt => {
    if (isFocused) {
      setFocus(false);
    }
    if (onBlur) {
      onBlur(evt);
    }
  };

  // handle setting up google autocomplete
  useEffect(() => {
    if (type === "address") {
      setupGoogleAutocomplete(name);
    }
  }, [name, setupGoogleAutocomplete, type]);

  // handle focus on click of input
  useEffect(() => {
    const _clickRef = clickRef.current;

    const focusOnClick = evt => {
      evt.preventDefault();
      if (isFocused) return;
      inputRef.current.focus();
    };

    if (!clickRef.current || !inputRef.current) return;
    clickRef.current.addEventListener("click", focusOnClick);

    return () => {
      _clickRef.removeEventListener("click", focusOnClick);
    };
  }, [isFocused, name, setupGoogleAutocomplete, type]);

  if (type === "address") {
    return (
      <div
        className={`form-input ${name} ${
          isFocused || value["fullAddress"] !== "" ? "focused" : ""
        } ${className ? className : ""} ${size ? `form-input--${size}` : ""} ${
          displayError ? `error` : ""
        } ${disabled ? "form-input--disabled" : ""}`}
        ref={clickRef}
      >
        <label className="form-input__label" htmlFor={name}>
          {displayError && isGeocoding === false
            ? String(displayError)
            : required
            ? `${placeholder}*`
            : placeholder}
        </label>
        <input
          className={"form-input__input"}
          id={name}
          name={name}
          type={"text"}
          value={value["fullAddress"]}
          onChange={evt => {
            setFieldValue(`${name}.fullAddress`, evt.target.value);
          }}
          onBlur={handleBlur}
          onFocus={onFocus}
          maxLength={type === "tel" ? 14 : maxLength}
          disabled={disabled}
          autoComplete={autoComplete}
          data-testid="input-text-address"
          ref={inputRef}
        />
        <CSSTransition
          in={isGeocoding}
          key={name}
          timeout={1500}
          classNames={"fade"}
        >
          <div className="form-input__spinner" />
        </CSSTransition>
      </div>
    );
  }

  return (
    <div
      className={`form-input ${name} ${
        isFocused || (value !== "" && value !== null && value !== undefined)
          ? "focused"
          : ""
      } ${className ? className : ""} ${size ? `form-input--${size}` : ""} ${
        displayError ? `error` : ""
      } ${disabled ? "form-input--disabled" : ""}`}
      ref={clickRef}
    >
      <label className="form-input__label" htmlFor={name}>
        {displayError
          ? displayError
          : required
          ? `${placeholder}*`
          : placeholder}
      </label>
      {type === "textarea" ? (
        <textarea
          className={"form-input__input"}
          id={name}
          name={name}
          type={type}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          disabled={disabled}
          data-testid="input-text-textarea"
          ref={inputRef}
        />
      ) : type === "tel" ? (
        <PhoneInput
          id={name}
          name={name}
          country="US"
          placeholder="Enter phone number"
          value={value || ""}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          data-testid="input-text-phone"
          ref={inputRef}
        />
      ) : (
        <>
          <input
            className={"form-input__input"}
            id={name}
            name={name}
            type={type === "address" ? "text" : type}
            value={type === "tel" ? formatPhone(value || "") : value || ""}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={onFocus}
            autoComplete={autoComplete}
            maxLength={type === "tel" ? 14 : maxLength}
            disabled={disabled}
            data-testid="input-text"
            ref={inputRef}
          />
        </>
      )}
    </div>
  );
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string
};

const mapDispatch = dispatch => ({
  addToast: options => dispatch(toastOperations.addToast(options))
});

export default connect(null, mapDispatch)(InputText);
