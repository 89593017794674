import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function General404({ isLoggedIn }) {
  return (
    <div className="page-not-found">
      <div className="page-not-found__inner">
        <h1>Whoops!</h1>
        <p>We weren't able to find the page you were looking for.</p>

        {isLoggedIn ? (
          <p>
            Click <Link to="/user/my-homes">here</Link> to go back to your
            dashboard.
          </p>
        ) : (
          <p>
            Click <Link to="/login">here</Link> to log in to your account.
          </p>
        )}
      </div>
    </div>
  );
}

const mapState = state => ({
  isLoggedIn: state.global.userAuth.isLoggedIn
});

export default connect(mapState)(General404);
