import React, { Component } from "react";
import PropTypes from "prop-types";

import Slider from "@material-ui/core/Slider";
import PhotoCanvasFab from "hc-common/PhotoCanvasFab";

export class PhotoCanvasActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: 50
    };
  }

  handleSliderChange = (event, value) => {
    this.props.onChange(value);
    this.setState({ sliderValue: value });
  };
  render() {
    const {
      image,
      onAddCircle,
      onEditCircle,
      onEditImage,
      onRemoveCircle,
      onRemoveImage,
      currentStep,
      loading,
      isLocked
    } = this.props;

    return (
      <div className="photo-canvas-actions">
        <div className="photo-canvas-actions__inner">
          <div className="photo-canvas-actions__left">
            <PhotoCanvasFab
              color="gray"
              icon={"circle"}
              type={
                !loading && image ? (currentStep >= 1 ? "edit" : "add") : "add"
              }
              aria-label="Add"
              onClick={
                !loading && image
                  ? currentStep >= 1
                    ? onEditCircle
                    : onAddCircle
                  : onAddCircle
              }
              disabled={isLocked.arc || currentStep < 1 ? false : true}
            >
              {`${
                !loading && image ? (currentStep >= 1 ? "Edit" : "Add") : "Add"
              } Circle`}
            </PhotoCanvasFab>
            <PhotoCanvasFab
              color="gray"
              icon={"circle"}
              type="remove"
              onClick={onRemoveCircle}
              disabled={image ? (currentStep < 1 ? true : false) : true}
            >
              Remove circle
            </PhotoCanvasFab>
          </div>
          <div className="photo-canvas-actions__center">
            <p>
              {currentStep === 1 && this.props.isLocked.arc === false
                ? "Circle Size: "
                : "Zoom: "}
              {!loading && image
                ? `${Math.round((image.scale * 100) / 4)}%`
                : "loading..."}
            </p>
            <Slider
              min={5}
              value={!loading && image ? (image.scale * 100) / 4 : 0}
              onChange={this.handleSliderChange}
            />
          </div>
          <div className="photo-canvas-actions__right">
            <PhotoCanvasFab
              color="gray"
              icon={"image"}
              type="add"
              onClick={onEditImage}
              disabled={isLocked.background ? false : true}
            >
              Edit Image
            </PhotoCanvasFab>
            <PhotoCanvasFab
              color="gray"
              icon={"image"}
              type="remove"
              onClick={onRemoveImage}
              disabled={image ? false : true}
            >
              Remove Image
            </PhotoCanvasFab>
          </div>
        </div>
      </div>
    );
  }
}

PhotoCanvasActions.propTypes = {
  currentStep: PropTypes.number,
  image: PropTypes.object,
  isLocked: PropTypes.objectOf(PropTypes.bool),
  item: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string,
        url: PropTypes.string
      })
    ),
    coords: PropTypes.shape({
      circle: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number
      }),
      photo: PropTypes.shape({
        image: PropTypes.arrayOf(
          PropTypes.shape({
            size: PropTypes.string,
            url: PropTypes.string
          })
        ),
        height: PropTypes.number,
        width: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number
      })
    })
  }),
  loading: PropTypes.bool,
  onAddCircle: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditCircle: PropTypes.func.isRequired,
  onEditImage: PropTypes.func.isRequired,
  onRemoveCircle: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired
};

export default PhotoCanvasActions;
