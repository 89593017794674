import React from "react";
import CreateAccount from "../app/preauth/CreateAccount";
import LogIn from "../app/preauth/LogIn";
import FinishUp from "../app/preauth/FinishUp";
import HomeDashboard from "../app/dashboard/HomeDashboard";
import HomeGuests from "../app/dashboard/HomeGuests";

import Builder from "../app/builder/Builder";
import MyAccount from "../app/my-account/MyAccount";
import ResetPassword from "../app/preauth/ResetPassword";
import ForgotPassword from "../app/preauth/ForgotPassword";

import { Redirect } from "react-router-dom";
import CreateHostAccount from "../app/preauth/CreateHostAccount";

const Placeholder = ({ routeKey }) => <Redirect to="/login" />;

const portalRoutes = [
  {
    key: "home",
    name: "Home",
    path: "/",
    isProtected: false,
    Component: props => <Placeholder {...props} />
  },
  {
    key: "pricingPlan",
    name: "Pricing Plan",
    path: "/pricing",
    isProtected: false,
    Component: props => <Placeholder {...props} />
  },
  {
    name: "Create Your Account",
    key: "createYourAccount",
    path: "/create-account",
    isProtected: false,
    Component: props => <CreateAccount {...props} />
  },
  {
    name: "Create Host Account",
    key: "createyourHostAccountInvite",
    path: "/create-host",
    isProtected: false,
    Component: props => <CreateHostAccount {...props} />
  },
  {
    name: "Log In",
    key: "logIn",
    path: "/login/:email?/:uid?",
    isProtected: false,
    Component: props => <LogIn {...props} />
  },
  {
    name: "Finish Up",
    key: "finishUp",
    path: "/finish-up",
    isProtected: true,
    Component: props => <FinishUp {...props} />
  },
  {
    name: "Home Dashboard",
    key: "homeDashboard",
    path: "/user/my-homes",
    isProtected: true,
    Component: props => <HomeDashboard {...props} />
  },
  {
    name: "Reset Password",
    key: "resetPassword",
    path: "/reset-password",
    isProtected: false,
    Component: props => <ResetPassword {...props} />
  },
  {
    name: "My Account",
    key: "myAccount",
    path: "/user/my-account",
    isProtected: true,
    Component: props => <MyAccount {...props} />
  },
  {
    name: "Edit Home Page",
    key: "editHomePage",
    path:
      "/home/:createType(new|edit)/:homeId?/:builderPage?",
    isProtected: true,
    Component: props => (
      <Builder
        isNew={props.match.params.createType === "new" ? true : false}
        {...props}
      />
    )
  },
  {
    name: "Forgot Password",
    key: "forgotPassword",
    path: "/forgot-password",
    isProtected: false,
    Component: props => <ForgotPassword {...props} />
  },
  {
    name: "Edit Home Page Complete",
    key: "editHomePageComplete",
    path: "/home/edit/:homeId/complete",
    isProtected: true,
    Component: props => <Placeholder {...props} />
  },
  {
    name: "View Home Guest Page",
    key: "homeGuestPage",
    path: "/home/guests/:homeId",
    isProtected: true,
    Component: props => <HomeGuests {...props} />
  }
];

export default portalRoutes;
