var uuidv4 = require("uuid/v4");

function blobToFile(blob, fileName, fileType) {
  return new File([blob], fileName, {
    lastModified: new Date(),
    type: fileType
  });
}

// set the background color of the canvas
function setTransparencyColor(canvas, transparencyColor) {
  const context = canvas.getContext("2d");
  context.globalCompositeOperation = "destination-over";
  context.fillStyle = transparencyColor;
  context.fillRect(0, 0, canvas.width, canvas.height);
}

export default function canvasToFile(
  canvas,
  type,
  quality = 1,
  transparencyColor = "#ffffff"
) {
  setTransparencyColor(canvas, transparencyColor);

  const dataURI = canvas.toDataURL(type, quality);

  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const canvasBlob = new Blob([ia], { type: mimeString });
  return blobToFile(canvasBlob, uuidv4(), type);
}
