import React, { useState } from "react";
import PropTypes from "prop-types";

import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import canvasToFile from "../../utilities/canvasToFile";
function ImageCropper({ image, onChange, onInit }) {
  const [crop, setCrop] = useState(null);
  const [clientImage, setClientImage] = useState(null);

  // fired whenever the crop coordinates change, updates crop state
  const handleCropChange = newCrop => {
    setCrop(newCrop);
  };

  // generates a cropped image and returns both a object URL and a file object
  // used to generate both a file object and local URL for the cropped image
  const getCroppedImage = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("canvas is empty");
          return;
        }
        blob.name = fileName;
        const fileURL = window.URL.createObjectURL(blob);
        const file = canvasToFile(canvas, "image/png", 1);
        resolve([fileURL, file]);
      }, "image/png");
    });
  };

  // helper for cropping an image and returning url and file
  // used in both onComplete and when the image is loaded and initial crop is set
  const makeClientCrop = async (crop, image) => {
    if (image && crop.width && crop.height) {
      try {
        const [croppedImageURL, croppedImageFile] = await getCroppedImage(
          image,
          crop,
          "newFile.jpg"
        );

        return {
          croppedImageURL,
          croppedImageFile
        };
      } catch (error) {
        throw error;
      }
    }
  };

  // fired when a crop change is completed - fires onChange
  const handleCropComplete = crop => {
    makeClientCrop(crop, clientImage).then(crop => {
      if (!crop) return;
      const { croppedImageFile, croppedImageURL } = crop;
      onChange(croppedImageFile, croppedImageURL);
    });
  };

  // fired when the image in ReactCrop is loaded
  // Sets the client image as the image object passed
  // sets initial crop to be centered.
  // fires makeClientCrop, generates file + url, and fires onInit callback
  const handleImageLoaded = image => {
    setClientImage(image);

    const imageAspectRatio = image.width / image.height;
    const targetAspectRatio = 1.34677;

    const width =
      targetAspectRatio > imageAspectRatio
        ? image.width
        : image.height * targetAspectRatio;

    const height =
      targetAspectRatio > imageAspectRatio
        ? image.width / targetAspectRatio
        : image.height;

    const x = (image.width - width) / 2;
    const y = (image.height - height) / 2;

    const initialCrop = {
      aspect: targetAspectRatio,
      unit: "px",
      width,
      height,
      x,
      y
    };
    setCrop(initialCrop);

    makeClientCrop(initialCrop, image).then(
      ({ croppedImageFile, croppedImageURL }) => {
        onInit(croppedImageFile, croppedImageURL);
      }
    );

    return false; // Return false if you set crop state in here.
  };

  return (
    <div className="image-cropper">
      {image && (
        <ReactCrop
          className="testme123"
          src={image}
          crop={crop}
          onImageLoaded={handleImageLoaded}
          onChange={handleCropChange}
          onComplete={handleCropComplete}
          crossorigin="anonymous"
        ></ReactCrop>
      )}
    </div>
  );
}

ImageCropper.propTypes = {
  image: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onInit: PropTypes.func
};

export default ImageCropper;
