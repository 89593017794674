import React, { useContext } from "react";
import ButtonMain from "./ButtonMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { SuggestionContext } from "./SuggestionProvider";
import pluralize from "pluralize";

function SuggestionButton() {
  const { showSuggestionPrompt, featureName } = useContext(SuggestionContext);
  const handleClick = () => {
    showSuggestionPrompt();
  };
  return (
    <ButtonMain onClick={handleClick} color="ghost-gray">
      <FontAwesomeIcon icon={faPlus} /> Suggest a{" "}
      {featureName ? pluralize.singular(featureName) : "..."}
    </ButtonMain>
  );
}

SuggestionButton.propTypes = {};

export default SuggestionButton;
