import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function ButtonLoader() {
  return (
    <div className="button-loader">
      <div className="button-loader__spinner" />
    </div>
  );
}

function ButtonMain({
  link,
  color = "aqua",
  type = "link",
  isDisabled = false,
  onClick,
  children,
  loading = false,
  className = "",
  outbound = false
}) {
  if (onClick) {
    return (
      <button
        className={`btn-main ${className} ${color} ${
          isDisabled ? "btn-main--disabled" : ""
        }`}
        type={"button"}
        disabled={isDisabled || loading}
        onClick={e =>
          loading || isDisabled ? e.preventDefault() : onClick(e, link)
        }
        tabIndex={0}
      >
        {!loading ? children : <ButtonLoader />}
      </button>
    );
  }

  if (type === "link") {
    if (outbound === false) {
      return (
        <Link
          className={`btn-main ${className} ${color} ${
            isDisabled ? "btn-main--disabled" : ""
          }`}
          to={link}
          disabled={isDisabled}
          onClick={e => (loading || isDisabled ? e.preventDefault() : null)}
        >
          {!loading ? children : <ButtonLoader />}
        </Link>
      );
    } else {
      return (
        <a
          className={`btn-main ${className} ${color} ${
            isDisabled ? "btn-main--disabled" : ""
          }`}
          href={link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {!loading ? children : <ButtonLoader />}
        </a>
      );
    }
  }

  if (type === "submit") {
    return (
      <button
        className={`btn-main ${className} ${color} ${
          isDisabled ? "btn-main--disabled" : ""
        }`}
        type="submit"
        disabled={loading || isDisabled}
        tabIndex={0}
        onClick={e => (onClick ? (loading ? e.preventDefault() : null) : null)}
      >
        {!loading ? children : <ButtonLoader />}
      </button>
    );
  }

  if (type === "external") {
    return (
      <a
        className={`btn-main ${className} external ${color} ${
          isDisabled ? "btn-main--disabled" : ""
        }`}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => (loading ? e.preventDefault() : null)}
      >
        {!loading ? children : <ButtonLoader />}
      </a>
    );
  }
  return (
    <button
      className={`btn-main ${className} ${color} ${
        isDisabled ? "btn-main--disabled" : ""
      }`}
      type={type}
      disabled={loading || isDisabled}
      tabIndex={0}
      onClick={e => (loading ? e.preventDefault() : null)}
    >
      {!loading ? children : <ButtonLoader />}
    </button>
  );
}

ButtonMain.propTypes = {
  link: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  state: PropTypes.object,
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ButtonMain;
