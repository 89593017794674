import React, { useState } from "react";
import PropTypes from "prop-types";
import SimpleNavBar from "../common/SimpleNavBar";
import { Formik } from "formik";
import InputText from "../common/InputText";
import { Yup } from "hc-utils/FormValidator";
import ButtonMain from "../common/ButtonMain";
import { globalOperations } from "../../duck/global";
import _get from "lodash.get";
import { connect } from "react-redux";
import { toastOperations } from "../../duck/toast";

import { Helmet } from "react-helmet";

function SuccessMessage({ email }) {
  return (
    <div className="account-text forgot-success-message">
      <h4>
        Thank you. An email has been sent to {email} to reset your Home
        Concierge password.
      </h4>
      <p>
        If you do not receive an email, please contact{" "}
        <a href="https://homeconceirge.com">help@homeconcierge.com</a>
      </p>
    </div>
  );
}
function ForgotPasswordForm({ onSubmit, isLoading }) {
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email address.")
          .required("Email is required.")
      })}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors
      }) => (
        <form
          onSubmit={handleSubmit}
          className="user-form user-form--forgotpass"
          name="forgot-password-form"
        >
          <p>
            Please enter your email associated with your Home Concierge account
            below.
          </p>
          <p>
            You will be sent an email to continue with resetting your password.
          </p>
          <InputText
            name="email"
            type="email"
            size="full"
            placeholder="Enter Email"
            autoComplete="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            displayError={touched.email && errors.email ? errors.email : false}
          />
          <ButtonMain color="aqua" type="submit" loading={isLoading}>
            Reset Password
          </ButtonMain>
        </form>
      )}
    </Formik>
  );
}

function ForgotPassword({
  sendForgotPassword,
  sendForgotPasswordLoading,
  addToast
}) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [sentEmailAddr, setSentEmailAddr] = useState(null);
  const handleForgotSubmit = ({ email }) => {
    sendForgotPassword(email)
      .then(() => {
        setSentEmailAddr(email);
        setShowSuccessMessage(true);
      })
      .catch(() => {
        addToast({
          text:
            "There were missing fields that are required. Please make sure you have filled out your email above.",
          intent: "error"
        });
      });
  };
  return (
    <>
      <Helmet>
        <title>Home Concierge | Forgot Password</title>
        <meta
          name="description"
          content="Forgot your password? Enter your email to reset your password."
        />
      </Helmet>
      <div className="forgot-password">
        <div className="forgot-password__inner">
          <SimpleNavBar
            prev="/"
            prevText="Back to Home"
            type="simple"
            pageName="Forgot Password"
          />

          {showSuccessMessage === false ? (
            <ForgotPasswordForm
              isLoading={sendForgotPasswordLoading}
              onSubmit={handleForgotSubmit}
            />
          ) : (
            <SuccessMessage email={sentEmailAddr} />
          )}
        </div>
      </div>
    </>
  );
}

const mapDispatch = dispatch => ({
  sendForgotPassword: email =>
    dispatch(globalOperations.sendForgotPassword(email)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

const mapState = state => ({
  sendForgotPasswordLoading: _get(
    state.loading,
    "sendForgotPassword.loading",
    false
  )
});

ForgotPassword.propTypes = {
  sendForgotPassword: PropTypes.func.isRequired,
  sendForgotPasswordLoading: PropTypes.bool.isRequired,
  addToast: PropTypes.func.isRequired
};

export default connect(mapState, mapDispatch)(ForgotPassword);
