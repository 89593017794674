import React from "react";
import PropTypes from "prop-types";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import icons from "../../assets/icons";
import Image from "./Image";

function BuilderButton({
  icon,
  name,
  valueKey,
  onClick,
  isActive,
  isEmpty,
  disabled,
  children,
}) {
  const computeButtonIcon = ({ type, data }) => {
    if (type === "image") {
      return data;
    }
    if (type === "svg") {
      return icons[data];
    }
  };

  return (
    <div
      className={`builder-button ${
        disabled ? "builder-button--disabled" : ""
      } ${isActive ? "builder-button--active" : ""}`}
    >
      <div className="builder-button__inner">
        <button
          onClick={() => onClick(name, valueKey, icon)}
          className="builder-button__btn"
        >
          {icon && <Image src={computeButtonIcon(icon)} alt="builder button" />}
          {isEmpty !== undefined && (
            <div
              className={`builder-button__indicator builder-button__indicator--${
                isEmpty ? "empty" : "filled"
              }`}
            >
              {isEmpty === false ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faQuestion} />
              )}
            </div>
          )}
        </button>
        {children && (
          <div className="builder-button__title">
            <h4>{children}</h4>
          </div>
        )}
      </div>
    </div>
  );
}

BuilderButton.propTypes = {
  icon: PropTypes.shape({ type: PropTypes.string, data: PropTypes.string }),
  name: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default BuilderButton;
