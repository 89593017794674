import React from "react";
import PropTypes from "prop-types";

import _get from "lodash.get";

function FileUpload({ onUpload }) {
  const handleUpload = evt => {
    const files = _get(evt, "target.files", null);
    if (!onUpload) return;
    onUpload(files);
  };
  return (
    <div className="file-upload">
      <div className="file-upload__inner">
        <div className="file-upload__text">
          <p>Drop photo here to upload</p>
          <p>OR</p>
        </div>
        <div className="file-upload__input">
          <label htmlFor="file-upload-input">Select File</label>
          <input
            type="file"
            id="file-upload-input"
            onChange={handleUpload}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </div>
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  onUpload: PropTypes.func
};

export default FileUpload;
