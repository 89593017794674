import React, { useState } from "react";
import PropTypes from "prop-types";

import PromptWrapper from "../common/PromptWrapper";
import { connect } from "react-redux";
import { dashboardOperations } from "./duck";
import GuestFormPotential from "./GuestFormPotential";
import GuestFormActive from "./GuestFormActive";
import { withRouter } from "react-router-dom";
import GuestFormTypeSelector from "./GuestFormTypeSelector";
import clsx from "clsx";

function GuestNewPrompt({ onClose, data, match, onSubmit }) {
  const [guestType, setGuestType] = useState(
    data ? (data.is_prospective ? "potential" : "active") : null
  );

  const [isUpgraded, setIsUpgraded] = useState(false);

  const [formPage, setFormPage] = useState(1);

  const handlePageChange = newFormPage => setFormPage(newFormPage);

  const handlePotentialUpgrade = () => {
    setGuestType("active");
    setIsUpgraded(true);
  };

  return (
    <PromptWrapper
      closeOnOutsideClick={false}
      className={clsx("guest-new-prompt")}
      onClose={onClose}
    >
      <div className="guest-new-prompt__header">
        <h2 data-testid="guest-new-prompt-header-text">
          {data
            ? `Update ${guestType === "potential" ? "Brochure " : ""}Guest`
            : formPage === 1
            ? `Add New ${guestType === "potential" ? "Brochure " : ""}Guest`
            : `Note to ${
                guestType === "potential" ? "Brochure" : "Confirmed"
              } Guest`}
        </h2>

        {!guestType && <p>Choose the type of guest to invite to your home</p>}
      </div>
      {!guestType && <GuestFormTypeSelector onSelect={setGuestType} />}
      {guestType === "potential" && (
        <GuestFormPotential
          onPageChange={handlePageChange}
          onUpgrade={handlePotentialUpgrade}
          data={data}
          homeId={parseInt(match.params.homeId)}
          onSubmit={onSubmit}
        />
      )}
      {guestType === "active" && (
        <GuestFormActive
          data={data}
          onSubmit={onSubmit}
          onPageChange={handlePageChange}
          isUpgraded={isUpgraded}
        />
      )}
    </PromptWrapper>
  );
}

const mapDispatch = dispatch => ({
  getHosts: houseId => dispatch(dashboardOperations.getHosts(houseId))
});

GuestNewPrompt.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  match: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

export default withRouter(connect(null, mapDispatch)(GuestNewPrompt));
