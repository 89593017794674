import React from "react";
import PropTypes from "prop-types";
import PromptWrapper from "../common/PromptWrapper";

import BuilderErrorList from "./BuilderErrorList";

import { connect } from "react-redux";
import ButtonMain from "../common/ButtonMain";

function BuilderErrorPrompt({
  errors,
  onClose,
  onFinish,
  currentBuilderId,
  currentBuilder
}) {
  const getLinkByPageHash = pageHash => {
    const pageKey =
      currentBuilder[
        Object.keys(currentBuilder).find(
          hash => parseInt(hash) === parseInt(pageHash)
        )
      ].key;

    return `/home/edit/${currentBuilderId}/${pageKey}`;
  };
  const getNameByPageHash = pageHash => {
    const pageName =
      currentBuilder[
        Object.keys(currentBuilder).find(
          hash => parseInt(hash) === parseInt(pageHash)
        )
      ].name;

    return pageName;
  };

  const getKeyByPageHash = pageHash => {
    return currentBuilder[
      Object.keys(currentBuilder).find(
        hash => parseInt(hash) === parseInt(pageHash)
      )
    ].key;
  };
  const errorsFormatted = Object.keys(errors).map(pageHash => {
    const pageErrors = errors[pageHash];

    return {
      link: getLinkByPageHash(pageHash),
      key: getKeyByPageHash(pageHash),
      name: getNameByPageHash(pageHash),
      errors: { ...pageErrors }
    };
  });

  return (
    <PromptWrapper onClose={onClose} className="builder-error-prompt-wrapper">
      <div className="builder-error-prompt">
        <div className="builder-error-prompt__text">
          <h2>Your home is not completed!</h2>
          <p>
            You must have all required fields to be able to invite guests to
            your home.
          </p>
        </div>
        <div className="builder-error-prompt__list">
          <BuilderErrorList errors={errorsFormatted} onClose={onClose} />
        </div>
        <div className="builder-error-prompt__cta">
          <ButtonMain color={"gray"} onClick={onClose}>
            Go Back
          </ButtonMain>
          <ButtonMain color={"blue"} onClick={onFinish}>
            Finish Later
          </ButtonMain>
        </div>
      </div>
    </PromptWrapper>
  );
}
BuilderErrorPrompt.propTypes = {
  currentBuilderId: PropTypes.number,
  currentBuilder: PropTypes.object,
  errors: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired
};

const mapState = state => ({
  currentBuilderId: state.builder.currentBuilderId,
  currentBuilder: state.builder.currentBuilder
});

export default connect(mapState)(BuilderErrorPrompt);
