import React from "react";
import PropTypes from "prop-types";

import ButtonMain from "./ButtonMain";
import NavTitle from "./NavTitle";

function SimpleNavBar({
  next,
  nextText = "Next",
  prev,
  prevText = "Previous",
  pageName
}) {
  return (
    <div className="navigation-bar">
      <div className="navigation-bar__inner">
        {prev && (
          <div className="navigation-bar__nav navigation-bar__nav--prev">
            <ButtonMain link={prev} color="gray">
              {prevText}
            </ButtonMain>
          </div>
        )}
        <div className="navigation-bar__title">
          <NavTitle title={pageName} />
        </div>
        {next && (
          <div className="navigation-bar__nav navigation-bar__nav--next">
            <ButtonMain link={next} color="aqua">
              {nextText}
            </ButtonMain>
          </div>
        )}
      </div>
    </div>
  );
}

SimpleNavBar.propTypes = {
  next: PropTypes.string,
  prev: PropTypes.string,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  pageName: PropTypes.string.isRequired
};

export default SimpleNavBar;
