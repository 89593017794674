import accountActions from "../../app/my-account/duck/actions";
import Creators from "./actions";
import { loadingOperations } from "../loading";
import { api } from "hc-utils/HomeAPIWrapper";
import builderActions from "../../app/builder/duck/actions";
import dashboardActions from "../../app/dashboard/duck/actions";
import allowedRoles from "../../config/allowedRoles";

import _get from "lodash.get";

const setLoading = loadingOperations.setLoading;
const clearAccountData = accountActions.clearAccountData;
const setUserCredentials = Creators.setUserCredentials;
const setCurrentPage = Creators.setCurrentPage;
const logoutUser = Creators.logoutUser;
const setCurrentHome = Creators.setCurrentHome;
const setRegionsList = Creators.setRegionsList;

const setAccountEmail = accountActions.setAccountEmail;
const setClientToken = accountActions.setClientToken;
const setUserData = accountActions.setUserData;
const clearLoading = loadingOperations.clearLoading;

const clearCurrentBuilder = builderActions.clearCurrentBuilder;
const clearDashboard = dashboardActions.clearDashboard;

const checkIfLoggedIn = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("cache", "REQUEST"));
    dispatch(setLoading("login", "REQUEST"));
    api
      .checkUserCredentials()
      .then(userData => {
        const { email, role, token } = userData;

        dispatch(setUserCredentials({ token, role }));
        dispatch(setAccountEmail(email));
        dispatch(setLoading("cache", "SUCCESS"));
        dispatch(setLoading("login", "SUCCESS"));
        resolve(userData);
      })
      .catch(err => {
        dispatch(setLoading("cache", "FAILED"));
        dispatch(setLoading("login", "FAILED"));
        reject(err);
      });
  });
};

const handleLogoutUser = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("logout", "REQUEST"));
    api
      .logout()
      .then(response => {
        dispatch(setLoading("logout", "SUCCESS"));
        dispatch(logoutUser());
        dispatch(clearCurrentBuilder());
        dispatch(clearAccountData());
        dispatch(clearDashboard());
        dispatch(clearLoading());

        resolve({
          message: "Successfully logged out."
        });
      })
      .catch(err => {
        console.error(err);
        dispatch(setLoading("logout", "FAILED"));
        api.manualLogout().then(() => {
          dispatch(logoutUser());
          reject(err);
        });
      });
  });
};

const initializeInvite = uniqueId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("initializeInvite", "REQUEST"));
    api
      .initializeInvite(uniqueId)
      .then(values => {
        dispatch(setLoading("initializeInvite", "SUCCESS"));
        resolve(values);
      })
      .catch(err => {
        window.console.error(err);
        dispatch(setLoading("initializeInvite", "FAILED"));
        reject(err);
      });
  });
};

const finalizeInvite = values => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("finalizeInvite", "REQUEST"));
    api
      .finalizeInvite(values)
      .then(userCred => {
        dispatch(setLoading("finalizeInvite", "SUCCESS"));
        dispatch(
          setUserCredentials({ token: userCred.token, role: userCred.role })
        );
        dispatch(setUserData({ ...userCred }));
        dispatch(setAccountEmail(values.email));
        resolve(userCred);
      })
      .catch(error => {
        window.console.error(error);
        dispatch(setLoading("finalizeInvite", "FAILED"));
        reject(error);
      });
  });
};

const validateResetId = uniqueId => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("validateResetId", "REQUEST"));
    api
      .validateResetId(uniqueId)
      .then(email => {
        dispatch(setLoading("validateResetId", "SUCCESS"));
        resolve(email);
      })
      .catch(err => {
        window.console.error(err);
        dispatch(setLoading("validateResetId", "FAILED"));
        reject(err);
      });
  });
};

const resetPassword = (
  email,
  password,
  confirmPassword,
  uniqueId
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("resetPassword", "REQUEST"));
    api
      .resetPassword(email, password, confirmPassword, uniqueId)
      .then(() => {
        dispatch(setLoading("resetPassword", "SUCCESS"));
        resolve();
      })
      .catch(err => {
        window.console.error(err);
        dispatch(setLoading("resetPassword", "FAILED"));
        reject(err);
      });
  });
};

const attemptLogin = ({ email, password }) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("login", "REQUEST"));
    api
      .login({ email, password })
      .then(({ token, role, ...rest }) => {
        if (!allowedRoles.includes(role)) {
          dispatch(setLoading("login", "FAILED"));
          reject({
            message: `You must login with an "account owner" account to view your homes. This account is role "${role}"`
          });
        } else {
          dispatch(setUserCredentials({ token, role }));
          dispatch(setUserData({ token, role, ...rest }));
          dispatch(setAccountEmail(email));
          dispatch(setLoading("login", "SUCCESS"));
          resolve({ email, token, role, ...rest });
        }
      })
      .catch(error => {
        dispatch(setLoading("login", "FAILED"));
        const status = _get(error.response, "status", 500);

        if (status === 500) {
          reject({
            message: "There was an issue while logging in. Please try again."
          });
        } else if (status === 401) {
          reject({
            message: "Invalid username/password. Please try again"
          });
        } else {
          reject({
            message:
              "There wan an unknown error while logging you in. Please try again."
          });
        }
      });
  });
};

const sendForgotPassword = email => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("sendForgotPassword", "REQUEST"));
    api
      .sendForgotPassword(email)
      .then(() => {
        dispatch(setLoading("sendForgotPassword", "SUCCESS"));
        resolve();
      })
      .catch(err => {
        dispatch(setLoading("sendForgotPassword", "FAILED"));
        reject(err);
      });
  });
};

const generateClientToken = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("generateClientToken", "REQUEST"));
    api
      .generateClientToken()
      .then(token => {
        dispatch(setClientToken(token));
        dispatch(setLoading("generateClientToken", "SUCCESS"));
        resolve(token);
      })
      .catch(err => {
        dispatch(setLoading("generateClientToken", "FAILED"));
        reject(err);
      });
  });
};

const fetchRegionsList = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading("fetchRegionsList", "REQUEST"));
    api
      .getRegionsList()
      .then(regions => {
        dispatch(setRegionsList(regions));
        dispatch(setLoading("fetchRegionsList", "SUCCESS"));
        resolve(regions);
      })
      .catch(err => {
        dispatch(setLoading("fetchRegionsList", "FAILED"));
        reject(err);
      });
  });
};

export default {
  setCurrentPage,
  attemptLogin,
  handleLogoutUser,
  checkIfLoggedIn,
  setCurrentHome,
  logoutUser,
  setUserCredentials,
  initializeInvite,
  finalizeInvite,
  validateResetId,
  resetPassword,
  sendForgotPassword,
  generateClientToken,
  fetchRegionsList
};
