import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import SignupForm from "./SignupForm";
import SimpleNavBar from "../common/SimpleNavBar";
import _get from "lodash.get";
import parseQuery from "../../utilities/parseQuery";

import { toastOperations } from "../../duck/toast";
import { Helmet } from "react-helmet";

import { accountOperations } from "../my-account/duck";

function CreateAccount({ registerAccount, history, addToast }) {
  const params = parseQuery(window.location.search);

  const handleSignup = (values, actions) => {
    registerAccount(values)
      .then(data => {
        addToast({
          text:
            "Succesfully created account - proceed with entering your billing information and a subscription plan."
        });
        actions.setSubmitting(false);
        history.push({
          pathname: "/finish-up",
          state: {
            plan: _get(params, "plan", null),
            fromRegisterAccount: true
          }
        });
      })
      .catch(error => {
        window.console.error(error);
        actions.setSubmitting(false);
        addToast({
          text: _get(error, "message")
            ? error.message
            : "Unable to register your account. please try again.",
          intent: "error"
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>Create Account - Home Concierge</title>
        <meta
          name="description"
          content="Create a homeowner account with Home Concierge."
        />
      </Helmet>
      <SimpleNavBar
        prev="/"
        prevText="Back To Home"
        type="simple"
        pageName="Create Your Account"
      />

      <SignupForm onSubmit={handleSignup} />

      <div className="log-in-cta">
        <div className="log-in-cta__inner">
          <p>Already have an account?</p>
          <p>
            <Link to="/login" tabIndex={-1}>
              Log in.
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

const mapState = state => ({
  isLoggedIn: state.global.userAuth.isLoggedIn
});

const mapDispatch = dispatch => ({
  registerAccount: userCred =>
    dispatch(accountOperations.registerAccount(userCred)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

CreateAccount.propTypes = {
  registerAccount: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  addToast: PropTypes.func.isRequired
};

export default withRouter(connect(mapState, mapDispatch)(CreateAccount));
