import React from "react";
import PropTypes from "prop-types";
import GridWrapper from "../../GridWrapper";
import GridHeader from "../../GridHeader";
import { Formik, Field } from "formik";
import ButtonMain from "../../../common/ButtonMain";
import InputTextFormik from "../../../common/InputTextFormik";
import { Yup } from "hc-utils/FormValidator";

const HouseVrTourValidationSchema = Yup.object().shape({
  link: Yup.string().laxUrl("Website is invalid")
});

function HouseVRTour({ data, onSave }) {
  const initialValues = {
    link: data.url || ""
  };

  return (
    <GridWrapper>
      <GridHeader size={12}>Add house video link</GridHeader>

      <Formik
        validationSchema={HouseVrTourValidationSchema}
        onSubmit={onSave}
        initialValues={initialValues}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form className="house-vr-form" onSubmit={handleSubmit}>
            <Field
              component={InputTextFormik}
              name="link"
              placeholder="360 Tour URL"
              size="half"
              autoComplete="url"
              required={false}
            />
            <small>
              Paste a URL to a virtual tour of the home (i.e. Matterport,
              iGUIDE, Cupix)
            </small>
            <ButtonMain
              type="submit"
              color="aqua"
              loading={isSubmitting}
              isDisabled={values.link === initialValues.link}
            >
              Save VR Tour Link
            </ButtonMain>
          </form>
        )}
      </Formik>
    </GridWrapper>
  );
}

HouseVRTour.propTypes = {
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    url: PropTypes.string
  })
};

export default HouseVRTour;
