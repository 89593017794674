import React from "react";
import PropTypes from "prop-types";

function NavTitle({ icon, title }) {
  return (
    <span className="navigation-title">
      <h2>{title}</h2>
      {icon && <img src={icon} alt={title} />}
    </span>
  );
}

NavTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string
};
export default NavTitle;
