import { Route, Switch, Redirect } from "react-router-dom";

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BodyWrapper from "../app/common/BodyWrapper";
import General404 from "../app/common/General404";

function RouteGenerator({ routes, isLoggedIn }) {
  return (
    <>
      <Switch>
        {routes.map(({ name, key, isProtected, path, Component }) => (
          <SecuredRoute
            key={key}
            path={path}
            exact
            isLoggedIn={isLoggedIn}
            isProtected={isProtected}
            render={props => (
              <BodyWrapper routeKey={key} routeName={name} {...props}>
                <Component {...props} />
              </BodyWrapper>
            )}
          />
        ))}
        <Route render={() => <General404 />} />
      </Switch>
    </>
  );
}

const SecuredRoute = ({ render: Render, isProtected, isLoggedIn, ...rest }) => {
  if (isProtected) {
    return (
      <Route
        {...rest}
        render={props =>
          isLoggedIn ? <Render {...props} /> : <Redirect to="/login" />
        }
      />
    );
  } else {
    return <Route {...rest} render={() => <Render />} />;
  }
};

SecuredRoute.propTypes = {
  render: PropTypes.func.isRequired,
  isProtected: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

RouteGenerator.propTypes = {
  routes: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

const mapState = state => ({
  isLoggedIn: state.global.userAuth.isLoggedIn
});

export default connect(mapState)(RouteGenerator);
