import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import HomeListItem from "./HomeListItem";
import DashboardTable from "./DashboardTable";
import SpinnerLoader from "../common/SpinnerLoader";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ButtonMain from "../common/ButtonMain";
import PromptWrapper from "../common/PromptWrapper";
import { dashboardOperations } from "./duck";
import { toastOperations } from "../../duck/toast";
import { connect } from "react-redux";
import _get from "lodash.get";

import DashboardListNotFound from "./DashboardListNotFound";
import { escapeRegExp } from "hc-utils";

function HomeList({
  items,
  query,
  filters,
  clearFilters,
  isLoading,
  deleteHouse,
  addToast,
  hasPlan,
  openPreview
}) {
  useEffect(() => {
    openPreview();
  }, [openPreview]);

  const [showDeletePrompt, setDeletePrompt] = useState({
    show: false,
    id: null
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  let filteredItems = (items, query) => {
    return items
      .filter(item =>
        item.houseName
          ? item.houseName
              .toLowerCase()
              .search(escapeRegExp(query.toLowerCase())) > -1
          : "No Home Name"
      )
      .filter(item => {
        if (item.isPublished && filters.published === false) {
          return false;
        }
        if (!item.isPublished && filters.drafted === false) {
          return false;
        }
        return true;
      });
  };

  const promptDeleteHome = deleteId => {
    setDeletePrompt({
      show: true,
      id: deleteId,
      house: items.find(({ id }) => id === deleteId)
    });
  };

  const handleHomeDelete = () => {
    setDeleteLoading(true);
    deleteHouse(showDeletePrompt.id)
      .then(() => {
        setDeleteLoading(false);
        setDeletePrompt({ show: false, id: null, house: null });
        addToast({
          text: `Successfully deleted home "${showDeletePrompt.house.houseName}".`
        });
      })
      .catch(error => {
        setDeleteLoading(false);
        addToast({ text: error.message, intent: "error" });
      });
  };

  return (
    <div className="dashboard-list">
      <div className="dashboard-list__inner">
        <DashboardTable layout="userHomes">
          <TransitionGroup component={null}>
            {!isLoading ? (
              <CSSTransition
                key="userpages"
                timeout={{ enter: 300, exit: 0 }}
                in={!isLoading}
                classNames="fade"
              >
                {filteredItems(items, query).length > 0 ? (
                  <div className="list-wrapper">
                    {filteredItems(items, query).map(houseProps => (
                      <HomeListItem
                        {...houseProps}
                        onHomeDelete={promptDeleteHome}
                        key={houseProps.id}
                      />
                    ))}
                  </div>
                ) : (
                  <DashboardListNotFound
                    hasPlan={hasPlan}
                    query={query}
                    items={items}
                    filters={filters}
                    clearFilters={clearFilters}
                    noun="home"
                  />
                )}
              </CSSTransition>
            ) : (
              <CSSTransition
                key="loader"
                timeout={300}
                in={isLoading}
                classNames="fade"
              >
                <SpinnerLoader />
              </CSSTransition>
            )}
          </TransitionGroup>
          {showDeletePrompt.show && (
            <PromptWrapper
              onClose={() => setDeletePrompt({ show: false, id: null })}
              className="home-delete-prompt"
            >
              <h3>
                Are you sure you want to delete the house "
                {showDeletePrompt.house.houseName}"?
              </h3>
              <p>
                This action can not be undone - All current guests will not be
                able to access your home in the App.
              </p>
              <small>
                <strong>NOTE:</strong> Deleting this home will be reflected in
                your subscription billing.
              </small>
              <ButtonMain
                onClick={handleHomeDelete}
                color="red"
                loading={deleteLoading}
              >
                Delete this home.
              </ButtonMain>
            </PromptWrapper>
          )}
        </DashboardTable>
      </div>
    </div>
  );
}

HomeList.propTypes = {
  addToast: PropTypes.func.isRequired,
  deleteHouse: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.bool).isRequired,
  hasPlan: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array,
  query: PropTypes.string.isRequired
};

const mapDispatch = dispatch => ({
  deleteHouse: houseId => dispatch(dashboardOperations.deleteHouse(houseId)),
  addToast: options => dispatch(toastOperations.addToast(options)),
  openPreview: () => dispatch(dashboardOperations.openPreview())
});

const mapState = state => ({
  hasPlan: Boolean(
    _get(state.account, "accountInfo.plan_id", false) &&
      _get(state.account, "accountInfo.plan_name", false)
  )
});

export default connect(mapState, mapDispatch)(HomeList);
