// api/loadingReducer.js
import types from "./types";

export const initialToastState = {};

export default (state = [], action) => {
  const { type } = action;

  switch (type) {
    case types.ADD_TOAST:
      return [action.toast, ...state];

    case types.REMOVE_TOAST:
      return state.filter(toast => toast.id !== action.id);

    default:
      return state;
  }
};
