import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import _get from "lodash.get";
import { Helmet } from "react-helmet";

import {
  AccountPageBilling,
  AccountPageProfile,
  AccountPagePassword,
  AccountPageEmail,
  AccountPageBillingHistory,
  AccountPageSubscription,
  AccountPageAddress
} from "./pages";
import { globalOperations } from "../../duck/global";

export class AccountController extends Component {
  static propTypes = {};
  componentDidMount() {
    this.props.generateClientToken();
  }
  computeAccountPage(pageKey) {
    switch (pageKey) {
      case "profile": {
        const { accountInfo, addressLoading } = this.props;
        return (
          <>
            <Helmet>
              <title>Home Concierge | My Account | Profile</title>
              <meta
                name="description"
                content="Manage your profile settings."
              />
            </Helmet>
            <AccountPageProfile data={accountInfo} />
            <AccountPageAddress data={accountInfo} loading={addressLoading} />
          </>
        );
      }
      case "billing": {
        const { billingHistory } = this.props;
        const {
          billingInfo,
          paymentMethodsLoading,
          paymentMethodsLoadingStatus,
          billingHistoryLoading,
          billingHistoryLoadingStatus
        } = this.props;

        return (
          <>
            <Helmet>
              <title>Home Concierge | My Account | Billing</title>
              <meta
                name="description"
                content="Manage your billing and subscription settings."
              />
            </Helmet>
            <AccountPageSubscription
              loading={paymentMethodsLoading}
              loadStatus={paymentMethodsLoadingStatus}
            />
            <AccountPageBilling
              data={billingInfo}
              loading={paymentMethodsLoading}
              loadStatus={paymentMethodsLoadingStatus}
            />
            <AccountPageBillingHistory
              data={billingHistory}
              loading={billingHistoryLoading}
              loadStatus={billingHistoryLoadingStatus}
            />
          </>
        );
      }
      case "password": {
        return (
          <>
            <Helmet>
              <title>Home Concierge | My Account | Password</title>
              <meta
                name="description"
                content="Change your accounts password."
              />
            </Helmet>
            <AccountPagePassword />
          </>
        );
      }
      case "email": {
        const { accountInfo } = this.props;
        return (
          <>
            <Helmet>
              <title>Home Concierge | My Account | Email</title>
              <meta name="description" content="Change your accounts email." />
            </Helmet>
            <AccountPageEmail data={accountInfo} />
          </>
        );
      }

      default: {
        return <Redirect to="/user/my-account?page=profile" />;
      }
    }
  }
  render() {
    const { children, page } = this.props;
    return (
      <div className="account-controller">
        <div className="account-controller__inner">
          <div className="account-controller__content">
            {children({ AccountPage: this.computeAccountPage(page) })}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  accountInfo: state.account.accountInfo,
  billingHistory: {
    nextBillingCycle: "July 4th, 2019",
    entries: [
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      },
      {
        date: "Jan 1, 2019",
        description: "Payment",
        amount: "$25.12"
      }
    ]
  },
  billingInfo: {
    firstName: "test"
  },
  getUserDataLoading: _get(state.loading, "getUserData.loading", true),
  getUserDataLoadingStatus: _get(state.loading, "getUserData.status", null),
  addressLoading: _get(state.loading, "fetchBillingAddress.loading", true),
  paymentMethodsLoading: _get(
    state.loading,
    "fetchAccountPaymentMethods.loading",
    true
  ),
  paymentMethodsLoadingStatus: _get(
    state.loading,
    "fetchAccountPaymentMethods.status",
    null
  ),
  billingHistoryLoading: _get(
    state.loading,
    "fetchBillingHistory.loading",
    true
  ),
  billingHistoryLoadingStatus: _get(
    state.loading,
    "fetchBillingHistory.status",
    null
  )
});

const mapDispatch = dispatch => ({
  generateClientToken: () => dispatch(globalOperations.generateClientToken())
});

export default connect(mapState, mapDispatch)(AccountController);
