import types from "./types";

const setLoading = (requestName, requestStatus) => ({
  type: types.SET_LOADING,
  requestName,
  requestStatus
});

const clearLoading = () => ({
  type: types.CLEAR_LOADING
});

export default {
  setLoading,
  clearLoading
};
