import React, { createContext, useState } from "react";
import DemoBanner from "./DemoBanner";
import { CSSTransition } from "react-transition-group";
export const BannerContext = createContext();

export function withBannerContext(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <BannerContext.Consumer>
          {({ ...bannerCtxValues }) => (
            <WrappedComponent {...bannerCtxValues} {...this.props} />
          )}
        </BannerContext.Consumer>
      );
    }
  };
}

function BannerProvider({ children }) {
  const [display, setDisplay] = useState(false);
  const [cleared, setCleared] = useState(
    Boolean(localStorage.getItem("demoBannerPrompted"))
  );

  const showBanner = () => setDisplay(true);
  const hideBanner = () => {
    localStorage.setItem("demoBannerPrompted", "true");
    setCleared(true);
    setDisplay(false);
  };

  return (
    <BannerContext.Provider
      value={{ showBanner, hideBanner, bannerCleared: cleared }}
    >
      {children}

      <CSSTransition
        in={display}
        mountOnEnter
        unmountOnExit
        timeout={250}
        classNames="slideUp"
      >
        <DemoBanner />
      </CSSTransition>
    </BannerContext.Provider>
  );
}

export default BannerProvider;
