import React from "react";
import WhiteGloveIcon from "../../assets/white-glove-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faUser,
  faHandsHelping,
  faHome
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  help: faQuestionCircle,
  user: faUser,
  "hands-helping": faHandsHelping,
  my_homes: faHome
};

export default ({ icon, className = "" }) => (
  <span
    className={`iconset-icon ${
      className !== "" ? `iconset-icon--${className}` : ""
    }`}
  >
    {icon === "white_glove" ? (
      <img src={WhiteGloveIcon} alt={icon} />
    ) : (
      <FontAwesomeIcon icon={icons[icon]} />
    )}
  </span>
);
