import React, { Component, useReducer } from "react";
import UploadButton from "../common/UploadButton";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

function ImageLoader({ src, alt, ...rest }) {
  const imageReducer = (state, action) => {
    switch (action.type) {
      case "SET_STATUS": {
        return {
          loading: action.status === "REQUEST" ? true : false,
          status: action.status
        };
      }
      default: {
        return state;
      }
    }
  };
  const [state, dispatch] = useReducer(imageReducer, {
    loading: true,
    status: "REQUEST"
  });

  const handleLoad = () => {
    dispatch({ type: "SET_STATUS", status: "SUCCESS" });
  };

  const handleError = () => {
    dispatch({ type: "SET_STATUS", status: "FAILED" });
  };

  return (
    <div className="image-loader">
      <img
        src={src}
        alt={alt}
        {...rest}
        onLoad={handleLoad}
        onError={handleError}
      ></img>
      <CSSTransition
        in={state.status === "REQUEST"}
        out={state.status !== "REQUEST"}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div className="image-loader__loader"></div>
      </CSSTransition>
    </div>
  );
}

ImageLoader.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string
};

export class PromptImageUpload extends Component {
  render() {
    const { onUpload = () => {}, image, withUpload } = this.props;
    return (
      <div className="prompt-image-upload">
        <div className="prompt-image-upload__inner">
          {image ? (
            <div className="prompt-image-upload__image">
              <ImageLoader src={image} alt={"Image Loader"} />
            </div>
          ) : (
            <div className="prompt-image-upload__content">
              <h4>Drop photo here to upload or</h4>
              <UploadButton name="prompt-image-upload" onUpload={onUpload}>
                Select File
              </UploadButton>
            </div>
          )}
        </div>
        {image && (
          <div className="prompt-image-upload__replace">
            {withUpload !== false && (
              <UploadButton name="prompt-image-upload" onUpload={onUpload}>
                Select New Image
              </UploadButton>
            )}
          </div>
        )}
      </div>
    );
  }
}

PromptImageUpload.propTypes = {
  onUpload: PropTypes.func,
  image: PropTypes.string,
  withUpload: PropTypes.bool
};

export default PromptImageUpload;
