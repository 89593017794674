// initial state of builder when creating new home

export default {
  "1": {
    name: "Property Info",
    key: "propertyInfo",
    isAddable: true,
    visited: false,
    errors: {},
    touched: {},
    values: {
      propertyInfo: {
        form: {}
      }
    }
  },
  "2": {
    name: "House Tour",
    key: "houseTour",
    isAddable: true,
    visited: false,
    errors: {},
    touched: {}
  },
  "3": {
    name: "Guest Services",
    key: "guestServices",
    isAddable: true,
    visited: false,
    errors: {},
    touched: {}
  },
  "4": {
    name: "Shop",
    key: "shop",
    visited: false,
    isAddable: false,
    errors: {
      shop: '"Shop" is required and must have atleast one "selected" item.'
    },
    touched: {}
  },
  "5": {
    name: "Dine",
    key: "dine",
    isAddable: false,
    visited: false,
    errors: {
      dine: '"Dine" is required and must have atleast one "selected" item.'
    },
    touched: {}
  },
  "6": {
    name: "Play",
    key: "play",
    isAddable: false,
    visited: false,
    errors: {},
    touched: {}
  },
  "7": {
    name: "Spa",
    key: "spa",
    isAddable: false,
    visited: false,
    errors: {
      spa: '"Spa" is required and must have atleast one "selected" item.'
    },
    touched: {}
  },
  "8": {
    name: "Music",
    key: "music",
    isAddable: true,
    visited: false,
    errors: {
      music: '"Music" is required and must have atleast one "selected" item.'
    },
    touched: {}
  },
  "9": {
    name: "TV - Wifi - Office",
    key: "tvOffice",
    isAddable: true,
    visited: false,
    errors: {
      tvOffice:
        '"TV - Wifi - Office" is required and must have atleast one "selected" item.'
    },
    touched: {}
  },
  "10": {
    name: "Emergency",
    key: "emergency",
    isAddable: true,
    visited: false,
    errors: {
      emergency:
        '"Emergency" is required and must have atleast one "selected" item.'
    },
    touched: {}
  },
  "11": {
    name: "House Features",
    key: "houseFeatures",
    isAddable: true,
    visited: false,
    errors: {},
    touched: {}
  }
};
