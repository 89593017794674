import React from "react";
import PropTypes from "prop-types";
import PromptWrapper from "../common/PromptWrapper";
import PromptHeader from "./PromptHeader";
import { Formik, FieldArray } from "formik";
import { Yup } from "hc-utils/FormValidator";
import posed, { PoseGroup } from "react-pose";
import FeatureViewPromptPage from "./FeatureViewPromptPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonMain from "../common/ButtonMain";
import { connect } from "react-redux";
import _get from "lodash.get";

import uuidv4 from "uuid";

const TransWrapper = posed.div();

const FeatureViewPromptValidationSchema = Yup.object().shape({
  features: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().nullable(),
      canvas: Yup.object().nullable(),
      coords: Yup.object().shape({
        circle: Yup.object()
          .shape({
            radius: Yup.number().nullable(),
            x: Yup.number().nullable(),
            y: Yup.number().nullable()
          })
          .nullable(),
        photo: Yup.object()
          .shape({
            height: Yup.number().nullable(),
            width: Yup.number().nullable(),
            x: Yup.number().nullable(),
            y: Yup.number().nullable()
          })
          .nullable()
      }),
      text: Yup.string().nullable().required("Feature Description is required"),
      user_manual_url: Yup.string().laxUrl("Website is invalid").nullable(),
      video_url: Yup.string().laxUrl("Website is invalid").nullable()
    })
  )
});

const getItemImage = (images, size) => {
  const image = images.find(image => image.size === size);
  return image ? image.url : null;
};

const createFeatureItemObject = item => {
  const itemImage = getItemImage(item.images, "tablet");
  let itemObject = item;
  if (! itemImage) {
	// won't render if we have data on these and no image
	itemObject.coords = {
	  photo: null,
	  circle: null
	};
  }
  return ({
	...itemObject,
	image: {
	  file: null,
	  url: itemImage
	}
  });
}

const createBlankFeatureItem = ({ prefilled }) => ({
  uniq: uuidv4(),
  id: null,
  image: {
    file: null,
    url: null
  },
  coords: {
    photo: null,
    circle: null
  },
  text: prefilled || ""
});

function FeatureViewPromptNew({
  onClose,
  icon,
  title,
  onSubmit,
  data,
  prefilled = '',
  featureLoading
}) {
  const initialValues = {
    features: data
      ? data.items
          .map(createFeatureItemObject)
          .sort((a, b) =>
            a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
          )
      : [createBlankFeatureItem({ prefilled })]
  };

  const embedUrl = _get(data, 'embedUrl', '') || '';

  const isEmbedded = embedUrl && embedUrl.length;

  if (isEmbedded) {
	return (
	  <PromptWrapper onClose={onClose} className="feature-prompt-wrapper">
		<div className="feature-view-prompt">
		  <div className="feature-view-prompt__inner">
			<PromptHeader
			  icon={icon}
			  text={""}
			>
			  {title}
			</PromptHeader>
			<div style={{width: '100%'}}>
			  <iframe
				src={data.embedUrl + '?builder=1'}
				width="100%"
				height="600"
				data-testid="use-embed-standard"
				style={{
				  border: 'none',
				}}
				allowFullScreen={true}
			  ></iframe>
			</div>
		  </div>
		</div>
	  </PromptWrapper>
	);
  }

  return (
	<PromptWrapper onClose={onClose} className="feature-prompt-wrapper">
	  <div className="feature-view-prompt">
		<div className="feature-view-prompt__inner">
		  <PromptHeader
			icon={icon}
			text={"Additional pages can be added at the bottom of this screen."}
          >
            {title}
          </PromptHeader>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={FeatureViewPromptValidationSchema}
          >
            {formikProps => (
              <form
                className="feature-view-prompt__pages"
                onSubmit={formikProps.handleSubmit}
              >
                <FieldArray name="features">
                  {arrayHelpers => (
                    <>
                      {formikProps.values.features.map((feature, index) => (
                        <PoseGroup key={feature.id || feature.uniq || index}>
                          <TransWrapper
                            key={feature.id || feature.uniq || index}
                          >
                            <FeatureViewPromptPage
                              key={feature.id || feature.uniq || index}
                              item={feature}
                              prefilled={prefilled}
                              index={index}
                              onRemove={() => {
                                if (formikProps.values.features.length === 1) {
                                  arrayHelpers.replace(
                                    index,
                                    createBlankFeatureItem({ prefilled })
                                  );
                                } else {
                                  arrayHelpers.remove(index);
                                }
                              }}
                              onMove={direction => {
                                if (index === 0 && direction === "up") return;
                                if (
                                  index + 1 >=
                                    formikProps.values.features.length &&
                                  direction === "down"
                                )
                                  return;

                                arrayHelpers.swap(
                                  index,
                                  direction === "up" ? index - 1 : index + 1
                                );
                              }}
                            />
                          </TransWrapper>
                        </PoseGroup>
                      ))}

                      <div
                        className="feature-view-prompt__add"
                        onClick={() => {
                          arrayHelpers.push(
                            createBlankFeatureItem({ prefilled })
                          );
                        }}
                      >
                        <h2>Add a page</h2>
                        <button
                          className="feature-view-prompt__addbutton"
                          type="button"
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </button>
                      </div>
                      <div className="feature-view-prompt__submit">
                        <ButtonMain
                          color="aqua"
                          type="submit"
                          loading={featureLoading}
                        >
                          {!data ? "Save" : "Update"}
                        </ButtonMain>
                      </div>
                    </>
                  )}
                </FieldArray>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </PromptWrapper>
  );
}

const mapState = state => ({
  featureLoading:
    _get(state.loading, "createFeaturePage.loading", false) ||
    _get(state.loading, "updateFeature.loading", false)
});

FeatureViewPromptNew.propTypes = {
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sequence: PropTypes.number.isRequired,
	embedUrl: PropTypes.string,
	guestApp: PropTypes.object,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        sequence: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        user_manual_url: PropTypes.string,
        video_url: PropTypes.string,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            size: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
          })
        ),
        coords: PropTypes.shape({
          circle: PropTypes.shape({
            radius: PropTypes.number,
            x: PropTypes.number,
            y: PropTypes.number
          }),
          photo: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
            x: PropTypes.number,
            y: PropTypes.number
          })
        })
      })
    )
  }),
  prefilled: PropTypes.string,
  featureLoading: PropTypes.bool
};

export default connect(mapState)(FeatureViewPromptNew);
