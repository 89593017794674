import React, { Component } from "react";
import PropTypes from "prop-types";
import parseQuery from "../../utilities/parseQuery";
import AccountController from "./AccountController";
import { Link, withRouter } from "react-router-dom";

import SpinnerLoader from "hc-common/SpinnerLoader";

import _get from "lodash.get";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
function SidebarItem({ isActive, children, page }) {
  return (
    <div
      className={`account-sidebar-item ${
        isActive ? "account-sidebar-item--active" : ""
      }`}
    >
      <Link
        to={`/user/my-account?page=${page}`}
        className="account-sidebar-item__btn"
      >
        <h5>{children}</h5>
      </Link>
    </div>
  );
}

function AccountSidebar({ currentPage }) {
  return (
    <>
      <Helmet>
        <title>Home Concierge | My Account</title>
        <meta
          name="description"
          content="Forgot your password? Reset your password by entering your email."
        />
      </Helmet>
      <div className="account-sidebar">
        <div className="account-sidebar__inner">
          <div className="account-sidebar__header">
            <h3>Account Settings</h3>
          </div>
          <div className="account-sidebar__items">
            <SidebarItem page="profile" isActive={currentPage === "profile"}>
              Profile
            </SidebarItem>
            <SidebarItem page="billing" isActive={currentPage === "billing"}>
              Billing
            </SidebarItem>
            <SidebarItem page="password" isActive={currentPage === "password"}>
              Password
            </SidebarItem>
            <SidebarItem page="email" isActive={currentPage === "email"}>
              Email
            </SidebarItem>
          </div>
        </div>
      </div>
    </>
  );
}

export class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentParams: parseQuery(props.location.search)
    };
  }
  static propTypes = {
    location: PropTypes.object
  };

  componentDidMount() {}
  render() {
    const { location, isAccountLoading } = this.props;
    const currentPage = _get(parseQuery(location.search), "page", null);
    return (
      <div className="my-account">
        {isAccountLoading === false ? (
          <div className="my-account__inner">
            <AccountSidebar currentPage={currentPage} />
            <AccountController page={currentPage}>
              {({ AccountPage }) => AccountPage}
            </AccountController>
          </div>
        ) : (
          <div className="my-account__loading">
            <SpinnerLoader />
          </div>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  isAccountLoading: _get(state.loading, "getUserData.loading", false)
});

export default withRouter(
  connect(
    mapState,
    null
  )(MyAccount)
);
