import React from "react";
import ToolTip from "@material-ui/core/Tooltip";

const TooltipWrapper = ({ show, title, children, ...rest }) => {
  if (!show) return children;
  return (
    <ToolTip title={title} {...rest}>
      {children}
    </ToolTip>
  );
};

export default TooltipWrapper;
