import React from "react";
import { DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
const { FILE } = NativeTypes;

function UploadBorderWrapper({
  onUpload,
  isOver,
  canDrop,
  connectDropTarget,
  children
}) {
  return connectDropTarget(
    <div
      className={`upload-border-wrapper ${
        isOver ? "upload-border-wrapper--over" : ""
      }`}
    >
      <div className="upload-border-wrapper__inner">{children}</div>
    </div>
  );
}

export default DropTarget(
  props => [FILE],
  {
    drop(props, monitor) {
      props.onUpload(monitor.getItem().files);
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(UploadBorderWrapper);
