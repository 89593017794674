import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import StackedLogo from "../../assets/HC_Logo_Stacked.png";
import HeaderItem from "./HeaderItem";

import _get from "lodash.get";

function AppHeader({ isLoggedIn, userEmail, children, onClick }) {
  return (
    <header className="header" onClick={onClick}>
      <div className="header__inner">
        <div className="header__brand">
          <Link to="/">
            <img src={StackedLogo} alt="Home Concierge Vertical Logo" />
          </Link>
        </div>
        {children && (
          <div className="header__title">
            <h2>{children}</h2>
          </div>
        )}
        <div className="header__items">
          <HeaderItem
            icon="white_glove"
            url={
              "https://homeconcierge.com/white-glove-service--home-concierge.html"
            }
          >
            White Glove Service
          </HeaderItem>
          {isLoggedIn && (
            <>
              <HeaderItem icon="my_homes" link={"/user/my-homes"}>
                My Homes
              </HeaderItem>
              <HeaderItem icon="user" link={"/user/my-homes"}>
                {userEmail}
              </HeaderItem>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

AppHeader.propTypes = {
  userEmail: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const mapState = state => ({
  isLoggedIn: state.global.userAuth.isLoggedIn,
  userEmail: _get(state.account, "accountInfo.email", null)
});

export default connect(mapState)(AppHeader);
