import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";
import { Provider } from "react-redux";
import { Router, Route } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import "./index.scss";
import { api } from "./utilities/HomeAPIWrapper";
import { QueryParamProvider } from "use-query-params";
// create store
export const store = configureStore();

// set store object in api handler
api.setStore(store);

// create global site theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1683fc"
    },
    secondary: { main: "#f10009" }
  },
  typography: {
    useNextVariants: true
  }
});

// create browser history
const history = createBrowserHistory();

// send GA events on history change in production
history.listen(location => {
  setTimeout(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, 500);
});

// Render root app with Redux Provider, Router from react-router,
// MuiTheme provider from Material ui, and DragDropContextProvider for providing html5 backend to dnd operations
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <DragDropContextProvider backend={HTML5Backend}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </DragDropContextProvider>
      </MuiThemeProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
