import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _get from "lodash.get";
import { accountOperations } from "../my-account/duck";
import clsx from "clsx";
import SpinnerLoader from "../common/SpinnerLoader";

function PlanItem({
  id = null,
  selected = false,
  onClick,
  planName = null,
  planPrice = null,
  planDescription = null,
  isCurrent = false,
  skeleton = false,
  disabled = false
}) {
  const planCycle = id.slice(-1) === "a" ? "annual" : "monthly";
  const getPlanColor = id => {
    switch (id) {
      case "hcho_plan_1_m":
      case "hcho_plan_1_a": {
        return "blue";
      }
      case "hcho_plan_2_m":
      case "hcho_plan_2_a": {
        return "aqua";
      }
      default: {
        return "blue";
      }
    }
  };
  return (
    <li
      key={id}
      data-testid="plan-item"
      className={`plan-selector__item ${clsx({
        selected: selected,
        "not-selected": !selected,
        current: isCurrent,
        disabled,
        skeleton
      })}`}
    >
      {isCurrent && <h5>Your Current Subscription</h5>}
      <button
        data-testid="plan-item-button"
        className={`plan-selector__button ${getPlanColor(id)} `}
        onClick={() => (onClick ? onClick() : null)}
        type="button"
      >
        <div className="plan-selector__buttonheader">
          <h4>{skeleton ? null : planName}</h4>
        </div>
        <div className="plan-selector__buttontext">
          <p className="price">
            {skeleton
              ? ""
              : `${
                  planCycle === "annual"
                    ? parseFloat(planPrice) / 12
                    : planPrice
                } / Month`}
          </p>
          {id === "hcho_plan_2" && (
            <p className="price-small">+ 19.99 per additional home</p>
          )}
          <p className="text">{planDescription}</p>
          {!isCurrent && (
            <a
              className="more-info"
              href={`https://homeconcierge.com/pricing.html`}
              rel="noopener noreferrer"
              target="_blank"
              onClick={evt => evt.stopPropagation()}
            >
              Click here for more info
            </a>
          )}
        </div>
      </button>
    </li>
  );
}

PlanItem.propTypes = {
  id: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  planName: PropTypes.string,
  planPrice: PropTypes.string,
  planDescription: PropTypes.string,
  isCurrent: PropTypes.bool,
  skeleton: PropTypes.bool,
  disabled: PropTypes.bool
};

function PlanSelector({
  onPlanChange,
  onCycleChange,
  selectedPlan = {},
  selectedCycle = "monthly",
  withCycle,

  availablePlans,
  fetchAvailablePlans,
  fetchAvailablePlansLoading,
  userPlanId,
  userPlanCycle
}) {
  const canLoadPlans = useRef(true);

  useEffect(() => {
    if (!availablePlans && canLoadPlans.current) {
      canLoadPlans.current = false;
      fetchAvailablePlans().catch(error => {
        window.console.error(error);
      });
    }
  }, [availablePlans, fetchAvailablePlans, fetchAvailablePlansLoading]);
  if (fetchAvailablePlansLoading || !availablePlans) {
    return <SpinnerLoader />;
  }
  const currentPlan = availablePlans.annual
    .concat(availablePlans.monthly)
    .find(({ id }) => id === userPlanId);
  const plans = availablePlans[selectedCycle].reduce(
    (acc, curr) => {
      acc.available.push(curr);
      return acc;
    },
    {
      current: currentPlan,
      available: []
    }
  );

  return (
    <div className="plan-selector" data-testid="plan-selector">
      <div className="plan-selector__inner">
        <div className="plan-selector__current"></div>
        <div className="plan-selector__listwrapper">
          <ul className="plan-selector__list plan-selector__list--current">
            {plans.current && (
              <PlanItem
                id={plans.current.id}
                planName={plans.current.name}
                planPrice={plans.current.price}
                planDescription={plans.current.description}
                selected={false}
                isCurrent={true}
              />
            )}
          </ul>
          {withCycle && (
            <div className="plan-selector__cycle">
              <div className="plan-selector__cyclebtn">
                <label htmlFor="cycle-monthly">Monthly</label>
                <input
                  type="radio"
                  id="cycle-monthly"
                  name="cycle-selector"
                  checked={selectedCycle === "monthly"}
                  onChange={() => onCycleChange("monthly")}
                />
              </div>
              <div className="plan-selector__cyclebtn">
                <label htmlFor="cycle-annual">Annually</label>
                <input
                  type="radio"
                  id="cycle-annual"
                  name="cycle-selector"
                  checked={selectedCycle === "annual"}
                  onChange={() => onCycleChange("annual")}
                />
              </div>
            </div>
          )}
          <ul className="plan-selector__list plan-selector__list--available">
            {plans.available.map(plan => (
              <PlanItem
                key={plan.id}
                id={plan.id}
                planName={plan.name}
                planPrice={plan.price}
                planDescription={plan.description}
                selected={_get(selectedPlan, "id", null) === plan.id}
                onClick={() => onPlanChange(plan)}
                disabled={plan.id === _get(plans, "current.id", null)}
                isCurrent={false}
                userPlanCycle={userPlanCycle}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

const mapState = state => ({
  availablePlans: state.account.availablePlans,
  fetchAvailablePlansLoading: _get(
    state.loading,
    "fetchAvailablePlans.loading",
    false
  ),
  userPlanId: state.account.accountInfo.plan_id,
  userPlanCycle: _get(state.account, "accountInfo.plan_id")
    ? state.account.accountInfo.plan_id.slice(-1) === "a"
      ? "annual"
      : "monthly"
    : null
});

const mapDispatch = dispatch => ({
  fetchAvailablePlans: () => dispatch(accountOperations.fetchAvailablePlans())
});

export default connect(mapState, mapDispatch)(PlanSelector);
