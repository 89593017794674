import React, { Component } from "react";
import PropTypes from "prop-types";
import PromptWrapper from "../common/PromptWrapper";
import PromptHeader from "./PromptHeader";
import { Formik } from "formik";
import InputText from "../common/InputText";
import InputEditor from "./InputEditor";
import PromptImageUpload from "./PromptImageUpload";
import _get from "lodash.get";

export class POIViewPrompt extends Component {
  render() {
    const { onClose, icon, title, data } = this.props;

    return (
      <PromptWrapper onClose={onClose} className="poi-view-prompt-wrapper">
        {data === null ? (
          <p>Unable to preview point of interest. Please try again.</p>
        ) : (
          <div className="poi-view-prompt">
            <div className="poi-view-prompt__inner">
              <PromptHeader icon={icon}>{title}</PromptHeader>
              {data.image && (
                <PromptImageUpload
                  image={
                    data.image.length > 0
                      ? data.image.find(({ size }) => size === "tablet").url
                      : null
                  }
                  withUpload={false}
                />
              )}
              <Formik
                initialValues={{
                  poiName: data !== null ? data.name : "",
                  poiAddress: _get(data, "address.fullAddress", false)
                    ? data.address.fullAddress
                    : "",
                  poiText: data !== null ? data.description : ""
                }}
              >
                {({ values }) => (
                  <form
                    name="poi-prompt-form"
                    className="user-form user-form--prompt"
                  >
                    <InputText
                      name="poiName"
                      type="text"
                      size="full"
                      placeholder="Point Of Interest Name"
                      value={values.poiName}
                      displayError={false}
                      disabled={true}
                    />
                    <InputText
                      name="poiAddress"
                      type="text"
                      size="full"
                      placeholder="Address"
                      value={values.poiAddress}
                      displayError={false}
                      disabled={true}
                    />
                    <InputEditor
                      disabled={true}
                      value={values.poiText}
                      placeholder={"Description"}
                      id={data.id}
                      name={data.id}
                    />
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </PromptWrapper>
    );
  }
}

POIViewPrompt.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.shape({
      fullAddress: PropTypes.string
    }),
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
    image: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ),
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sequence: PropTypes.number.isRequired
  }),
  icon: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default POIViewPrompt;
