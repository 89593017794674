import axios from "axios";
import localforage from "localforage";

import _get from "lodash.get";

import * as Cookies from "js-cookie";
import { removeSubDomain } from ".";
import { toastOperations } from "../duck/toast";
import { globalCreators } from "../duck/global";

class HomeAPIWrapper {
  constructor() {
    this.apiURL = process.env.REACT_APP_API_BASE_URL;
    axios.defaults.baseURL = this.apiURL;
  }

  setStore(store) {
    if (this.store) return;
    this.store = store;
    axios.interceptors.response.use(null, error => {
      if (!error || !error.response) {
        return Promise.reject(error);
      }

      if (error.response.data) {
        if (
          error.response.data.error === "token_invalid" ||
          error.response.data.error === "user_not_found" ||
          error.response.status === 401
        ) {
          this.manualLogout().then(() => {
            this.store.dispatch(
              toastOperations.addToast({
                id: 999,
                intent: "error",
                text: "Please log back in to the application to use it."
              })
            );
            this.store.dispatch(globalCreators.logoutUser());
          });
        }
      }
      return Promise.reject(error);
    });
  }

  setAuthHeader(token) {
    axios.defaults.headers.common = {
      Authorization: `${token}`
    };
  }

  clearAuthHeader() {
    axios.defaults.headers.common = {};
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async updateChef(chefData) {
    try {
      const { data } = await axios.post(
        "/user/house/updateGuestServicePrivateChef",
        chefData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createChef(chefData) {
    try {
      const { data } = await axios.post(
        "/user/house/createGuestServicePrivateChef",
        chefData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteChef(chefId) {
    try {
      const { data } = await axios.delete(
        "/user/house/deleteGuestServicePrivateChef",
        {
          data: {
            guestServiceId: chefId
          }
        }
      );
      return data;
    } catch (error) {
      window.console.error(error);
      throw error;
    }
  }
  async deleteHost(houseId, hostId) {
    try {
      const { data } = await axios.delete(
        "/user/house/deleteGuestServiceHost",
        {
          data: {
            houseId,
            hostId
          }
        }
      );
      return data;
    } catch (error) {
      window.console.error(error);
      throw error;
    }
  }
  async updateHost(hostData) {
    try {
      const { data } = await axios.post(
        "/user/house/updateGuestServiceHost",
        hostData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return data;
    } catch (error) {
      window.console.error(error);
      throw error;
    }
  }
  async updatePhotoSequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      housePhotos:
        sequences.length > 0
          ? sequences.map(({ id, sequence, isSelected }) => ({
              id,
              sequenceNo: sequence,
              isSelected
            }))
          : []
    };

    try {
      const { data } = await axios.put(
        "/user/house/updateOrderHousePhotos",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updatePOISequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      pois: sequences.map(({ id, sequence, isSelected }) => ({
        id,
        sequenceNo: sequence,
        isSelected
      }))
    };

    try {
      const { data } = await axios.put(
        "/user/house/updateOrderHousePois",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateHostSequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      hosts:
        sequences.length > 0
          ? sequences.map(({ id, sequence, isSelected }) => ({
              id,
              sequenceNo: sequence,
              isSelected
            }))
          : []
    };
    try {
      const { data } = await axios.put(
        "/user/house/updateGuestServiceHostOrder",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateChefSequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      privateChefs: sequences.map(({ id, sequence, isSelected }) => ({
        id,
        sequenceNo: sequence,
        isSelected
      }))
    };
    try {
      const { data } = await axios.put(
        "/user/house/updateGuestServicePrivateChefsOrder",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateTvOfficeSequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      tvOffice:
        sequences.length > 0
          ? sequences.map(({ id, sequence }) => ({
              id,
              sequenceNo: sequence
            }))
          : []
    };
    try {
      const { data } = await axios.put(
        "/user/house/updateTvOfficeOrder",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateHouseFeatureSequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      houseFeatures:
        sequences.length > 0
          ? sequences.map(({ id, sequence }) => ({
              id,
              sequenceNo: sequence
            }))
          : []
    };
    try {
      const { data } = await axios.put(
        "/user/house/updateHouseFeatureOrder",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
      const { data } = await axios.post("/user/change-password", {
        old_password: oldPassword,
        new_password: newPassword
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHosts(houseId) {
    try {
      const { data } = await axios.get(
        "/user/house/getGuestServiceHostsByHouseId",
        {
          params: {
            houseId
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCalendar(houseId) {
    try {
      const { data } = await axios.get(
        "/user/house/getGuestServiceMaintenancesByHouseId",
        {
          params: {
            houseId
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateHousePublishStatus(houseId, publishStatus) {
    try {
      const { data } = await axios.post(
        "/user/house/updateHousePublishStatus",
        null,
        {
          params: {
            houseId,
            isPublished: publishStatus
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateMusicSequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      musics:
        sequences.length > 0
          ? sequences.map(({ id, sequence }) => ({
              id,
              sequenceNo: sequence
            }))
          : []
    };

    try {
      const { data } = await axios.put(
        "/user/house/updateMusicOrder",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateEmergencySequences(houseId, sequences) {
    const sequencePayload = {
      houseId,
      emergency:
        sequences.length > 0
          ? sequences.map(({ id, sequence }) => ({
              id,
              sequenceNo: sequence
            }))
          : []
    };
    try {
      const { data } = await axios.put(
        "/user/house/updateEmergencyOrder",
        sequencePayload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createPhoto(photoData) {
    try {
      const { data } = await axios.post(
        "/user/house/addHousePhotos",
        photoData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deletePhoto(houseId, photos) {
    const photoData = {
      houseId,
      deleteHousePhotos: [...photos]
    };

    try {
      const { data } = await axios.delete("/user/house/deleteHousePhotos", {
        data: photoData
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  async createHost(hostData) {
    try {
      const { data } = await axios.post(
        "/user/house/createGuestServiceHost",
        hostData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeHostAvailability() {}

  async setCalendar() {}

  async updateHouse(updatedData) {
    try {
      const { data } = await axios.put("/user/updateHouse", updatedData);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHouseData(homeId) {
    try {
      const { data } = await axios.get("/user/house/getHouseAllData", {
        params: {
          house_id: homeId
        }
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async createHouse(initData) {
    try {
      const { data: createData } = await axios.post(
        "/user/createHouse",
        initData
      );
      const getData = await this.getHouseData(createData.id);

      const formattedCreateData = {
        id: createData.id,
        houseName: createData.houseName,
        ownerName: createData.ownerName,
        isPublished: createData.isPublished,
        address: {
          fullAddress: createData.address.fullAddress,
          street: createData.address.street,
          city: createData.address.city,
          state: createData.address.state,
          country: createData.address.country,
          zip: createData.address.zip,
          coordinates: {
            lat: createData.address.coordinates.lat,
            lng: createData.address.coordinates.lat
          }
        },
        phone: createData.housePhone,
        email: createData.houseEmail,
        website: createData.homeWebsite,
        image: [],
        check_in_date_time: null,
        check_out_date_time: null,
        permission: {
          type: "owner",
          owner: null
        }
      };
      return { getData, createData: formattedCreateData };
    } catch (error) {
      throw error;
    }
  }

  async deleteHouse(houseId) {
    try {
      const { data } = await axios.delete("/user/deleteHouse", {
        data: { houseId }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async login(userCred) {
    try {
      const { data } = await axios.post(`/user/login`, userCred, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (data.role !== "guest") {
        await this.storeUserCredentials(data, userCred.email);
        this.setAuthHeader(data.token);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  async manualLogout() {
    try {
      const rootDomain = removeSubDomain(window.location.hostname);

      Cookies.remove("SharedAuthorization", {
        domain: rootDomain
      });
      await localforage.removeItem("userData");
      this.clearAuthHeader();
      return true;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    const rootDomain = removeSubDomain(window.location.hostname);
    try {
      const response = await axios.get(`/user/logout`);
      await localforage.removeItem("userData");

      this.clearAuthHeader();

      Cookies.remove("SharedAuthorization", {
        domain: rootDomain
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async setSharedAuth() {
    const rootDomain = removeSubDomain(window.location.hostname);
    const userData = await localforage.getItem("userData");
    try {
      if (!userData) {
        throw new Error("User data token not provided.");
      }
      Cookies.set("SharedAuthorization", userData.token, {
        expires: 7,
        domain: rootDomain
      });

      return;
    } catch (error) {
      throw error;
    }
  }

  async storeUserCredentials(data, email) {
    await localforage.removeItem("userData");
    await localforage.setItem(
      "userData",
      { email, role: data.role, name: data.name, token: data.token },
      err => {
        if (err) throw err;

        return data;
      }
    );
  }

  async fetchAccountPaymentMethods() {
    try {
      const { data } = await axios.get(`/billing/getPaymentMethods`);

      return data;
    } catch (error) {
      throw error;
    }
  }

  async fetchBillingHistory() {
    try {
      const { data } = await axios.get("/billing/getBillingHistory");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateDefaultCard(paymentMethodToken) {
    try {
      const { data } = await axios.patch("/billing/setDefaultPaymentMethod", {
        paymentMethodToken
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAccountPaymentMethod({ paymentMethodToken, paymentMethodNonce }) {
    try {
      const { data } = await axios.put("/billing/updatePaymentMethod", {
        paymentMethodNonce,
        paymentMethodToken
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async createNewPaymentMethod(nonce) {
    try {
      const { data } = await axios.post("/billing/createNewPaymentMethod", {
        paymentMethodNonce: nonce
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async deletePaymentMethod(paymentMethodToken) {
    try {
      const { data } = await axios.delete("/billing/deletePaymentMethod", {
        data: {
          paymentMethodToken
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAvailablePlans() {
    try {
      const { data } = await axios.get("/billing/getAvailablePlans");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async registerSubscription(planId, paymentMethodNonce, paymentMethodToken) {
    try {
      const { data } = await axios.post(`/billing/registerSubscription`, null, {
        params: {
          planId,
          paymentMethodNonce,
          paymentMethodToken
        }
      });

      return {
        planId: data.planId,
        planName: data.planName
      };
    } catch (error) {
      throw error;
    }
  }

  async cancelSubscription() {
    try {
      const { data } = await axios.patch("/billing/cancelSubscription");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeSubscription(newPlanId) {
    try {
      const { data } = await axios.put(
        `/billing/changeSubscription?newPlanId=${newPlanId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async checkUserCredentials() {
    const userData = await localforage.getItem("userData");

    if (!userData) {
      throw new Error("User data is empty");
    } else {
      if (!userData.token) {
        await localforage.removeItem("userData");
        throw new Error("User data does not provide auth token.");
      }

      await this.storeUserCredentials(userData, userData.email);
      this.setAuthHeader(userData.token);
      return userData;
    }
  }

  async initializeInvite(uniqueId) {
    try {
      const { data } = await axios.get("/initializePortalUser", {
        params: {
          uniqueId: uniqueId
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async registerAccount({
    firstName,
    lastName,
    password,
    email,
    companyName,
    phoneNumber,
    addressLine1,
    addressLine2,
    accountType,
    city,
    state,
    zipCode
  }) {
    try {
      const { data } = await axios.post("/user/registration", {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        accountType,
        password,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode
      });

      if (data.token) {
        await this.storeUserCredentials(data, data.email);
        this.setAuthHeader(data.token);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }

  async finalizeInvite({
    email,
    uniqueId,
    password,
    firstName,
    lastName,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    accountType,
    companyName
  }) {
    try {
      const { data } = await axios.post("/finalizePortalUser", {
        uniqueId,
        password,
        firstName,
        lastName,
        accountType,
        companyName,
        phoneNumber,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode
      });
      await this.storeUserCredentials(data, email);
      this.setAuthHeader(data.token);

      return {
        ...data
      };
    } catch (error) {
      throw error;
    }
  }

  async validateResetId(uniqueId) {
    try {
      const { data } = await axios.get(`/user/getEmailFromToken/${uniqueId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(email, password, confirmPassword, uniqueId) {
    try {
      const { data } = await axios.post("/user/resetPassword", {
        email,
        password,
        password_confirmation: confirmPassword,
        token: uniqueId
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendForgotPassword(email) {
    try {
      const { data } = await axios.post("/user/forgotPassword", {
        origin: "admin",
        email
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getUserHouses() {
    try {
      const { data } = await axios.get("/getPortalHomes", {
        headers: {
          "Content-Type": "application/json"
        }
      });
      const merged = [].concat.apply([], data.houses);
      return { houses: merged };
    } catch (error) {
      throw error;
    }
  }

  async getGuests(houseId) {
    try {
      const { data } = await axios.get("/user/house/getGuest", {
        params: { houseId }
      });

      return data.guest ? data.guest : data;
    } catch (error) {
      throw error;
    }
  }

  async resendEmail(guestId, houseId) {
    try {
      const { data } = await axios.post("/user/house/resendInviteEmail", {
        guestId,
        houseId
      });

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getGuestStatus(guestId) {
    try {
      const { data } = await axios.get(`/user/house/getGuestStatus/${guestId}`);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getUserData() {
    try {
      const { data } = await axios.get("/user/getUserData");
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getHouseManagers(houseId) {
    try {
      const response = await axios.get("/user/getHouseManagers", {
        params: { houseId }
      });
      return response.data.manager;
    } catch (error) {
      throw error;
    }
  }

  async deleteGuest(houseId, guestId) {
    try {
      const { data } = await axios.delete("/user/house/removeGuest", {
        data: { houseId, guestId }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createGuest(guestValues) {
    try {
      const { data } = await axios.post("/user/house/createGuest", guestValues);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateGuest(guestValues) {
    try {
      const { data } = await axios.post("/user/house/updateGuest", guestValues);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getHouseById(houseId) {
    try {
      const { data } = await axios.get("/user/getHouse", {
        params: {
          houseId
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCalendar(houseId, values) {
    try {
      const calendarFormData = new FormData();
      calendarFormData.set("houseId", houseId);
      calendarFormData.set(
        "houseKeepingDay",
        _get(values.houseKeeping, "day", "") === null
          ? ""
          : values.houseKeeping.day
      );
      calendarFormData.set(
        "houseKeepingTime",
        _get(values.houseKeeping, "time", "") === null
          ? ""
          : values.houseKeeping.time
      );
      calendarFormData.set(
        "trashPickupDay",
        _get(values.trashPickup, "day", "") === null
          ? ""
          : values.trashPickup.day
      );
      calendarFormData.set(
        "trashPickupTime",
        _get(values.trashPickup, "time", "") === null
          ? ""
          : values.trashPickup.time
      );
      calendarFormData.set(
        "gardenerDay",
        _get(values.gardener, "day", "") === null ? "" : values.gardener.day
      );
      calendarFormData.set(
        "gardenerTime",
        _get(values.gardener, "time", "") === null ? "" : values.gardener.time
      );
      calendarFormData.set(
        "poolJacuzziDay",
        _get(values.poolJacuzzi, "day", "") === null
          ? ""
          : values.poolJacuzzi.day
      );
      calendarFormData.set(
        "poolJacuzziTime",
        _get(values.poolJacuzzi, "time", "") === null
          ? ""
          : values.poolJacuzzi.day
      );

      const { data } = await axios.post(
        "/user/house/updateGuestServiceMaintenance",
        calendarFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createUnapprovedPOI(poiData) {
    try {
      const { data } = await axios.post(
        "/user/house/createUnapprovedPOIs",
        poiData
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFeaturePage(houseId, type, featureData, houseFeatureId) {
    const getPathByType = type => {
      switch (type) {
        case "tvOffice": {
          return `/user/house/${houseId}/addTvOffice`;
        }
        case "music": {
          return `/user/house/${houseId}/addMusic`;
        }
        case "emergency": {
          return `/user/house/${houseId}/addEmergency`;
        }
        case "security":
        case "doorsAndWindowsCategory":
        case "waterEqp":
        case "beAware":
        case "outside":
        case "tech":
        case "kitchen":
        case "kids":
        case "health":
        case "garage":
        case "floors":
        case "fansAC":
        case "guestHouse":
        case "inHouse": {
          return `/user/house/${houseId}/addHouseFeature`;
        }
        default: {
          return null;
        }
      }
    };

    const featureFormData = new FormData();

    featureFormData.set("key", featureData.key);
    featureFormData.set("name", featureData.name);
    featureFormData.set("sequence", featureData.sequence);

    if (houseFeatureId) {
      featureFormData.set("inHouseFeatureId", houseFeatureId);
    }

    for (var i = 0; i < featureData.items.length; i++) {
      const featureDataItem = featureData.items[i];

      featureFormData.append(
        "pageRenderedImage[]",
        featureDataItem.pageRenderedImage
      );
      featureFormData.append("pagePhotoImage[]", featureDataItem.photoImage);
      featureFormData.append("pageSequence[]", i + 1);
      featureFormData.append("pageText[]", featureDataItem.text);

	  if (_get(featureDataItem, 'coords.photo')) {
		featureFormData.append("pagePhotoX[]", featureDataItem.coords.photo.x);
		featureFormData.append("pagePhotoY[]", featureDataItem.coords.photo.y);

		featureFormData.append(
		  "pagePhotoWidth[]",
		  featureDataItem.coords.photo.width
		);
		featureFormData.append(
		  "pagePhotoHeight[]",
		  featureDataItem.coords.photo.height
		);
	  }

	  if (_get(featureDataItem, 'coords.arc')) {

		featureFormData.append(
		  "pageCircleX[]",
		  _get(featureDataItem.coords, "arc.x", null)
		);
		featureFormData.append(
		  "pageCircleY[]",
		  _get(featureDataItem.coords, "arc.y", null)
		);
		featureFormData.append(
		  "pageCircleWidth[]",
		  _get(featureDataItem.coords, "arc.radius", null)
		);
		featureFormData.append(
		  "pageCircleHeight[]",
		  _get(featureDataItem.coords, "arc.radius", null)
		);
	  }

      featureFormData.append("pageVideoUrl[]", featureDataItem.videoURL || "");
      featureFormData.append(
        "pageUserManualUrl[]",
        featureDataItem.userManualURL || ""
      );
    }

    try {
      const { data } = await axios.post(getPathByType(type), featureFormData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async generateClientToken() {
    try {
      const { data } = await axios.get("/billing/generateClientToken");
      return data.clientToken;
    } catch (error) {
      throw error;
    }
  }

  async updateAccountProfileInfo(profileInfo) {
    const updateData = {
      newFirstName: profileInfo.firstName,
      newLastName: profileInfo.lastName,
      companyName: profileInfo.companyName,
      phoneNumber: profileInfo.phoneNumber
    };
    try {
      const { data } = await axios.put("/user/updateProfileInfo", updateData);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeAccountEmail(newEmail) {
    try {
      const { data } = await axios.put("/user/updateUserEmail", { newEmail });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFeature(houseId, type, featureId, houseFeatureId) {
    const getPathByType = type => {
      switch (type) {
        case "tvOffice": {
          return `/user/house/${houseId}/deleteTvOffice`;
        }
        case "music": {
          return `/user/house/${houseId}/deleteMusic`;
        }
        case "emergency": {
          return `/user/house/${houseId}/deleteEmergency`;
        }
        case "security":
        case "doorsAndWindowsCategory":
        case "waterEqp":
        case "beAware":
        case "outside":
        case "tech":
        case "kitchen":
        case "kids":
        case "health":
        case "garage":
        case "floors":
        case "fansAC":
        case "guestHouse":
        case "inHouse": {
          return `/user/house/${houseId}/deleteHouseFeature`;
        }
        default: {
          return null;
        }
      }
    };

    const deleteFeatureParams = {
      ...(getPathByType(type) === `/user/house/${houseId}/deleteHouseFeature`
        ? { houseFeatureId: featureId }
        : { id: featureId })
    };

    try {
      const { data } = await axios.delete(getPathByType(type), {
        params: deleteFeatureParams,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getBillingAddress() {
    try {
      const { data } = await axios.get("/user/getProfileAddress");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateBillingAddress(values) {
    try {
      const { data } = await axios.post("/user/updateProfileAddress", {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFeature(
    houseId,
    type,
    featureId,
    featureData,
    originalFeatureData
  ) {
    const getPathByType = type => {
      switch (type) {
        case "tvOffice": {
          return `/user/house/${houseId}/updateTvOffice`;
        }
        case "music": {
          return `/user/house/${houseId}/updateMusic`;
        }
        case "emergency": {
          return `/user/house/${houseId}/updateEmergency`;
        }
        case "security":
        case "doorsAndWindowsCategory":
        case "waterEqp":
        case "beAware":
        case "outside":
        case "tech":
        case "kitchen":
        case "kids":
        case "health":
        case "garage":
        case "floors":
        case "fansAC":
        case "guestHouse":
        case "inHouse": {
          return `/user/house/${houseId}/updateHouseFeature`;
        }
        default: {
          return null;
        }
      }
    };

    const featureFormData = new FormData();

    featureFormData.set("id", featureId);
    featureFormData.set("key", featureData.key);
    featureFormData.set("sequence", featureData.sequence);

    for (var i = 0; i < featureData.items.length; i++) {
      // new form data for the specific feature page
      const featureFormItem = featureData.items[i];
      // original form data from store to compare with new values
      const originalFeatureFormItem = originalFeatureData.items.find(
        ({ id }) => id === featureFormItem.id
      );

      // always append the pageId and pageSequence with the request
      featureFormData.append(`pageId[${i}]`, featureFormItem.id);
      featureFormData.append(`pageSequence[${i}]`, i + 1);

      // if the photoImage is a newly uploaded image and not a URL, append new iamge
      if (featureFormItem.photoImage instanceof Blob) {
        featureFormData.append(
          `pagePhotoImage[${i}]`,
          featureFormItem.photoImage
        );
      }

      // if any coords have changed/new image uploaded, save new rendered image
      if (
        _get(originalFeatureFormItem, "coords.photo.x", null) !==
		_get(featureFormItem, 'coords.photo.x') ||
        _get(originalFeatureFormItem, "coords.photo.y", null) !==
          _get(featureFormItem, "coords.photo.y", null) ||
        _get(originalFeatureFormItem.coords, "circle.x", null) !==
          _get(featureFormItem, "coords.arc.x", null) ||
        _get(originalFeatureFormItem.coords, "circle.y", null) !==
          _get(featureFormItem, "coords.arc.y", null) ||
        _get(originalFeatureFormItem.coords, "circle.radius", null) !==
          _get(featureFormItem, "coords.arc.radius", null) ||
        featureFormItem.photoImage instanceof Blob
      ) {
        featureFormData.append(
          `pageRenderedImage[${i}]`,
          featureFormItem.pageRenderedImage
        );
      }

      featureFormData.append(`pageText[${i}]`, featureFormItem.text);

	  if (_get(featureFormItem, 'coords.photo')) {

		featureFormData.append(
		  `pagePhotoX[${i}]`,
		  featureFormItem.coords.photo.x
		);

		featureFormData.append(
		  `pagePhotoY[${i}]`,
		  featureFormItem.coords.photo.y
		);

		featureFormData.append(
		  `pagePhotoWidth[${i}]`,
		  featureFormItem.coords.photo.width
		);

		featureFormData.append(
		  `pagePhotoHeight[${i}]`,
		  featureFormItem.coords.photo.height
		);
	  }

	  if (_get(featureFormItem, 'coords.arc')) {

		featureFormData.append(
		  `pageCircleX[${i}]`,
		  _get(featureFormItem.coords, "arc.x", null)
		);

		featureFormData.append(
		  `pageCircleY[${i}]`,
		  _get(featureFormItem.coords, "arc.y", null)
		);

		featureFormData.append(
		  `pageCircleWidth[${i}]`,
		  _get(featureFormItem.coords, "arc.radius", null)
		);

		featureFormData.append(
		  `pageCircleHeight[${i}]`,
		  _get(featureFormItem.coords, "arc.radius", null)
		);
	  }

      featureFormData.append("pageVideoUrl[]", featureFormItem.videoURL || "");

      featureFormData.append(
        "pageUserManualUrl[]",
        featureFormItem.userManualURL || ""
      );
    }

    try {
      const { data } = await axios.post(getPathByType(type), featureFormData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getRegionsList() {
    try {
      const { data } = await axios.get("/user/getRegionsList");

      return data.regions;
    } catch (error) {
      throw error;
    }
  }

  async updateHouseVideo(houseId, link) {
    try {
      const { data } = await axios.put(
        `/user/house/${houseId}/updateHouseVideo`,
        {
          link
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateHouseVrTour(houseId, link) {
    try {
      const { data } = await axios.put(
        `/user/house/${houseId}/updateHouseVrTour`,
        {
          link
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export const api = new HomeAPIWrapper();
export default HomeAPIWrapper;
