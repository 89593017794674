import types from "./types";

const setUserData = userData => ({
  type: types.SET_USER_DATA,
  userData
});

const setAccountProfileInfo = profileInfo => ({
  type: types.SET_ACCOUNT_PROFILE_INFO,
  profileInfo
});

const setAccountEmail = newEmail => ({
  type: types.SET_ACCOUNT_EMAIL,
  newEmail
});

const setAccountPaymentMethods = methods => ({
  type: types.SET_ACCOUNT_PAYMENT_METHODS,
  methods
});

const setAccountPaymentMethod = method => ({
  type: types.SET_ACCOUNT_PAYMENT_METHOD,
  method
});

const setAccountBillingHistory = billingHistory => ({
  type: types.SET_ACCOUNT_BILLING_HISTORY,
  billingHistory
});

const setDefaultCard = paymentMethodToken => ({
  type: types.SET_DEFAULT_CARD,
  paymentMethodToken
});

const addNewPaymentMethod = (paymentMethod, methodType) => ({
  type: types.ADD_NEW_PAYMENT_METHOD,
  paymentMethod,
  methodType
});

const removePaymentMethod = paymentMethodToken => ({
  type: types.REMOVE_PAYMENT_METHOD,
  paymentMethodToken
});

const createAccount = ({
  email,
  id,
  first_name,
  last_name,
  company_name,
  phone_number,
  account_type,
  role,
  token,
  plan_name,
  plan_id
}) => ({
  type: types.CREATE_ACCOUNT,
  email,
  id,
  first_name,
  last_name,
  role,
  company_name,
  account_type,
  phone_number,
  plan_name,
  plan_id,
  token
});

const setClientToken = token => ({
  type: types.SET_CLIENT_TOKEN,
  token
});

const addSubscription = subscription => ({
  type: types.ADD_SUBSCRIPTION,
  subscription
});

const setAvailablePlans = plans => ({
  type: types.SET_AVAILABLE_PLANS,
  plans
});

const removeSubscription = () => ({
  type: types.REMOVE_SUBSCRIPTION
});

const setBillingAddress = billingAddress => ({
  type: types.SET_BILLING_ADDRESS,
  billingAddress
});

const clearAccountData = () => ({
  type: types.CLEAR_ACCOUNT_DATA
});

export default {
  setUserData,
  setAccountProfileInfo,
  setAccountEmail,
  setAccountPaymentMethods,
  setAccountPaymentMethod,
  setAccountBillingHistory,
  setDefaultCard,
  addNewPaymentMethod,
  removePaymentMethod,
  setClientToken,
  addSubscription,
  setAvailablePlans,
  removeSubscription,
  setBillingAddress,
  createAccount,
  clearAccountData
};
