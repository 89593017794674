import React, { Component } from "react";
import ReactDOM from "react-dom";
import { NativeTypes } from "react-dnd-html5-backend";

import { DropTarget } from "react-dnd";

const { FILE } = NativeTypes;

export class UploadWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWrapper: false,
      isOver: false,
      portalDest: null
    };
  }

  componentDidMount() {
    const uploadWrapperContainer = document.querySelector(
      ".upload-wrapper-portal"
    );
    if (uploadWrapperContainer) {
      this.setState({ portalDest: uploadWrapperContainer });
    }
  }

  handleFileDrop = (item, monitor) => {
    if (monitor) {
      this.setState({ droppedFile: monitor.getItem().files[0] });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.canDrop !== this.props.canDrop) {
      this.setState({ showWrapper: this.props.canDrop }, () => {});
    }
  }

  // fired onDrop of upload-wrapper
  onDrop = evt => {
    evt.preventDefault();
    const { onUpload } = this.props;
    const files = evt.dataTransfer.files;
    onUpload(files);
  };

  render() {
    const { showWrapper } = this.state;
    const { connectDropTarget } = this.props;
    if (!this.state.portalDest) {
      return null;
    }
    return ReactDOM.createPortal(
      connectDropTarget(
        <div className={`upload-wrapper ${!showWrapper ? "hidden" : ""}`}>
          <div className="upload-wrapper__inner">
            <h3>Drop to upload!</h3>
          </div>
        </div>
      ),
      this.state.portalDest
    );
  }
}

export default DropTarget(
  props => [FILE],
  {
    drop(props, monitor) {
      props.onUpload(monitor.getItem().files);
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(UploadWrapper);
