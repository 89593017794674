const SET_USER_DATA = "SET_USER_DATA";
const SET_ACCOUNT_PROFILE_INFO = "SET_ACCOUNT_PROFILE_INFO";
const SET_ACCOUNT_EMAIL = "SET_ACCOUNT_EMAIL";
const SET_ACCOUNT_PAYMENT_METHODS = "SET_ACCOUNT_PAYMENT_METHODS";
const SET_ACCOUNT_PAYMENT_METHOD = "SET_ACCOUNT_PAYMENT_METHOD";
const SET_ACCOUNT_BILLING_HISTORY = "SET_ACCOUNT_BILLING_HISTORY";
const SET_DEFAULT_CARD = "SET_DEFAULT_CARD";
const ADD_NEW_PAYMENT_METHOD = "ADD_NEW_PAYMENT_METHOD";
const REMOVE_PAYMENT_METHOD = "REMOVE_PAYMENT_METHOD";
const SET_CLIENT_TOKEN = "SET_CLIENT_TOKEN";
const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
const SET_AVAILABLE_PLANS = "SET_AVAILABLE_PLANS";
const REMOVE_SUBSCRIPTION = "REMOVE_SUBSCRIPTION";
const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
const CREATE_ACCOUNT = "CREATE_ACCOUNT";
const CLEAR_ACCOUNT_DATA = "CLEAR_ACCOUNT_DATA";
export default {
  SET_USER_DATA,
  SET_ACCOUNT_PROFILE_INFO,
  SET_ACCOUNT_EMAIL,
  SET_ACCOUNT_PAYMENT_METHODS,
  SET_ACCOUNT_PAYMENT_METHOD,
  SET_ACCOUNT_BILLING_HISTORY,
  SET_DEFAULT_CARD,
  ADD_NEW_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
  SET_CLIENT_TOKEN,
  ADD_SUBSCRIPTION,
  SET_AVAILABLE_PLANS,
  REMOVE_SUBSCRIPTION,
  SET_BILLING_ADDRESS,
  CREATE_ACCOUNT,
  CLEAR_ACCOUNT_DATA
};
