import React from "react";
import {
  faPlus,
  faMinus,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CircleIcon = ({ type }) => {
  return (
    <div
      className={`photo-canvas-fab__circleicon ${`photo-canvas-fab__circleicon--${type}`}`}
    >
      <FontAwesomeIcon
        icon={type === "add" ? faPlus : type === "edit" ? faPencilAlt : faMinus}
      />
    </div>
  );
};

const ImageIcon = ({ type }) => {
  return (
    <div
      className={`photo-canvas-fab__imageicon ${`photo-canvas-fab__imageicon--${type}`}`}
    >
      <div className="photo-canvas-fab__imageiconinner" />
    </div>
  );
};

function PhotoCanvasFab({ children, disabled, icon, type, onClick }) {
  return (
    <div
      className={`photo-canvas-fab ${
        disabled ? `photo-canvas-fab--disabled` : ""
      }`}
      onClick={onClick}
    >
      <div className={`photo-canvas-fab__inner`}>
        <div className={`photo-canvas-fab__icon`}>
          {icon === "circle" && <CircleIcon type={type} />}
          {icon === "image" && <ImageIcon type={type} />}
        </div>
        <div className={`photo-canvas-fab__text`}>
          <p>{children}</p>
        </div>
      </div>
    </div>
  );
}

export default PhotoCanvasFab;
