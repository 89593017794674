import React, { Component } from "react";
import { connect } from "react-redux";

import HouseFeaturesSelector from "./HouseFeaturesSelector.js";

export class HouseFeaturesController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      options: [],
      categories: null,
      currentCategory: this.props.value
    };
  }

  componentDidMount() {
    const { currentCategory } = this.state;

    this.setGridItems(currentCategory);
  }

  componentDidUpdate(prevProps) {
    // if the values of play dont match or categories is not yet set
    if (
      JSON.stringify(prevProps.houseFeatures) !==
        JSON.stringify(this.props.houseFeatures) ||
      this.state.categories === null
    ) {
      this.computeCategoryObjects(this.props.houseFeatures);
      this.setGridItems(this.state.currentCategory);
    }
  }
  setGridItems(currentCategory) {
    const { houseFeatures } = this.props;
    const categoryValues = houseFeatures[currentCategory];

    const options = categoryValues.options.map(optionsValue => {
      const inOptions = categoryValues.selected.find(
        selectedValue => optionsValue.key === selectedValue.key
      );
      return {
        ...optionsValue,
        isSelected: inOptions ? true : false
      };
    });
    if (categoryValues) {
      this.setState({
        selected: categoryValues.selected,
        options: options
      });
    }
  }
  computeCategoryObjects(items) {
    const categories = Object.keys(items)
      .map(itemKey => ({
        id: items[itemKey].id,
        key: itemKey,
        image: items[itemKey].image,
        name: items[itemKey].name,
        isEmpty: items[itemKey].selected.length === 0,
		sequenceNo: items[itemKey]['sequenceNo']
      }))
      .sort((a, b) => {
		return a.sequenceNo > b.sequenceNo ? 1 : -1
	  });
    this.setState({ categories });
  }

  handleCategoryChange = (name, key, icon) => {
    const { onCategoryChange } = this.props;
    this.setState({ currentCategory: name }, () => {
      this.setGridItems(this.state.currentCategory);
      onCategoryChange(this.state.currentCategory);
    });
  };
  render() {
    const { children } = this.props;
    const { selected, options, categories, currentCategory } = this.state;
    return (
      <div className="feature-controller">
        <div className="feature-controller__inner">
          <div className="feature-controller__selector">
            {categories && (
              <HouseFeaturesSelector
                categories={categories}
                activeKey={currentCategory}
                onClick={this.handleCategoryChange}
              />
            )}
          </div>
          <div className="feature-controller__content">
            {categories && children({ selected, options, categories })}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    houseFeatures: state.builder.currentBuilder[ownProps.pageHash].values,
    pageValues:
      state.builder.currentBuilder[ownProps.pageHash].values[ownProps.value]
  };
};

export default connect(mapState)(HouseFeaturesController);
