import { Yup } from "hc-utils/FormValidator";

export const PropertyInfoSchema = Yup.object().shape({
  houseName: Yup.string()
    .max(23, "The house name must be shorter than 16 characters.")
    .required("Home name is required."),
  ownerName: Yup.string().nullable(),
  houseAddr: Yup.object()
    .shape({
      fullAddress: Yup.string().nullable(),
      lat: Yup.number().nullable(),
      lng: Yup.number().nullable()
    })
    .test("is-valid-addr", "Must select a valid address", val => {
      if (!val.fullAddress) return false;
      if (!val.lat || !val.lng) return false;
      return true;
    })
    .required(),
  housePhone: Yup.string().nullable(),
  homeEmail: Yup.string().email("Invalid email.").nullable(),
  homeWebsite: Yup.string().laxUrl("Website is invalid.").nullable(),
  homeWifiNetworkName: Yup.string().nullable(),
  homeWifiNetworkPass: Yup.string().nullable(),
  homeGateCode: Yup.string().nullable(),
  homeDoorCode: Yup.string().nullable(),
  homeSecurityInfo: Yup.string().nullable(),
  homeCheckOutInfo: Yup.string().nullable(),
  houseRegion: Yup.object()
    .shape({
      value: Yup.number().required("You must select a region for the home."),
      label: Yup.string().required("You must select a region for the home.")
    })
    .nullable()
    .required("You must select a region for the home."),
  book_airbnb: Yup.string().laxUrl("Website is invalid").nullable(),
  book_vrbo: Yup.string().laxUrl("Website is invalid").nullable(),
  book_luxe: Yup.string().laxUrl("Website is invalid").nullable(),
  book_other_name: Yup.string()
  .when("book_other_url", {
    is: value => value && value.length > 0,
    then: Yup.string().nullable().required("Provide a name for the site."),
    otherwise: Yup.string().nullable()
  }),
  book_other_url: Yup.string().laxUrl("Website is invalid").nullable()
});

export const PropertyInfoInit = {
  houseName: "",
  ownerName: "",
  houseAddr: "",
  housePhone: "",
  homeEmail: ""
};
