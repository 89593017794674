import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import _get from "lodash.get";
const GuestHostList = ({
  type,
  hosts,
  homeHosts,
  values,
  onChange,
  onBlur,
  title,
  error
}) => {
  const [options, setOptions] = useState(null);
  useEffect(() => {
    if (!hosts) {
      setOptions([]);
      return;
    }
    const optionsObj = hosts.selected
      .concat(hosts.available)
      .map(({ id, first_name, last_name, sequence_no }) => ({
        value: id,
        label: `${first_name} ${last_name}`,
        isDisabled:
          type === "main" &&
          _get(values.backupHostId, "value", null) === parseInt(id)
            ? true
            : type === "backup" &&
              _get(values.mainHostId, "value", null) === parseInt(id)
            ? true
            : false
      }));
    setOptions(optionsObj);
  }, [hosts, values, homeHosts, type]);

  const defaultValue = {
    value: homeHosts[type] ? homeHosts[type].id : null,
    label: homeHosts[type]
      ? `${homeHosts[type].first_name} ${homeHosts[type].last_name}`
      : "No Host Selected",
    isDisabled: false,
    isDefault: true
  };

  const optionsWithDefault = options ? options.concat(defaultValue) : [];
  const value = values[type === "main" ? "mainHostId" : "backupHostId"];
  return (
    <div className="guest-host-list">
      <div className="guest-host-list__header">
        <h4>{title}</h4>
      </div>
      <div className="guest-host-list__select">
        <Select
          value={_get(value, "value", false) ? value : defaultValue}
          onChange={val => onChange(val, type)}
          options={optionsWithDefault}
          isDisabled={true}
          placeholder={"No Host Selected"}
          onBlur={() => onBlur(type)}
          getOptionLabel={option =>
            `${option.label} ${option.isDefault ? "(Default)" : ""}`
          }
          styles={{
            container: provided => ({
              ...provided,
              width: "100%"
            }),
            option: (provided, props) => {
              return {
                ...provided,
                borderTop: props.data.isDefault ? "2px solid #56a6ff" : "",
                " span": {
                  fontWeight: "bold",
                  marginLeft: "5px",

                  color: value ? "#1683fc" : "white"
                }
              };
            },
            singleValue: (provided, props) => ({
              ...provided,
              " span": {
                fontWeight: "bold",
                marginLeft: "5px",
                color: "#1683fc"
              }
            })
          }}
        />
      </div>
      {error && (
        <div className="guest-host-list__error">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

GuestHostList.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  homeHosts: PropTypes.objectOf(PropTypes.object),
  hosts: PropTypes.shape({
    available: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        id: PropTypes.any.isRequired
      })
    ),
    selected: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        id: PropTypes.any.isRequired
      })
    ),
    feature_id: PropTypes.number
  }),
  loading: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  values: PropTypes.object
};

export default GuestHostList;
