import types from "./types";

export default (state = {}, action) => {
  switch (action.type) {
    case types.SET_USER_DATA: {
      return {
        ...state,
        accountInfo: action.userData
      };
    }

    case types.CREATE_ACCOUNT: {
      const {
        email,
        id,
        first_name,
        last_name,
        phone_number,
        company_name,
        plan_id,
        plan_name,
        account_type,
        role,
        token
      } = action;

      return {
        ...state,
        accountInfo: {
          email,
          id,
          first_name,
          last_name,
          phone_number,
          company_name,
          account_type,
          plan_id,
          plan_name,
          role,
          token
        }
      };
    }

    case types.SET_ACCOUNT_EMAIL: {
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          ...{
            email: action.newEmail
          }
        }
      };
    }

    case types.SET_ACCOUNT_PROFILE_INFO: {
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          ...{
            first_name: action.profileInfo.firstName,
            last_name: action.profileInfo.lastName
          }
        }
      };
    }

    case types.SET_ACCOUNT_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: action.methods
      };
    }
    case types.ADD_SUBSCRIPTION: {
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          plan_id: action.subscription.planId,
          plan_name: action.subscription.planName
        }
      };
    }
    case types.SET_ACCOUNT_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethods: state.paymentMethods.map(method => {
          if (method.token === action.method.token) {
            return {
              ...action.method,
              type: "creditCard"
            };
          } else {
            return method;
          }
        })
      };
    }
    case types.SET_ACCOUNT_BILLING_HISTORY: {
      return {
        ...state,
        billing: {
          transactions: action.billingHistory
        }
      };
    }
    case types.SET_DEFAULT_CARD: {
      return {
        ...state,
        paymentMethods: state.paymentMethods.map(method => {
          if (method.default) {
            return {
              ...method,
              default: false
            };
          }

          if (method.token === action.paymentMethodToken) {
            return {
              ...method,
              default: true
            };
          }
          return method;
        })
      };
    }
    case types.ADD_NEW_PAYMENT_METHOD: {
      const alreadyExists = state.paymentMethods.find(
        ({ token, uniqueNumberIdentifier }) =>
          action.paymentMethod.uniqueNumberIdentifier
            ? action.paymentMethod.uniqueNumberIdentifier ===
              uniqueNumberIdentifier
            : action.paymentMethod.token === token
      );
      return {
        ...state,
        paymentMethods: alreadyExists
          ? state.paymentMethods
          : state.paymentMethods.concat({
              ...action.paymentMethod,
              default:
                state.paymentMethods.length === 0
                  ? true
                  : action.paymentMethod.default,
              type: action.methodType
            })
      };
    }
    case types.REMOVE_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          method => method.token !== action.paymentMethodToken
        )
      };
    }
    case types.SET_CLIENT_TOKEN: {
      return {
        ...state,
        clientToken: action.token
      };
    }
    case types.SET_AVAILABLE_PLANS: {
      return {
        ...state,
        availablePlans: action.plans
      };
    }
    case types.REMOVE_SUBSCRIPTION: {
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          plan_id: null,
          plan_name: null
        }
      };
    }

    case types.SET_BILLING_ADDRESS: {
      return {
        ...state,
        billingAddress: action.billingAddress
      };
    }
    case types.CLEAR_ACCOUNT_DATA: {
      return {};
    }
    default: {
      return state;
    }
  }
};
