import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { builderOperations } from "../../duck";
import { toastOperations } from "../../../../duck/toast";
import { canvasToFile } from "hc-utils";
import BuilderPageWrapper from "../../BuilderPageWrapper";
import FeatureController from "../../FeatureController";
import FeatureSelectedGrid from "../../FeatureSelectedGrid";
import FeatureOptionsGrid from "../../FeatureOptionsGrid";
import WarningPrompt from "../../../common/WarningPrompt";

import { Helmet } from "react-helmet";
import _get from "lodash.get";
import FeatureViewPrompt from "../../FeatureViewPrompt";

export class Music extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPrompt: false,
      queuedData: null
    };
  }

  getFeatureById(id) {
    const { music } = this.props;

    if (music.selected.find(musicObject => id === musicObject.id)) {
      return music.selected.find(musicObject => id === musicObject.id);
    } else {
      return null;
    }
  }

  handleNew = (key, name, icon, id, prefilled) => {
    const featureData = this.getFeatureById(id);
    this.setState({
      newPrompt: true,
      queuedCategory: { key, name, icon, prefilled },
      queuedData: featureData
    });
  };

  onDragStop = (
    id,
    originalAvailability,
    newAvailability,
    originalIndex,
    newIndex
  ) => {
    const { changeSelectedGridItemSequences, currentPageHash } = this.props;

    changeSelectedGridItemSequences(
      parseInt(currentPageHash),
      "music",
      originalAvailability,
      id,
      newIndex,
      true
    );
  };

  onEdit = ({ id }) => {
    const { music } = this.props;
    const featureData = this.getFeatureById(id);
    const option = music.options.find(({ key }) => key === featureData.key);

    this.setState({
      newPrompt: true,
      queuedData: featureData,
      queuedCategory: {
        key: featureData.key,
        name: featureData.name,
        icon: option.image,
        prefilled: option.prefilled
      }
    });
  };
  onDelete = ({ id }) => {
    this.setState({ showDeleteModal: true, queuedDeleteData: id });
  };

  deleteMusic = id => {
    const {
      currentPageHash,

      addToast,
      deleteFeature
    } = this.props;

    deleteFeature(currentPageHash, "music", "selected", id)
      .then(() => {
        addToast({ text: "Succesfully removed info page." });
      })
      .catch(error => {
        addToast({ text: error.message, intent: "error" });
      });
  };
  handleSubmit = values => {
    const {
      createFeaturePage,

      updateFeature,
      addToast,
      music
    } = this.props;
    const { queuedCategory, queuedData } = this.state;

    if (!queuedData) {
      createFeaturePage("8", "music", "selected", {
        name: queuedCategory.name,
        key: queuedCategory.key,
        sequence: music.selected.length,
        items: values.features.map((value, i) => ({
          id: value.id,
          sequence: i + 1,
          text: value.text,
          photoImage: _get(value.image, "file", null),
          pageRenderedImage: value.canvas ? canvasToFile(value.canvas.canvas, "image/png", 1) : null,
          coords: {
            photo: value.coords.photo,
            arc: value.coords.circle
          },
          videoURL: value.video_url,
          userManualURL: value.user_manual_url,
          isNew: Boolean(value.isNew)
        }))
      })
        .then(() => {
          this.setState({
            newPrompt: false,
            queuedCategory: null,
            queuedData: null
          });
          addToast({ text: "Succesfully added feature." });
        })
        .catch(error => {
          addToast({ text: error.message, intent: "error" });
        });
    } else {
      updateFeature("8", "music", "selected", queuedData.id, {
        name: queuedCategory.name,
        key: queuedCategory.key,
        sequence: queuedData.sequence,
        items: values.features
          .filter(value => value !== null)
          .map(value => {
            return {
              id: value.id,
              text: value.text || "",
              pageRenderedImage: this.state.isNew
                ? null
                : value.canvas ? canvasToFile(value.canvas.canvas, "image/jpeg", 0.8) : null,

              photoImage: _get(value.image, "file", false),

              coords: {
                photo: value.coords.photo,
                arc: value.coords.circle
              },
              videoURL: value.video_url,
              userManualURL: value.user_manual_url,
              isNew: Boolean(value.isNew)
            };
          })
      })
        .then(() => {
          this.setState({
            newPrompt: false,
            queuedCategory: null,
            queuedData: null
          });
          addToast({ text: "Succesfully updated feature." });
        })
        .catch(error => {
          addToast({ text: error.message, intent: "error" });
        });
    }
  };

  render() {
    const { pageKey, propertyInfoBuilder } = this.props;
    const {
      newPrompt,
      queuedCategory,
      queuedData,
      showDeleteModal,
      queuedDeleteData
    } = this.state;
    return (
      <>
        <Helmet>
          <title>
            Home Concierge | {propertyInfoBuilder.houseName} | Music
          </title>
          <meta
            name="description"
            content="Add a page describing your houses music features."
          />
        </Helmet>
        <BuilderPageWrapper pageKey={pageKey}>
          <FeatureController pageHash={8} value={"music"}>
            {({ selected, options }) => (
              <>
                <FeatureSelectedGrid
                  items={selected}
                  onDragStop={this.onDragStop}
                  onEdit={this.onEdit}
                  onDelete={this.onDelete}
                  options={options}
                />
                <div className="grid-seperator">OR</div>
                <FeatureOptionsGrid items={options} onNew={this.handleNew} />
              </>
            )}
          </FeatureController>
        </BuilderPageWrapper>
        {newPrompt && (
          <FeatureViewPrompt
            data={queuedData}
            icon={queuedCategory.icon}
            title={queuedCategory.name}
            prefilled={queuedCategory.prefilled}
            onSubmit={this.handleSubmit}
            onClose={() =>
              this.setState({
                newPrompt: false,
                queuedData: null
              })
            }
          />
        )}
        {showDeleteModal && (
          <WarningPrompt
            onClose={() =>
              this.setState({
                showDeleteModal: false,
                queuedDeleteData: null
              })
            }
            onConfirm={() => {
              this.setState({ showDeleteModal: false }, () => {
                this.deleteMusic(queuedDeleteData);
              });
            }}
            onCancel={() =>
              this.setState({
                showDeleteModal: false,
                queuedDeleteData: null
              })
            }
          >
            Are you sure you want to delete this feature page?
          </WarningPrompt>
        )}
      </>
    );
  }
}

const mapState = (state, ownProps) => ({
  currentPageHash: parseInt(
    Object.keys(state.builder.currentBuilder).find(
      pageHash => state.builder.currentBuilder[pageHash].key === "music"
    )
  ),
  music: state.builder.currentBuilder[8].values["music"],
  propertyInfoBuilder: _get(
    state.builder.currentBuilder[1].values,
    "propertyInfo.form",
    null
  )
});

const mapDispatch = dispatch => ({
  removeSelectedGridItem: (pageHash, value, availabilityType, id) =>
    dispatch(
      builderOperations.removeSelectedGridItem(
        pageHash,
        value,
        availabilityType,
        id
      )
    ),
  addSelectedGridItem: (pageHash, value, availabilityType, itemData) =>
    dispatch(
      builderOperations.addSelectedGridItem(
        pageHash,
        value,
        availabilityType,
        itemData
      )
    ),

  changeSelectedGridItemSequences: (
    pageHash,
    value,
    availabilityType,
    id,
    newSequence,
    isCompact
  ) =>
    dispatch(
      builderOperations.changeSelectedGridItemSequences(
        pageHash,
        value,
        availabilityType,
        id,
        newSequence,
        isCompact
      )
    ),
  changeGridItemAvailability: (
    originalAvailability,
    newAvailability,
    pageHash,
    value,
    id,
    isCompact
  ) =>
    dispatch(
      builderOperations.changeGridItemAvailability(
        originalAvailability,
        newAvailability,
        pageHash,
        value,
        id,
        isCompact
      )
    ),
  updateGridItem: (pageHash, value, availabilityType, id, newValues) =>
    dispatch(
      builderOperations.updateGridItem(
        pageHash,
        value,
        availabilityType,
        id,
        newValues
      )
    ),
  createFeaturePage: (pageHash, value, availabilityType, featureData) =>
    dispatch(
      builderOperations.createFeaturePage(
        pageHash,
        value,
        availabilityType,
        featureData
      )
    ),
  deleteFeature: (pageHash, value, availabilityType, featureId) =>
    dispatch(
      builderOperations.deleteFeature(
        pageHash,
        value,
        availabilityType,
        featureId
      )
    ),
  updateFeature: (pageHash, value, availabilityType, featureId, featureData) =>
    dispatch(
      builderOperations.updateFeature(
        pageHash,
        value,
        availabilityType,
        featureId,
        featureData
      )
    ),

  addToast: options => dispatch(toastOperations.addToast(options))
});

Music.propTypes = {
  addSelectedGridItem: PropTypes.func,
  addToast: PropTypes.func,
  changeGridItemAvailability: PropTypes.func,
  changeSelectedGridItemSequence: PropTypes.func,
  currentPageHash: PropTypes.number,
  errors: PropTypes.object,
  music: PropTypes.object,
  pageKey: PropTypes.string,
  pageVisited: PropTypes.bool,
  removeSelectedGridItem: PropTypes.func,
  touched: PropTypes.object,
  updateGridItem: PropTypes.func,
  values: PropTypes.object
};

export default connect(mapState, mapDispatch)(Music);
