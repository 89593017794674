import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { globalOperations } from "../../duck/global";
import { toastOperations } from "../../duck/toast";
import { connect } from "react-redux";
import IconSet from "./IconSet";

const LinkWrapper = ({ outbound, url, link, className, children }) => {
  return outbound ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </a>
  ) : (
    <Link to={link} className={className}>
      {children}
    </Link>
  );
};

function HeaderItem({
  icon,
  name = icon,
  addToast,
  children,
  handleLogoutUser,
  link,
  url
}) {
  const logoutUser = () => {
    handleLogoutUser()
      .then(() => {
        addToast({ text: "Succesfully logged out.", intent: "success" });
      })
      .catch(err => {
        // We dont need to handle anything if it catches - user will be manually logged out
        console.error(err);
      });
  };

  return (
    <div className={`header__item ${name ? `header__item--${name}` : ""}`}>
      <LinkWrapper
        outbound={url ? true : false}
        url={url}
        link={link}
        className="header-button user-item"
      >
        <div className="user-item__icon">
          <IconSet icon={icon} className="header" />
        </div>
        <div className="user-item__name">
          <h4>{children}</h4>
        </div>
      </LinkWrapper>
      {icon === "user" && (
        <div className="header-dropdown">
          <ul>
            <li className="header-dropdown__item header-dropdown__item--logout">
              <button onClick={logoutUser}>Log Out</button>
            </li>
            <li className="header-dropdown__item header-dropdown__item--myaccount">
              <Link to="/user/my-account">My Account</Link>
            </li>
            <li className="header-dropdown__item header-dropdown__item--myhomes">
              <Link to="/user/my-homes">My Homes</Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

HeaderItem.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const mapDispatch = dispatch => ({
  handleLogoutUser: () => dispatch(globalOperations.handleLogoutUser()),
  addToast: options => dispatch(toastOperations.addToast(options))
});

export default connect(null, mapDispatch)(HeaderItem);
