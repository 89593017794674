import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dashboardOperations } from "./duck";
import { globalOperations } from "../../duck/global";
import { toastOperations } from "../../duck/toast";
import GuestCancelPrompt from "./GuestCancelPrompt";
import DashboardController from "./DashboardController";
import GuestList from "./GuestList";
import GuestNewPrompt from "./GuestNewPrompt";
import GuestInfoPrompt from "./GuestInfoPrompt";

import _get from "lodash.get";

import { Helmet } from "react-helmet";

class HomeGuests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeId: props.match.params.homeId,
      cancelPrompt: false,
      newGuestPrompt: false,
      queuedCancelId: null
    };
  }

  componentDidMount() {
    const {
      getHomeGuestList,
      getUserHomesList,
      userHomes,
      homeGuests,
      addToast,
      history
    } = this.props;
    const { homeId } = this.state;

    // if there are guests set for current home, dont make any requests
    if (homeGuests) return;
    // if userHomes length is 0, make a call to getUserHomesList. Otherwise, just resolve getHomeGuestList
    const operations =
      userHomes.length === 0
        ? [getUserHomesList(), getHomeGuestList(homeId)]
        : [getHomeGuestList(homeId)];

    Promise.all(operations).catch(error => {
      addToast({
        text: error.message,
        intent: "error"
      });
      history.push("/user/my-homes");
    });
  }

  cancelGuest = (e, guestId) => {
    e.preventDefault();
    this.setState({ cancelPrompt: true, queuedCancelId: guestId });
  };

  confirmCancel = () => {
    const { addToast, homeGuests } = this.props;
    const { queuedCancelId, homeId } = this.state;
    const deletedGuest = homeGuests.find(guest => guest.id === queuedCancelId);

    this.props
      .deleteHomeGuest(homeId, queuedCancelId)
      .then(() => {
        this.setState({ cancelPrompt: false, queuedCancelId: null });
        addToast({
          text: `Successfully canceled reservation for "${deletedGuest.contact.first_name} ${deletedGuest.contact.last_name}".`,
          intent: "success"
        });
      })
      .catch(err => {
        this.setState({ cancelPrompt: false, queuedCancelId: null });

        addToast({
          text: err.message,
          intent: "error"
        });
      });
  };
  closeCancelPrompt = () => {
    this.setState({ cancelPrompt: false, queuedCancelId: null });
  };
  promptNewGuest = () => {
    this.setState({ newGuestPrompt: true });
  };

  closeNewPrompt = () => {
    this.setState({ newGuestPrompt: false });
  };

  com;

  createNewGuest = values => {
    const { createHomeGuest, addToast, currentHomeName } = this.props;
    const { homeId } = this.state;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      checkIn,
      checkOut,
      isPotential,
      wifiNetworkName,
      wifiNetworkPass,
      doorCode,
      gateCode,
      securityInfo,
      houseKeeping,
      gardener,
      trashPickup,
      poolJacuzzi,
      mainHostId,
      backupHostId,
      customMessage,
      sendReceipt
    } = values;
    createHomeGuest({
      houseId: homeId,
      phoneNumber,
      email,
      firstName,
      lastName,
      isProspective: isPotential,
      mainHostId: _get(mainHostId, "value", null),
      welcomeMessage: customMessage,
      sendReceipt,
      ...(!isPotential && {
        checkInDateTime: checkIn,
        checkOutDateTime: checkOut,
        wifiName: wifiNetworkName,
        wifiCode: wifiNetworkPass,
        doorCode,
        gateCode,
        securityInfo,
        houseKeepingDay: _get(houseKeeping, "day", null),
        houseKeepingTime: _get(houseKeeping, "time", null),
        poolDay: _get(poolJacuzzi, "day", null),
        poolTime: _get(poolJacuzzi, "time", null),
        gardenerDay: _get(gardener, "day", null),
        gardenerTime: _get(gardener, "time", null),
        trashPickupDay:
          _get(trashPickup, "day", null) !== ""
            ? _get(trashPickup, "day", null)
            : null,
        trashPickupTime:
          _get(trashPickup, "time", null) !== ""
            ? _get(trashPickup, "time", null)
            : null,
        guestHostId: _get(backupHostId, "value", null)
      })
    })
      .then(createdGuest => {
        this.setState({
          newGuestPrompt: false,
          queuedGuestData: null
        });
        addToast({
          text: `Succesfully invited guest "${createdGuest.contact.first_name} ${createdGuest.contact.last_name}" to ${currentHomeName}`
        });
      })
      .catch(err => {
        this.closeNewPrompt();
        addToast({
          text: err.message,
          intent: "error"
        });
      });
  };

  onGuestEdit = (evt, guestId) => {
    const { homeGuests } = this.props;

    const queuedGuest = homeGuests.find(({ id }) => id === guestId);
    this.setState({ queuedGuestData: queuedGuest }, () => {
      this.setState({ editGuestPrompt: true });
    });
  };

  updateGuest = values => {
    const { updateHomeGuest, addToast } = this.props;
    const { homeId, queuedGuestData } = this.state;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      checkIn,
      checkOut,
      wifiNetworkName,
      wifiNetworkPass,
      doorCode,
      gateCode,
      securityInfo,
      houseKeeping,
      gardener,
      trashPickup,
      poolJacuzzi,
      mainHostId,
      backupHostId,
      customMessage,
      isPotential
    } = values;

    updateHomeGuest({
      guestId: queuedGuestData.id,
      houseId: homeId,
      phoneNumber,
      email,
      firstName,
      lastName,
      isProspective: isPotential,
      mainHostId:
        _get(mainHostId, "isDefault") === true
          ? null
          : _get(mainHostId, "value", null),
      ...(!isPotential && {
        checkInDateTime: checkIn ? checkIn : null,
        checkOutDateTime: checkOut ? checkOut : null,
        wifiName: wifiNetworkName,
        wifiCode: wifiNetworkPass,
        doorCode,
        gateCode,
        securityInfo,
        houseKeepingDay: _get(houseKeeping, "day", null),
        houseKeepingTime: _get(houseKeeping, "time", null),
        poolDay: _get(poolJacuzzi, "day", null),
        poolTime: _get(poolJacuzzi, "time", null),
        gardenerDay: _get(gardener, "day", null),
        gardenerTime: _get(gardener, "time", null),
        trashPickupDay:
          _get(trashPickup, "day", null) !== ""
            ? _get(trashPickup, "day", null)
            : null,
        trashPickupTime:
          _get(trashPickup, "time", null) !== ""
            ? _get(trashPickup, "time", null)
            : null,
        guestHostId: _get(backupHostId, "value", null),
        welcomeMessage: customMessage
      })
    })
      .then(createdGuest => {
        this.setState({ queuedGuestData: null, editGuestPrompt: false });
        addToast({
          text: `Successfully updated guest "${firstName} ${lastName}".`,
          intent: "success"
        });
      })
      .catch(err => {
        this.setState({ queuedGuestData: null, editGuestPrompt: false });
        addToast({
          text: err.message,
          intent: "error"
        });
      });
  };

  onGuestInfo = (evt, guestId) => {
    const { homeGuests } = this.props;

    const queuedGuest = homeGuests.find(({ id }) => id === guestId);
    this.setState({ queuedGuestData: queuedGuest }, () => {
      this.setState({ infoGuestPrompt: true });
    });
  };

  saveGuestEmail = values => {
    this.createNewGuest(values);
  };

  render() {
    const {
      homeGuests,
      currentHomeName,
      isGuestListLoading,
      isGuestNewLoading,
      isGuestCancelLoading,
      currentHome,
      isGuestUpdateLoading,
      getUserDataLoading
    } = this.props;

    const {
      cancelPrompt,
      queuedCancelId,
      newGuestPrompt,
      queuedGuestData,
      editGuestPrompt,
      infoGuestPrompt
    } = this.state;

    return (
      <>
        <Helmet>
          <title>
            Home Concierge | {currentHomeName ? currentHomeName : "loading..."}|
            Guests
          </title>
          <meta name="description" content="Manage the homes invited guests." />
        </Helmet>
        <DashboardController
          title={`Guests: ${currentHomeName ? currentHomeName : "loading..."}`}
          layout="guests"
          onNew={this.promptNewGuest}
        >
          {({ query, filters, clearFilters }) => (
            <>
              <GuestList
                query={query}
                items={homeGuests}
                filters={filters}
                clearFilters={clearFilters}
                isLoading={
                  isGuestListLoading ||
                  getUserDataLoading ||
                  homeGuests === undefined
                }
                cancelGuest={this.cancelGuest}
                onGuestEdit={this.onGuestEdit}
                onGuestInfo={this.onGuestInfo}
              />
            </>
          )}
        </DashboardController>
        {cancelPrompt === true && (
          <GuestCancelPrompt
            guestId={queuedCancelId}
            onCancel={this.confirmCancel}
            onClose={this.closeCancelPrompt}
            isLoading={isGuestCancelLoading}
          />
        )}
        {newGuestPrompt === true && (
          <GuestNewPrompt
            homeName={currentHome.houseName}
            onClose={this.closeNewPrompt}
            onSubmit={this.createNewGuest}
            isLoading={isGuestNewLoading}
            homeId={this.state.homeId}
            data={queuedGuestData}
          />
        )}

        {editGuestPrompt === true && (
          <GuestNewPrompt
            homeName={currentHome.houseName}
            onClose={() =>
              this.setState({ editGuestPrompt: false, queuedGuestData: null })
            }
            onSubmit={this.updateGuest}
            isLoading={isGuestUpdateLoading}
            homeId={this.state.homeId}
            data={queuedGuestData}
          />
        )}
        {infoGuestPrompt === true && (
          <GuestInfoPrompt
            onClose={() =>
              this.setState({ infoGuestPrompt: false, queuedGuestData: null })
            }
            data={queuedGuestData}
          />
        )}
      </>
    );
  }
}

HomeGuests.propTypes = {
  addToast: PropTypes.func.isRequired,
  createHomeGuest: PropTypes.func.isRequired,
  currentHome: PropTypes.object.isRequired,
  currentHomeName: PropTypes.string.isRequired,
  deleteHomeGuest: PropTypes.func.isRequired,
  getHomeGuestList: PropTypes.func.isRequired,
  getUserHomesList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  homeGuests: PropTypes.array,
  isGuestCancelLoading: PropTypes.bool.isRequired,
  isGuestListLoading: PropTypes.bool.isRequired,
  isGuestNewLoading: PropTypes.bool.isRequired,
  isGuestUpdateLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setCurrentHome: PropTypes.func.isRequired,
  updateHomeGuest: PropTypes.func.isRequired,
  userHomes: PropTypes.array
};

const mapState = (state, ownProps) => ({
  userHomes: state.dashboard.userHomes,
  homeGuests:
    state.dashboard.homeGuests[parseInt(ownProps.match.params.homeId)],
  isGuestCancelLoading: state.loading.cancelGuest.loading === true,
  isGuestNewLoading: state.loading.createGuest.loading === true,
  isGuestUpdateLoading: _get(state.loading, "updateGuest.loading", false),
  isGuestListLoading:
    state.loading.login.loading === true ||
    state.loading.homeGuests.loading === true,
  getUserDataLoading: _get(state.loading, "getUserData.loading", false),
  currentHome: state.dashboard.userHomes
    ? state.dashboard.userHomes.find(
        home => parseInt(home.id) === parseInt(ownProps.match.params.homeId)
      )
    : null,
  currentHomeName: state.global.current.home.name
});

const mapDispatch = dispatch => ({
  getUserHomesList: () => dispatch(dashboardOperations.getUserHomesList()),
  getHomeGuestList: homeId =>
    dispatch(dashboardOperations.getHomeGuestList(homeId)),
  deleteHomeGuest: (homeId, guestId) =>
    dispatch(dashboardOperations.deleteHomeGuest(homeId, guestId)),
  createHomeGuest: guestValues =>
    dispatch(dashboardOperations.createHomeGuest(guestValues)),
  updateHomeGuest: guestValues =>
    dispatch(dashboardOperations.updateHomeGuest(guestValues)),
  addToast: options => dispatch(toastOperations.addToast(options)),
  setCurrentHome: homeId => dispatch(globalOperations.setCurrentHome(homeId))
});

HomeGuests.propTypes = {};

export default withRouter(connect(mapState, mapDispatch)(HomeGuests));
