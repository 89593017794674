import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import Select, { components } from "react-select";
import clsx from "clsx";

import _get from "lodash.get";

const SelectControl = props => (
  <components.Control
    {...props}
    className={clsx({
      "select-input-control": true
    })}
  />
);

const SelectSingleValue = props => (
  <components.SingleValue
    {...props}
    className={clsx({
      [props.className]: Boolean(props.className),
      "select-input-value": true
    })}
    data-testid="select-input-value"
  />
);

const SelectPlaceholder = props => (
  <components.Placeholder
    {...props}
    className={clsx({
      [props.className]: Boolean(props.className),
      "select-input-placeholder": true
    })}
    data-testid="select-input-value"
  />
);

const SelectValueContainer = props => (
  <components.ValueContainer
    {...props}
    className={clsx({
      [props.className]: Boolean(props.className),
      "select-input-value-container": true
    })}
  />
);

const SelectIndicatorSeperator = props => (
  <components.IndicatorSeparator
    {...props}
    className="select-input-indicator-seperator"
  />
);

const SelectIndicatorContainer = props => (
  <components.IndicatorsContainer
    {...props}
    className="select-input-indicator-container"
  />
);

function SelectInput({
  name,
  options,
  placeholder,
  size = "full",
  disabled = false,
  required = false
}) {
  const [field, meta, helpers] = useField({ name });

  const handleChange = option => {
    helpers.setValue(option);
  };

  const displayError = meta.touched && meta.error;

  return (
    <div
      className={clsx({
        "select-input": true,
        [name]: true,
        [`select-input--${size}`]: Boolean(size),
        error: Boolean(displayError),
        "select-input--disabled": disabled
      })}
    >
      <Select
        name={name}
        components={{
          Control: SelectControl,
          Placeholder: SelectPlaceholder,
          SingleValue: SelectSingleValue,
          ValueContainer: SelectValueContainer,
          IndicatorSeparator: SelectIndicatorSeperator,
          IndicatorsContainer: SelectIndicatorContainer
        }}
        value={
          options
            ? options.find(
                option =>
                  String(option.value) ===
                  String(_get(field.value, "value", null))
              )
            : ""
        }
        isDisabled={disabled}
        options={options}
        placeholder={
          displayError
            ? displayError
            : required
            ? `${placeholder}*`
            : placeholder
        }
        onChange={handleChange}
        onBlur={field.onBlur}
      />
    </div>
  );
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  placeholder: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export default SelectInput;
