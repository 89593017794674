import React, { useState } from "react";
import PropTypes from "prop-types";
import POISelectedGrid from "../../POISelectedGrid";
import POIAvailableGrid from "../../POIAvailableGrid";
import { useDispatch, useSelector } from "react-redux";

import _get from "lodash.get";
import { builderOperations } from "../../duck";
import PeoplePoiPrompt from "../../PeoplePoiPrompt";
import { toastOperations } from "../../../../duck/toast";
import POIViewPrompt from "../../POIViewPrompt";

function GuestServicesPois({
  selected,
  available,
  valueKey,
  type = "people-poi"
}) {
  const dispatch = useDispatch();

  // redux selectors
  const subData = useSelector(state =>
    _get(state.builder, `currentBuilder[3].values[${valueKey}]`, null)
  );

  const currentPageHash = useSelector(state =>
    Object.keys(state.builder.currentBuilder).find(
      pageHash => state.builder.currentBuilder[pageHash].key === "guestServices"
    )
  );

  // local state
  const [viewPrompt, setViewPrompt] = useState(false);
  const [queuedData, setQueuedData] = useState();

  // on poi view
  const onView = availabilityType => ({ title, id, type }) => {
    const item = subData[availabilityType].find(poi => poi.id === id);
    setViewPrompt(true);
    setQueuedData(item);
  };

  // on poi toggle availability
  const onToggleAvailability = ({ title, id, type }) => {
    const newAvailability = type === "selected" ? "available" : "selected";

    if (newAvailability === "selected") {
      if (subData.selected.length >= 12) {
        dispatch(
          toastOperations.addToast({
            text: "You can not have more than 12 items selected.",
            intent: "warning"
          })
        );
        return;
      }
    }

    dispatch(
      builderOperations.changeGridItemAvailabilityAndSequence(
        type,
        newAvailability,
        currentPageHash,
        subData.key,
        id,
        true
      )
    );
  };

  // on drag stop
  const onDragStop = (
    id,
    originalAvailability,
    newAvailability,
    originalIndex,
    newIndex
  ) => {
    if (newAvailability === "selected") {
      if (subData.selected.length >= 12) {
        dispatch(
          builderOperations.updateSwitchGridItemAvailability(
            originalAvailability,
            newAvailability,
            currentPageHash,
            subData.key,
            id,
            true,
            newIndex,
            originalIndex
          )
        );

        dispatch(
          builderOperations.switchGridItemAvailability(
            originalAvailability,
            newAvailability,
            currentPageHash,
            subData.key,
            id,
            true,
            newIndex,
            originalIndex
          )
        );

        return;
      }
    }

    if (originalAvailability !== newAvailability) {
      dispatch(
        builderOperations.changeGridItemAvailabilityAndSequence(
          originalAvailability,
          newAvailability,
          currentPageHash,
          subData.key,
          id,
          true,
          newIndex
        )
      );
    } else {
      if (newIndex !== originalIndex) {
        dispatch(
          builderOperations.changeSelectedGridItemSequences(
            parseInt(currentPageHash),
            subData.key,
            originalAvailability,
            id,
            newIndex,
            true
          )
        );
      }
    }
  };

  return (
    <>
      <div className="guest-services-poi-grid">
        <div className="guest-services-poi-grid__inner">
          <div className="guest-services-poi-grid__content">
            <POISelectedGrid
              items={selected}
              onDragStop={onDragStop}
              onView={onView("selected")}
              onRemove={onToggleAvailability}
            />
            <div className="grid-seperator">OR</div>
            <POIAvailableGrid
              items={available}
              onDragStop={onDragStop}
              onView={onView("available")}
              onAdd={onToggleAvailability}
            />
          </div>
        </div>
      </div>
      {viewPrompt && (
        <>
          {type === "people-poi" ? (
            <PeoplePoiPrompt
              data={queuedData}
              onClose={() => {
                setViewPrompt(false);
                setQueuedData(null);
              }}
              categoryImage={subData.image}
              categoryName={subData.name}
              onSubmit={() => setViewPrompt(false)}
            />
          ) : (
            <POIViewPrompt
              data={queuedData}
              icon={subData.image}
              title={queuedData.name}
              onClose={() => {
                setViewPrompt(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
}

GuestServicesPois.propTypes = {
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        fullAddress: PropTypes.string
      }),
      description: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          size: PropTypes.string
        })
      ),
      key: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      sequence: PropTypes.number.isRequired,
      website: PropTypes.string
    })
  ),
  available: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        fullAddress: PropTypes.string
      }),
      description: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          size: PropTypes.string
        })
      ),
      key: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      sequence: PropTypes.number.isRequired,
      website: PropTypes.string
    })
  )
};

export default GuestServicesPois;
