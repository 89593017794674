import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import SignupFormSection from "./SignupFormSection";
import InputText from "../common/InputText";
import InputTel from "../common/InputTel";
import InputCheckbox from "../common/InputCheckbox";
import ButtonMain from "../common/ButtonMain";
import { Yup } from "hc-utils/FormValidator";
import _get from "lodash.get";

function RegisterForm({
  onSubmit,
  accountType,
  initialValues,
  withBilling = false
}) {
  const billingSubSchema = {
    addressLine1: Yup.string().required("Address Line 1 is required."),
    addressLine2: Yup.string(),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    zipCode: Yup.string().required("Zip Code is required.")
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "First name too short")
      .max(50, "First name too long")
      .required("First name is required."),
    lastName: Yup.string()
      .min(2, "Last name too short")
      .max(50, "Last name too long")
      .required("Last name is required."),
    ...(accountType === "company" && {
      companyName: Yup.string().required("Company name is required.")
    }),
    email: Yup.string().email("Invalid email.").required("Email is required."),
    password: Yup.string().required("Password is required."),
    phoneNumber: Yup.string().required("Phone number is required"),
    confirmPassword: Yup.string()
      .required("You must confirm your password.")
      .test("passwords-match", "Passwords do not match.", function (value) {
        return this.parent.password === value;
      }),

    ...(withBilling ? billingSubSchema : {}),

    acceptTerms: Yup.boolean().oneOf(
      [true],
      'You must accept our <a href="https://www.homeconcierge.com/builderterms" target="_blank" rel="noopener noreferrer">TERMS</a>'
    )
  });

  return (
    <Formik
      initialValues={{
        firstName: _get(initialValues, "firstName", ""),
        lastName: _get(initialValues, "lastName", ""),
        ...(accountType === "company" && {
          companyName: _get(initialValues, "companyName", "")
        }),
        email: _get(initialValues, "email", ""),
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        acceptTerms: false
      }}
      validationSchema={RegisterSchema}
      onSubmit={(values, actions) =>
        onSubmit({ ...values, accountType }, actions)
      }
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        isSubmitting
      }) => (
        <form
          name="signup-form"
          className="user-form user-form--signup"
          onSubmit={handleSubmit}
        >
          <SignupFormSection>
            <InputText
              name="firstName"
              type="text"
              size="half"
              placeholder="First Name"
              autoComplete="given-name"
              disabled={_get(initialValues, "firstName", false) ? true : false}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              displayError={
                touched.firstName && errors.firstName ? errors.firstName : false
              }
            />
            <InputText
              name="lastName"
              type="text"
              size="half"
              placeholder="Last Name"
              autoComplete="family-name"
              disabled={_get(initialValues, "lastName", false) ? true : false}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              displayError={
                touched.lastName && errors.lastName ? errors.lastName : false
              }
            />

            <InputText
              name="email"
              type="email"
              size="full"
              placeholder="Email"
              autoComplete="email"
              disabled={_get(initialValues, "email", false) ? true : false}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              displayError={
                touched.email && errors.email ? errors.email : false
              }
            />
            {accountType === "company" && (
              <InputText
                name="companyName"
                type="text"
                size="full"
                placeholder="Company Name"
                autoComplete="org"
                disabled={
                  _get(initialValues, "companyName", false) ? true : false
                }
                value={values.companyName}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                displayError={
                  touched.companyName && errors.companyName
                    ? errors.companyName
                    : false
                }
              />
            )}
            <InputTel
              name={"phoneNumber"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["phoneNumber"]}
              autoComplete="tel"
              required={true}
              type="tel"
              size="full"
              placeholder="Phone Number"
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              displayError={
                errors.phoneNumber && touched.phoneNumber
                  ? errors.phoneNumber
                  : false
              }
            />

            <InputText
              name="password"
              type="password"
              size="full"
              required={true}
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              displayError={
                touched.password && errors.password ? errors.password : false
              }
            />
            <InputText
              name="confirmPassword"
              type="password"
              size="full"
              required={true}
              placeholder="Confirm Password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              displayError={
                touched.confirmPassword && errors.confirmPassword
                  ? errors.confirmPassword
                  : false
              }
            />
          </SignupFormSection>
          {withBilling && (
            <SignupFormSection
              header={
                accountType === "individual"
                  ? "Owner Billing Address"
                  : "Company/Owner Billing Address"
              }
            >
              <InputText
                name="addressLine1"
                type="text"
                size="full"
                placeholder="Address Line 1"
                autoComplete="street-address"
                value={values.addressLine1}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                displayError={
                  touched.addressLine1 && errors.addressLine1
                    ? errors.addressLine1
                    : false
                }
              />

              <InputText
                name="addressLine2"
                type="text"
                size="full"
                placeholder="Address Line 2"
                autoComplete="address-line2"
                value={values.addressLine2}
                onChange={handleChange}
                onBlur={handleBlur}
                displayError={
                  touched.addressLine2 && errors.addressLine2
                    ? errors.addressLine2
                    : false
                }
              />
              <InputText
                name="city"
                type="text"
                size="full"
                placeholder="City"
                autoComplete="address-level2"
                value={values.city}
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                displayError={touched.city && errors.city ? errors.city : false}
              />
              <InputText
                name="state"
                type="text"
                size="half"
                placeholder="State"
                required={true}
                autoComplete="address-level1"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                displayError={
                  touched.state && errors.state ? errors.state : false
                }
              />
              <InputText
                name="zipCode"
                type="text"
                size="half"
                required={true}
                placeholder="ZIP Code"
                autoComplete="postal-code"
                value={values.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                displayError={
                  touched.zipCode && errors.zipCode ? errors.zipCode : false
                }
              />
            </SignupFormSection>
          )}
          <InputCheckbox
            name="acceptTerms"
            type="checkbox"
            size="full"
            text={() => (
              <p>
                By creating an account, you agree to our{" "}
                <a
                  href="https://www.homeconcierge.com/builderterms"
                  target="_blank"
                  rel="noopener noreferrer"
                  tabIndex={-1}
                >
                  TERMS
                </a>
              </p>
            )}
            value={values.acceptTerms}
            onChange={handleChange}
            onBlur={handleBlur}
            displayError={
              touched.acceptTerms && errors.acceptTerms
                ? errors.acceptTerms
                : false
            }
          />
          <ButtonMain color="aqua" type="submit" loading={isSubmitting}>
            Continue
          </ButtonMain>
        </form>
      )}
    </Formik>
  );
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  accountType: PropTypes.oneOf(["individual", "company"]),
  initialValues: PropTypes.object,
  withBilling: PropTypes.bool
};

export default RegisterForm;
