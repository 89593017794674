import React from "react";

const SpinnerLoader = React.memo(function SpinnerLoader({ text = "Loading" }) {
  return (
    <div className="spinner-loader">
      <div className="spinner-loader__inner">
        <div className="spinner">
          <div className="spinner__icon" />
          <div className="spinner__text">{text}</div>
        </div>
      </div>
    </div>
  );
});

export default SpinnerLoader;
