import { combineReducers } from "redux";
import accountReducer from "../app/my-account/duck";
import globalReducer from "../duck/global";
// import commonReducer from "../app/common/duck";
import dashboardReducer from "../app/dashboard/duck";
import loadingReducer from "../duck/loading";
import toastReducer from "../duck/toast";
import { builderReducer, builderCacheReducer } from "../app/builder/duck";

export default combineReducers({
  account: accountReducer,
  global: globalReducer,
  loading: loadingReducer,
  dashboard: dashboardReducer,
  toast: toastReducer,
  builder: builderReducer,
  builderCache: builderCacheReducer
});
