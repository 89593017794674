import React from "react";
import { connect } from "react-redux";

import AppHeader from "./app/common/AppHeader";
import portalRoutes from "./config/portalRoutes";
import RouteGenerator from "./config/RouteGenerator";
import { globalOperations } from "./duck/global";
import Toasts from "./app/common/Toasts";
import "react-datetime/css/react-datetime.css";
import "./scss/main.scss";
import BraintreeProvider from "./utilities/braintree-react/BraintreeProvider";
import { accountOperations } from "./app/my-account/duck";
import _get from "lodash.get";
import ReactGA from "react-ga";
import SpinnerLoader from "./app/common/SpinnerLoader";
import AppFooter from "./app/common/AppFooter";
import BannerProvider from "./app/common/BannerProvider";
import TidioProvider from "./app/common/TidioProvider";

class App extends React.PureComponent {
  componentDidMount() {
    const { checkIfLoggedIn, fetchUserData } = this.props;
    checkIfLoggedIn()
      .then(() => {
        fetchUserData().then(userData => {
          this.initializeGA(
            process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
            userData.id
          );
        });
      })
      .catch(() => {
        this.initializeGA(
          process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
          null
        );
      });
  }
  initializeGA = (trackingId, userId) => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize(trackingId, {
        debug: process.env.NODE_ENV === "development" ? true : false
      });
      if (userId) {
        ReactGA.set({
          userId
        });
      }
    }
  };
  render() {
    const {
      currentHomeName,
      currentPageKey,
      clientToken,

      isCacheLoading
    } = this.props;

    return (
      <TidioProvider>
        <BraintreeProvider clientToken={clientToken}>
          <div className="App">
            <BannerProvider>
              {isCacheLoading === false ? (
                <>
                  <AppHeader>
                    {currentPageKey === "editHomePage" && currentHomeName}
                  </AppHeader>
                  <RouteGenerator routes={portalRoutes} />
                  <AppFooter />
                  <Toasts />
                </>
              ) : (
                <div className="app-loader">
                  <SpinnerLoader />
                </div>
              )}
            </BannerProvider>
          </div>
        </BraintreeProvider>
      </TidioProvider>
    );
  }
}

const mapState = state => ({
  userToken: state.global.userAuth.token,
  isLoggedIn: state.global.userAuth.isLoggedIn,
  currentPageName: state.global.current.page.name,
  currentPageKey: state.global.current.page.key,
  currentHomeName: state.global.current.home.name,
  clientToken: state.account.clientToken,
  isCacheLoading: _get(state.loading, "cache.loading", false),
  isLoginLoading: _get(state.loading, "login.loading", false)
});

const mapDispatch = dispatch => ({
  checkIfLoggedIn: () => dispatch(globalOperations.checkIfLoggedIn()),
  fetchUserData: () => dispatch(accountOperations.fetchUserData())
});
export default connect(mapState, mapDispatch)(App);
