import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

class PhotoGridProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewPromptOpen: true
    };
  }

  render() {
    const { children, homePhotos } = this.props;
    const { isNewPromptOpen } = this.state;
    return (
      <div className="photo-grid-wrapper">
        <div className="photo-grid-wrapper__inner">
          {children({
            photos: homePhotos,
            isNewPromptOpen,
            openNewPrompt: this.openNewPrompt,
            closeNewPrompt: this.closeNewPrompt
          })}
        </div>
      </div>
    );
  }
}

PhotoGridProvider.propTypes = {
  children: PropTypes.any,
  homePhotos: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired
        })
      ),
      sequence: PropTypes.number.isRequired,
      title: PropTypes.string
    })
  )
};

const mapState = state => ({
  homePhotos:
    state.builder.currentBuilder[
      Object.keys(state.builder.currentBuilder).find(
        pageHash => state.builder.currentBuilder[pageHash].key === "houseTour"
      )
    ].values.housePhotos.selected
});

export default connect(mapState)(PhotoGridProvider);
