import types from "./types";
import { accountTypes } from "../../my-account/duck";

export const initialDashboardState = {
  userHomes: [],
  homeGuests: [],
  homeAdmins: []
};

export default (state = initialDashboardState, action) => {
  switch (action.type) {
    case types.SET_GUEST_STATUS: {
      return {
        ...state,
        homeGuests: {
          ...state.homeGuests,
          [action.houseId]: state.homeGuests[action.houseId].map(guest =>
            guest.id === action.id
              ? {
                  ...guest,
                  is_app_open: action.is_app_open,
                  invitedGuests: action.invitedGuests,
                  emailSent: action.emailSent
                }
              : guest
          )
        }
      };
    }

    case types.SET_HOME_GUEST_LIST: {
      return {
        ...state,
        homeGuests: {
          ...state.homeGuests,
          [action.homeId]: action.guestList
        }
      };
    }

    case types.SET_USER_HOMES_LIST: {
      return {
        ...state,
        userHomes: action.userHomes
      };
    }
    case types.REMOVE_HOME_GUEST: {
      return {
        ...state,
        homeGuests: {
          ...state.homeGuests,
          [action.houseId]: state.homeGuests[action.houseId].filter(
            guest => guest.id !== action.guestId
          )
        }
      };
    }
    case types.ADD_HOME_GUEST: {
      return {
        ...state,
        homeGuests: {
          ...state.homeGuests,
          [action.houseId]: state.homeGuests[action.houseId].concat(
            action.guest
          )
        }
      };
    }
    case types.UPDATE_HOME_GUEST: {
      return {
        ...state,
        homeGuests: {
          ...state.homeGuests,
          [action.houseId]: state.homeGuests[action.houseId].map(guest => {
            if (guest.id === action.guestId) {
              return action.guest;
            }
            return guest;
          })
        }
      };
    }

    case types.REMOVE_HOUSE: {
      return {
        ...state,
        userHomes: state.userHomes.filter(({ id }) => id !== action.houseId)
      };
    }
    case types.ADD_USER_HOME: {
      return {
        ...state,
        userHomes: [...state.userHomes, action.userHome]
      };
    }

    case types.UPDATE_HOUSE_INFO: {
      return {
        ...state,
        userHomes: state.userHomes.map(home => {
          if (parseInt(action.houseId) === parseInt(home.id)) {
            return {
              ...home,
              houseName: action.houseName,
              address: {
                ...home.address,
                fullAddress: action.houseAddress
              }
            };
          } else {
            return home;
          }
        })
      };
    }
    case types.SET_HOUSE_PUBLISH_STATUS: {
      return {
        ...state,
        userHomes: state.userHomes.map(home => {
          if (parseInt(action.houseId) === parseInt(home.id)) {
            return {
              ...home,
              isPublished: action.publishStatus
            };
          } else {
            return home;
          }
        })
      };
    }
    case types.UPDATE_HOUSE_PHOTO: {
      return {
        ...state,
        userHomes: state.userHomes.map(home =>
          home.id === action.homeId ? { ...home, image: action.image } : home
        )
      };
    }

    case types.CLEAR_DASHBOARD: {
      return initialDashboardState;
    }

    case accountTypes.REMOVE_SUBSCRIPTION: {
      return {
        ...state,
        userHomes: state.userHomes.filter(
          home => home.permission.type !== "owner"
        )
      };
    }
    default: {
      return state;
    }
  }
};
