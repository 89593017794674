import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import PromptWrapper from "../common/PromptWrapper";

import moment from "moment-timezone";
import permissionTypeSelector from "../../selectors/permissionTypeSelector";

import { connect } from "react-redux";
import { dashboardOperations } from "./duck";
import SpinnerLoader from "../common/SpinnerLoader";
import { toastOperations } from "../../duck/toast";

import { ReactComponent as MailIcon } from "../../assets/mail-icon.svg";

function Table({ columns, items }) {
  return (
    <table style={{ width: "100%", tableLayout: "fixed" }}>
      <thead>
        <tr>
          {columns.map(({ name }) => (
            <th key={name}>{name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => (
          <tr key={i}>
            {Object.entries(item).map(entry => {
              const key = entry[0];
              return <td key={key}>{item[key]}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
const convertUTCToLocal = date => {
  const inputFmt = "YYYY-MM-DD hh:mm:ss";
  const outputFmt = "MMMM Do YYYY, h:mm a";
  const utcDate = moment.utc(date, inputFmt);
  return utcDate.local().format(outputFmt);
};
function GuestEmailRoster({ emailsSent }) {
  const items = emailsSent.map(({ sent_to, subject, dateSent, body }) => ({
    sentTo: sent_to,
    subject,
    sentDate: convertUTCToLocal(dateSent)
  }));

  const columnSchema = [
    {
      name: "Sent to",
      label: "sentTo"
    },
    {
      name: "Subject",
      label: "sibject"
    },
    {
      name: "Date sent",
      label: "sentDate"
    }
  ];
  return (
    <div className="guest-info-roster">
      {Array.isArray(emailsSent) && emailsSent.length > 0 ? (
        <Table columns={columnSchema} items={items} />
      ) : (
        "No emails have been sent to this guest."
      )}
    </div>
  );
}

GuestEmailRoster.propTypes = {
  emailsSent: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      dateSent: PropTypes.string.isRequired,
      sent_to: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired
    })
  )
};

function GuestInfoRoster({ invitedGuests }) {
  const items = invitedGuests.map(({ firstName, lastName, email }) => ({
    name: `${firstName} ${lastName}`,
    email
  }));

  const columnSchema = [
    {
      name: "Name",
      label: "name"
    },
    {
      name: "Email",
      label: "email"
    }
  ];
  return (
    <div className="guest-info-roster">
      {Array.isArray(invitedGuests) && invitedGuests.length > 0 ? (
        <Table columns={columnSchema} items={items} />
      ) : (
        "This guest hasn't invited any family or friends to the home."
      )}
    </div>
  );
}

GuestInfoRoster.propTypes = {
  invitedGuests: PropTypes.array
};

function GuestInfoData({ data, onEmailResend, resendLoading = false }) {
  const { is_app_open, unique_id } = data;

  const invitePath = '/guest/view/';

  let inviteLink = process.env.REACT_APP_GUEST_APP_URL.trimRight('/') + invitePath + unique_id;

  const copyLink = (link) => {
	navigator.clipboard.writeText(link).then(function() {
	  alert('Copy to clipboard was successful!');
	}, function(err) {
	  alert('Could not copy text.');
	});
  }

  return (
	<div className="guest-info-data">
	  <div className="guest-info-data__item">
		<div className="guest-info-data__itemheader">
		  <h4>Has opened app?</h4>
		</div>
		<div className="guest-info-data__itemcontent">
		  <p className={is_app_open ? "success" : "error"}>
			{is_app_open ? "Yes" : "No"}
		  </p>
		</div>
	  </div>
	  <div className="guest-info-data__item">
		<div className="guest-info-data__itemheader">
		  <h4>Invite Link</h4>
		</div>
		<div className="guest-info-data__itemcontent">
		  <p className={is_app_open ? "success" : "error"} style={{fontSize: '12px'}}>
			{inviteLink}
			<br/>
			<button style={{border: 'none', color: 'blue', fontSize: '14px'}} type={'button'}
					onClick={() => copyLink(inviteLink)}>(click to copy)
			</button>
		  </p>
		</div>
	  </div>
	  <div className="guest-info-data__item">
		<div className="guest-info-data__itemheader">
		  <h2>
			Emails sent{" "}
			<button
			  disabled={resendLoading}
			  alt="Resend invite email"
			  onClick={onEmailResend}
			>
			  <MailIcon/>
			  <span>Resend email</span>
			</button>
		  </h2>
		</div>
		<div className="guest-info-data__itemroster">
		  <GuestEmailRoster emailsSent={data.emailSent}/>
		</div>
	  </div>

	  <div className="guest-info-data__item ">
		<div className="guest-info-data__itemheader">
		  <h2>Invited friends & family</h2>
		</div>
		<div className="guest-info-data__itemroster">
		  {data.is_prospective ? (
			<p>Potential guests can not invite friends & family.</p>
		  ) : (
			<GuestInfoRoster invitedGuests={data.invitedGuest}/>
		  )}
		</div>
	  </div>
	</div>
  );
}

function GuestInfoPrompt({
						   onClose,
						   data,
						   getGuestStatus,
						   resendEmail,
						   addToast
						 }) {
  const [statusLoading, setStatusLoading] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);

  useEffect(() => {
	setStatusLoading(true);
	getGuestStatus(data.id, data.house_id).finally(() => {
	  setStatusLoading(false);
	});
  }, [data.house_id, data.id, getGuestStatus]);

  const handleEmailResend = () => {
    setResendLoading(true);
    resendEmail(data.id, data.house_id)
      .then(() => {
        addToast({
          text: `Successfully re-sent invite email to ${data.contact.email}.`
        });
      })
      .catch(err => {
        addToast({
          text: `There was an error re-sending the guest invite email.`,
          intent: "error"
        });
      })
      .finally(() => {
        setResendLoading(false);
      });
  };

  return (
    <PromptWrapper className="guest-info-prompt" onClose={onClose}>
      {statusLoading ? (
        <div className="guest-info-prompt__loader">
          <SpinnerLoader />
        </div>
      ) : (
        <>
          <h2>
            {data
              ? `${data.contact.first_name} ${data.contact.last_name}`
              : "Couldn't get guest name."}
          </h2>
          <GuestInfoData
            data={data}
            onEmailResend={handleEmailResend}
            resendLoading={resendLoading}
          />
        </>
      )}
    </PromptWrapper>
  );
}

const mapState = state => ({
  permissionType: permissionTypeSelector(state)
});

const mapDispatch = dispatch => ({
  getGuestStatus: (guestId, houseId) =>
    dispatch(dashboardOperations.getGuestStatus(guestId, houseId)),
  resendEmail: (guestId, houseId) =>
    dispatch(dashboardOperations.resendEmail(guestId, houseId)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

GuestInfoPrompt.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  permissionType: PropTypes.oneOf(["host", "owner"])
};

export default connect(mapState, mapDispatch)(GuestInfoPrompt);
