import React from "react";
import PropTypes from "prop-types";
import acceptedCardsIcon from "../../assets/icons/AcceptedCards.svg";

function FormGroup({ title, features = [], children }) {
  return (
    <div className="form-group">
      <h4 className="form-group__title">{title}</h4>
      <div className="form-group__with">
        {features.includes("AcceptedCards") && <AcceptedCards />}
      </div>
      <div className="form-group__inputs">{children}</div>
    </div>
  );
}

function AcceptedCards() {
  return (
    <div className="accepted-cards">
      <p>Accepted Credit</p>
      <img src={acceptedCardsIcon} alt="accepted-cards" />
    </div>
  );
}

FormGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FormGroup;
