import React from "react";
import PropTypes from "prop-types";
import { builderOperations } from "./duck";
import { connect } from "react-redux";

class BuilderPageWrapper extends React.PureComponent {
  componentWillUnmount() {
    this.props.setVisited(this.props.currentPageHash);
  }

  render() {
    const { children, className } = this.props;
    return (
      <div
        className={`builder-page ${
          className ? `builder-page--${className}` : ""
        }`}
      >
        <div className="builder-page__inner">{children}</div>
      </div>
    );
  }
}

const mapDispatch = dispatch => ({
  setVisited: pageHash => dispatch(builderOperations.setVisited(pageHash))
});
const mapState = (state, ownProps) => ({
  currentPageHash: parseInt(
    Object.keys(state.builder.currentBuilder).find(
      pageHash =>
        state.builder.currentBuilder[pageHash].key === ownProps.pageKey
    )
  )
});

BuilderPageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  currentPageHash: PropTypes.number,
  pageKey: PropTypes.string,
  setVisited: PropTypes.func
};

export default connect(
  mapState,
  mapDispatch
)(BuilderPageWrapper);
