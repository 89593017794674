import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import SimpleNavBar from "../common/SimpleNavBar";
import { CSSTransition } from "react-transition-group";
import SpinnerLoader from "../common/SpinnerLoader";
import RegisterForm from "./RegisterForm";
import { StringParam, useQueryParam } from "use-query-params";
import { globalOperations } from "../../duck/global";
import { connect } from "react-redux";
import { toastOperations } from "../../duck/toast";
import { withRouter } from "react-router-dom";
import _get from "lodash.get";

function CreateHostAccount({
  initializeInvite,
  finalizeInvite,
  addToast,
  history,

  isFinalizeInviteLoadingStatus
}) {
  const [initializeData, setInitializeData] = useState(null);
  const [initializeError, setInitializeError] = useState(null);
  const [uniqueId] = useQueryParam("id", StringParam);

  useEffect(() => {
    if (
      uniqueId &&
      !initializeData &&
      isFinalizeInviteLoadingStatus !== "SUCCESS"
    ) {
      initializeInvite(uniqueId)
        .then(data => {
          setInitializeData(data);
        })
        .catch(err => {
          setInitializeError(err);
          window.console.error(err);
        });
    }
  }, []); // eslint-disable-line

  const handleRegisterSubmit = (values, actions) => {
    finalizeInvite({
      uniqueId,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      password: values.password,
      accountType: values.accountType,
      companyName: values.companyName
    })
      .then(() => {
        addToast({ text: "Succesfully logged in!", intent: "success" });
        history.push("/user/my-homes");
      })
      .catch(err => {
        window.console.error(err);
        addToast({ intent: "error", text: err.message });
      });
  };

  const showForm = Boolean(initializeData);

  return (
    <>
      <Helmet>
        <title>Create Host Account from Invite - Home Concierge</title>
        <meta
          name="description"
          content="Create a host account with Home Concierge from an invite from a homeowner."
        />
      </Helmet>

      <div className="create-invite-account">
        <div className="create-invite-account__inner">
          <SimpleNavBar
            prev="/"
            prevText="Back To Home"
            type="simple"
            pageName="Create Your Host Account"
          />
          {initializeError ? (
            <p>
              Error while initializing host data. Please contact your host to
              re-invite you as a host.
            </p>
          ) : (
            <>
              <CSSTransition
                in={showForm === true}
                classNames="fade"
                timeout={500}
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <RegisterForm
                  initialValues={initializeData}
                  onSubmit={handleRegisterSubmit}
                  accountType="individual"
                />
              </CSSTransition>

              <CSSTransition
                out={showForm === true}
                in={showForm === false}
                classNames="fade"
                timeout={350}
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <SpinnerLoader />
              </CSSTransition>
            </>
          )}
        </div>
      </div>
    </>
  );
}

CreateHostAccount.propTypes = {
  initializeInvite: PropTypes.func.isRequired,
  finalizeInvite: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isFinalizeInviteLoadingStatus: PropTypes.string
};

const mapState = state => ({
  isFinalizeInviteLoadingStatus: _get(
    state.loading,
    "finalizeInvite.status",
    null
  )
});

const mapDispatch = dispatch => ({
  initializeInvite: uniqueId =>
    dispatch(globalOperations.initializeInvite(uniqueId)),
  finalizeInvite: userValues =>
    dispatch(globalOperations.finalizeInvite(userValues)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

export default withRouter(connect(mapState, mapDispatch)(CreateHostAccount));
