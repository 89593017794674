import React from "react";
import Toast from "./Toast";
import { connect } from "react-redux";
import { toastOperations } from "../../duck/toast";

import { CSSTransition, TransitionGroup } from "react-transition-group";

function Toasts({ toasts, removeToast }) {
  return (
    <div className="toasts">
      <div className="toasts__inner">
        <TransitionGroup component={null}>
          {toasts.map(({ id, ...rest }) => (
            <CSSTransition key={id} timeout={{enter: 450, exit: 225}} classNames="trans">
              <Toast {...rest} key={id} onDismiss={() => removeToast(id)} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </div>
  );
}

const mapDispatch = dispatch => ({
  removeToast: id => dispatch(toastOperations.removeToast(id))
});
const mapState = state => ({
  toasts: state.toast
});
export default connect(
  mapState,
  mapDispatch
)(Toasts);
