const maxFileMB = 7;

export function getObjectUrl(file) {
  return new Promise((resolve, reject) => {
    resolve(window.URL.createObjectURL(file));
    return;
    // reader.readAsDataURL(file);
    // reader.onload = () => {
    //   resolve(reader.result);
    // };
    // reader.onerror = error => {
    //   reject(error);
    // };
  });
}

export function processImage(file) {
  return new Promise((resolve, reject) => {
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    //if no file found, reject/return
    if (!file) {
      reject({ message: "No files found." });
      return;
    }

    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024;

    // If not valid file type
    if (!validImageTypes.includes(fileType)) {
      reject({
        message: `${file.name} is not an image(must be .png, .gif, .jpeg).`
      });
      return;
    }

    //if not valid image, reject
    if (fileSize > maxFileMB) {
      reject({
        message: `Files must not be larger than ${maxFileMB}MB.`
      });
      return;
    }

    // otherwise, get base64 of image and resolve data
    resolve(file);
  });
}
