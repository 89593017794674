import React from "react";
import posed, { PoseGroup } from "react-pose";

import GridHeader from "../../GridHeader";
import BuilderButton from "../../BuilderButton";

const TransWrapper = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    opacity: { duration: 150, ease: "out" },
  },
});

function HouseFeaturesSelector({ categories, onClick, activeKey }) {
  return (
    <div className="features-grid-selector">
      <div className="features-grid-selector__inner">
        <GridHeader color="blue">Choose a category below</GridHeader>
        <div className="features-grid-selector__items">
          <PoseGroup flipMove={false} animateOnMount={true}>
            {categories.map((category) => (
              <TransWrapper key={category.key}>
                <BuilderButton
                  key={category.key}
                  onClick={onClick}
                  type="svg"
                  icon={{ type: "image", data: category.image }}
                  name={category.key}
                  isEmpty={category.isEmpty}
                  isActive={activeKey === category.key ? true : false}
                >
                  {category.name}
                </BuilderButton>
              </TransWrapper>
            ))}
          </PoseGroup>
        </div>
      </div>
    </div>
  );
}

export default HouseFeaturesSelector;
