import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";

const BuilderStepItem = React.memo(function BuilderStepItem({
  name,
  active,
  visited,
  errors,
  link,
  onNav
}) {
  const hasErrors = errors
    ? Object.entries(errors).length !== 0 && errors.constructor === Object
      ? true
      : false
    : false;

  return (
    <div
      className={`builder-step-item ${
        active ? `builder-step-item--active` : ""
      } ${visited ? `builder-step-item--visited` : ``} ${
        hasErrors ? `builder-step-item--error` : "builder-step-item--success"
      }`}
    >
      <button className="builder-step-item__btn" onClick={e => onNav(e, link)}>
        <div className="builder-step-item__inner">
          <div className="builder-step-item__indicator">
            <div className="builder-step-item__indicatorinner">
              {!hasErrors & visited ? (
                <div className="builder-step-item__check">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              ) : null}
              {hasErrors & visited ? (
                <div className="builder-step-item__failed">
                  <FontAwesomeIcon icon={faQuestion} />
                </div>
              ) : null}
            </div>
          </div>
          <div className="builder-step-item__label">
            <h5>{name}</h5>
          </div>
        </div>
      </button>
    </div>
  );
});

BuilderStepItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  visited: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  link: PropTypes.any,
  onNav: PropTypes.func.isRequired
};

export default BuilderStepItem;
