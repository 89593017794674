import React from "react";
import PropTypes from "prop-types";
import UploadButton from "../common/UploadButton";

const PersonImage = ({
  src,
  alt = "Person Image",
  onUpload,
  withUpload = true
}) => {
  if (!src) return null;
  return (
    <div className="person-image">
      <div className="person-image__img">
        <img src={src} alt={alt} />
      </div>
      {withUpload && (
        <div className="person-image__new">
          <UploadButton name="person-image-input" onUpload={onUpload}>
            Select New Photo
          </UploadButton>
        </div>
      )}
    </div>
  );
};

PersonImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onUpload: PropTypes.func
};

export default PersonImage;
