import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, Formik, useFormikContext } from "formik";
import _get from "lodash.get";
import GuestFormSection from "./GuestFormSection";
import InputTextFormik from "../common/InputTextFormik";
import { connect } from "react-redux";
import { dashboardOperations } from "./duck";
import { withRouter } from "react-router-dom";
import ButtonMain from "../common/ButtonMain";
import GuestFormScheduleInfo from "./GuestFormScheduleInfo";
import GuestFormSecurityInfo from "./GuestFormSecurityInfo";
import GuestFormHostsInfo from "./GuestFormHostsInfo";
import { Yup } from "hc-utils/FormValidator";
import InputEditorFormik from "../common/InputEditorFormik";
import SpinnerLoader from "../common/SpinnerLoader";
import { CSSTransition } from "react-transition-group";

import phone from "phone";
import InputTelFormik from "../common/InputTelFormik";
import InputDateTimeFormik from "../common/InputDateTimeFormik";

import moment from "moment";

const formatCheckTime = str => (str ? str.replace(" ", "T") : str);

const EmailPageLoader = () => (
  <div className="input-editor-loader">
    <SpinnerLoader />
  </div>
);

const CustomEmailPage = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  homeName,
  isUpgraded
}) => {
  const [showInput, setShowInput] = useState(false);
  useEffect(() => {
    if (!isUpgraded) {
      setFieldValue(
        "customMessage",
        `<p>My name is ${values.mainHostId.label} and I will be your host
      during your upcoming stay. As a part of your rental, we are
      providing the Home Concierge App for your home.</p>
      `
      );
    }
    setShowInput(true);

    return () => {
      setShowInput(false);
      setFieldValue("customMessage", null);
    };
  }, [isUpgraded, setFieldValue, values.mainHostId.label]);

  return (
    <GuestFormSection title="Customize Your Confirming Email" className="">
      {showInput && (
        <Field
          component={InputEditorFormik}
          id="customMessage"
          name="customMessage"
          className="default-spacing"
          touched={touched}
          placeholder={`Custom Email Message${
            isUpgraded
              ? ' (Optional - please click "Send To Guest" if you have NO custom message)'
              : ""
          }`}
        />
      )}
      <CSSTransition
        in={!showInput}
        out={showInput}
        mountOnEnter
        unmountOnExit
        appear
        classNames="fade"
        timeout={250}
      >
        <EmailPageLoader />
      </CSSTransition>
    </GuestFormSection>
  );
};

CustomEmailPage.propTypes = {
  homeName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired
};

const GuestFormPersonalInfo = ({ isNew }) => {
  const { values } = useFormikContext();
  const currentCheckIn = values.checkIn ? moment(values.checkIn) : null;
  const currentCheckOut = values.checkOut ? moment(values.checkOut) : null;

  const checkValidCheckInDate = current => {
    return currentCheckOut ? current.isBefore(currentCheckOut) : true;
  };

  const checkValidCheckOutDate = current => {
    return currentCheckIn ? current.isAfter(currentCheckIn) : true;
  };

  return (
    <GuestFormSection
      title="Guest Personal Info"
      subtitle="Personal info for the guest and their vacation information."
    >
      <Field
        component={InputTextFormik}
        name="firstName"
        placeholder="First Name"
        size="half"
        autoComplete="given-name"
        required={true}
      ></Field>
      <Field
        component={InputTextFormik}
        name="lastName"
        placeholder="Last Name"
        autoComplete="family-name"
        size="half"
        required={true}
      ></Field>
      <Field
        component={InputTextFormik}
        name="email"
        placeholder="Email"
        autoComplete="email"
        size="full"
        required={true}
        disabled={!isNew}
      ></Field>
      <Field
        component={InputTelFormik}
        name="phoneNumber"
        placeholder="Phone number"
        autoComplete="phone"
        size="full"
        required={false}
      ></Field>
      <Field
        component={InputDateTimeFormik}
        isValidDate={checkValidCheckInDate}
        name="checkIn"
        placeholder="Check-in Date"
        size="full"
        required={true}
        type="datetime-local"
      ></Field>
      <Field
        component={InputDateTimeFormik}
        isValidDate={checkValidCheckOutDate}
        name="checkOut"
        placeholder="Check-out Date"
        size="full"
        required={true}
        type="datetime-local"
      ></Field>
    </GuestFormSection>
  );
};

///////////

function GuestDataPage({ getCalendarLoading, isNew }) {
  return (
    <div className="guest-data-page">
      <GuestFormPersonalInfo isNew={isNew} />

      <GuestFormSecurityInfo isNew={isNew} house={null} />
      <GuestFormScheduleInfo
        isNew={isNew}
        loading={getCalendarLoading}
        calendar={null}
      />
      <GuestFormHostsInfo isNew={isNew} />
    </div>
  );
}

GuestDataPage.propTypes = {
  history: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired
};

const EnhancedGuestDataPage = withRouter(GuestDataPage);

///////////

function GuestFormActive({
  data,
  homeName,
  onSubmit,
  createGuestLoading,
  updateGuestLoading,
  homeGuests,
  userEmail,
  onPageChange,
  isUpgraded = false
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    setCurrentPage(Math.min(2, currentPage + 1));
  };

  const handlePrev = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const handleSubmit = values => {
    // if the prompt has queued guest data, dont go to next page and just update guest
    if (data && !isUpgraded) {
      onSubmit(values);
      return;
    }

    if (currentPage === 2) {
      onSubmit(values);
    }

    if (currentPage === 1) {
      handleNext();
    }
  };

  return (
    <Formik
      initialValues={{
        isPotential: false,
        guestType: "active",
        firstName: _get(data, "contact.first_name", ""),
        lastName: _get(data, "contact.last_name", ""),
        email: _get(data, "contact.email", ""),
        phoneNumber: _get(data, "contact.phone", false)
          ? phone(data.contact.phone)[0]
          : "",
        checkIn: formatCheckTime(_get(data, "check_in_date_time", "")),
        checkOut: formatCheckTime(_get(data, "check_out_date_time", "")),
        wifiNetworkName: _get(data, "wifi_name", ""),
        wifiNetworkPass: _get(data, "wifi_code", ""),
        doorCode: _get(data, "door_code", ""),
        gateCode: _get(data, "gate_code", ""),
        securityInfo: _get(data, "security_info", ""),
        houseKeeping: {
          day: _get(data, "maintanenceCalendar.houseKeeping.day", "") || "",
          time: _get(data, "maintanenceCalendar.houseKeeping.time", "") || ""
        },
        gardener: {
          day: _get(data, "maintanenceCalendar.gardener.day", "") || "",
          time: _get(data, "maintanenceCalendar.gardener.time", "") || ""
        },
        poolJacuzzi: {
          day: _get(data, "maintanenceCalendar.poolJacuzzi.day", "") || "",
          time: _get(data, "maintanenceCalendar.poolJacuzzi.time", "") || ""
        },
        trashPickup: {
          day: _get(data, "maintanenceCalendar.trashPickup.day", "") || "",
          time: _get(data, "maintanenceCalendar.trashPickup.time", "") || ""
        },
        mainHostId: _get(data, "mainHost.id", null)
          ? {
              value: data.mainHost.id,
              label: `${data.mainHost.first_name} ${data.mainHost.last_name}`,
              isDisabled: false,
              isDefault: false
            }
          : null,
        backupHostId: _get(data, "guestHost.id", null)
          ? {
              value: data.guestHost.id,
              label: `${data.guestHost.first_name} ${data.guestHost.last_name}`,
              isDisabled: false,
              isDefault: false
            }
          : null,
        sendReceipt: true,
        customMessage: ""
      }}
      validationSchema={
        currentPage === 1
          ? Yup.object().shape({
              firstName: Yup.string()
                .min(2, "First name too short")
                .max(50, "First name too long")
                .required("First name is required.")
                .nullable(),
              lastName: Yup.string()
                .min(2, "Last name too short")
                .max(50, "Last name too long")
                .required("Last name is required.")
                .nullable(),
              email: Yup.string()
                .email("Invalid email.")
                .test(
                  "is-home-owner",
                  "You can not invite yourself as a guest to your own home.",
                  val => {
                    if (val === userEmail) {
                      return false;
                    }

                    return true;
                  }
                )
                .test(
                  "is-duplicate",
                  "This guest is already invited to this home",
                  val => {
                    const guestWithSameEmail = homeGuests.find(
                      ({ contact: { email } }) => email === val
                    );

                    if (data) {
                      return true;
                    }
                    if (guestWithSameEmail) {
                      return false;
                    }
                    return true;
                  }
                )
                .required("Email is required.")
                .nullable(),
              checkIn: Yup.date("Check-in date must be a proper date format.")
                .required("Check-in date is required")
                .test(
                  "is-valid-date",
                  "This date does not appear to be valid.",
                  val => {
                    if (!moment(val).isValid()) {
                      return false;
                    }

                    return true;
                  }
                )
                .nullable(),
              checkOut: Yup.date("Check-out date must be a proper date format.")
                .required("Check-out date is required")
                .test(
                  "is-valid-date",
                  "This date does not appear to be valid.",
                  val => {
                    if (!moment(val).isValid()) {
                      return false;
                    }

                    return true;
                  }
                )
                .nullable(),
              wifiNetworkName: Yup.string("Invalid value.").nullable(),
              wifiNetworkPass: Yup.string("Invalid value.").nullable(),
              doorCode: Yup.string("Invalid value.").nullable(),
              gateCode: Yup.string("Invalid value.").nullable(),
              securityInfo: Yup.string("Invalid value").nullable(),
              houseKeeping: calendarItemSchema(
                "Housekeeping requires both a time and day."
              ),
              trashPickup: calendarItemSchema(
                "Trash Pickup requires both a time and day."
              ),
              gardener: calendarItemSchema(
                "Gardener requires both a time and day."
              ),
              poolJacuzzi: calendarItemSchema(
                "Pool Jacuzzi requires both a time and day."
              ),
              mainHostId: Yup.object()
                .nullable()
                .test("is-null", "You must select a main host.", val => {
                  return _get(val, "label", false) !== "No Host Selected"
                    ? true
                    : false;
                }),
              sendReceipt: Yup.bool()
            })
          : Yup.object().shape({
              customMessage: Yup.string().nullable()
            })
      }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} data-testid="guest-form-active">
          {currentPage === 1 && (
            <EnhancedGuestDataPage
              isNew={data ? (data["check_in_date_time"] ? false : true) : true}
              values={values}
            />
          )}
          {currentPage === 2 && (
            <CustomEmailPage
              values={values}
              isUpgraded={isUpgraded}
              setFieldValue={setFieldValue}
              homeName={homeName}
            />
          )}
          <div className="guest-form-nav">
            {currentPage === 2 && (
              <>
                <ButtonMain type="button" onClick={handlePrev} color="aqua">
                  Previous
                </ButtonMain>

                <ButtonMain
                  type="submit"
                  color="blue"
                  loading={createGuestLoading || updateGuestLoading}
                >
                  Send To Guest
                </ButtonMain>
              </>
            )}
            {currentPage === 1 && (
              <ButtonMain
                type="submit"
                color="aqua"
                loading={updateGuestLoading}
              >
                {data
                  ? "Update & Send Info To Guest"
                  : currentPage === 2
                  ? "Send To Guest"
                  : "Continue"}
              </ButtonMain>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}

GuestFormActive.propTypes = {
  createGuestLoading: PropTypes.bool.isRequired,
  data: PropTypes.object,
  getCalendar: PropTypes.func.isRequired,
  getCalendarLoading: PropTypes.bool.isRequired,
  getHosts: PropTypes.func.isRequired,
  getHostsLoading: PropTypes.bool.isRequired,
  getSecurityData: PropTypes.func.isRequired,
  homeName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateGuestLoading: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isUpgraded: PropTypes.bool
};

const mapState = state => ({
  getCalendarLoading: _get(state.loading, "getCalendar.loading", false),
  getHostsLoading: _get(state.loading, "getHosts.loading", false),

  homeName: _get(state.global, "current.home.name", null),
  createGuestLoading: _get(state.loading, "createGuest.loading", false),
  updateGuestLoading: _get(state.loading, "updateGuest.loading", false),
  homeGuests: state.dashboard.homeGuests[state.global.current.home.id],
  userEmail: _get(state.account, "accountInfo.email", null)
});
const mapDispatch = dispatch => ({
  getHosts: houseId => {
    return dispatch(dashboardOperations.getHosts(houseId));
  },
  getCalendar: houseId => dispatch(dashboardOperations.getCalendar(houseId)),
  getSecurityData: houseId =>
    dispatch(dashboardOperations.getSecurityData(houseId))
});

const calendarItemSchema = validationMsg =>
  Yup.object().shape(
    {
      day: Yup.string()
        .when("time", {
          is: val => {
            return val !== "" && val !== null && val !== undefined;
          },
          then: Yup.string().required(validationMsg),
          otherwise: Yup.string()
        })
        .nullable(),

      time: Yup.string()
        .when("day", {
          is: val => {
            return val !== "" && val !== null && val !== undefined;
          },
          then: Yup.string().required(validationMsg),
          otherwise: Yup.string()
        })
        .nullable()
    },
    ["day", "time"]
  );

export default connect(mapState, mapDispatch)(GuestFormActive);
