import React from "react";
import PropTypes from "prop-types";
import ButtonMain from "../../../common/ButtonMain";
import GridHeader from "../../GridHeader";
import PhotoGridProvider from "../../PhotoGridProvider";

import uuidv4 from "uuid";
import BlankGridItem from "../../BlankGridItem";
import GridItem from "../../GridItem";
import UserPlaceHolderIcon from "../../../../assets/icons/UserPlaceholder.jpeg";
import GridLayout from "../../GridLayout";
import UploadWrapper from "../../../common/UploadWrapper";
import SpinnerLoader from "../../../common/SpinnerLoader";
import UploadButton from "../../../common/UploadButton";
import GridWrapper from "../../GridWrapper";
import { connect } from "react-redux";

const PhotoGridItem = props => {
  const {
    photoData,
    onEdit,
    onDelete,
    onView,
    gridIndex,
    id,
    currentPageHash,
    onDragStop
  } = props;
  if (!id) return <BlankGridItem {...props} sequence={props.gridIndex} />;
  return (
    <>
      <GridItem
        {...props}
        photoData={photoData ? photoData : UserPlaceHolderIcon}
        type="selected"
        overlayItems={{
          selected: ["view", "delete"],
          available: ["view", "delete"]
        }}
        onEdit={onEdit}
        onView={onView}
        onDelete={onDelete}
        gridIndex={gridIndex}
        currentPageHash={currentPageHash}
        onDragStop={onDragStop}
        id={id}
        columns={4}
        valueName={"housePhotos"}
      />
    </>
  );
};

const HomePhotosAdd = ({ onUpload, isLoading }) => {
  return (
    <div className="photos-add">
      <div className="photos-add__inner">
        {isLoading ? (
          <SpinnerLoader text={"Uploading"} />
        ) : (
          <button className="photos-add__btn">
            <p>Drop Photos To upload</p>
            <p>OR</p>
            <UploadButton name="photo-file" multiple={true} onUpload={onUpload}>
              Select File
            </UploadButton>
          </button>
        )}
      </div>
    </div>
  );
};

const HomePhotosSelected = ({ photos, key, children }) => {
  return (
    <GridLayout key={key} rows={7} columns={4} items={photos}>
      {children}
    </GridLayout>
  );
};

function HousePhotos({
  onDeleteAll,
  onDelete,
  onView,
  onDragStop,
  onUpload,
  createPhotoLoading
}) {
  return (
    <GridWrapper>
      <GridHeader size={12}>Upload Photos</GridHeader>

      <ButtonMain onClick={onDeleteAll} className="grid-action-btn">
        Delete All
      </ButtonMain>
      <PhotoGridProvider>
        {({ photos }) => (
          <>
            <HomePhotosSelected photos={photos}>
              {({ gridArray }) => (
                <div className="home-photos-grid-items">
                  {gridArray.map((item, index) => (
                    <PhotoGridItem
                      key={item.id ? item.id : uuidv4()}
                      id={item.id}
                      gridIndex={index}
                      sequence={item.sequence}
                      type={"selected"}
                      title={item.name}
                      photoData={
                        item.image
                          ? item.image.length > 0
                            ? item.image.find(image => image.size === "mobile")
                                .url
                            : null
                          : null
                      }
                      debugTitle={item.title}
                      onDelete={onDelete}
                      onView={onView}
                      onDragStop={onDragStop}
                    />
                  ))}
                </div>
              )}
            </HomePhotosSelected>
            <HomePhotosAdd onUpload={onUpload} isLoading={createPhotoLoading} />
          </>
        )}
      </PhotoGridProvider>
      <UploadWrapper onUpload={onUpload} />
    </GridWrapper>
  );
}

HousePhotos.propTypes = {
  onDeleteAll: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  createPhotoLoading: PropTypes.bool
};
const mapState = state => ({
  createPhotoLoading: state.loading.createPhoto
    ? state.loading.createPhoto.loading
    : false
});

export default connect(mapState)(HousePhotos);
