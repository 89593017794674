import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ClickAwayListener } from "@material-ui/core";
import PropTypes from "prop-types";
class PromptWrapper extends React.Component {
  render() {
    const {
      className = "",
      children,
      onClose,
      closeOnOutsideClick = true
    } = this.props;
    return (
      <div className={`prompt-wrapper ${className}`}>
        <ClickAwayListener
          onClickAway={onClose}
          mouseEvent={closeOnOutsideClick ? "onMouseDown" : false}
          touchEvent={closeOnOutsideClick ? "onTouchEnd" : false}
        >
          <div className={`prompt-wrapper__inner ${className}__inner`}>
            <div onClick={onClose} className="prompt-wrapper__close">
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="prompt-wrapper__content">{children}</div>
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

PromptWrapper.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  closeOnOutsideClick: PropTypes.bool
};

export default PromptWrapper;
