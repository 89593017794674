import React, { useContext, useEffect, useRef } from "react";

import braintreeClient from "braintree-web/client";
import braintreePayPalCheckout from "braintree-web/paypal-checkout";
import BraintreeContext from "../../utilities/braintree-react/contexts/braintreeContext";
import { useFormikContext } from "formik";

function PaypalCheckoutBtn({
  onAuthorize,
  onCancel,
  onError,
  error,
  setFieldTouched
}) {
  const { clientToken } = useContext(BraintreeContext);
  const { setFieldValue } = useFormikContext();

  const canLoad = useRef(true);
  useEffect(() => {
    if (!canLoad.current) return;
    canLoad.current = false;
    braintreeClient.create(
      {
        authorization: clientToken
      },
      (err, instance) => {
        if (err) {
          console.error(err);
          return;
        }
        braintreePayPalCheckout.create(
          {
            client: instance
          },
          (err, ppInstance) => {
            if (err) {
              window.console.error(err);
              return;
            }
            window.paypal.Button.render(
              {
                env: process.env.REACT_APP_PAYPAL_ENV,
                style: {
                  size: "large",
                  color: "blue"
                },
                payment: function() {
                  return ppInstance.createPayment({
                    flow: "vault",
                    billingAgreementDescription:
                      "By submitting this form you agree to allow Home Concierge to take scheduled funds from your PayPal account for your chosen subscription plan."
                  });
                },

                onAuthorize: (data, actions) => {
                  return ppInstance.tokenizePayment(data, (err, payload) => {
                    setFieldValue("paypal", true);
                    onAuthorize(payload.nonce, payload);
                  });
                },
                onCancel: data => {
                  setFieldTouched("paypal");
                  onCancel(data);
                },
                onError: err => {
                  setFieldTouched("paypal");
                  onError(err);
                }
              },
              "#paypal-button"
            );
          }
        );
      }
    );
  }, [
    clientToken,
    onAuthorize,
    onCancel,
    onError,
    setFieldTouched,
    setFieldValue
  ]);
  return (
    <div className="paypal-button-wrapper">
      <div id="paypal-button" />
      {error && <p className="paypal-button-wrapper__error">{error}</p>}
    </div>
  );
}

PaypalCheckoutBtn.propTypes = {};

export default PaypalCheckoutBtn;
