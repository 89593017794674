import { builderTypes } from "../../app/builder/duck";

export const builderErrorChecker = store => next => action => {
  next(action);
  const { type, pageHash, homeId } = action;
  const actionsToCheck = [
    builderTypes.REMOVE_SELECTED_GRID_ITEM,
    builderTypes.ADD_SELECTED_GRID_ITEM,
    builderTypes.CHANGE_GRID_ITEM_AVAILABILITY,
    builderTypes.SET_SELECTED_GRID_ITEM_SEQUENCE,
    builderTypes.SET_VISITED,
    builderTypes.SET_CURRENT_BUILDER
  ];

  if (!store.getState().builder.currentBuilder) return;

  //if the type is initial builder setter, set all errors on load
  if (type === builderTypes.SET_CURRENT_BUILDER) {
    if (!homeId) return;

    Object.keys(store.getState().builder.currentBuilder).forEach(hash => {
      const errors = checkForErrors(
        store.getState().builder.currentBuilder[hash].values
      );
      store.dispatch({
        type: builderTypes.SET_BUILDER_PAGE_ERRORS,
        errors,
        pageHash: parseInt(hash)
      });
    });

    return;
  }

  //otherwise, check if it is checked action, and set/check error for the specific current page
  if (actionsToCheck.includes(type)) {
    if (pageHash) {
      const errors = checkForErrors(
        store.getState().builder.currentBuilder[pageHash].values
      );

      store.dispatch({
        type: builderTypes.SET_BUILDER_PAGE_ERRORS,
        errors,
        pageHash: parseInt(pageHash)
      });
    }
  }
};

function checkForErrors(values) {
  let errors = {};

  for (var i = 0; i < Object.keys(values).length; i++) {
    const errorKey = Object.keys(values)[i];
    if (errorKey === "propertyInfo" || errorKey === "privateChefs")
      return errors;
    if (!values[errorKey].selected) return errors;
    // if (values[errorKey].available.length < 1) return errors;

    if (errorKey === "otherPlay") {
      return errors;
    }

    if (values[errorKey].required) {
      if (errorKey === "houseFeatures") {
        const hasSingleSelected = Object.keys(values).reduce((curr, acc) => {
          if (acc !== false && curr.selected.length > 0) {
            acc = true;
            return acc;
          } else {
            acc = false;
            return acc;
          }
        });
        if (!hasSingleSelected) {
          errors[errorKey] = "House Features must have atleast one.";
        }
      }

      if (errorKey)
        if (values[errorKey].selected.length === 0 && errorKey !== "hosts") {
          // if (values[errorKey].available) {
          //   if (values[errorKey].available.length < 1) {
          //     return errors;
          //   }
          // }

          if (errorKey === "housePhotos") {
            errors[
              errorKey
            ] = `"${values[errorKey].name}" requires atleast one home photo.`;
          } else if (errorKey === "hosts") {
            errors[errorKey] = `You must add atleast one main host.`;
          } else {
            errors[
              errorKey
            ] = `"${values[errorKey].name}" is required and must have atleast one "selected" item.`;
          }
        }
    }
    if (errorKey === "hosts") {
      const mainHost = values[errorKey].selected.find(
        host => host.sequence === 1
      );

      if (!mainHost) {
        errors[errorKey] = "You must set a main host.";
      }
    }
  }

  return errors;
}
