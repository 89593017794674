import React from "react";
import PropTypes from "prop-types";
import GridHeader from "../../GridHeader";
import GridLayout from "../../GridLayout";
import GridItem from "../../GridItem";
import HostPlaceholder from "../../../../assets/icons/HostPlaceholder.png";
import BlankGridItem from "../../BlankGridItem";
function HostsSelectedGrid({
  currentPageHash,
  items,
  onDragStop,
  onRemove,
  onView
}) {
  const getBannerText = i => {
    switch (i) {
      case 0: {
        return { text: "Main Host*", color: "red" };
      }
      case 1: {
        return { text: "Backup Host", color: "blue" };
      }
      case 2: {
        return { text: "Management", color: "red" };
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div
      className="guest-services-hosts-selected"
      data-testid="guest-services-hosts-selected-grid"
    >
      <GridHeader color="red">Selected Hosts</GridHeader>
      <GridLayout rows={1} compact={false} columns={3} items={items}>
        {({ gridArray }) => {
          return (
            <div
              className="guest-services-selected-grid-items"
              data-testid="guest-services-selected-grid"
            >
              {gridArray.map((item, i) =>
                item.id ? (
                  <GridItem
                    photoData={
                      item.image
                        ? item.image.length > 0
                          ? item.image.find(image => image.size === "thumb").url
                          : HostPlaceholder
                        : HostPlaceholder
                    }
                    overlayItems={{
                      available: ["edit", "add", "delete"],
                      selected: ["edit", "remove"]
                    }}
                    onDragStop={(...values) => onDragStop(...values, "hosts")}
                    columns={3}
                    key={item.id ? item.id : i}
                    valueName="hosts"
                    id={item.id}
                    title={`${item.first_name} ${item.last_name}`}
                    gridIndex={i}
                    sequence={item.sequence}
                    type="selected"
                    currentPageHash={currentPageHash}
                    onEdit={values => onView(values, "hosts")}
                    onRemove={onRemove}
                    banner={getBannerText(i)}
                  />
                ) : (
                  <BlankGridItem
                    gridIndex={i}
                    sequence={i + 1}
                    key={i}
                    banner={getBannerText(i)}
                    type="selected"
                  />
                )
              )}
            </div>
          );
        }}
      </GridLayout>
    </div>
  );
}

HostsSelectedGrid.propTypes = {
  currentPageHash: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.oneOf(["thumb", "tablet", "desktop"]).isRequired,
          url: PropTypes.string.isRequired
        })
      ),
      phone: PropTypes.string,
	  phoneText: PropTypes.string,
      sequence: PropTypes.number,
      website: PropTypes.string
    })
  ),
  onDragStop: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
};

export default HostsSelectedGrid;
