import React from "react";
import PropTypes from "prop-types";
import {Link, Redirect, useParams} from "react-router-dom";
import { connect } from "react-redux";
import SimpleNavBar from "../common/SimpleNavBar";
import LoginForm from "./LoginForm";
import { Helmet } from "react-helmet";

function LogIn({ isLoggedIn }) {
  let routerParams = useParams();

  if (isLoggedIn && ! routerParams.uid) return <Redirect to="/user/my-homes" />;

  return (
    <>
      <Helmet>
        <title>Home Concierge | Log in</title>
        <meta name="description" content="Log in to the admin portal." />
      </Helmet>
      <SimpleNavBar prev="/" prevText="Back to Home" pageName="Log In" />
      <LoginForm />
      <div className="create-account-cta">
        <div className="create-account-cta__inner">
          <div className="create-account-cta__forgot">
            <p>Forgot password?</p>
            <p>
              <Link to="/forgot-password">Click here</Link>
            </p>
          </div>
          <div className="create-account-cta__signup">
            <p>Don't have an account yet?</p>
            <p>
              <Link to="/create-account" tabIndex={-1}>
                Sign up.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

LogIn.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

const mapState = state => ({
  isLoggedIn: state.global.userAuth.isLoggedIn
});
export default connect(mapState)(LogIn);
