import React from "react";
import PropTypes from "prop-types";

import DashboardTableRow from "./DashboardTableRow";
import noHousePhotosSVG from "../../assets/icons/no-house-photo.png";
import { connect } from "react-redux";
import { dashboardOperations } from "./duck";

import _get from "lodash.get";

import ListButton from "./ListButton";
import TooltipWrapper from "../common/TooltipWrapper";

function HomeListItem({
  address,
  id,
  image,
  houseName,
  permission,
  isPublished,
  onHomeDelete
}) {
  const appURL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : process.env.REACT_APP_GUEST_APP_URL;

  const composedAddress = address ? `${address.fullAddress}` : "";
  return (
    <DashboardTableRow>
      <div
        className={`d-list-item d-list-item--status ${
          isPublished ? "published" : ""
        }`}
        data-testid="d-list-item-home"
      >
        <TooltipWrapper
          show={true}
          title={
            isPublished
              ? "Your Home is published and ready to invite guests!"
              : "There are required fields still needed!"
          }
        >
          <span className="status-indicator"></span>
        </TooltipWrapper>
      </div>
      <div className="d-list-item d-list-item--icon">
        <img
          src={
            image
              ? _get(
                  image.find(({ size }) => size === "thumb"),
                  "url",
                  noHousePhotosSVG
                )
              : noHousePhotosSVG
          }
          data-testid="home-list-item-image"
          alt={`${houseName}`}
        />
      </div>
      <div className="d-list-item d-list-item--name">
        <span className="d-list-item__permission">
          {!permission ? (
            <small data-testid="home-list-item-overline">Unknown Role</small>
          ) : permission.type === "host" ? (
            <TooltipWrapper show={true} title={`Added by ${permission.owner}`}>
              <small data-testid="home-list-item-overline">
                Added as <strong>Host</strong>
              </small>
            </TooltipWrapper>
          ) : (
            <small data-testid="home-list-item-overline">Your Home</small>
          )}
        </span>
        <span title={houseName} data-testid="home-list-item-name">
          {houseName}
        </span>
      </div>
      <div className="d-list-item d-list-item--address">
        <span title={composedAddress}>{composedAddress}</span>
      </div>
      <div className="d-list-item d-list-item--actions">
        <ListButton link={`/home/guests/${id}`} outbound={false} icon="guest">
          Guests
        </ListButton>
        <ListButton
          link={`/home/edit/${id}/propertyInfo`}
          outbound={false}
          icon={"edit"}
        >
          Edit
        </ListButton>
        <ListButton
          outbound={true}
          icon={"preview"}
          link={`${appURL}/app/${id}`}
        >
          Preview
        </ListButton>

        <ListButton
          outbound={false}
          icon={"delete"}
          isDisabled={_get(permission, "type") === "host"}
          title={"Unable to delete home as a host."}
          onClick={() => onHomeDelete(id)}
        >
          Delete
        </ListButton>
      </div>
    </DashboardTableRow>
  );
}

HomeListItem.propTypes = {
  address: PropTypes.object.isRequired,
  check_in_date_time: PropTypes.string,
  check_out_date_time: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.number.isRequired,
  isPublished: PropTypes.number,
  onHomeDelete: PropTypes.func.isRequired,
  openPreview: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    owner: PropTypes.string,
    type: PropTypes.string.isRequired
  }),
  image: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  houseName: PropTypes.string.isRequired
};

const mapState = (state, ownProps) => ({
  isPublished: state.dashboard.userHomes.find(({ id }) => id === ownProps.id)
    .isPublished
});

const mapDispatch = dispatch => ({
  openPreview: () => dispatch(dashboardOperations.openPreview())
});

export default connect(mapState, mapDispatch)(HomeListItem);
