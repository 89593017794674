import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

import clsx from "clsx";
import _get from "lodash.get";

function InputTelFormik({
  size = "full",
  placeholder,
  className,
  disabled,
  maxLength = null,
  required = false,
  fieldName,
  autoComplete = "new-password",
  field: { name, onBlur, value },
  form: { setFieldTouched, setFieldValue, submitCount, errors, touched }
}) {
  const clickRef = useRef(null);
  const inputRef = useRef(null);

  const [isFocused, setFocus] = useState(false);

  const displayError =
    (_get(touched, name, null) || submitCount > 0) && _get(errors, name, null)
      ? _get(errors, name)
      : false;

  const onFocus = () => {
    if (!isFocused) {
      setFocus(true);
    }
  };

  const handleBlur = evt => {
    setFieldTouched(name);

    if (isFocused) {
      setFocus(false);
    }
    if (onBlur) {
      onBlur(evt);
    }
  };

  useEffect(() => {
    const _clickRef = clickRef.current;

    const focusOnClick = evt => {
      evt.preventDefault();
      if (isFocused) return;
      inputRef.current.focus();
    };

    if (!clickRef.current || !inputRef.current) return;
    clickRef.current.addEventListener("click", focusOnClick);

    return () => {
      _clickRef.removeEventListener("click", focusOnClick);
    };
  }, [isFocused]);

  return (
    <div
      className={clsx({
        "form-tel": true,
        [name]: true,
        focused:
          isFocused || (value !== "" && value !== null && value !== undefined),
        [className]: className,
        [`form-tel--${size}`]: size,
        error: displayError,
        "form-tel--disabled": disabled
      })}
      ref={clickRef}
    >
      <label className="form-tel__label" htmlFor={name}>
        {displayError
          ? displayError
          : required
          ? `${placeholder}*`
          : placeholder}
      </label>

      <PhoneInput
        country="US"
        name={name}
        id={name}
        value={value}
        disabled={disabled}
        autoComplete={autoComplete}
        onChange={e => {
          setFieldValue(name, e !== null || e !== undefined ? e : "");
        }}
        onBlur={handleBlur}
        onFocus={onFocus}
        ref={inputRef}
      />
    </div>
  );
}

InputTelFormik.propTypes = {
  size: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,

  className: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
};
export default InputTelFormik;
