import React from "react";
import PropTypes from "prop-types";

function DashboardTableRow({ children }) {
  return (
    <div className="d-table-row " data-testid="dashboard-table-row">
      <div className="d-table-row__inner">{children}</div>
    </div>
  );
}

DashboardTableRow.propTypes = {
  children: PropTypes.any.isRequired
};

export default DashboardTableRow;
