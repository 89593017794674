const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_STARTED = "LOGIN_STARTED";
const LOGIN_FAILED = "LOGIN_FAILED";
const SET_LOAD_STATUS = "SET_LOAD_STATUS";
const SET_USER_CREDENTIALS = "SET_USER_CREDENTIALS";
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_CURRENT_HOME = "SET_CURRENT_HOME";
const LOGOUT_USER = "LOGOUT_USER";
const SET_REGIONS_LIST = "SET_REGIONS_LIST";
export default {
  LOGIN_FAILED,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  SET_USER_CREDENTIALS,
  SET_LOAD_STATUS,
  SET_CURRENT_PAGE,
  SET_CURRENT_HOME,
  LOGOUT_USER,
  SET_REGIONS_LIST
};
