import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { connect } from "react-redux";
import ButtonMain from "../common/ButtonMain";
import InputChoiceBox from "../common/InputChoiceBox";
import FormGroup from "../common/FormGroup";
import FormNotice from "../common/FormNotice";
import BillingLedger from "../common/BillingLedger";
import PaypalCheckoutBtn from "../common/PaypalCheckoutBtn";
import cardIcon from "../../assets/icons/Card.svg";
import payPalIcon from "../../assets/icons/paypal-icon.svg";
import { Yup } from "hc-utils/FormValidator";
import BraintreeClient from "../../utilities/braintree-react/BraintreeClient";
import PlanSelector from "./PlanSelector";
import { accountOperations } from "../my-account/duck";
import _get from "lodash.get";
import { toastOperations } from "../../duck/toast";
import { withRouter } from "react-router-dom";
import PaypalAccountCard from "../common/PaypalAccountCard";

const BillingFormSchema = Yup.object().shape({
  selectedPlan: Yup.object().test(
    "plan-selected",
    "You must select a subscription plan.",
    value => {
      return !!_get(value, "id", false);
    }
  ),
  paymentMethodChoice: Yup.string().required(
    "You must choose a payment processor."
  ),
  paypal: Yup.boolean().test(
    "isRequired",
    "You must authorize your payment with paypal.",
    function (paypalIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return paypalIsValid;
      } else {
        return true;
      }
    }
  ),
  number: Yup.boolean().test(
    "isRequired",
    "Card Number is not valid.",
    function (numberIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return true;
      } else {
        return numberIsValid;
      }
    }
  ),
  cvv: Yup.boolean().test(
    "isRequired",
    "CVV is not valid",
    function (cvvIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return true;
      } else {
        return cvvIsValid;
      }
    }
  ),
  expirationDate: Yup.boolean().test(
    "isRequired",
    "Expiration date is not valid",
    function (expIsValid) {
      if (this.options.parent.paymentMethodChoice === "paymentMethodPP") {
        return true;
      } else {
        return expIsValid;
      }
    }
  )
});

function BillingForm({
  selectedPlan = {},
  registerSubscription,
  registerSubscriptionLoading,
  addToast,
  history
}) {
  // state for storing the hostedFieldInstance from BraintreeClient for card number info
  const [hostedFieldInstance, setHostedFieldInstance] = useState(null);
  // state for storing the paypal nonce locally if the user is checking out with paypal account
  const [paypalNonce, setPaypalNonce] = useState(null);
  const [paypalData, setPaypalData] = useState(null);

  // async method for either tokenizing the hosted fields request or using the paypal nonce to registerSubscription
  const registerSubscriptionWithPayment = async values => {
    let nonce = "";
    if (values.paymentMethodChoice === "paymentMethodCard") {
      const { nonce: cardNonce } = await hostedFieldInstance.tokenize();
      nonce = cardNonce;
    }

    if (values.paymentMethodChoice === "paymentMethodPP") {
      nonce = paypalNonce;
    }
    try {
      const registerData = await registerSubscription(
        values.selectedPlan.id,
        nonce
      );
      return registerData;
    } catch (error) {
      throw error;
    }
  };

  // fired on BillingForm submit
  const fireSubmit = values => {
    // call registerSubscription method, show err/success toasts
    registerSubscriptionWithPayment(values)
      .then(() => {
        addToast({
          text:
            "You have successfully registered for a subscription plan! Continue with adding guests, setting up account manager accounts and configuring your home!"
        });
        history.push({
          pathname: "/user/my-homes"
        });
      })
      .catch(err => {
        addToast({
          text: err.message
            ? err.message
            : "there was an error registering your subscription. Please try again later.",
          intent: "error"
        });
      });
  };

  return (
    <Formik
      initialValues={{
        selectedPlan: selectedPlan || {},
        selectedCycle: "monthly",
        paymentMethodChoice: "paymentMethodCard",
        // This is set true when paypal is authorized - false on cancel, error
        paypal: false,
        // these are the card values that are set by BrainTree client
        number: false,
        cvv: false,
        expirationDate: false
      }}
      validationSchema={BillingFormSchema}
      onSubmit={fireSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
        submitCount
      }) => (
        <form
          name="login-form"
          className="user-form user-form--billing"
          onSubmit={handleSubmit}
        >
          <FormGroup title="Choose Subscription Plan">
            <PlanSelector
              onPlanChange={plan => setFieldValue("selectedPlan", plan)}
              onCycleChange={cycle => {
                setFieldValue("selectedCycle", cycle);
                setFieldValue("selectedPlan", {});
              }}
              selectedPlan={values.selectedPlan}
              selectedCycle={values.selectedCycle}
              withCycle={true}
            />
            {errors["selectedPlan"] && touched["selectedPlan"] && (
              <p style={{ color: "#e02b2b", margin: "5px 0px 0px" }}>
                {errors["selectedPlan"]}
              </p>
            )}
          </FormGroup>
          <FormGroup title="Choose Payment Method">
            <InputChoiceBox
              name="paymentMethodCard"
              groupName="paymentMethodChoice"
              size="half"
              checked={values.paymentMethodChoice === "paymentMethodCard"}
              icon={cardIcon}
              placeholder="Credit Card"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputChoiceBox
              name="paymentMethodPP"
              groupName="paymentMethodChoice"
              size="half"
              icon={payPalIcon}
              checked={values.paymentMethodChoice === "paymentMethodPP"}
              placeholder="Paypal"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
          {values.paymentMethodChoice === "paymentMethodCard" ? (
            <FormGroup
              title="Enter Payment Information"
              features={["AcceptedCards"]}
            >
              <BraintreeClient
                touched={touched}
                setInstance={instance => setHostedFieldInstance(instance)}
                fields={{
                  number: {
                    id: "card-number",
                    label: "Credit Card Number*",
                    placeholder: "****************",
                    errorMsg: "Not a valid card number."
                  },
                  cvv: {
                    id: "cvv",
                    label: "CVV*",
                    placeholder: "***",
                    errorMsg: "Not a valid CVV."
                  },
                  expirationDate: {
                    id: "expiration-date",
                    label: "Expiration Date*",
                    placeholder: "MM/YY",
                    errorMsg: "Not a valid expiration date."
                  }
                }}
                styleObj={{
                  input: {
                    display: "block",
                    width: "100%",
                    border: "0",
                    outline: "0",
                    background: "transparent",
                    height: "auto",
                    padding: "0px 0px 0px 10px"
                  }
                }}
              />
            </FormGroup>
          ) : paypalData ? (
            <PaypalAccountCard
              firstName={paypalData.details.firstName}
              lastName={paypalData.details.lastName}
              email={paypalData.details.email}
            />
          ) : (
            <PaypalCheckoutBtn
              onAuthorize={(nonce, data) => {
                setFieldValue("paypal", true);
                setPaypalNonce(nonce);
                setPaypalData(data);
              }}
              setFieldTouched={setFieldTouched}
              onError={() => setFieldValue("paypal", false)}
              onCancel={() => setFieldValue("paypal", false)}
              error={
                touched.paypal &&
                values.paypal === false &&
                values.paymentMethodChoice === "paymentMethodPP"
                  ? errors["paypal"]
                  : false
              }
            />
          )}

          {_get(values, "selectedPlan.id", false) && (
            <FormNotice>
              Your subscription will be billed at $
              {_get(values, "selectedPlan.price", null)} every month.
              <br /> We'll send a reminder email before each shipment so you
              have enough time to modify or cancel your plan.
            </FormNotice>
          )}

          <BillingLedger
            price={_get(values, "selectedPlan.price", null)}
            term={values.selectedCycle}
          />
          <FormNotice>
            By clicking "Complete Order", you are accepting our{" "}
            <a
              href="https://www.homeconcierge.com/builderterms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            , and you understand that your subscription will be for an ongoing
            service that is billed based on the shipping frequency and/or
            shipping date chosen by you. The recurring charge may change if you
            change your membership or we change our prices(with notice to you).
            Cancel anytime by visiting your account page on our website.
          </FormNotice>
          <ButtonMain
            color="aqua"
            type="submit"
            loading={registerSubscriptionLoading}
          >
            Complete Order
          </ButtonMain>
        </form>
      )}
    </Formik>
  );
}

const mapDispatch = dispatch => ({
  registerSubscription: (planId, paymentMethodNonce) =>
    dispatch(
      accountOperations.registerSubscription(planId, paymentMethodNonce)
    ),
  addToast: options => dispatch(toastOperations.addToast(options))
});

const mapState = state => ({
  registerSubscriptionLoading: _get(
    state.loading,
    "registerSubscription.loading",
    false
  )
});

BillingForm.propTypes = {
  selectedPlan: PropTypes.string,
  registerSubscription: PropTypes.func.isRequired,
  registerSubscriptionLoading: PropTypes.bool.isRequired,
  addToast: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(connect(mapState, mapDispatch)(BillingForm));
