const SET_VISITED = "SET_VISITED";

const REMOVE_SELECTED_GRID_ITEM = "REMOVE_SELECTED_GRID_ITEM";
const ADD_SELECTED_GRID_ITEM = "ADD_SELECTED_GRID_ITEM";
const CHANGE_SELECTED_GRID_ITEM_SEQUENCE = "CHANGE_SELECTED_GRID_ITEM_SEQUENCE";
const SET_SELECTED_GRID_ITEM_SEQUENCE = "SET_SELECTED_GRID_ITEM_SEQUENCE";
const CHANGE_GRID_ITEM_AVAILABILITY = "CHANGE_GRID_ITEM_AVAILABILITY";
const UPDATE_GRID_ITEM = "UPDATE_GRID_ITEM";

const SET_BUILDER_PAGE_ERRORS = "SET_BUILDER_PAGE_ERRORS";
const SET_CURRENT_BUILDER = "SET_CURRENT_BUILDER";

const CLEAR_CURRENT_BUILDER = "CLEAR_CURRENT_BUILDER";

const SET_CALENDAR_VALUES = "SET_CALENDAR_VALUES";
const SET_FORM_VALUES = "SET_FORM_VALUES";
const REMOVE_ALL_GRID_ITEMS = "REMOVE_ALL_GRID_ITEMS";
const UPDATE_HOUSE_PHOTO = "UPDATE_HOUSE_PHOTO";
const SWITCH_GRID_ITEM_AVAILABILITY = "SWITCH_GRID_ITEM_AVAILABILITY";

// Cache actions
const CACHE_USER_HOME_DATA = "CACHE_USER_HOME_DATA";

const SET_HOUSE_VIDEO_LINK = "SET_HOUSE_VIDEO_LINK";

const SET_HOUSE_VR_TOUR_LINK = "SET_HOUSE_VR_TOUR_LINK";

const SET_HOUSE_CONDITIONAL = "SET_HOUSE_CONDITIONAL";

export default {
  SET_VISITED,

  REMOVE_SELECTED_GRID_ITEM,
  ADD_SELECTED_GRID_ITEM,
  CHANGE_SELECTED_GRID_ITEM_SEQUENCE,
  SET_SELECTED_GRID_ITEM_SEQUENCE,
  CHANGE_GRID_ITEM_AVAILABILITY,
  UPDATE_GRID_ITEM,
  SET_BUILDER_PAGE_ERRORS,
  SET_CURRENT_BUILDER,
  CLEAR_CURRENT_BUILDER,
  SET_CALENDAR_VALUES,
  SET_FORM_VALUES,
  REMOVE_ALL_GRID_ITEMS,
  UPDATE_HOUSE_PHOTO,
  SWITCH_GRID_ITEM_AVAILABILITY,
  CACHE_USER_HOME_DATA,
  SET_HOUSE_VIDEO_LINK,
  SET_HOUSE_VR_TOUR_LINK,
  SET_HOUSE_CONDITIONAL
};
