import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import makeAsyncScriptLoader from "react-async-script";
import { withRouter } from "react-router-dom";
import _get from "lodash.get";

import { builderOperations } from "./duck";

import BuilderSteps from "./BuilderSteps";
import PropertyInfo from "./pages/PropertyInfo/PropertyInfo";
import BuilderHelpPrompt from "./BuilderHelpPrompt";
import BuilderErrorPrompt from "./BuilderErrorPrompt";
import ButtonMain from "../common/ButtonMain";

import Play from "./pages/Play/Play";
import Shop from "./pages/Shop/Shop";
import Dine from "./pages/Dine/Dine";
import Spa from "./pages/Spa/Spa";
import Music from "./pages/Music/Music";
import TvOffice from "./pages/TvOffice/TvOffice.js";
import Emergency from "./pages/Emergency/Emergency.js";
import HouseFeatures from "./pages/HouseFeatures/HouseFeatures";
import Image from "./Image";
import GuestServicesNew from "./pages/GuestServices/GuestServicesNew";

import HouseFeaturesIcon from "../../assets/icons/HouseFeaturesIcon.svg";
import SuggestionButton from "../common/SuggestionButton";
import {
  SuggestionContext,
  withSuggestionContext
} from "../common/SuggestionProvider";
import POISuggestPrompt from "./POISuggestPrompt";
import parseQuery from "hc-utils/parseQuery";
import { toastOperations } from "../../duck/toast";
import HouseTour from "./pages/HouseTour/HouseTour";
import { withBannerContext } from "../common/BannerProvider";

class BuilderController extends React.PureComponent {
  constructor(props) {
    super(props);
    this.component = React.createRef();
    this.state = {
      showHelpPrompt: false,
      showReqPrompt: false,
      showReqPromptLink: null
    };
  }

  componentDidMount() {
    const { showBanner, bannerCleared } = this.props;
    if (!bannerCleared) {
      showBanner();
    }
  }

  fireComponentOnNav = (_, link) => {
    const { history, currentBuilderId } = this.props;

    if (!this.component.current) {
      history.push(link(currentBuilderId));
      return;
    }

    if (!this.component.current.onNav) {
      history.push(link(currentBuilderId));
      return;
    }

    this.component.current.onNav(link);
  };

  fireDoneOnNav = (_, link) => {
    const { history, currentBuilderId, errorCount } = this.props;

    if (errorCount > 0) {
      this.setState({
        showReqPrompt: true,
        showReqPromptLink: link(currentBuilderId)
      });
      return;
    }
    if (!this.component.current) {
      history.push(link(currentBuilderId));
      return;
    }

    if (!this.component.current.onNav) {
      history.push(link(currentBuilderId));
      return;
    }

    this.component.current.onNav(link);
  };
  componentDidUpdate(prevProps) {
    const { updateHousePublishStatus } = this.props;
    if (prevProps.errorCount !== this.props.errorCount) {
      if (this.props.errorCount === 0) {
        if (prevProps.errorCount > 0) {
          updateHousePublishStatus(this.props.currentBuilderId, 1);
        }
      } else {
        if (prevProps.errorCount === 0) {
          updateHousePublishStatus(this.props.currentBuilderId, 0);
        }
      }
    }
  }
  fireComponentOnSuggest = () => {
    if (!this.component.current) return;
    if (this.component.current.onSuggestPrompt) {
      this.component.current.onSuggestPrompt();
    }
  };

  computeBuilderPage(pageKey, currentBuilder) {
    const { currentPageHash, location } = this.props;
    const { errors, touched, values, visited } = currentBuilder[
      Object.keys(currentBuilder).find(
        pageHash => currentBuilder[pageHash].key === pageKey
      )
    ];

    const builderPageProps = {
      pageKey,
      errors,
      touched,
      values,
      location,
      pageVisited: visited,
      currentPageHash,
      history: this.props.history,
      ref: this.component
    };

    switch (pageKey) {
      case "propertyInfo": {
        return (
          <PropertyInfo
            pageKey={pageKey}
            errors={errors}
            touched={touched}
            values={values}
            ref={this.component}
            {...builderPageProps}
          />
        );
      }
      case "houseTour": {
        return <HouseTour {...builderPageProps} />;
      }
      case "guestServices": {
        return <GuestServicesNew {...builderPageProps} />;
      }
      case "play": {
        return <Play {...builderPageProps} />;
      }
      case "shop": {
        return <Shop {...builderPageProps} />;
      }

      case "dine": {
        return <Dine {...builderPageProps} />;
      }
      case "spa": {
        return <Spa {...builderPageProps} />;
      }
      case "music": {
        return <Music {...builderPageProps} />;
      }
      case "tvOffice": {
        return <TvOffice {...builderPageProps} />;
      }
      case "emergency": {
        return <Emergency {...builderPageProps} />;
      }
      case "houseFeatures": {
        return <HouseFeatures {...builderPageProps} />;
      }
      default: {
        return <p>DEFAULT</p>;
      }
    }
  }

  showHelpPrompt = () => {
    this.setState({
      showHelpPrompt: true
    });
  };
  onSuggestSubmit = (feature_id, values, actions) => {
    const { addToast, createUnapprovedPOI } = this.props;

    const { closeSuggestionPrompt } = this.props.suggestionContext;
    // since there is no edit functionality on POI, no need to handle updateGridItem

    // Here needs to handle sending call to suggest POI

    createUnapprovedPOI({ ...values, featureId: feature_id })
      .then(() => {
        actions.setSubmitting(false);
        addToast({
          text:
            "Thank you for sugggesting a new point of interest. We will review your submission and will be available once approved."
        });

        closeSuggestionPrompt();
      })
      .catch(error => {
        window.console.error(error);
        actions.setSubmitting(false);
        addToast({
          text: error.message,
          intent: "error"
        });
      });
  };

  render() {
    const {
      builderPageKey,
      builderPages,
      children,
      currentBuilder,
      currentErrors,
      currentVisited,
      isNew,
      homeId,
      builderControllerLoading,
      currentBuilderErrors,
      currentBuilderPageImage,
      location
    } = this.props;

    const currentBuilderPage = Object.keys(builderPages).find(pageHash => {
      return builderPages[pageHash].key === builderPageKey;
    });

    if (!currentBuilderPage) return null;

    const query = parseQuery(location.search);

    const { showHelpPrompt, showReqPrompt, showReqPromptLink } = this.state;

    const handleErrorPromptFinish = () => {
      this.component.current.onNav(builderId => "/user/my-homes");
    };

    return (
      <div className="builder-controller" data-testid="builder-controller">
        <div className="builder-controller__inner">
          <div className="builder-controller__steps">
            <BuilderSteps
              isNew={isNew}
              homeId={homeId}
              pages={builderPages}
              pageKey={builderPageKey}
              onNav={this.fireComponentOnNav}
            />
          </div>

          <div className="builder-controller__header">
            <div className="builder-controller__left">
              <div className="builder-controller__icon">
                <Image
                  src={
                    currentBuilderPageImage
                      ? currentBuilderPageImage
                      : HouseFeaturesIcon
                  }
                  alt={"test"}
                />
              </div>
              <div className="builder-controller__title">
                <h2>{builderPages[currentBuilderPage].name}</h2>
              </div>
            </div>

            <div className="builder-controller__actions">
              {this.props.match.params.builderPage &&
                this.props.match.params.builderPage.match(
                  /^(play|shop|dine|spa|guestServices)$/
                ) &&
                !_get(query, "category", "").match(
                  /^(hosts|maintanenceCalendar)$/
                ) && <SuggestionButton />}
              <ButtonMain color="gray" onClick={this.showHelpPrompt}>
                Need Help?
              </ButtonMain>
              <ButtonMain
                color="aqua"
                onClick={this.fireDoneOnNav}
                loading={builderControllerLoading}
                link={() => "/user/my-homes"}
                type={"link"}
              >
                Done
              </ButtonMain>
            </div>
          </div>
          {Object.entries(currentErrors).length > 0 && currentVisited && (
            <ul className="builder-controller__errors">
              {Object.keys(currentErrors).map(errorKey => (
                <li key={errorKey}>{currentErrors[errorKey]}</li>
              ))}
            </ul>
          )}
          <div className="builder-controller__content">
            {children({
              component: this.computeBuilderPage(builderPageKey, currentBuilder)
            })}
          </div>
          <div className="upload-wrapper-portal" />
          {showHelpPrompt && (
            <BuilderHelpPrompt
              onClose={() => this.setState({ showHelpPrompt: false })}
              builderPageKey={builderPageKey}
            />
          )}
          {showReqPrompt && (
            <BuilderErrorPrompt
              onClose={() => this.setState({ showReqPrompt: false })}
              errors={currentBuilderErrors}
              onFinish={handleErrorPromptFinish}
              link={showReqPromptLink}
            />
          )}
          <SuggestionContext.Consumer>
            {({ closeSuggestionPrompt, suggestionPrompt }) => (
              <>
                {suggestionPrompt && (
                  <POISuggestPrompt
                    onSubmit={this.onSuggestSubmit}
                    onClose={() => closeSuggestionPrompt()}
                  />
                )}
              </>
            )}
          </SuggestionContext.Consumer>
        </div>
      </div>
    );
  }
}

BuilderController.propTypes = {
  builderPageKey: PropTypes.string,
  builderPages: PropTypes.object,
  children: PropTypes.any,
  currentBuilder: PropTypes.object,
  currentErrors: PropTypes.object,
  currentVisited: PropTypes.bool,
  isNew: PropTypes.bool,
  homeId: PropTypes.number,
  builderControllerLoading: PropTypes.bool,
  currentBuilderErrors: PropTypes.object
};

const mapState = (state, ownProps) => ({
  currentPageHash: Object.keys(state.builder.currentBuilder).find(
    pageHash =>
      state.builder.currentBuilder[pageHash].key === ownProps.builderPageKey
  ),
  currentBuilderId: state.builder.currentBuilderId,
  currentErrors: state.builder.currentBuilder[
    Object.keys(state.builder.currentBuilder).find(
      pageHash =>
        state.builder.currentBuilder[pageHash].key === ownProps.builderPageKey
    )
  ]
    ? state.builder.currentBuilder[
        Object.keys(state.builder.currentBuilder).find(
          pageHash =>
            state.builder.currentBuilder[pageHash].key ===
            ownProps.builderPageKey
        )
      ].errors
    : null,
  currentVisited: state.builder.currentBuilder[
    Object.keys(state.builder.currentBuilder).find(
      pageHash =>
        state.builder.currentBuilder[pageHash].key === ownProps.builderPageKey
    )
  ]
    ? state.builder.currentBuilder[
        Object.keys(state.builder.currentBuilder).find(
          pageHash =>
            state.builder.currentBuilder[pageHash].key ===
            ownProps.builderPageKey
        )
      ].visited
    : null,
  currentBuilderErrors: state.builder.currentBuilderErrors,

  builderControllerLoading:
    _get(state.loading, "updateHouse.loading", false) ||
    _get(state.loading, "createHouse.loading", false),
  errorCount: state.builder.currentBuilderErrors
    ? Object.keys(state.builder.currentBuilderErrors).reduce(
        (acc, curr) =>
          acc + Object.keys(state.builder.currentBuilderErrors[curr]).length,

        0
      )
    : null,

  isPublished: state.builder.isPublished,

  currentBuilderPageImage: state.builder.currentBuilder
    ? _get(
        state.builder.currentBuilder[
          Object.keys(state.builder.currentBuilder).find(
            pageHash =>
              state.builder.currentBuilder[pageHash].key ===
              ownProps.builderPageKey
          )
        ],
        "image",
        null
      )
    : null
});

const mapDispatch = dispatch => ({
  updateHousePublishStatus: (houseId, publishStatus) =>
    dispatch(
      builderOperations.updateHousePublishStatus(houseId, publishStatus)
    ),
  createUnapprovedPOI: data =>
    dispatch(builderOperations.createUnapprovedPOI(data)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

const URL = `https://maps.google.com/maps/api/js?units=imperial&key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=distancematrix,directions,places`;

const AsyncBuilderController = makeAsyncScriptLoader(URL)(BuilderController);

export default withBannerContext(
  withSuggestionContext(
    withRouter(connect(mapState, mapDispatch)(AsyncBuilderController))
  )
);
