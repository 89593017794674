import React, { useState } from "react";
import PropTypes from "prop-types";

import GridHeader from "./GridHeader";
import GridLayout from "./GridLayout";
import GridItem from "./GridItem";
import BlankGridItem from "./BlankGridItem";

import UserPlaceholderIcon from "../../assets/icons/UserPlaceholder.jpeg";

import { escapeRegExp } from "hc-utils";

const uuidv4 = require("uuid/v4");

function DragAndDropIndicator() {
  return (
    <div className="dnd-indicator">
      <div className="dnd-indicator__arrow" />
      <div className="dnd-indicator__text">Drag & Drop</div>
    </div>
  );
}

const POIGridItem = props => {
  if (!props.id)
    return (
      <BlankGridItem {...props} key={props.gridIndex} banner={props.banner} />
    );
  return (
    <GridItem
      {...props}
      key={`${props.title}-${props.id}`}
      photoData={props.image ? props.image : UserPlaceholderIcon}
      overlayItems={{
        available: ["view", "add"],
        selected: ["view", "remove"]
      }}
      onDragStop={props.onDragStop}
    />
  );
};

function POIAvailableGrid({ items, onDragStop, onView, onAdd }) {
  const [searchQuery, setSearchQuery] = useState("");

  let filteredItems = (items, query) => {
    return items.filter(
      item =>
        `${item.name}`.toLowerCase().search(escapeRegExp(query.toLowerCase())) >
        -1
    );
  };

  return (
    <div
      className="poi-grid-items poi-grid-items--available"
      data-testid="poi-available-grid"
    >
      <GridHeader color="gray">Mix & Match From These Favorites</GridHeader>
      <div className="poi-grid-items__search">
        <DragAndDropIndicator />

        <input
          type="text"
          name="poi-search"
          className="poi-search"
          onChange={({ currentTarget }) => setSearchQuery(currentTarget.value)}
          value={searchQuery}
          placeholder={"Search for location"}
        />
      </div>

      <GridLayout
        rows={3}
        compact={true}
        columns={4}
        withAdd={false}
        items={items}
      >
        {({ gridArray }) =>
          filteredItems(gridArray, searchQuery).length > 0 ? (
            <div className="poi-grid-items__grid">
              {filteredItems(gridArray, searchQuery).map((item, i) => {
                return (
                  <React.Fragment key={item.id ? `${item.id}` : uuidv4()}>
                    <POIGridItem
                      image={
                        item.image
                          ? item.image.length > 0
                            ? item.image.find(({ size }) => size === "thumb")
                                .url
                            : null
                          : null
                      }
                      id={item.id}
                      sequence={item.sequence}
                      title={item.name}
                      gridIndex={i}
                      type="available"
                      currentPageHash={6}
                      onDragStop={onDragStop}
                      columns={4}
                      onView={onView}
                      onAdd={onAdd}
                      disableAvailableDrop={true}
                      scrollContainer={`.poi-grid-items__grid`}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div className="poi-grid-items__empty">
              No locations found for "{searchQuery}"
            </div>
          )
        }
      </GridLayout>
    </div>
  );
}

POIAvailableGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        fullAddress: PropTypes.string
      }),
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          size: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired
        })
      ),
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sequence: PropTypes.number.isRequired
    })
  ),
  onAdd: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
};

export default POIAvailableGrid;
