import React, { useEffect } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Yup } from "hc-utils/FormValidator";

import AccountPage from "../AccountPage";
import InputText from "hc-common/InputText";
import _get from "lodash.get";
import ButtonMain from "../../common/ButtonMain";
import { accountOperations } from "../duck";
import { toastOperations } from "../../../duck/toast";

const AccountPageEmailFormSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email."),
  newEmail: Yup.string()
    .email("Invalid email.")
    .required("New email is required.")
    .test(
      "email-different",
      "New email cant be same as current email.",
      function (value) {
        return this.parent.email !== value;
      }
    )
});

let AccountPageEmailForm = ({
  data,
  isReadOnly,
  isUpdating,
  rootFormikProps
}) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched
  } = rootFormikProps;

  useEffect(() => {
    setFieldValue("email", _get(data, "email", ""));
    setFieldValue("newEmail", "");
    setFieldTouched("newEmail", false);
  }, [data, setFieldTouched, setFieldValue]);

  return (
    <form
      name="email-form"
      className={"email-form"}
      onSubmit={handleSubmit}
      data-testid="email-form"
    >
      <InputText
        name="email"
        type="text"
        size="full"
        required={true}
        placeholder="Email Address"
        autoComplete="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={true}
        displayError={errors.email && touched.email ? errors.email : false}
      />
      <InputText
        name="newEmail"
        type="text"
        size="full"
        required={true}
        placeholder="New Email Address"
        autoComplete="email"
        value={values.newEmail}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isReadOnly}
        displayError={
          errors.newEmail && touched.newEmail ? errors.newEmail : false
        }
      />

      <ButtonMain
        color="aqua"
        type="submit"
        isDisabled={!values.newEmail}
        loading={isUpdating}
      >
        Change Email
      </ButtonMain>
    </form>
  );
};

function AccountPageEmail({ data, isUpdating, addToast, changeAccountEmail }) {
  const handleEmailSubmit = values => {
    changeAccountEmail(values.newEmail)
      .then(newEmail => {
        addToast({
          text: `Successfully changed email address to "${newEmail}"`
        });
      })
      .catch(error => {
        addToast({ text: error.message, intent: "error" });
      });
  };

  return (
    <AccountPage page="profile">
      <div className="account-page__header">
        <h3>Change Email</h3>
      </div>
      <div className={`account-page__content `}>
        <Formik
          initialValues={{
            email: _get(data, "email", ""),
            newEmail: ""
          }}
          validationSchema={AccountPageEmailFormSchema}
          onSubmit={values => handleEmailSubmit(values)}
        >
          {rootFormikProps => (
            <AccountPageEmailForm
              isReadOnly={false}
              isUpdating={isUpdating}
              data={data}
              rootFormikProps={rootFormikProps}
            />
          )}
        </Formik>
      </div>
    </AccountPage>
  );
}
const mapState = state => ({
  isUpdating: _get(state.loading, "changeAccountEmail.loading", false)
});
const mapDispatch = dispatch => ({
  changeAccountEmail: newEmail =>
    dispatch(accountOperations.changeAccountEmail(newEmail)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

export default connect(mapState, mapDispatch)(AccountPageEmail);
