import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import _get from "lodash.get";
import { builderOperations } from "../../duck";
import HostsSelectedGrid from "./HostsSelectedGrid";
import HostsAvailableGrid from "./HostsAvailableGrid";
import { toastOperations } from "../../../../duck/toast";
import HostPrompt from "./HostPrompt";
import WarningPrompt from "../../../common/WarningPrompt";

function GuestServicesHosts({ selected, available }) {
  const dispatch = useDispatch();
  const [viewPrompt, setViewPrompt] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [queuedDeleteData, setQueuedDeleteData] = useState();
  const [queuedData, setQueuedData] = useState();

  const hosts = useSelector(state =>
    _get(state.builder, "currentBuilder[3].values.hosts", null)
  );

  const currentPageHash = useSelector(state =>
    Object.keys(state.builder.currentBuilder).find(
      pageHash => state.builder.currentBuilder[pageHash].key === "guestServices"
    )
  );

  const currentBuilderId = useSelector(state =>
    _get(state, "builder.currentBuilderId", null)
  );

  // on toggle availability of hos
  const onToggleAvailability = ({ title, id, type }) => {
    const newAvailability = type === "selected" ? "available" : "selected";

    if (newAvailability === "selected" && type === "available") {
      if (hosts.selected.length >= 3) {
        dispatch(
          toastOperations.addToast({
            text:
              "All hosts are already set. Please remove a host from selected grid to add a new one.",
            intent: "warning"
          })
        );
        return;
      }
    }

    dispatch(
      builderOperations.changeGridItemAvailabilityAndSequence(
        type,
        newAvailability,
        currentPageHash,
        "hosts",
        id,
        true
      )
    );
  };

  // on host view
  const onView = availabilityType => ({ title, id, type }) => {
    const item = hosts[availabilityType].find(poi => poi.id === id);
    setViewPrompt(true);
    setQueuedData({ ...item, availabilityType });
  };

  // on edit submit
  const handleHostPromptSubmit = values => {

    const hostsAvailableItemsLength = hosts.available.length;

    let hostData = new FormData();

    hostData.set("houseId", currentBuilderId);

    hostData.set("phoneNumber", values.hostPhone);
	hostData.set("phoneNumberText", values.hostPhoneText);
    hostData.set("email", values.hostEmail);
    hostData.set("website", values.hostWebsite);
    hostData.set("firstName", values.hostFirstName);
    hostData.set("lastName", values.hostLastName);
    hostData.set("description", values.hostDescription);
    // if values.image is set, that means a new file was given. Set in host data for "image"
    // otherwise, dont set it to avoid unnecessary reprocessing/saving of iamge
    if (values.image) {
      hostData.set("image", values.image);
    }
    if (queuedData) {
      // if the guest is not new(updating), set the hosts ID and their image if different
      hostData.set("hostId", queuedData.id);

      //need to set the sequence as the same as the queuedHostData sequence value since it doesnt change between prompt open and submit
      hostData.set("sequenceNo", queuedData.sequence);

      dispatch(
        builderOperations.updateHost(
          currentPageHash,
          "hosts",
          queuedData.availabilityType,
          hostData,
          queuedData
        )
      )
        .then(() => {
          dispatch(
            toastOperations.addToast({ text: "Succesfully updated host." })
          );
          setViewPrompt(false);
        })
        .catch(error => {
          toastOperations.addToast({ text: error.message, intent: "error" });
        });
    } else {
      // since their is not queued host data for a new host, add them at the very last sequence for existing hosts
      hostData.set("sequenceNo", hostsAvailableItemsLength + 1);
      // create the host
      dispatch(
        builderOperations.createHost(
          currentPageHash,
          "hosts",
          "available",
          hostData
        )
      )
        .then(host => {
          dispatch(
            toastOperations.addToast({ text: "Succesfully added host." })
          );
          setViewPrompt(false);
          setQueuedData(null);
        })
        .catch(error => {
          dispatch(
            toastOperations.addToast({ text: error.message, intent: "error" })
          );
        });
    }
  };
  // on drag stop
  const onDragStop = (
    id,
    originalAvailability,
    newAvailability,
    originalIndex,
    newIndex
  ) => {
    if (newAvailability === "selected") {
      if (hosts.selected.length >= 3) {
        dispatch(
          toastOperations.addToast({
            text:
              "All hosts are already set. Please remove a host from selected grid to add a new one.",
            intent: "warning"
          })
        );
        return;
      }

      if (hosts.selected.length >= 12) {
        dispatch(
          builderOperations.updateSwitchGridItemAvailability(
            originalAvailability,
            newAvailability,
            currentPageHash,
            hosts.key,
            id,
            true,
            newIndex,
            originalIndex
          )
        );

        dispatch(
          builderOperations.switchGridItemAvailability(
            originalAvailability,
            newAvailability,
            currentPageHash,
            hosts.key,
            id,
            true,
            newIndex,
            originalIndex
          )
        );

        return;
      }
    }

    if (originalAvailability !== newAvailability) {
      dispatch(
        builderOperations.changeGridItemAvailabilityAndSequence(
          originalAvailability,
          newAvailability,
          currentPageHash,
          hosts.key,
          id,
          true,
          newIndex
        )
      );
    } else {
      if (newIndex !== originalIndex) {
        dispatch(
          builderOperations.changeSelectedGridItemSequences(
            parseInt(currentPageHash),
            hosts.key,
            originalAvailability,
            id,
            newIndex,
            originalAvailability === "selected" ? false : true
          )
        );
      }
    }
  };

  const handleDelete = ({ title, id, gridIndex, type, currentCategory }) => {
    dispatch(builderOperations.deleteHost(currentPageHash, "hosts", type, id))
      .then(() => {
        dispatch(
          toastOperations.addToast({ text: "Succesfully removed host." })
        );
      })
      .catch(error => {
        dispatch(
          toastOperations.addToast({
            text: error.message,
            intent: "error"
          })
        );
      });
  };

  const handleDeleteClick = ({ title, id, gridIndex, type }) => {
    const hostObj = hosts[type].find(host => host.id === id);

    setQueuedDeleteData({
      title,
      id,
      gridIndex,
      type,
      currentCategory: "hosts",
      first_name: hostObj.first_name,
      last_name: hostObj.last_name
    });
    setDeletePrompt(true);
  };

  return (
    <>
      <div className="guest-services-hosts-grid">
        <div className="guest-services-hosts-grid__inner">
          <div className="guest-services-hosts-grid__content">
            <HostsSelectedGrid
              items={selected}
              currentPageHash={currentPageHash}
              onDragStop={onDragStop}
              onView={onView("selected")}
              onRemove={onToggleAvailability}
            />
            <HostsAvailableGrid
              items={available}
              currentPageHash={currentPageHash}
              onDragStop={onDragStop}
              onView={onView("available")}
              onAdd={onToggleAvailability}
              onDelete={handleDeleteClick}
              onNew={() => {
                setViewPrompt(true);
                setQueuedData(null);
              }}
            />
          </div>
        </div>
      </div>
      {viewPrompt && (
        <HostPrompt
          data={queuedData}
          icon={hosts.image}
          image={
            queuedData
              ? queuedData.image
                ? queuedData.image.length > 0
                  ? queuedData.image.find(({ size }) => size === "tablet").url
                  : null
                : null
              : null
          }
          onSubmit={handleHostPromptSubmit}
          onClose={() => setViewPrompt(false)}
        />
      )}
      {deletePrompt && (
        <WarningPrompt
          onClose={() => {
            setDeletePrompt(false);
            setQueuedDeleteData(null);
          }}
          onConfirm={() => {
            setDeletePrompt(false);
            handleDelete(queuedDeleteData);
          }}
          onCancel={() => {
            setDeletePrompt(false);
            setQueuedDeleteData(null);
          }}
        >
          Are you sure you want to remove "{queuedDeleteData.first_name}{" "}
          {queuedDeleteData.last_name}" as a{" "}
          {queuedDeleteData.currentCategory === "hosts" ? "host" : "chef"}?
        </WarningPrompt>
      )}
    </>
  );
}

GuestServicesHosts.propTypes = {
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        fullAddress: PropTypes.string
      }),
      description: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          size: PropTypes.string
        })
      ),
      key: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
	  phoneText: PropTypes.string,
      sequence: PropTypes.number.isRequired,
      website: PropTypes.string
    })
  ),
  available: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        fullAddress: PropTypes.string
      }),
      description: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          size: PropTypes.string
        })
      ),
      key: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
	  phoneText: PropTypes.string,
      sequence: PropTypes.number.isRequired,
      website: PropTypes.string
    })
  )
};

export default GuestServicesHosts;
