import types from "./types";

export const initialGlobalState = {
  loadStatus: {
    login: null,
    cache: true
  },
  userAuth: {
    isLoggedIn: false,
    token: null,
    role: null,
    email: null
  },
  current: {
    page: {},
    home: { name: null }
  }
};

export default (state = initialGlobalState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
    case types.LOGIN_FAILED:
    case types.LOGIN_STARTED: {
      return {
        ...state,
        loadStatus: {
          login: action.login
        }
      };
    }
    case types.SET_USER_CREDENTIALS: {
      return {
        ...state,
        userAuth: {
          isLoggedIn: action.isLoggedIn,
          token: action.token,
          role: action.role,
          email: action.email
        }
      };
    }

    case types.SET_CURRENT_PAGE: {
      return {
        ...state,
        current: {
          ...state.current,
          page: {
            key: action.routeKey,
            name: action.routeName
          }
        }
      };
    }
    case types.SET_LOAD_STATUS: {
      return {
        ...state,
        loadStatus: {
          ...state.loadStatus,
          [action.statusKey]: action.status
        }
      };
    }
    case types.LOGOUT_USER: {
      return initialGlobalState;
    }
    case types.SET_CURRENT_HOME: {
      return {
        ...state,
        current: {
          ...state.current,
          home: {
            name: action.homeName,
            id: action.homeId
          }
        }
      };
    }
    case types.SET_REGIONS_LIST: {
      return {
        ...state,
        regions: action.regions
      };
    }
    default: {
      return state;
    }
  }
};
