import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function Image({ src, alt = "Home Concierge Image", ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const handleLoad = () => setLoaded(true);
  const handleError = (err) => setError(error);

  useEffect(() => {
    setLoaded(false);
    setError(null);
  }, [src]);

  return (
    <div
      className={clsx({
        "hc-image": true,
        "hc-image--loading": loaded === false && !error,
        "hc-image--error": !!error,
      })}
    >
      <img
        src={src}
        alt={alt}
        className={clsx({
          "hc-image__img": true,
        })}
        onLoad={handleLoad}
        onError={handleError}
        {...props}
      />
    </div>
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Image;
