import React from "react";
import { Yup } from "hc-utils/FormValidator";

import _get from "lodash.get";
import PromptWrapper from "../common/PromptWrapper";
import { Formik } from "formik";
import PlanSelector from "../preauth/PlanSelector";
import BillingLedger from "../common/BillingLedger";
import FormNotice from "../common/FormNotice";
import ButtonMain from "../common/ButtonMain";

import { connect } from "react-redux";

const ChoosePlanSchema = Yup.object().shape({
  selectedPlan: Yup.object().test(
    "plan-selected",
    "You must select a subscription plan.",
    value => {
      return !!_get(value, "id", false);
    }
  )
});

function ChoosePlanPrompt({
  onClose,
  currentPlan,
  onSubmit,
  fetchAvailablePlansLoading,
  submitLoading,
  userPlanCycle
}) {
  return (
    <PromptWrapper onClose={onClose} className="choose-plan-prompt-wrapper">
      <div
        className="choose-plan-prompt-wrapper__inner"
        data-testid="choose-plan-prompt"
      >
        <h2>{currentPlan ? "Change Your" : "Choose your"} Subscription Plan</h2>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            selectedPlan: {},
            selectedCycle: userPlanCycle || "monthly"
          }}
          validationSchema={ChoosePlanSchema}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            submitCount,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit} data-testid="choose-plan-prompt-form">
              <PlanSelector
                onPlanChange={plan => {
                  setFieldValue("selectedPlan", plan);
                }}
                onCycleChange={cycle => {
                  setFieldValue("selectedCycle", cycle);
                  setFieldValue("selectedPlan", {});
                }}
                selectedPlan={values.selectedPlan}
                selectedCycle={values.selectedCycle}
                withCycle={true}
              />
              {errors["selectedPlan"] && touched["selectedPlan"] && (
                <p style={{ color: "#e02b2b", margin: "5px 0px 0px" }}>
                  {errors["selectedPlan"]}
                </p>
              )}
              <BillingLedger
                price={_get(values, "selectedPlan.price", null)}
                term={_get(values, "selectedCycle", null)}
                loading={fetchAvailablePlansLoading}
              />
              {_get(values, "selectedPlan.price", false) && (
                <FormNotice>
                  Your subscription will be billed at $
                  {_get(values, "selectedPlan.price", false)
                    ? values.selectedPlan.price
                    : "..."}{" "}
                  every {values.selectedCycle === "monthly" ? "month" : "year"}.
                  <br /> We'll send a reminder email before each shipment so you
                  have enough time to modify or cancel your plan.
                </FormNotice>
              )}

              <ButtonMain
                type="submit"
                loading={submitLoading}
                isDisabled={
                  currentPlan === _get(values.selectedPlan, "id", null)
                }
              >
                {currentPlan ? "Change Plan" : "Confirm Subscription"}
              </ButtonMain>
            </form>
          )}
        </Formik>
      </div>
    </PromptWrapper>
  );
}

const mapState = state => ({
  userPlanCycle: _get(state.account, "accountInfo.plan_id")
    ? state.account.accountInfo.plan_id.slice(-1) === "a"
      ? "annual"
      : "monthly"
    : null
});

export default connect(mapState)(ChoosePlanPrompt);
