import React from "react";
import PropTypes from "prop-types";

function PromptHeader({ icon, children, text }) {
  return (
    <div className="prompt-header">
      <div className="prompt-header__inner">
        <div className="prompt-header__icon">
          <img src={icon} alt={icon} />
        </div>
        <div className="prompt-header__title">{children}</div>
        {text && (
          <div className="prompt-header__addtl">
            <p>{text}</p>
          </div>
        )}
      </div>
    </div>
  );
}

PromptHeader.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
};

export default PromptHeader;
