import React from "react";
import GridHeader from "../../GridHeader";
import GridLayout from "../../GridLayout";
import GridItem from "../../GridItem";
import BlankGridItem from "../../BlankGridItem";
import UserPlaceholderIcon from "../../../../assets/icons/UserPlaceholder.jpeg";

const uuidv4 = require("uuid/v4");

const PlayGridItem = props => {
  if (!props.id)
    return (
      <BlankGridItem key={props.gridIndex} banner={props.banner} {...props} />
    );
  return (
    <GridItem
      {...props}
      key={props.title}
      photoData={props.image ? props.image : UserPlaceholderIcon}
      overlayItems={{
        available: ["view", "add"],
        selected: ["view", "remove"]
      }}
      onDragStop={props.onDragStop}
    />
  );
};

function PlaySelectedGrid({
  items,
  onDragStop,
  onView,
  onRemove,
  currentCategory,
  title = "Use Our Pre-selected Favorites"
}) {
  return (
    <div
      className="play-grid-items play-grid-items--selected"
      data-testid="play-selected-grid"
    >
      <GridHeader color="blue">{title}</GridHeader>

      <GridLayout rows={3} compact={true} columns={4} items={items}>
        {({ gridArray }) => (
          <div className="play-grid-items__grid">
            {gridArray.map((item, i) => (
              <PlayGridItem
                key={item.id ? item.id : uuidv4()}
                image={
                  item.image
                    ? item.image.length > 0
                      ? item.image.find(({ size }) => size === "thumb").url
                      : null
                    : null
                }
                id={item.id}
                sequence={item.sequence}
                title={item.name}
                gridIndex={i}
                type="selected"
                currentPageHash={6}
                onDragStop={onDragStop}
                columns={4}
                onView={onView}
                onRemove={onRemove}
              />
            ))}
          </div>
        )}
      </GridLayout>
    </div>
  );
}

export default PlaySelectedGrid;
