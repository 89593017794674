import React, { Component } from "react";
import PropTypes from "prop-types";
import PhotoCanvas from "hc-utils/PhotoCanvas";
import PhotoCanvasActions from "./PhotoCanvasActions";
import PromptPhotoCanvasLoader from "./PromptPhotoCanvasLoader";

import _get from "lodash.get";

export class PromptPhotoCanvas extends Component {
  constructor(props) {
    super(props);

    this.handleFileSelected = this.handleFileSelected.bind(this);

    this.setNextStep = this.setNextStep.bind(this);

    this.state = {
      currentStep: 0,
      imageObject: null,
      imageLoading: false,
      isLocked: {
        arc: null,
        background: null
      }
    };
  }

  isUrl(str) {
    try {
      new URL(str);
      return true;
    } catch (_) {
      return false;
    }
  }

  componentDidMount() {
    const { image, id } = this.props;

    this.video = document.querySelector("#camera-video");
    this.canvas = document.querySelector(`canvas#image-viewport-${id}`);
    this.canvas.width = 3 * 700;
    this.canvas.height = 3 * (700 * 0.5625);
    const photoCanvas = new PhotoCanvas({ canvas: this.canvas });
    this.setState({ photoCanvas }, () => {
      if (image) {
        const imageIsUrl = this.isUrl(image);

        if (!image.startsWith("blob") && imageIsUrl) {
          this.setState({ imageLoading: true });
          fetch(image)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], "PhotoCanvas Image File");
              this.uploadCanvasImage(window.URL.createObjectURL(file));
              this.props.setCanvas(this.state.photoCanvas);
            })
            .catch(() => {
              alert("couldnt fetch blob");
            });
        } else {
          this.uploadCanvasImage(image);
          this.props.setCanvas(this.state.photoCanvas);
        }
      } else {
        alert("no image");
      }
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      if (!file instanceof Blob) resolve(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  uploadCanvasImage = imageURL => {
    if (!imageURL) return;

    this.state.photoCanvas
      .newImage({
        imageData: imageURL,
        coords: this.props.coords ? this.props.coords.photo : null
      })
      .then(canvasImage => {
        this.setState({ canvasImage, imageLoading: false }, () => {
          this.props.setImageObject(this.state.canvasImage);
          if (this.props.coords) {
            if (this.props.coords.arc) {
              if (
                this.props.coords.arc.x !== null &&
                this.props.coords.arc.y !== "null"
              ) {
                this.setNextStep();
              }
            }
          }
          this.setState({
            isLocked: {
              arc: this.state.photoCanvas.arcLocked,
              background: this.state.photoCanvas.backgroundLocked
            }
          });
          this.state.canvasImage.forceDispatchEvent("change", {
            pos: canvasImage.pos,
            height: canvasImage.height,
            width: canvasImage.width
          });
        });
      })
      .catch(err => {
        window.console.error(err);
      });
  };

  handleFileSelected(evt) {
    const file = document.querySelector("#image-input").files[0];
    if (!file) return;
    this.uploadCanvasImage(window.URL.createObjectURL(file));
  }

  setNextStep() {
    this.state.photoCanvas.lockImage();

    this.state.photoCanvas
      .newArc({
        radius: this.props.coords
          ? this.props.coords.arc
            ? this.props.coords.arc.radius !== "null" &&
              this.props.coords.arc.radius !== null
              ? this.props.coords.arc.radius === "null"
                ? null
                : this.props.coords.arc.radius
              : 250
            : 250
          : 250,
        coords:
          _get(this.props.coords, "arc.x", null) === "null" ||
          _get(this.props.coords, "arc.y", null) === null
            ? null
            : this.props.coords.arc
      })
      .then(canvasArc => {
        this.setState({ canvasArc }, () => {
          this.setState({
            currentStep: 1,
            isLocked: {
              arc: this.state.photoCanvas.arcLocked,
              background: this.state.photoCanvas.backgroundLocked
            }
          });
          this.props.setArcObject(this.state.canvasArc);

          this.state.canvasArc.forceDispatchEvent("change", {
            pos: canvasArc.pos,
            radius: canvasArc.radius
          });
        });
      });
  }
  setBackStep = () => {
    this.state.photoCanvas.unlockImage();
    this.state.photoCanvas.removeItem("arc");
    this.setState(
      {
        currentStep: 0,
        isLocked: {
          arc: this.state.photoCanvas.arcLocked,
          background: this.state.photoCanvas.backgroundLocked
        }
      },
      () => {
        this.props.onRemoveCircle();
      }
    );
  };
  handleZoom = scale => {
    if (this.state.canvasImage) {
      if (this.state.isLocked.arc === false) {
        this.state.canvasArc.manualZoom(scale);
        this.state.photoCanvas.redraw();
      } else {
        this.state.canvasImage.manualZoom(scale);
        this.state.photoCanvas.redraw();
      }
      return;
    }
  };

  onChange = () => {};
  onEditCircle = () => {
    this.state.photoCanvas.lockImage();
    this.setState({
      isLocked: {
        arc: this.state.photoCanvas.arcLocked,
        background: this.state.photoCanvas.backgroundLocked
      }
    });
  };

  onEditImage = () => {
    this.state.photoCanvas.unlockImage();
    this.setState({
      isLocked: {
        arc: this.state.photoCanvas.arcLocked,
        background: this.state.photoCanvas.backgroundLocked
      }
    });
  };
  render() {
    const { item, id, removeImage } = this.props;
    const { currentStep, imageLoading } = this.state;

    return (
      <div className={`camera-main`}>
        <div className="camera-main__inner">
          <canvas
            id={`image-viewport-${id}`}
            className={imageLoading ? "loading" : ""}
            style={{
              background: "white"
            }}
          />

          {imageLoading && <PromptPhotoCanvasLoader />}

          <PhotoCanvasActions
            onChange={this.handleZoom}
            loading={this.state.imageLoading}
            image={
              this.state.isLocked.background
                ? this.state.canvasArc
                : this.state.canvasImage
            }
            // image={this.canvasImage}
            item={item}
            onAddCircle={this.setNextStep}
            onRemoveCircle={this.setBackStep}
            onEditCircle={this.onEditCircle}
            onEditImage={this.onEditImage}
            onRemoveImage={removeImage}
            currentStep={currentStep}
            isLocked={this.state.isLocked}
          />
          {/* <>
            <button onClick={this.setNextStep}>Add Circle ></button>
          </>

          {currentStep === 1 && (
            <button id="picture-btn" onClick={this.takePicture}>
              Finish >
            </button>
          )}

          {currentStep === 2 && (
            <h2>Click and hold to save processed picture</h2>
          )} */}
        </div>
      </div>
    );
  }
}

PromptPhotoCanvas.propTypes = {
  coords: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  image: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object,
  onRemoveCircle: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  setArcObject: PropTypes.func.isRequired,
  setCanvas: PropTypes.func.isRequired,

  setImageObject: PropTypes.func.isRequired
};

export default PromptPhotoCanvas;
