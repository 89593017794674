import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { connect } from "react-redux";
import { globalOperations } from "../../duck/global";
import ButtonMain from "../common/ButtonMain";
import InputText from "../common/InputText";
import {useParams, withRouter, useHistory} from "react-router-dom";
import { toastOperations } from "../../duck/toast";
import { Yup } from "hc-utils/FormValidator";
import ReactGA from "react-ga";

function LoginForm({ attemptLogin, addToast, loginLoading, history }) {
  let routerParams = useParams();

  const fireSubmit = props => {
    return attemptLogin(props)
      .then(({ role, id }) => {
        if (process.env.NODE_ENV === "production") {
          ReactGA.set({ userId: id });
        }
        history.push("/user/my-homes");
        addToast({ text: "Succesfully logged in!", intent: "success" });
      })
      .catch(err => {
        addToast({
          text: err.message,
          intent: "error"
        });
      });
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email.").required("Email is required."),
    password: Yup.string().required("Password is required.")
  });

  useEffect(() => {
	if (routerParams.uid) {
	  fireSubmit({
		email: routerParams.email,
		password: routerParams.uid
	  }).then(() => {
		// refresh
		history.go(0);
	  });
	}
  }, [routerParams.uid]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={LoginSchema}
      onSubmit={fireSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors
      }) => (
        <form
          name="login-form"
          className="user-form user-form--login"
          onSubmit={handleSubmit}
        >
          <InputText
            name="email"
            type="email"
            size="full"
            placeholder="Email"
            autoComplete="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            displayError={errors.email && touched.email ? errors.email : false}
          />
          <InputText
            name="password"
            type="password"
            size="full"
            placeholder="Password"
            autoComplete="current-password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            displayError={
              errors.password && touched.password ? errors.password : false
            }
          />
          <ButtonMain color="aqua" type="submit" loading={loginLoading}>
            Log in
          </ButtonMain>
        </form>
      )}
    </Formik>
  );
}

const mapState = state => ({
  loginLoading: state.loading.login.loading === true
});

const mapDispatch = dispatch => ({
  attemptLogin: userCred => dispatch(globalOperations.attemptLogin(userCred)),
  addToast: options => dispatch(toastOperations.addToast(options))
});

LoginForm.propTypes = {
  attemptLogin: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  loginLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(connect(mapState, mapDispatch)(LoginForm));
