import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PlaySelector from "./PlaySelector";
import { withSuggestionContext } from "../../../common/SuggestionProvider";
export class PlayController extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      available: [],
      selected: [],
      currentCategory: this.props.category,
      categories: null
    };
  }

  componentDidMount() {
    const { currentCategory } = this.props;
    const { play } = this.props;
    this.setGridItems(currentCategory);
    this.computeCategoryObjects(play);

    const { setFeatureId, setFeatureName } = this.props.suggestionContext;
    // const { dine, dineFeatureId } = this.props;
    const categoryValues = play[currentCategory];

    if (!categoryValues) return;

    setFeatureId(categoryValues.feature_id);
    setFeatureName(categoryValues.name);
  }

  componentDidUpdate(prevProps) {
    const { play } = this.props;
    const { currentCategory } = this.props;

    // if the values of play dont match or categories is not yet set
    if (
      JSON.stringify(prevProps.play) !== JSON.stringify(this.props.play) ||
      this.state.categories == null
    ) {
      this.computeCategoryObjects(play);
      this.setGridItems(currentCategory);
    }

    if (this.props.currentCategory !== prevProps.currentCategory) {
      this.computeCategoryObjects(play);
      this.setGridItems(currentCategory);

      // const { setFeatureId, setFeatureName } = this.props.suggestionContext;
      // // const { dine, dineFeatureId } = this.props;
      // const categoryValues = play[currentCategory];

      // setFeatureId(categoryValues.feature_id);
      // setFeatureName(categoryValues.name);
    }
  }
  setGridItems(currentCategory) {
    const { play } = this.props;
    const categoryValues = play[currentCategory];
    if (categoryValues) {
      this.setState({
        available: categoryValues.available,
        selected: categoryValues.selected
      });
    }
  }
  computeCategoryObjects(items) {
    const categories = Object.keys(items).map(itemKey => ({
      key: itemKey,
      image: items[itemKey].image,
      name: items[itemKey].name,
      feature_id: items[itemKey].feature_id,
      isEmpty: items[itemKey].selected.length === 0
    }));
    this.setState({ categories });
  }

  handleCategoryChange = category => {
    const { history, currentBuilderId } = this.props;
    const { setFeatureId, setFeatureName } = this.props.suggestionContext;

    setFeatureId(category.feature_id);
    setFeatureName(category.name);
    history.push({
      pathname: `/home/edit/${currentBuilderId}/play`,
      search: `?category=${category.key}`
    });
  };

  onQueryChange = query => {
    const { play } = this.props;
    const { currentCategory } = this.props;

    if (!query || query.length <= 0) {
      this.setState({ available: play[currentCategory].available });
    }

    this.setState({
      available: play[currentCategory].available.filter(value =>
        value.name.toLowerCase().includes(query)
      )
    });
  };

  render() {
    const { children, currentCategory } = this.props;

    const { available, selected, categories } = this.state;

    return (
      <div className="play-controller" data-testid="play-controller">
        <div className="play-controller__inner">
          <div className="play-controller_selector">
            {categories && (
              <PlaySelector
                categories={categories}
                activeKey={currentCategory}
                onClick={this.handleCategoryChange}
              />
            )}
          </div>
          <div className="play-controller__content">
            {categories &&
              children({
                available,
                selected,
                query: this.state.query
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  play: state.builder.currentBuilder[6].values,
  currentBuilderId: state.builder.currentBuilderId
});

export default withSuggestionContext(
  withRouter(connect(mapState)(PlayController))
);
