import React from "react";
import posed, { PoseGroup } from "react-pose";

import GridHeader from "../../GridHeader";
import BuilderButton from "../../BuilderButton";

const TransWrapper = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    opacity: { duration: 150, ease: "out" }
  }
});

function PlaySelector({ categories, onClick, activeKey }) {
  return (
    <div className="play-grid-selector">
      <div className="play-grid-selector__inner">
        <GridHeader color="blue">Choose a category below</GridHeader>
        <div className="play-grid-selector__items">
          {categories && categories.length > 0 ? (
            <PoseGroup flipMove={false} animateOnMount={true}>
              {categories
                .sort((a, b) =>
                  a.feature_id > b.feature_id
                    ? 1
                    : a.feature_id === b.feature_id
                    ? a.feature_id > b.feature_id
                      ? 1
                      : -1
                    : -1
                )
                .map((category, i) => (
                  <TransWrapper key={category.feature_id}>
                    <BuilderButton
                      key={category.key}
                      valueKey={category.key}
                      onClick={() => onClick(category)}
                      type="svg"
                      icon={{ type: "image", data: category.image }}
                      name={category.name}
                      isEmpty={category.isEmpty}
                      isActive={activeKey === category.key ? true : false}
                    >
                      {category.name}
                    </BuilderButton>
                  </TransWrapper>
                ))}
            </PoseGroup>
          ) : (
            <p>No Play Categories are available for this region.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlaySelector;
