import React from "react";
import PropTypes from "prop-types";
import ButtonMain from "../common/ButtonMain";
import BuilderStepItem from "./BuilderStepItem";

class BuilderSteps extends React.PureComponent {
  render() {
    const { pages, pageKey, isNew, onNav } = this.props;

    const currentBuilderPage = Object.keys(pages).find(
      pageHash => pages[pageHash].key === pageKey
    );

    const prevBuilderPage = pages[parseInt(currentBuilderPage) - 1];

    const nextBuilderPage = pages[parseInt(currentBuilderPage) + 1];

    const nextBuilderPageLink = houseId =>
      nextBuilderPage
        ? `/home/edit/${houseId}/${nextBuilderPage.key}`
        : "/user/my-homes";

    const prevBuilderPageLink = houseId =>
      prevBuilderPage
        ? isNew
          ? `/home/new/${prevBuilderPage.key}`
          : `/home/edit/${houseId}/${prevBuilderPage.key}`
        : "/user/my-homes";
    return (
      <div className="builder-steps">
        <div className="builder-steps__inner">
          <div className="builder-steps__back">
            <ButtonMain onClick={onNav} link={prevBuilderPageLink} color="gray">
              {prevBuilderPage ? "Back" : "Dashboard"}
            </ButtonMain>
          </div>
          <div className="builder-steps__nav">
            <div className="builder-steps__navline" />
            <div className="builder-steps__navitems">
              {Object.keys(pages).map(pageHash => {
                return (
                  <BuilderStepItem
                    key={pages[pageHash].key}
                    name={pages[pageHash].name}
                    pageKey={pages[pageHash].key}
                    link={houseId =>
                      `/home/edit/${houseId}/${pages[pageHash].key}`
                    }
                    pageHash={pageHash}
                    onNav={onNav}
                    errors={pages[pageHash].errors}
                    active={pages[pageHash].key === pageKey}
                    visited={pages[pageHash].visited}
                  />
                );
              })}
            </div>
          </div>
          <div className="builder-steps__next">
            <ButtonMain
              disabled={!nextBuilderPageLink ? true : false}
              onClick={onNav}
              link={nextBuilderPageLink}
              color="blue"
            >
              Next
            </ButtonMain>
          </div>
        </div>
      </div>
    );
  }
}

BuilderSteps.propTypes = {
  pages: PropTypes.object.isRequired,
  pageKey: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  onNav: PropTypes.func.isRequired
};

export default BuilderSteps;
