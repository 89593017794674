import React from "react";
import PropTypes from "prop-types";

function GridHeader({ children, color = "blue" }) {
  return (
    <div className={`grid-header ${color ? `grid-header--${color}` : ""}`}>
      <div className="grid-header__inner">
        <h3>{children}</h3>
      </div>
    </div>
  );
}

GridHeader.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string
};

export default GridHeader;
